<template>
  <svg width="26" height="26" id="file" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 4.45894V21.57C1 23.4638 2.73564 25 4.86661 25H21.1334C23.274 25 25 23.4638 25 21.57V7.80193C25 6.89372 24.595 6.02415 23.8622 5.37681L20.0534 2.00483C19.3206 1.35749 18.3371 1 17.3053 1L4.85697 1.02899C2.72599 1.03865 1 2.56522 1 4.45894Z" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.29932 1.47363V9.71518" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.30904 11.918C8.02941 11.918 6.90125 13.6185 6.90125 14.6909C6.90125 15.5218 7.48943 16.2948 8.30904 16.2948C9.254 16.2948 9.68791 15.4542 9.68791 14.6716C9.69755 13.6957 8.58867 11.918 8.30904 11.918Z" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 32},
    height: {type: [Number, String], default: 26},
    viewBox: {type: String, default: '0 0 32 26'},
    fill: {type: String, default: '#151515'}
  }
}
</script>
