<template>
  <v-main class="signup">
    <v-container style="max-width: 1100px">
      <v-row justify="center">
        <v-col lg="6" md="7" sm="7" cols="10">
          <div>
            <h1 class="title-h3 text-center mb-2">
              {{ $t('signupComplete.tell-us-about-yourself') }}
            </h1>
            <SignupCompleteForm />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import SignupCompleteForm from '@/components/signup/SignupCompleteForm.vue'

export default {
  name: 'SignupComplete',
  components: { SignupCompleteForm },
  created() {
    document.title = this.$t('signupComplete.registration')
  },
}
</script>
