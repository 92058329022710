<template>
  <v-card :class="[isStructure ? 'is-structure' : '']">
    <v-card-title class="mb-4 justify-center">
      {{ $t(questionCode) }}
    </v-card-title>
    <v-card-actions
      :class="[showCancel ? 'justify-space-between' : 'justify-center']">
      <v-btn
        color="primary px-8"
        x-large
        elevation="0"
        @click="agree"
        :ripple="false">
        {{ $t(agreeCode) }}
      </v-btn>
      <v-btn
        v-if="showCancel"
        class="px-8 black-hover"
        outlined
        x-large
        elevation="0"
        @click="cancel"
        :ripple="false">
        {{ $t(cancelCode) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PromiseDialogFrame',
  props: {
    isStructure: {
      type: Boolean,
      default: false,
    },
    questionCode: {
      type: String,
      default: 'confirm-save',
    },
    cancelCode: {
      type: String,
      default: 'not-save',
    },
    agreeCode: {
      type: String,
      default: 'save',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  inject: ['agree', 'cancel'],
  methods: {},
}
</script>

<style lang="scss">
.is-structure {
  .v-card__title {
    justify-content: flex-start !important;
  }

  .v-card__actions {
    justify-content: flex-start !important;
    flex-direction: row-reverse;
    gap: 16px;
  }
}
</style>
<i18n>
{
  "ru": {
    "tablePageName": "Письма",
    "confirm-save": "Сохранить изменения?",
    "save": "Сохранить",
    "create-template": "Шаблон успешно создан",
    "go-to-template": "Перейти к шаблону",
    "not-save": "Не сохранять",
    "email-not-free": "Превышен лимит писем. За это письмо будет взята оплата согласно тарифу",
    "agree": "Я согласен",
    "cancel": "Отмена",
    "not-enough-money": "Недостаточно средств на счете. Перейти к пополнению баланса на недостающую сумму?",
    "yes": "Да",
    "users-limit": "Вы достигли максимального количества пользователей. Хотите сменить тариф?",
    "structure-delete": "Вы уверены, что хотите удалить блок?",
    "structure-confirm": "Удалить",
    "structure-cancel": "Отменить",
    "delete-letters": "Вы точно хотите удалить выделенные письма?",
    "delete-templates": "Вы точно хотите удалить выделенные шаблоны?",
    "save-project": "Обновить настройки проекта?"
  },
  "en": {
    "tablePageName": "Emails",
    "confirm-save": "Save changes?",
    "create-template": "Template created successfully",
    "go-to-template": "Go to template",
    "save": "Save",
    "not-save": "Do not save",
    "email-not-free": "Emails limit exceeded. This email will be paid for according to the tariff",
    "agree": "I agree",
    "cancel": "Cancel",
    "not-enough-money": "Balance is too low. Top up balance by needed sum?",
    "yes": "Yes",
    "users-limit": "You have reached users limit for this project. Do you want to change plan?",
    "delete-letters": "Are you sure about deleting those emails?",
    "delete-templates": "Are you sure about deleting those templates?",
    "save-project": "Do you want to update project data?"
  }
}
</i18n>
