<template>
  <v-card class="folder-move">
    <v-card-title class="text-h5 justify-space-between">
      {{ $t('documentFolderMove.move-to-folder') }}
      <v-btn depressed :ripple="false" icon class="" @click="$emit('close')">
        <IconClose />
      </v-btn>
    </v-card-title>

    <v-card-text class="justify-center">
      <v-select
        v-show="folders.length"
        outlined
        v-model="currentFolder"
        :items="folders"
        item-value="id"
        item-text="name"
        return-object />
      <!-- [Переписать] -->
      <v-text-field
        v-show="createFolder"
        v-model="folderName"
        :error="$v.folderName.$error"
        outlined />
      <template v-if="$v.folderName.$error">
        <ValidateErrorHint :display="!$v.folderName.required" />
      </template>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn
        depressed
        outlined
        x-large
        class="mr-3 px-8 black-hover"
        :ripple="false"
        @click="$emit('close')">
        {{ $t('documentFolderMove.cancel') }}
      </v-btn>
      <v-btn
        depressed
        color="primary px-8"
        x-large
        :ripple="false"
        @click="accept">
        {{ $t('documentFolderMove.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable */
import { required } from 'vuelidate/lib/validators'
import ValidateErrorHint from '@/components/tools/ValidateErrorHint'

import IconClose from '@/components/icons/IconClose.vue'

import { displayNotify } from '@/utilities/helpers'

import project from '@/mixins/project.mixin.js'

export default {
  name: 'DocumentFolderMove',
  components: { ValidateErrorHint, IconClose },
  mixins: [project],
  props: {
    itemsToMoveIds: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      createFolder: false,
      currentFolder: {},
      folderName: '',
    }
  },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    project() {
      return this.$store.state.project.full
    },
    folders() {
      if (this.pageType === 'letter') return this.project.lettersFolders
      if (this.pageType === 'letter-custom') return this.project.customFolders
      if (this.pageType === 'template') return this.project.templatesFolders
      return []
    },
  },
  watch: {
    pageType() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    // [Переписать] этого метода не должно существовать, после того как все управление папками перейдет в стор
    init() {
      if (!this.folders.length) {
        this.createFolder = true
      } else {
        this.currentFolder = this.folders[0]
      }
    },
    async accept() {
      let folderId = false

      if (this.createFolder) {
        this.$v.$touch()
        if (this.$v.$invalid) return false

        folderId = await this.$store.dispatch('foldersStore/createFolder', {
          name: this.folderName,
          type: this.pageType == 'letter-custom' ? 'custom' : this.pageType,
        })
        if (!folderId) return
        this.createFolder = false
      } else {
        folderId = this.currentFolder.id
      }

      try {
        const result = await this.$store.dispatch('foldersStore/updateFolder', {
          id: folderId,
          items: this.itemsToMoveIds,
          type: this.pageType == 'letter-custom' ? 'custom' : this.pageType,
        })
        if (result) {
          await this.loadProject()
        } else {
          throw new Error('any error')
        }
      } catch (e) {
        displayNotify(this.$t('documentFolderMove.items-not-added'), 'error')
      } finally {
        this.$emit('close')
      }
    },
  },
  validations: {
    folderName: {
      required,
    },
  },
}
</script>
