<template>
  <v-app>
    <HeaderAnalytics
      class="header"
      :sidebar-show="sidebarShow"
      @toggle-sidebar="sidebarShow = !sidebarShow">
      <template #project-menu>
        <HeaderMenuProject />
      </template>
      <template #user-menu>
        <HeaderMenuUser />
      </template>
    </HeaderAnalytics>

    <SidebarMain
      name="sidebar"
      class="sidebar"
      :sidebar-show="sidebarShow"
      @create-letter="createLetter"
      @create-new-letter="createNewLetter" />

    <LetterAnalyticsMap />
    <PromiseDialogWrapper ref="paymentForExtraLetter">
      <PromiseDialogFrame
        question-code="email-not-free"
        agree-code="agree"
        cancel-code="cancel"
      />
    </PromiseDialogWrapper>
  </v-app>
</template>

<script>
import HeaderAnalytics from '@/components/header/HeaderAnalytics'
import HeaderMenuProject from '@/components/header/HeaderMenuProject'
import HeaderMenuUser from '@/components/header/HeaderMenuUser'
import LetterAnalyticsMap from '@/views/letter/LetterAnalyticsMap'
import SidebarMain from '@/components/sidebars/SidebarMain'

import { createLetter } from '@/utilities/letter.js'
import { loadTemplates } from '@/utilities/template.js'
import {checkIfLettersLimitReached} from "../utilities/project";
import PromiseDialogWrapper from "../components/PromiseDialogWrapper";
import PromiseDialogFrame from "../components/PromiseDialogFrame";

export default {
  name: 'LayoutAnalytics',
  components: {
    PromiseDialogFrame,
    PromiseDialogWrapper,
    HeaderAnalytics,
    HeaderMenuProject,
    HeaderMenuUser,
    LetterAnalyticsMap,
    SidebarMain,
  },
  data() {
    return {
      sidebarShow: false,
    }
  },
  methods: {
    async createNewLetter() {
      if (this.project.templatesTotal === 1) {
        try {
          const templates = await loadTemplates()
          const letterData = {
            name: '',
            subject: '',
            preheader: '',
            template_id: templates.items[0].id,
          }
          const result = await createLetter(letterData)
          await this.$router.push({
            name: 'letter-editor',
            params: { id: result },
          })
        } catch (e) {
          console.log('createNewLetter', e)
        }
      } else {
        await this.$router.push({ name: 'letter-create' })
      }
    },
    async createLetter() {
      if (checkIfLettersLimitReached(this.project)) {
        const answer = await this.$refs.paymentForExtraLetter.open()
        if (answer === false) return
      }
      try {
        const result = await createLetter(this.letterData)
        await this.$router.push({
          name: 'letter-editor',
          params: { id: result.id },
        })
      } catch (e) {
        console.log('createLetter error', e)
      }
    },
  },
}
</script>


<style lang="scss">
.letter-analytics {
  .analytics-main {
    max-width: 610px;
    margin: 110px auto 70px;

    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }
}
</style>
