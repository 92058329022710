<template>
  <v-container>
    <!-- Фильтры/Сортировка -->
    <ControlPanel />

    <!-- Список с контентом -->
    <v-row justify="center">
      <v-col lg="10" md="11" sm="9" cols="12">
        <CardContainer v-if="isCardContainer" />
        <RowContainer v-else-if="isRowContainer" />
        <AnalyticsContainer v-else-if="isAnalyticsContainer" />
      </v-col>
    </v-row>

    <!-- Пагинация -->
    <v-row justify="center" v-if="isActivePagination">
      <v-col cols="8">
        <v-pagination
          v-model="currentPage"
          :length="pageCount"
          :total-visible="7"
          class="my-4"
          @input="getData" />
      </v-col>
    </v-row>

    <!-- Модалки -->
    <PromiseDialogWrapper ref="paymentForExtraLetter">
      <PromiseDialogFrame
        question-code="email-not-free"
        agree-code="agree"
        cancel-code="cancel" />
    </PromiseDialogWrapper>
  </v-container>
</template>

<script>
import ControlPanel from '@/components/home/ControlPanel.vue'

import CardContainer from '@/components/home/containers/CardContainer.vue'
import RowContainer from '@/components/home/containers/RowContainer.vue'
import AnalyticsContainer from '@/components/home/containers/AnalyticsContainer.vue'

import PromiseDialogFrame from '@/components/PromiseDialogFrame.vue'
import PromiseDialogWrapper from '@/components/PromiseDialogWrapper.vue'

export default {
  name: 'ContentBox',
  components: {
    ControlPanel,
    CardContainer,
    RowContainer,
    AnalyticsContainer,
    PromiseDialogFrame,
    PromiseDialogWrapper,
  },
  computed: {
    tableStore() {
      return this.$store.state.tableStore
    },
    isRowContainer() {
      return (
        this.tableStore.viewType === 'row' &&
        this.tableStore.pageType !== 'analytics'
      )
    },
    isCardContainer() {
      return this.tableStore.viewType === 'card'
    },
    isAnalyticsContainer() {
      return this.tableStore.pageType === 'analytics'
    },
    isActivePagination() {
      return this.tableStore.meta.totalCount > this.tableStore.limit
    },
    pageCount() {
      return this.tableStore.meta.pageCount
    },
    currentPage: {
      get() {
        return this.tableStore.page
      },
      set(newValue) {
        this.$store.commit('tableStore/changePage', newValue)
      },
    },
  },
  methods: {
    async getData() {
      await this.$store.dispatch('itemsStore/getData')
    },
  },
}
</script>
