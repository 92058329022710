<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <path d="M9.79102 1.65381H11.7539L8.70703 15.9995H6.74414L9.79102 1.65381Z" :fill="stroke"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>