<template>
  <header class="header-base">
    <v-btn
      class="header-sidebar-toggle btn-small mr-4"
      :class="{ 'btn-menu-active': sidebarShow }"
      :ripple="false"
      outlined
      @click="toggleSidebar">
      {{ $t('headerBase.menu') }}
      <v-icon right size="25">mdi-chevron-down</v-icon>
    </v-btn>

    <router-link class="d-flex" :to="{ name: 'letter-index' }">
      <IconLetterosLogo />
    </router-link>

    <div class="mr-auto ml-auto duplicate-menu">
      <router-link
        :exact="true"
        active-class="duplicate-active"
        :to="{ name: 'letter-index' }"
        class="duplicate-link">
        {{ $t('headerBase.letters') }}
      </router-link>
      <router-link
        active-class="duplicate-active"
        :to="{ name: 'template-index' }"
        class="duplicate-link">
        {{ $t('headerBase.templates') }}
      </router-link>
    </div>

    <slot name="project-menu" />
    <slot name="user-menu" />
  </header>
</template>

<script>
import IconLetterosLogo from '@/components/icons/IconLetterosLogo.vue'

export default {
  name: 'HeaderBase',
  components: {
    IconLetterosLogo,
  },
  data() {
    return {
      menu: null,
    }
  },
  computed: {
    sidebarShow() {
      return this.$store.state.tableStore.sidebarShow
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.commit('tableStore/changeSidebarShow', !this.sidebarShow)
    },
  },
}
</script>

<style lang="scss" scoped>
.header-base {
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
}
.duplicate-menu {
  a.duplicate-link {
    margin: 0 15px;
    text-decoration: none;
    color: #151515;
    &:hover {
      color: #001dff;
    }
    &.duplicate-active {
      color: #001dff;
    }
  }
}
</style>
