<template>
  <div class="flex" style="display: flex">
    <label class="label">{{ content }}</label>
    <label class="label" style="color: rgba(0, 0, 0, 0.6)">*</label>
  </div>
</template>

<script>
export default {
  name: 'LabelForRequiredField',
  components: {},
  props: {
    content: {
      type: String,
      default: ''
    },
  },
}
</script>

