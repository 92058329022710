import {
  updateProjectIntegrations,
  getProject,
  createProject,
} from '@/utilities/project'

/* [Переписать]
 * Привести к общему стайл гайду, пример /dynamic/custom.js
 * Убрать лишние поля, делать в паре с бэком
 */
export default {
  namespaced: true,
  state: {
    id: null,
    full: {
      id: null,
      name: '',
      role: '',
      tariff: null,
      logo_url: '',
      header_toggler: false,
      overdraft: '',
      billing_toggler: false,
      created_by: null,
      updated_by: null,
      created_at: '',
      updated_at: '',
      favicon: '',
      is_active: true,
      balance: '',
      lettersFolders: [],
      lettersTotal: 0,
      customFolders: [],
      customTotal: 0,
      templatesFolders: [],
      templatesTotal: 0,
      integration_platform: [],
      integration_base: {
        s3: {
          login: '',
          password: '',
          container_name: '',
          container_url: '',
          region: '',
          endpoint_url: '',
        },
        ftp: {
          host_url: '',
          login: '',
          password: '',
          directory_name: '',
          directory_url: '',
        },
        domain: {
          url: '',
        },
      },
      accountProfile: {
        inn: '',
        bic: '',
        checking_account: '',
      },
      users: [],
    },
    loading: false,
    integrationS3: {},
    integrationFtp: {},
    integrationDomain: {},
    integrationAmoHook: {},
    showIntegrations: false,
    createdProject: {},
  },
  mutations: {
    setProjectId(state, data) {
      state.id = +data
    },
    clearProjectId(state) {
      state.id = null
    },
    setProject(state, data) {
      if (!data.accountProfile) {
        data.accountProfile = {
          inn: '',
          bic: '',
          checking_account: '',
        }
      }
      state.full = { ...state.full, ...data }
      state.createdProject = data
    },
    setProjectLoading(state, data) {
      state.loading = data
    },
    setIntegrationAmoHook(state, data) {
      state.integrationAmoHook = data
    },
    showIntegrations(state) {
      state.showIntegrations = true
    },
  },
  actions: {
    async createProject({ commit }, { name, url }) {
      try {
        const data = new FormData()
        data.append('name', name)
        data.append('url', url)
        const result = await createProject(data)
        localStorage.setItem('account_id', result.id)
        commit('setProjectId', result.id)
        commit('setProject', result)
        commit('userStore/addProjectToUser', result, { root: true })
      } catch (e) {
        console.log('createProject error', { error: e })
      }
    },
    async loadProject({ commit }, id) {
      try {
        commit('setProjectLoading', true)
        const result = await getProject(id)
        commit('setProject', result)
        commit('setProjectId', id)
        commit('setProjectLoading', false)
      } catch (e) {
        commit('setProjectLoading', false)
        console.log('loadProject error', { error: e })
      }
    },
    /**
     * // [Переписать] коммент ниже, проверить актуальность, и если необходимо внести фикс
     * TODO Загружать все интеграции, а не только amo-hook
     *  Также, правильна ли логика - если несколько инетеграций одного типа, то берется первое
     */
    async loadIntegrations({ commit }, projectId) {
      try {
        const result = await getProject(projectId)
        // const { s3Data, ftpData } = result
        // [Переписать] совместно с Владом, нужно избавиться от этих вычислений, чтобы бэк сразу присылал данные в нужном формате
        let amoHookConfig = {}
        if (result.integrations.length) {
          amoHookConfig = result.integrations.find((k) => {
            if (
              k.name == 'amo-hook' &&
              Object.prototype.hasOwnProperty.call(k.config, 'attributes')
            ) {
              return k
            }
          })
          if (!amoHookConfig) {
            amoHookConfig = {}
          } else {
            amoHookConfig = amoHookConfig.config.attributes
          }
        }

        // this.integrationS3 = s3Data
        // this.integrationFtp = ftpData
        commit('setIntegrationAmoHook', amoHookConfig)
        commit('showIntegrations')
      } catch (e) {
        console.log(e)
      }
    },
    async saveIntegration({ state }, { data, type }) {
      try {
        const projectId = state.id

        const formData = {
          name: type,
          accountId: projectId,
        }

        if (type == 'amo-hook') {
          data.domain = data.domain.split('.')[0].split('//').reverse()[0]
          formData.config = {
            attributes: {
              domain: data.domain,
            },
          }
        } else {
          formData.config = { ...data }
        }

        const result = await updateProjectIntegrations(formData)
        console.log('saveData success', result)
      } catch (e) {
        console.log('saveData error', { error: e })
      }
    },
  },
  getters: {
    getProjectProfile(state) {
      const data = state.full.accountProfile
      return data
        ? { inn: data.inn, bic: data.bic, rs: data.checking_account }
        : { inn: '', bic: '', rs: '' }
    },
    getProjectFull(state) {
      return state.full
    },
  },
}
