<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill" :stroke="stroke">
    <path
        :fill="stroke" d="M0.219727 3.76118V3.17408H0.960591V1.22174L0.219727 1.74826V1.0307L0.96525 0.499512H1.66884V3.17408H2.26992V3.76118H0.219727Z"/>
    <path
        :fill="stroke" d="M0.149902 10.3525V9.83529L1.30546 8.86144C1.40797 8.77447 1.49495 8.69681 1.5664 8.62847C1.63784 8.56013 1.69531 8.4949 1.7388 8.43277C1.78539 8.37064 1.81801 8.31162 1.83665 8.25571C1.85839 8.19669 1.86927 8.13456 1.86927 8.06933C1.86927 7.93575 1.83044 7.83014 1.75278 7.75248C1.67512 7.67171 1.56795 7.63133 1.43127 7.63133C1.25732 7.63133 1.1253 7.68259 1.03521 7.7851C0.945128 7.8845 0.900086 8.0336 0.900086 8.23241H0.196498C0.196498 8.04603 0.222902 7.87984 0.275709 7.73384C0.328517 7.58474 0.407729 7.45893 0.513345 7.35642C0.618961 7.25391 0.747874 7.1747 0.900086 7.11878C1.0554 7.06287 1.23246 7.03491 1.43127 7.03491C1.62076 7.03491 1.78539 7.06132 1.92518 7.11412C2.06807 7.16383 2.18767 7.23527 2.28396 7.32846C2.38026 7.41855 2.45171 7.52727 2.4983 7.65463C2.548 7.77888 2.57285 7.91711 2.57285 8.06933C2.57285 8.18115 2.55422 8.28832 2.51694 8.39083C2.47966 8.49024 2.42686 8.58653 2.35852 8.67972C2.29328 8.77291 2.21407 8.863 2.12088 8.94998C2.02769 9.03695 1.92673 9.12393 1.81801 9.21091L1.11908 9.76539H2.61945V10.3525H0.149902Z"/>
    <path :stroke="stroke" d="M4.33203 2.34863L17.1893 2.34863" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path :stroke="stroke" d="M4.33203 9.05078L17.1893 9.05078" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path :stroke="stroke" d="M4.33203 15.6138L17.1893 15.6138" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        :fill="stroke" d="M1.38457 16.9997C1.18577 16.9997 1.01026 16.9733 0.858046 16.9205C0.708941 16.8646 0.583134 16.7885 0.480624 16.6922C0.378115 16.5959 0.300456 16.4825 0.247648 16.352C0.197947 16.2185 0.173096 16.074 0.173096 15.9187H0.876684C0.876684 16.074 0.917066 16.1936 0.997831 16.2775C1.0817 16.3614 1.21528 16.4033 1.39855 16.4033C1.56629 16.4033 1.69055 16.3645 1.77131 16.2868C1.85208 16.2091 1.89246 16.1035 1.89246 15.97C1.89246 15.8333 1.85208 15.7261 1.77131 15.6485C1.69055 15.5708 1.56163 15.532 1.38457 15.532H1.08636V15.0008H1.38457C1.543 15.0008 1.65948 14.9666 1.73404 14.8983C1.80859 14.8268 1.84586 14.7274 1.84586 14.6001C1.84586 14.482 1.80859 14.3904 1.73404 14.3251C1.66259 14.2568 1.55076 14.2226 1.39855 14.2226C1.23702 14.2226 1.11743 14.2615 1.03977 14.3391C0.962108 14.4137 0.923279 14.5208 0.923279 14.6606H0.219691C0.219691 14.5053 0.244542 14.364 0.294243 14.2366C0.347051 14.1093 0.423157 14.0005 0.52256 13.9105C0.621963 13.8173 0.743111 13.7474 0.886003 13.7008C1.032 13.6511 1.19819 13.6262 1.38457 13.6262C1.57095 13.6262 1.73559 13.6495 1.87848 13.6961C2.02448 13.7396 2.14718 13.8017 2.24658 13.8825C2.34599 13.9602 2.42054 14.0533 2.47024 14.1621C2.52305 14.2708 2.54945 14.3888 2.54945 14.5162C2.54945 14.6964 2.49975 14.8501 2.40035 14.9775C2.30094 15.1017 2.16582 15.1903 1.99497 15.2431C2.18756 15.299 2.33511 15.3922 2.43762 15.5226C2.54324 15.65 2.59605 15.8209 2.59605 16.0352C2.59605 16.175 2.56809 16.3039 2.51218 16.4219C2.45626 16.54 2.3755 16.6425 2.26988 16.7295C2.16737 16.8133 2.04001 16.8801 1.8878 16.9298C1.7387 16.9764 1.57095 16.9997 1.38457 16.9997Z"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>