<template>
  <v-main class="virtual-devices">
    <v-container>
      <h1 class="title-h1 text-center mb-3">
        {{ $t('letterDevices.virtual-devices') }}
      </h1>
      <div v-if="virtualDevices.length" class="virtual-devices-container">
        <div class="d-flex mb-2 mr-n4">
          <div
            class="virtual-devices-select select-multiple-checkboxes select-multiple-checkboxes-1">
            <v-select
              v-model="platformSelected"
              class="mr-4"
              attach=".select-multiple-checkboxes-1"
              :items="platform"
              :menu-props="{ offsetY: true, nudgeBottom: 4 }"
              :placeholder="$t('letterDevices.platform')"
              outlined
              multiple
              append-icon="mdi-chevron-down">
              <template #selection="{ index }">
                <span v-if="index === 0">
                  {{ $t('letterDevices.platform') }}
                  {{ platformSelected.length }}
                </span>
              </template>
              <template #item="{ item, attrs, on }">
                <v-list-item :disabled="item.disabled" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      v-on="on"
                      v-bind="attrs"
                      :ripple="false"
                      :input-value="!active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{ item.name }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
          <div
            class="virtual-devices-select select-multiple-checkboxes select-multiple-checkboxes-2">
            <v-select
              v-model="emailClientSelected"
              class="mr-4"
              attach=".select-multiple-checkboxes-2"
              :items="emailClient"
              :menu-props="{ offsetY: true, nudgeBottom: 4 }"
              :placeholder="$t('letterDevices.email-client')"
              outlined
              multiple
              append-icon="mdi-chevron-down">
              <template #selection="{ index }">
                <span v-if="index === 0">
                  {{ $t('letterDevices.email-client') }}
                  {{ emailClientSelected.length }}
                </span>
              </template>
              <template #item="{ item, attrs, on }">
                <v-list-item :disabled="item.disabled" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      v-on="on"
                      v-bind="attrs"
                      :ripple="false"
                      :input-value="!active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{ item.name }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>

        <v-checkbox
          class="checkbox-dark text--dark mb-8"
          @change="togglePopular"
          on-icon="$checkboxStardOn">
          <template #label>
            <span class="title-h4 ml-1">
              {{ $t('letterDevices.select-popular') }}
            </span>
          </template>
        </v-checkbox>

        <v-expansion-panels
          class="virtual-devices-items mb-4"
          accordion
          flat
          tile>
          <v-expansion-panel
            v-for="(item, index) in virtualDevices"
            :key="index"
            class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <div class="d-flex align-center">
                <v-checkbox
                  class="checkbox-dark text--dark mr-1 mb-0"
                  @click.stop
                  @change="toggleCategory($event, item.os)" />
                {{ item.os }}
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <template v-for="(device, deviceKey) in item.devices">
                <v-checkbox
                  :key="deviceKey"
                  :value="device.id"
                  v-if="device.isVisible"
                  v-model="selectedDevices"
                  class="text--dark mb-5">
                  <template v-slot:label>
                    <div v-html="device.name"></div>
                  </template>
                </v-checkbox>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
          class="white--text px-12"
          :ripple="false"
          x-large
          depressed
          @click.prevent="startRender"
          color="primary">
          {{ $t('letterDevices.start-rendering') }}
        </v-btn>
      </div>
      <div v-if="error" class="text-center">{{ error }}</div>
    </v-container>
  </v-main>
</template>

<script>
import { createDevices, getDevices } from '@/utilities/acid.js'
// [Переписать] отрефакторить [LATER]
export default {
  name: 'LetterDevices',
  data() {
    return {
      error: false,
      platform: [
        { name: 'Outlook', disabled: true, slug: 'platform_outlook' },
        { name: 'Apple Mail', disabled: true, slug: 'platform_apple_mail' },
        {
          name: 'Мобильные приложения',
          disabled: true,
          slug: 'platform_mobile',
        },
        { name: 'Edge', disabled: true, slug: 'platform_edge' },
        { name: 'Firefox', disabled: true, slug: 'platform_firefox' },
        { name: 'Chrome', disabled: true, slug: 'platform_chrome' },
      ],
      platformSelected: [],
      emailClient: [
        { name: 'Mail.ru', disabled: true, slug: 'client_mail_ru' },
        { name: 'Yahoo', disabled: true, slug: 'client_yahoo' },
        {
          name: 'Gmail/Google Workspace',
          disabled: true,
          slug: 'client_gmail',
        },
        { name: 'Outlook.com', disabled: true, slug: 'client_outlook' },
        { name: 'Office 365', disabled: true, slug: 'client_office' },
        { name: 'Остальное', disabled: true, slug: 'client_other' },
      ],
      emailClientSelected: [],
      virtualDevices: [],
      selectedDevices: [],
    }
  },
  computed: {
    type() {
      return this.$route.name == 'letter-custom-devices' ? 'custom' : 'default'
    },
    rendersRoute() {
      return this.type == 'custom' ? 'letter-custom-renders' : 'letter-renders'
    },
  },
  watch: {
    platformSelected: function (n) {
      this.hideDevices()
      if (n && n.length) {
        n.forEach((platform) => {
          this.showPlatformDevices(platform.slug)
        })

        this.emailClientSelected.forEach((client) => {
          let search_key = this.getSearchKey(client.slug)
          if (search_key) this.showClientDevices(search_key)
        })
      }
    },
    emailClientSelected: function (n) {
      this.hideDevices()
      if (n && n.length) {
        n.forEach((client) => {
          let search_key = this.getSearchKey(client.slug)
          if (search_key) this.showClientDevices(search_key)
        })

        this.platformSelected.forEach((platform) => {
          this.showPlatformDevices(platform.slug)
        })
      }
    },
  },
  created() {
    this.$loader('show')
    // this.enableUserLoggedIn()
    this.updateDevices()
    this.emailClientSelected = this.emailClient
    this.platformSelected = this.platform
  },
  methods: {
    async updateDevices() {
      const result = await createDevices()

      if (result.created || result.exist) {
        this.getDevices()
      } else {
        this.error = 'Ошибка при получение списка устройств'
      }
    },
    async getDevices() {
      const result = await getDevices()

      if (result.devices) {
        for (let itemIndex in result.devices) {
          let objectDevices = { os: '', devices: [] }
          let osName = itemIndex

          switch (itemIndex) {
            case 'windows':
              osName = 'Windows'
              break
            case 'android':
              osName = 'Android'
              break
            case 'web':
              osName = 'Web'
              break
            case 'other':
              osName = 'Остальные'
              break
          }

          objectDevices.os = osName
          result.devices[itemIndex].forEach((item) => {
            let device_name = `<b>${item.client}</b> ${item.os}`
            if (item.browser) {
              device_name += `&nbsp;[${item.browser}]`
            }

            objectDevices.devices.push({
              id: item.id,
              name: device_name,
              popular: item.popular,
              client: item.client,
              category: item.category,
              browser: item.browser,
              isVisible: true,
            })
          })

          this.virtualDevices.push(objectDevices)
        }

        this.findPlatform()
        this.findClient()
        this.setFromStorage()
      } else {
        this.error = 'Ошибка при получение списка устройств'
      }

      this.$loader('hide')
    },
    // enableUserLoggedIn() {
    //   this.$emit('is-user-logged-in', true)
    // },
    startRender() {
      this.onlyVisible()
      localStorage.removeItem('renders')
      localStorage.setItem('devices', this.selectedDevices)
      this.$router.push({
        name: this.rendersRoute,
        params: { id: this.$route.params.id },
      })
    },
    togglePopular(state) {
      const popularIds = []

      this.virtualDevices.forEach((list) => {
        list.devices.forEach((item) => {
          if (item.popular) {
            if (!this.selectedDevices.includes(item.id) && state) {
              this.selectedDevices.push(item.id)
            } else if (!state) {
              popularIds.push(item.id)
            }
          }
        })
      })

      if (!state && popularIds.length) {
        this.selectedDevices = this.selectedDevices.filter((item) => {
          return !popularIds.includes(item)
        })
      }
    },
    toggleCategory(state, name) {
      const currectIds = []

      this.virtualDevices.forEach((list) => {
        if (list.os == name) {
          list.devices.forEach((item) => {
            currectIds.push(item.id)
          })
        }
      })

      if (currectIds.length) {
        if (state) {
          currectIds.forEach((k) => {
            if (!this.selectedDevices.includes(k)) {
              this.selectedDevices.push(k)
            }
          })
        } else {
          this.selectedDevices = this.selectedDevices.filter((item) => {
            return !currectIds.includes(item)
          })
        }
      }
    },
    findPlatform() {
      const activePlatform = []

      this.virtualDevices.forEach((list) => {
        list.devices.forEach((device) => {
          if (device.client.toLowerCase().indexOf('outlook') != -1) {
            activePlatform.push('platform_outlook')
          }

          if (device.client.toLowerCase().indexOf('apple mail') != -1) {
            activePlatform.push('platform_apple_mail')
          }

          if (device.category.toLowerCase() == 'mobile') {
            activePlatform.push('platform_mobile')
          }

          if (device.browser.toLowerCase() == 'edge') {
            activePlatform.push('platform_edge')
          }

          if (device.browser.toLowerCase() == 'firefox') {
            activePlatform.push('platform_firefox')
          }

          if (device.browser.toLowerCase() == 'chrome') {
            activePlatform.push('platform_chrome')
          }
        })
      })

      activePlatform.forEach((name) => {
        this.platform.find((k) => k.slug == name).disabled = false
      })
    },
    findClient() {
      const activeClients = []

      this.virtualDevices.forEach((list) => {
        list.devices.forEach((device) => {
          if (device.client.toLowerCase().indexOf('mail.ru') != -1) {
            activeClients.push('client_mail_ru')
          } else if (device.client.toLowerCase().indexOf('yahoo') != -1) {
            activeClients.push('client_yahoo')
          } else if (device.client.toLowerCase().indexOf('outlook.com') != -1) {
            activeClients.push('client_outlook')
          } else if (device.client.toLowerCase().indexOf('office 365') != -1) {
            activeClients.push('client_office')
          } else if (
            device.client.toLowerCase().indexOf('google workspace') != -1
          ) {
            activeClients.push('client_gmail')
          } else if (device.client.toLowerCase().indexOf('gmail') != -1) {
            activeClients.push('client_gmail')
          } else {
            activeClients.push('client_other')
          }
        })
      })

      activeClients.forEach((name) => {
        this.emailClient.find((k) => k.slug == name).disabled = false
      })
    },
    hideDevices() {
      this.virtualDevices.forEach((list) => {
        list.devices.forEach((device) => (device.isVisible = false))
      })
    },
    getSearchKey(slug) {
      let key = false
      switch (slug) {
        case 'client_mail_ru':
          key = ['mail.ru']
          break
        case 'client_yahoo':
          key = ['yahoo']
          break
        case 'client_outlook':
          key = ['outlook.com']
          break
        case 'client_office':
          key = ['office 365']
          break
        case 'client_gmail':
          key = ['google workspace', 'gmail']
          break
        case 'client_other':
          key = ['']
          break
      }

      return key
    },
    findOtherDevices(device) {
      if (
        device.client.toLowerCase().indexOf('mail.ru') == -1 &&
        device.client.toLowerCase().indexOf('yahoo') == -1 &&
        device.client.toLowerCase().indexOf('outlook.com') == -1 &&
        device.client.toLowerCase().indexOf('office 365') == -1 &&
        device.client.toLowerCase().indexOf('google workspace') == -1 &&
        device.client.toLowerCase().indexOf('gmail') == -1
      ) {
        device.isVisible = true
      }
    },
    showClientDevices(search_keys) {
      search_keys.forEach((key) => {
        this.virtualDevices.forEach((list) => {
          list.devices.forEach((device) => {
            if (key == '') {
              this.findOtherDevices(device)
            } else if (device.client.toLowerCase().indexOf(key) != -1) {
              device.isVisible = true
            }
          })
        })
      })
    },
    showPlatformDevices(slug) {
      this.virtualDevices.forEach((list) => {
        list.devices.forEach((device) => {
          if (slug == 'platform_outlook') {
            this.enablePlatformDevice(device, 'outlook')
          }

          if (slug == 'platform_apple_mail') {
            this.enablePlatformDevice(device, 'apple mail')
          }

          if (slug == 'platform_mobile') {
            this.enablePlatformDevice(device, 'mobile', 'equal')
          }

          if (slug == 'platform_edge') {
            this.enablePlatformDevice(device, 'edge', 'equal')
          }

          if (slug == 'platform_firefox') {
            this.enablePlatformDevice(device, 'firefox', 'equal')
          }

          if (slug == 'platform_chrome') {
            this.enablePlatformDevice(device, 'chrome', 'equal')
          }
        })
      })
    },
    enablePlatformDevice(device, key, type) {
      if (type == 'equal') {
        if (key == 'mobile') {
          if (device.category.toLowerCase() == key) {
            device.isVisible = true
          }
        } else {
          if (device.browser.toLowerCase() == key) {
            device.isVisible = true
          }
        }
      } else {
        if (device.client.toLowerCase().indexOf(key) != -1) {
          device.isVisible = true
        }
      }
    },
    setFromStorage() {
      let storageDevices = localStorage.getItem('devices')
      if (!storageDevices) return

      storageDevices = storageDevices.split(',')

      this.selectedDevices = storageDevices
    },
    onlyVisible() {
      this.selectedDevices = this.selectedDevices.filter((item) => {
        let isVisible = false
        this.virtualDevices.filter((list) => {
          list.devices.filter((device) => {
            if (device.id == item) {
              isVisible = device.isVisible
            }
          })
        })

        return isVisible
      })
    },
  },
}
</script>

<style lang="scss">
.virtual-devices-container {
  width: 610px;
  margin: 0 auto;
}

.virtual-devices-select {
  width: 50%;

  .v-select {
    font-size: 18px;
  }

  .v-list-item__action:first-child {
    margin-right: 0px;
  }
}

.virtual-devices-items {
  .v-input--checkbox .v-label {
    padding-left: 6px;
    font-size: 18px;
    font-weight: 500;
  }

  .accordion-item {
    border-top: 1px solid $color-gray-lighten;

    .v-icon {
      font-size: 36px;
    }
  }

  .v-expansion-panel-content__wrap {
    padding-bottom: 12px;
  }
}
</style>
