<template>
  <v-menu
    content-class="dropdown-menu"
    nudge-bottom="6"
    offset-y
    min-width="245"
    left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        primary
        depressed
        color="primary"
        link
        v-bind="attrs"
        v-on="on"
        :ripple="false">
        {{ $t('headerMenuLetter.export') }}
        <v-icon right size="23">mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        class="dropdown-menu-item"
        :ripple="false"
        link
        @click="exportLetter('zip')">
        <IconZip />
        {{ $t('headerMenuLetter.download-zip') }}
      </v-list-item>
      <v-list-item
        class="dropdown-menu-item"
        :ripple="false"
        link
        @click="exportLetter('html')">
        <IconHtml />
        {{ $t('headerMenuLetter.download-html') }}
      </v-list-item>
      <v-list-item
        class="dropdown-menu-item"
        :ripple="false"
        link
        @click="exportLetter('eml')">
        <IconEml />
        {{ $t('headerMenuLetter.download-eml') }}
      </v-list-item>
      <v-list-item
        class="dropdown-menu-item"
        :ripple="false"
        link
        @click="exportLetter('code')">
        <IconCopy />
        {{ $t('headerMenuLetter.copy-the-code') }}
      </v-list-item>
      <v-list-item
        class="dropdown-menu-item"
        :ripple="false"
        link
        @click="cloneLetter">
        <IconCopy />
        {{ $t('headerMenuLetter.clone-letter') }}
      </v-list-item>
      <v-list-item
        v-for="(platform, index) in integrations"
        :key="index"
        class="dropdown-menu-item"
        :ripple="false"
        link>
        <div>
          <v-img :src="platform.logoUrl" />
        </div>
        {{ platform.name }}
      </v-list-item>
      <v-divider />
      <v-list-item
        class="dropdown-menu-item mb-1"
        link
        @click="saveLetter"
        :ripple="false">
        <IconSave />
        {{ $t('headerMenuLetter.save') }}
      </v-list-item>
      <v-list-item
        class="dropdown-menu-item"
        link
        @click="saveExit"
        :ripple="false">
        <IconExit />
        {{ $t('headerMenuLetter.save-and-exit') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
/* eslint-disable */
import IconZip from '@/components/icons/IconZip.vue'
import IconHtml from '@/components/icons/IconCodeHtml.vue'
import IconEml from '@/components/icons/IconEml.vue'
import IconCopy from '@/components/icons/IconCopy.vue'
import IconSave from '@/components/icons/IconSave.vue'
import IconExit from '@/components/icons/IconExit.vue'

import { downloadFile } from '@/utilities/fileHandler.js'
import { getLetterFullHtml } from '@/utilities/letter.js'
import { copyToClipBoard, displayNotify } from '@/utilities/helpers'
import { checkIfLettersLimitReached } from '@/utilities/project'

import project from '@/mixins/project.mixin.js'

export default {
  name: 'HeaderMenuLetterCustom',
  components: {
    IconZip,
    IconHtml,
    IconEml,
    IconCopy,
    IconSave,
    IconExit,
  },
  mixins: [project],
  computed: {
    integrations() {
      return this.$store.state.project.full.integration_platform
    },
    isLetterSave() {
      return this.$store.state.customStore.isLetterSave
    },
    project() {
      return this.$store.state.project.full
    },
  },
  methods: {
    async saveLetter() {
      await this.$store.dispatch('customStore/saveLetter')
      if (this.isLetterSave) {
        displayNotify(this.$t('letterLayout.letter-saved'))
      } else {
        displayNotify(this.$t('letterLayout.letter-not-saved'), 'error')
      }
    },
    // Сохранение и выход из письма
    async saveExit() {
      await this.$router.push({ name: 'letter-index' })
      // -> Далее запускается сценарий из beforeRouteLeave (В этом случае баг)
    },
    // Экспорт письма (zip | html | eml | копирование кода в буфер обмена) [Переписать] [LATER]
    async exportLetter(type = 'zip') {
      await this.$store.dispatch('customStore/saveLetter')
      if (this.isLetterSave) {
        if (type === 'code') {
          const result = await getLetterFullHtml(
            this.$store.state.customStore.id
          )

          await copyToClipBoard(result)
          // [Переписать] проработать ошибки [NEW_API]
          displayNotify(this.$t('letterLayout.copy-success'))
          return
        } else {
          await downloadFile(
            `letter/export?id=${this.$store.state.customStore.id}&type=${type}`
          )
          // [Переписать] проработать ошибки [NEW_API]
          displayNotify(
            this.$t('letterLayout.export-success', { format: type })
          )
        }
      } else {
        displayNotify(this.$t('letterLayout.export-error'))
      }
    },
    // Клонирование письма прям из самого письма
    async cloneLetter() {
      this.$loader('show')

      // [Переписать] проверить, что там происходит [LATER]
      if (checkIfLettersLimitReached(this.project)) {
        const answer = await this.$refs.paymentForExtraLetter.open()
        if (answer === false) return
      }

      const result = await this.$store.dispatch('customStore/cloneLetter')
      // [Переписать] проработать ошибки [NEW_API]
      this.$loader('hide')
      displayNotify(this.$t('headerMenuLetter.clone-success'))
      this.$router.push({
        name: 'letter-custom-editor',
        params: { id: result.id },
      })
    },
  },
}
</script>
