<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <rect x="1.9187" y="2.5" width="20.1666" height="15.3751" rx="2.5" :stroke="stroke" stroke-linejoin="round"/>
    <line x1="1" y1="21.6667" x2="22.9999" y2="21.6667" :stroke="stroke" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>