import { displayNotify } from '@/utilities/helpers'

export default {
  computed: {
    selected: {
      get() {
        return this.$store.getters['itemsStore/selectedById'](this.item.id)
      },
      set(value) {
        this.$store.commit('itemsStore/changeSelected', {
          id: this.item.id,
          selected: value,
        })
      },
    },
    isDataLoading() {
      return (
        this.$store.state.tableStore.pageType === 'template' &&
        this.item.state < 50 &&
        this.item.state >= 0 &&
        !this.item.isAppLetterosCom
      )
    },
    editID() {
      return this.$store.state.tableStore.currentEditId
    },
    editMode() {
      return this.$store.state.tableStore.editMode
    },
    isDisabled() {
      return !(this.editMode === 'rename' && this.editID === this.item.id)
    },
  },
  watch: {
    editMode(value) {
      if (value === 'rename') {
        // Для ожидания применения isDisabled (заменить на добавление класса .active)
        this.$nextTick(() => {
          this.$refs.rename.click()
          this.$refs.rename.focus()
        })
      }
    },
  },
  methods: {
    renameHandler(e) {
      try {
        this.$store.dispatch('itemsStore/renameHandler', {
          id: this.item.id,
          name: e.target.value,
        })
      } catch (e) {
        displayNotify(this.$t('theCard.rename-error'), 'error')
      } finally {
        this.$store.commit('tableStore/changeEditMode', '')
      }
    },
    previewLoaded(e) {
      const preview = e.target.src

      if (preview.indexOf('/img/letterPreview.png') === -1) {
        e.target.classList.add('loaded')
      }
    },
  },
}
