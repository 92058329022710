<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M4.23537 0.564941H13.2752C15.3018 0.564941 16.9413 2.19206 16.9413 4.20336V13.1751C16.9413 15.1864 15.3018 16.8135 13.2752 16.8135H4.23537C2.2088 16.8135 0.569336 15.1864 0.569336 13.1751V4.20336C0.569336 2.19206 2.2088 0.564941 4.23537 0.564941Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.7761 7.19775C20.5731 7.19775 21.3131 7.44634 21.9165 7.87572" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23.4308 11.7515V19.7967C23.4308 21.808 21.7913 23.4351 19.7647 23.4351H10.7363C10.3036 23.4351 9.88237 23.356 9.48389 23.2204" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3.86 3.86"/>
    <path d="M7.75343 21.9209C7.32079 21.3221 7.07031 20.5876 7.07031 19.7966" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5562 11.661L5.55615 5.71753" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.6928 6.54224V11.8078H6.58081" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>