<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M16.6542 22.9997H7.34611C4.926 22.9997 2.96582 21.2154 2.96582 19.0125V12.6528C2.96582 10.4498 4.926 8.66553 7.34611 8.66553H16.6542C19.0744 8.66553 21.0345 10.4498 21.0345 12.6528V19.0125C21.0345 21.2154 19.0744 22.9997 16.6542 22.9997Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.11597 8.23693V5.71499C7.11597 2.50524 9.02139 0.990081 11.9781 1.00005C15.11 1.01002 16.8731 2.77438 16.8731 5.41594" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.033 16.1117C12.9462 16.1117 13.6865 15.4378 13.6865 14.6065C13.6865 13.7752 12.9462 13.1013 12.033 13.1013C11.1197 13.1013 10.3794 13.7752 10.3794 14.6065C10.3794 15.4378 11.1197 16.1117 12.033 16.1117Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 18.3347V16.1018" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>