/* eslint-disable */
import { Picker } from 'emoji-picker-element'
const pickerId = 'emoji-picker-id'
/*
 Вызываем эту функцию в компоненте, где нужен пикер. Она вернет айди пикера.

 Для использования нужно создать кнопку с классом emoji-opener и уникальным айди
 и повесить на эту кнопку openEmojiPicker(айди_кнопки, айди_пикера)
 также необходимо определить обработчик события выбора смайла, например:

 mounted() {
   const picker = document.getElementById(айди_пикера)
   picker.addEventListener('emoji-click', (event) => {
     this.addSmile(event)
   })
 }
 */
function createEmojiPicker() {
  const style = document.createElement('style')
  style.textContent = `
        .tabpanel {
            scrollbar-width: thin;
            scrollbar-color: #a8a8a9;
          }
          .tabpanel::-webkit-scrollbar {
            height: 12px;
            width: 4px;
          }
          .tabpanel::-webkit-scrollbar-thumb {
            background-color: #a8a8a9;
            border-radius: 5px;
        }
        section.picker {
          border-radius: 25px;
        }
      `
  const picker = new Picker()
  document.body.appendChild(picker)
  picker.shadowRoot.appendChild(style)
  picker.style.cssText = 'display: none;'
  picker.setAttribute('id', pickerId)

  const app = document.getElementById('app')
  app.addEventListener('click', (event) => {
    closeEmojiPicker(event)
  })
  return pickerId
}

function closeEmojiPicker(event) {
  if (
    !event.target.closest('.emoji-opener') &&
    !event.target.closest('emoji-picker')
  ) {
    const pickers = document.querySelectorAll('emoji-picker')
    pickers.forEach((picker) => {
      picker.style.cssText = 'display: none;'
    })
  }
}

function openEmojiPicker(buttonId, pickerId) {
  const emojiButton = document.getElementById(buttonId)
  const buttonPos = emojiButton.getBoundingClientRect()
  const top = buttonPos.y + 40
  const left = buttonPos.x - 133
  const emojiPicker = document.getElementById(pickerId)
  emojiPicker.style.cssText = 'top: ' + top + 'px;' + 'left: ' + left + 'px;'
}

export { createEmojiPicker, openEmojiPicker, closeEmojiPicker }
