// [Переписать] весь файл
function moduleParser(module, options) {
  let elements = []
  const list = getHtmlElementsWithOptions(module)
  list.forEach((value) => {
    const result = parseHtmlElement(value, options)
    if (result.length) elements.push([...result])
  })
  return elements
}

// [Переписать] удалить везде использование letteros-color
function getHtmlElementsWithOptions(module) {
  return module.querySelectorAll(`
    [letteros-element], [letteros-module], [letteros-tag],
    [letteros-color], [letteros-color-list], [letteros-background-list], [letteros-border-color],
    [letteros-background], [letteros-border-radius], 
    [letteros-hide], [letteros-align], [data-visibility],
    [letteros-resizer],
    [letteros-product-card], [letteros-google], [letteros-page], 
    [letteros-desktop-hide], [letteros-structure-columns]`)
}

function parseHtmlElement(element) {
  let elementOptions = []
  const isModule = element.hasAttribute('letteros-element')
  const module = element.getAttribute('letteros-module')
  const label = element.getAttribute('letteros-tag')

  const colorValue = element.hasAttribute('letteros-color')
  const colorList = element.hasAttribute('letteros-color-list')
  const backgroundList = element.hasAttribute('letteros-background-list')
  const borderColorValue = element.hasAttribute('letteros-border-color')

  const backgroundValue = element.getAttribute('letteros-background')
  const borderRadiusValue = element.hasAttribute('letteros-border-radius')
  const hideValue = element.getAttribute('letteros-hide')
  const alignValue = element.hasAttribute('letteros-align')
  const visibilityValue = element.getAttribute('data-visibility')

  const metaParse = element.hasAttribute('letteros-product-card')
  const googleSpreadsheetParse = element.hasAttribute('letteros-google')
  const selectorParse = element.hasAttribute('letteros-page')

  const columnsCount = element.hasAttribute('letteros-structure-columns')

  if (isModule) {
    elementOptions.push({
      element: element,
      label: label,
      value: visibilityValue,
      inputType: 'visibility',
    })
    elementOptions.push({
      element: element,
      label: label,
      value: '',
      inputType: 'condition',
    })
  }

  if (module) {
    if (module === 'Timer') {
      elementOptions.push({
        element: element,
        label: label,
        value: '',
        inputType: 'timer',
      })
    }

    if (module === 'Gpromo') {
      elementOptions.push({
        element: element,
        label: 'Gmail Promotions Annotations',
        value: '',
        inputType: 'gpa',
      })
    }
  }
  if (hideValue) {
    elementOptions.push({
      element: element,
      label: hideValue,
      inputType: 'hide',
    })
  }
  if (colorValue || colorList) {
    elementOptions.push({
      element: element,
      label: label,
      value: convertRGBAtoHEX(element.style.color),
      inputType: 'color',
      styleName: 'color',
    })
  }
  if (backgroundValue || backgroundList) {
    const value =
      backgroundValue === 'color'
        ? convertRGBAtoHEX(element.style.backgroundColor)
        : element.getAttribute('background')
    if (backgroundValue === 'color' || backgroundList) {
      elementOptions.push({
        element: element,
        label: 'Цвет фона',
        value: value,
        inputType: 'color',
        styleName: 'backgroundColor',
      })
    } else {
      elementOptions.push({
        element: element,
        label: 'Фоновое изображение',
        value: value,
        inputType: 'backgroundImage',
        styleName: 'backgroundImage',
      })
    }
  }
  if (alignValue) {
    elementOptions.push({
      element: element,
      label: label,
      value: alignValue,
      inputType: 'align',
    })
  }
  if (borderRadiusValue) {
    elementOptions.push({
      element: element,
      label: label,
      value: element.style.borderRadius,
      inputType: 'borderRadius',
    })
  }
  if (borderColorValue) {
    const label = 'Цвет обводки'
    elementOptions.push({
      element: element,
      label: label,
      inputType: 'color',
      styleName: 'borderColor',
    })
  }
  if (columnsCount) {
    elementOptions.push({
      element: element,
      label: 'Количество колонок',
      value: '1',
      inputType: 'columnsCount',
    })
  }

  if (googleSpreadsheetParse) {
    elementOptions.push({
      element: element,
      inputType: 'googleSpreadsheets',
    })
  }

  if (selectorParse) {
    elementOptions.push({
      element: element,
      inputType: 'selectorParse',
    })
  }

  if (metaParse) {
    elementOptions.push({
      element: element,
      inputType: 'metaParse',
    })
  }

  return elementOptions
}

function getElementOptions(target) {
  let elementOptions = []
  const targetLink = target.closest('a')
  const targetImage = target.tagName === 'IMG'

  let targetTextElement = target.closest('p, a, span')
  const label = target.getAttribute('letteros-tag')

  const letterosElement = target.closest('[letteros-element]')
  const elementName = letterosElement.getAttribute('letteros-element')

  if (targetTextElement?.tagName === 'A' && targetImage) {
    targetTextElement = null
  }

  if (elementName === 'Видео') {
    const optionArray = []
    optionArray.push({
      element: targetLink,
      label: 'URL',
      value: targetLink.href,
      inputType: 'video',
    })
    const result = parseHtmlElement(target)
    if (result.length) optionArray.push(...result)
    elementOptions.push(...optionArray)
    return elementOptions
  }

  if (targetImage) {
    if (targetLink) {
      const optionArray = []
      optionArray.push({
        element: targetLink,
        label: 'URL',
        value: targetLink.href,
        inputType: 'link',
      })
      const result = parseHtmlElement(target)
      if (result.length) optionArray.push(...result)
      elementOptions.push(...optionArray)
    }

    elementOptions.push({
      element: target,
      label: label,
      value: target.src,
      inputType: 'image',
    })
  }
  if (targetTextElement) {
    const result = parseHtmlElement(targetTextElement)
    if (result.length) {
      elementOptions.push([result])
    } else {
      const result = parseHtmlElement(target.parentElement)
      elementOptions.push([...result])
    }
    elementOptions.push({
      element: targetTextElement,
      label: label,
      value: '',
      inputType: 'textEditor',
    })
  }
  return elementOptions
}

function convertRGBAtoHEX(raw) {
  if (!raw) return ''
  const channels = parseRGBA(raw)
  const hexChannels = channels.map((entry) =>
    `0${entry.toString(16)}`.slice(-2)
  )

  return `#${hexChannels.join('')}`
}

function parseRGBA(raw) {
  return raw
    .replace(/rgba|rgb|\(|\)/g, '')
    .split(/,\s*/g)
    .map((entry, index) => {
      const number = parseFloat(entry, 10)
      return index === 3 ? Math.floor(number * 255) : number
    })
}

export { moduleParser, getElementOptions }
