<template>
  <svg width="26" height="26" id="platform" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.1248 4.94263C20.8985 6.52341 23.5485 10.2336 23.5485 14.5575C23.5485 15.4967 23.4267 16.3987 23.1926 17.2634" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M2.86347 17.1983C2.63874 16.3522 2.52637 15.4688 2.52637 14.5575C2.52637 10.2336 5.17639 6.52341 8.95009 4.94263" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M18.3235 23.5772C16.7691 24.4792 14.9618 24.9999 13.0328 24.9999C11.0757 24.9999 9.24039 24.4699 7.66724 23.54" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13.0326 8.68074C15.1685 8.68074 16.9 6.96135 16.9 4.84037C16.9 2.71939 15.1685 1 13.0326 1C10.8968 1 9.16528 2.71939 9.16528 4.84037C9.16528 6.96135 10.8968 8.68074 13.0326 8.68074Z" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4.86734 24.5351C7.00322 24.5351 8.73469 22.8157 8.73469 20.6947C8.73469 18.5738 7.00322 16.8544 4.86734 16.8544C2.73147 16.8544 1 18.5738 1 20.6947C1 22.8157 2.73147 24.5351 4.86734 24.5351Z" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M21.1326 24.5351C23.2685 24.5351 24.9999 22.8157 24.9999 20.6947C24.9999 18.5738 23.2685 16.8544 21.1326 16.8544C18.9967 16.8544 17.2653 18.5738 17.2653 20.6947C17.2653 22.8157 18.9967 24.5351 21.1326 24.5351Z" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 32},
    height: {type: [Number, String], default: 26},
    viewBox: {type: String, default: '0 0 32 26'},
    fill: {type: String, default: '#151515'}
  }
}
</script>
