import Vue from 'vue'
import Vuex from 'vuex'

import project from '@/store/modules/project'
import authStore from '@/store/modules/auth'
import { moduleUser } from '@/store/modules/user'
import { moduleFolders } from '@/store/modules/folders'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userLoading: false,
  },
  mutations: {},
  actions: {},
  modules: {
    auth: authStore,
    userStore: {
      namespaced: true,
      ...moduleUser,
    },
    foldersStore: {
      namespaced: true,
      ...moduleFolders,
    },
    project,
  },
  getters: {},
})
