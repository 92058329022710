import axios from '../plugins/axios_and_toast.js'

async function createProject(data) {
  const result = await axios.post('/account/create', data)
  return result.data
}

async function getProject(id) {
  const result = await axios.get(
    `/account/view?expand=users,tarif,integrations,accountProfile,lettersFolders,lettersTotal,templatesFolders,customFolders,customTotal,inputed_test_emails,templatesTotal&id=${id}`
  )
  return result.data
}

async function updateProject(id, data) {
  return await axios.put(`/account/update?id=${id}`, data)
}

async function deleteProject(id) {
  return await axios.delete(`/account/delete?id=${id}`)
}

async function updateProjectIntegrations(data) {
  return await axios.post(`/service/connect`, data)
}

async function importTemplate(data) {
  const result = await axios.post(`/template/import`, data)
  return result.data
}

async function inviteUserByEmail(data) {
  const result = await axios.post(`/account/invite-user`, data)
  return result.data
}

async function removeUser(id) {
  const result = await axios.delete(`/account/remove-user?id=${id}`)
  return result.data
}

async function updateUserRole(id, data) {
  const result = await axios.put(`/account/update-role?id=${id}`, data)
  return result.data
}

async function updateTarif(data) {
  let url = '/account/set-tarif?name=' + data.name
  if (data.count) url += '&count=' + data.count
  if (data.type) url += '&type=' + data.type
  return await axios.post(url)
}

function checkIfLettersLimitReached(project) {
  if (project.billing_toggler || !project.length) return false

  if (
    project.tarif.name !== 'custom' &&
    project.tarif.letters_created >= project.tarif.letters_limit
  )
    return true

  return (
    project.tarif.name === 'custom' &&
    project.tarif.letters_created >= project.tarif.overwritten_letters_limit
  )
}

async function getPaymentHistory(params) {
  const result = await axios.get(`/payment-history`, { params })
  return result.data
}

async function leaveProject() {
  await axios.post(`/account/leave-project`)
}

export {
  createProject,
  getProject,
  updateUserRole,
  updateProject,
  importTemplate,
  deleteProject,
  updateProjectIntegrations,
  inviteUserByEmail,
  removeUser,
  updateTarif,
  checkIfLettersLimitReached,
  getPaymentHistory,
  leaveProject,
}
