<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <g clip-path="url(#clip0_5829_259)">
      <path d="M34.2874 11.8779L34.2874 11.8779L34.2917 11.8823C34.368 11.9588 34.4381 12.0139 34.5 12.056V36.6119C34.5 38.2202 33.2369 39.5 31.7122 39.5H8.3816C6.8569 39.5 5.59375 38.2202 5.59375 36.6119V3.40166C5.59375 1.80074 6.85654 0.519622 8.37622 0.513552C8.37645 0.513551 8.37667 0.51355 8.37689 0.513549L23.2629 0.500077C23.3044 0.565395 23.3582 0.636273 23.4314 0.711684L23.4317 0.712019L34.2874 11.8779Z" fill="white" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <rect x="11.6252" y="15.3447" width="17.1112" height="3" rx="1.5" fill="#151515"/>
      <rect x="11.6252" y="21.8623" width="17.1112" height="3" rx="1.5" fill="#151515"/>
      <rect x="11.6252" y="28.3789" width="17.1112" height="3" rx="1.5" fill="#151515"/>
      <path d="M23.125 1.18132L33.7661 11.5H26.1366C24.4592 11.5 23.125 10.1851 23.125 8.59864V1.18132Z" fill="#151515" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_5829_259">
        <rect width="40" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 40},
    height: {type: [Number, String], default: 40},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 40 40'},
    fill: {type: String, default: 'none'}
  }
}
</script>