export default {
  methods: {
    async loadProject() {
      const projectId = localStorage.getItem('account_id')
      if (projectId) {
        await this.$store.dispatch('project/loadProject', projectId)
      }
    },
  }
}
