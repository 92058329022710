<template>
  <v-main class="letter-create">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="8" sm="10" cols="12">
          <h1 class="title-h1 text-center mb-3">
            {{ $t('letterCreate.new-letter') }}
          </h1>
          <label class="d-sr-only">
            {{ $t('letterCreate.the-name-of-the-letter') }}
          </label>
          <v-text-field
            v-model="name"
            class="mb-n3"
            outlined
            :placeholder="$t('letterCreate.the-name-of-the-letter')"
            @change="putLetterData" />
        </v-col>
      </v-row>

      <v-row justify="center" v-if="items.length">
        <v-col lg="10" md="11" sm="9" cols="12">
          <v-row>
            <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              v-for="(item, index) in items"
              :key="index"
              @click="updateTemplateId(item.id)">
              <ItemCardSimple
                :class="{ selected: templateId === item.id }"
                :item="item"
                :selected="templateId === item.id" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ItemCardSimple from '@/components/home/items/ItemCardSimple.vue'
import creatorStore from '@/mixins/Home/creatorStore.mixin.js'

export default {
  name: 'LetterCreate',
  mixins: [creatorStore],
  components: { ItemCardSimple },
  data() {
    return {
      name: '',
      templateId: null,
    }
  },
  computed: {
    items() {
      return this.$store.state.itemsStore.items
    },
  },
  async mounted() {
    document.title = this.$t('tableIndex.letters')

    // Подтягиваем шаблоны
    this.$store.commit('tableStore/changePageType', 'template')
    await this.$store.dispatch('itemsStore/getData')

    // Выбираем по дефолту первый шаблон
    this.initTemplateId()
  },
  methods: {
    initTemplateId() {
      if (this.items.length) {
        this.templateId = this.items[0].id
        this.putLetterData()
      }
    },
    updateTemplateId(id) {
      this.templateId = id
      this.putLetterData()
    },
    putLetterData() {
      this.$store.commit('creatorStore/changeLetterData', {
        name: this.name,
        template_id: this.templateId,
      })
    },
  },
}
</script>
