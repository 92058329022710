import axios from '../plugins/axios_and_toast.js'

// todo надо сюда вынести все действия с папками, на беке также избавится для отдельных
// методов для создания папок для писем и шаблонов. Это одно и то же, только тип разный

async function createFolder(data) {
  const result = await axios.post('/folder/create', data)
  return result.data
}

async function updateFolder(id, data) {
  const result = await axios.put('/folder/update?id=' + id, data)
  return result.data
}

async function deleteFolder(id) {
    const result = await axios.delete('/folder/delete?id=' + id)
    return result.data
}

export {
  createFolder,
  updateFolder,
  deleteFolder,
}
