<template>
  <v-row justify="center">
    <v-col cols="12">
      <label class="d-sr-only">
        {{ $t('tableHeader.search') }}
      </label>
      <v-text-field
        class="input-search select-text"
        outlined
        :placeholder="$t('tableHeader.search')"
        prepend-inner-icon="mdi-magnify"
        :hide-details="true"
        :value="filterName"
        @change="updateSearch" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ControlSearch',
  components: {},
  computed: {
    filterName() {
      return this.$store.state.itemsStore.filter.name
    },
  },
  methods: {
    async updateSearch(value) {
      this.$store.commit('itemsStore/changeFilterName', value)
      await this.$store.dispatch('itemsStore/getData')
    },
  },
}
</script>
