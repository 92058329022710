<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <rect x="8.5" y="0.5" width="33" height="49" rx="3.5" fill="#FAFAFA" :stroke="stroke"/>
    <path d="M16.6217 34H2.37173C1.06499 34 0 32.9412 0 31.6275V17.3725C0 16.0654 1.05846 15 2.37173 15H16.6283C17.935 15 19 16.0588 19 17.3725V31.634C18.9935 32.9412 17.935 34 16.6217 34Z" fill="#151515"/>
    <path d="M27.2784 40.8667H41.6001C43.7002 40.8667 45.4017 39.0416 45.4017 36.7888V35.9754C45.4017 33.7226 43.7002 31.8975 41.6001 31.8975H27.2784C25.1783 31.8975 23.4768 33.7226 23.4768 35.9754V36.7888C23.4768 39.0416 25.1783 40.8667 27.2784 40.8667Z" fill="#151515"/>
    <mask id="mask0_5831_268" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="22" y="7" width="28" height="21">
      <path d="M25.4368 27.1535H46.7642C48.214 27.1535 49.3947 25.9728 49.3947 24.5231V10.3995C49.3947 8.94975 48.214 7.76904 46.7642 7.76904H25.4368C23.9871 7.76904 22.8064 8.94975 22.8064 10.3995V24.5306C22.8064 25.9803 23.9871 27.1535 25.4368 27.1535Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_5831_268)">
      <path d="M46.7642 26.6535H25.4368C24.2614 26.6535 23.3064 25.7023 23.3064 24.5306V10.3995C23.3064 9.22589 24.2632 8.26904 25.4368 8.26904H46.7642C47.9378 8.26904 48.8947 9.22589 48.8947 10.3995V24.5231C48.8947 25.6967 47.9378 26.6535 46.7642 26.6535Z" fill="white" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.0767 27.6533L26.3483 16.1806C27.5216 15.2465 29.188 15.2614 30.3463 16.2105L45.2305 28.4994" fill="#151515"/>
      <path d="M41.4955 18.4677C43.3858 18.4677 44.9181 16.9353 44.9181 15.0451C44.9181 13.1549 43.3858 11.6226 41.4955 11.6226C39.6053 11.6226 38.073 13.1549 38.073 15.0451C38.073 16.9353 39.6053 18.4677 41.4955 18.4677Z" fill="#151515"/>
      <g filter="url(#filter0_d_5831_268)">
        <path d="M46.7642 26.6535H25.4368C24.2614 26.6535 23.3064 25.7023 23.3064 24.5306V10.3995C23.3064 9.22589 24.2632 8.26904 25.4368 8.26904H46.7642C47.9378 8.26904 48.8947 9.22589 48.8947 10.3995V24.5231C48.8947 25.6967 47.9378 26.6535 46.7642 26.6535Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
    <path d="M7 20H13" stroke="white" stroke-width="2.52697" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 20V30" stroke="white" stroke-width="2.52697" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <filter id="filter0_d_5831_268" x="12.8064" y="7.76904" width="46.5884" height="39.3845" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="10"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5831_268"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5831_268" result="shape"/>
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 50},
    height: {type: [Number, String], default: 50},
    stroke: {type: String, default: '#151515'},
    viewBox: {type: String, default: '0 0 50 50'},
    fill: {type: String, default: 'none'}
  }
}
</script>