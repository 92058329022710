<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <g id="surface1">
      <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 33.421875 31.828125 L 26.109375 24.515625 L 26.03125 24.515625 C 30.714844 18.992188 30.207031 10.757812 24.882812 5.851562 C 19.558594 0.945312 11.3125 1.117188 6.191406 6.234375 C 1.070312 11.355469 0.902344 19.601562 5.808594 24.929688 C 10.714844 30.253906 18.945312 30.761719 24.46875 26.078125 C 24.46875 26.078125 24.46875 26.132812 24.46875 26.15625 L 31.78125 33.46875 C 31.992188 33.683594 32.28125 33.800781 32.578125 33.800781 C 32.878906 33.800781 33.167969 33.683594 33.378906 33.46875 C 33.613281 33.261719 33.75 32.96875 33.757812 32.65625 C 33.765625 32.347656 33.644531 32.046875 33.421875 31.828125 Z M 15.75 27 C 9.535156 27 4.5 21.964844 4.5 15.75 C 4.5 9.535156 9.535156 4.5 15.75 4.5 C 21.964844 4.5 27 9.535156 27 15.75 C 27 21.964844 21.964844 27 15.75 27 Z M 15.75 27 "/>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 36 36'},
    fill: {type: String, default: 'none'}
  }
}
</script>