<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <g clip-path="url(#clip0_3115_1449)">
      <path d="M15.8814 8.8703V15.0209C15.8814 16.4352 14.7296 17.5816 13.3088 17.5816H2.99304C1.57221 17.5816 0.42041 16.4352 0.42041 15.0209V4.95398C0.42041 3.53975 1.57221 2.39331 2.99304 2.39331H9.50869" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.9406 1.01245L6.55762 11.3472" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.1487 0.418457H17.5798V5.75737" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 18},
    height: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>