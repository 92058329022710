<template>
  <v-main class="template-create">
    <v-container>
      <v-row justify="center">
        <v-col lg="7" md="10" sm="9" cols="12">
          <h1 class="template-creating-title title-h1 text-center mb-3">
            {{ $t('templateCreate.creating-a-template') }}
          </h1>
          <v-list class="template-creating-actions" rounded>
            <v-list-item
              class="template-creating-actions-item mb-5"
              link
              to="form">
              <div class="template-creating-actions-icon">
                <IconCreate class="mr-3" />
              </div>
              <v-list-item-content>
                <h2 class="title-h4 mb-3">
                  {{ $t('templateCreate.create-by-generator') }}
                </h2>
                <p class="template-creating-actions-text mb-0">
                  {{ $t('templateCreate.5-minutes-and-you-will-have') }}
                </p>
              </v-list-item-content>
              <v-icon class="mr-n2" size="26">mdi-chevron-right</v-icon>
            </v-list-item>
            <v-list-item
              class="template-creating-actions-item mb-5"
              :ripple="false"
              link
              to="load">
              <div class="template-creating-actions-icon">
                <IconUploadSecond class="mr-3" />
              </div>
              <v-list-item-content>
                <h2 class="title-h4 mb-3">
                  {{ $t('templateCreate.upload-a-ready-made-template') }}
                </h2>
                <p class="template-creating-actions-text mb-0">
                  {{
                    $t('templateCreate.you-can-upload-your-own-template-file')
                  }}
                </p>
              </v-list-item-content>
              <v-icon class="mr-n2" size="26">mdi-chevron-right</v-icon>
            </v-list-item>
            <v-list-item
              class="template-creating-actions-item"
              :ripple="false"
              link
              href="https://letteros.com/migration/"
              target="_blank">
              <div class="template-creating-actions-icon html">
                <IconHtmlSecond class="mr-3" />
              </div>
              <v-list-item-content>
                <h2 class="title-h4 mb-3">
                  {{ $t('templateCreate.order-up-to-your-design') }}
                </h2>
                <p class="template-creating-actions-text mb-0">
                  {{ $t('templateCreate.you-can-upload-the-layout') }}
                </p>
              </v-list-item-content>
              <v-icon class="mr-n2" size="26">mdi-chevron-right</v-icon>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import IconCreate from '@/components/icons/IconCreate.vue'
import IconUploadSecond from '@/components/icons/IconUploadSecond.vue'
import IconHtmlSecond from '@/components/icons/IconHtml.vue'

export default {
  name: 'TemplateCreate',
  components: {
    IconCreate,
    IconUploadSecond,
    IconHtmlSecond,
  },
}
</script>
