<template>
  <div class="option-background-image">
    <label class="option__label">{{ label }}</label>
    <label class="file-uploader" :style="{ 'background-image': preview }">
      <input
        ref="fileInput"
        class="d-sr-only"
        type="file"
        :accept="'image/jpeg, image/png, image/gif'"
        @change="sendFile" />
      <v-btn
        class="upload-btn white font-weight-medium btn-black-border"
        depressed
        outlined
        :ripple="false"
        @click="$refs.fileInput.click()">
        {{ $t('optionBackgroundImage.upload') }}
      </v-btn>
    </label>
    <p class="gray--text mt-1">{{ $t('optionBackgroundImage.upload-info') }}</p>
    <label class="option__label">
      {{ $t('optionBackgroundImage.image-url') }}
    </label>
    <v-text-field
      v-model="url"
      placeholder=""
      outlined
      dense
      @change="changeImageByUrl" />
  </div>
</template>

<script>
import { uploadFile } from '@/utilities/fileHandler'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'OptionBackgroundImage',
  components: {},
  props: {
    element: [HTMLElement, null],
    label: String,
  },
  data() {
    return {
      preview: '',
      url: '',
    }
  },
  computed: {
    pageType() {
      return this.$route.name.includes('letter') ? 'letter' : 'template'
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.preview = this.element.style.backgroundImage
      this.url = this.preview.slice(4, -1).replace(/"/g, '')
    },
    async sendFile(event) {
      try {
        const result = await uploadFile(
          event.target.files[0],
          'import/image/upload',
          this.pageType
        )
        this.preview = `url(${result.url})`
        this.url = result.url
        this.element.style.backgroundImage = `url(${result.url})`
        displayNotify(this.$t('optionBackgroundImage.image-updated'))
      } catch (e) {
        displayNotify(
          this.$t('optionBackgroundImage.image-not-updated'),
          'error'
        )
      }
    },
    changeImageByUrl() {
      this.preview = `url(${this.url})`
      this.element.style.backgroundImage = `url(${this.url})`
    },
  },
}
</script>

<style lang="scss">
.option-background-image {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  .option-label {
    width: 100%;
  }

  .file-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed;
    border-radius: 15px;
    width: 250px;
    height: 130px;
    background-position: center;
    background-size: contain;
    position: relative;
  }

  label.file-uploader::before {
    border-radius: 15px;
    opacity: 0.3;
    background-color: #000000;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .module-option__background {
    background-color: #ffffff;
    border: 1px solid #a9a9a9;
    border-radius: 15px;
    text-align: center;
    padding: 0 0 1rem 0;
  }

  .upload-btn {
    border-radius: 10px;

    &:focus:before {
      opacity: 0;
    }

    &:hover {
      background-color: #151515 !important;
      border-color: #151515 !important;
      color: #fff;

      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
