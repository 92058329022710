<template>
  <div class="option-hide">
    <label class="label">{{ $t('optionHide.hide') }} {{ label }}</label>
    <v-switch
      inset
      hide-details
      v-model="value"
      @change="setHideElement"
      :ripple="false"></v-switch>
  </div>
</template>

<script>
export default {
  name: 'OptionHide',
  components: {},
  props: {
    element: [HTMLElement, null],
    label: String,
  },
  data() {
    return {
      value: '',
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.value = this.element.style.display == 'none' ? true : false
    },
    setHideElement(value) {
      this.element.style.display = value ? 'none' : ''
    },
  },
}
</script>

<style lang="scss">
.option-hide {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .label {
    margin: 0;
    display: flex;
    align-items: center;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
