<template>
  <div class="option-link">
    <label class="option__label">{{ $t('optionGSS.link-to-sheet') }}</label>
    <v-text-field
      v-model="linkToSheet"
      placeholder="https://docs.google.com/..."
      outlined
      dense />
    <div class="option-element">
      <v-btn
        depressed
        block
        outlined
        class="btn-small btn-black-border"
        :ripple="false"
        @click="downloadFromSheet">
        {{ $t('optionGSS.download') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { parseGoogleSpreadsheet } from '@/utilities/letter'
import { v4 as uuidv4 } from 'uuid'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'OptionGoogleSpreadsheets',
  props: {
    element: [HTMLElement, null],
  },
  data() {
    return {
      linkToSheet: '',
      data: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const fields = this.element.querySelectorAll(
        '[letteros-google-text], [letteros-google-img], [letteros-google-url]'
      )

      if (!fields.length) return

      fields.forEach((field) => {
        let value = null
        let type = null

        if (field.hasAttribute('letteros-google-text')) {
          value = field.getAttribute('letteros-google-text')
          type = 'text'
        }
        if (field.hasAttribute('letteros-google-img')) {
          value = field.getAttribute('letteros-google-img')
          type = 'image'
        }
        if (field.hasAttribute('letteros-google-url')) {
          value = field.getAttribute('letteros-google-url')
          type = 'link'
        }

        if (!type || !value) return

        const uniqId = uuidv4()

        this.data.push({
          id: uniqId,
          type: type,
          value: value,
        })

        field.setAttribute('letteros-google-uuid', uniqId)
      })
    },
    async downloadFromSheet() {
      if (!this.linkToSheet) return
      this.$loader('show')

      let result = null
      try {
        result = await parseGoogleSpreadsheet(this.data, this.linkToSheet)
      } catch (e) {
        displayNotify(this.$t('optionGSS.parse-error'), 'error')
        this.$loader('hide')
        return
      }

      if (!result) {
        displayNotify(this.$t('optionGSS.parse-error'), 'error')
        this.$loader('hide')
        return
      }

      this.putDataToElement(result)

      displayNotify(this.$t('optionGSS.parse-success'))
      this.$loader('hide')
    },
    putDataToElement(result) {
      result.forEach((item) => {
        const element = this.element.querySelector(
          '[letteros-google-uuid="' + item.id + '"]'
        )

        if (item.type === 'link') {
          element.setAttribute('letteros-href', item.response)
          element.setAttribute(
            'data-original-title',
            decodeURIComponent(item.response)
          )
        }

        if (item.type === 'image') {
          element.setAttribute('src', item.response)
        }

        if (item.type === 'text') {
          element.innerHTML = item.response
        }

        element.removeAttribute('letteros-google-uuid')
      })
    },
  },
}
</script>
