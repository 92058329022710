<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <g clip-path="url(#clip0_5831_265)">
      <rect x="8.56152" y="21.4084" width="31.9317" height="29" rx="5" transform="rotate(-45 8.56152 21.4084)" fill="#D7D7D7"/>
      <rect x="20.8898" y="32.3235" width="29.9687" height="6.13114" transform="rotate(-45 20.8898 32.3235)" fill="#151515" stroke="white" stroke-width="2"/>
      <rect y="20.4221" width="41" height="29" rx="5" fill="#151515"/>
      <circle cx="9" cy="29.4221" r="4" fill="white"/>
      <rect x="5" y="39.4221" width="8" height="3" rx="1.5" fill="white"/>
      <rect x="16" y="39.4221" width="9" height="3" rx="1.5" fill="white"/>
      <rect x="28" y="39.4221" width="8" height="3" rx="1.5" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_5831_265">
        <rect width="50" height="50" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 50},
    height: {type: [Number, String], default: 50},
    stroke: {type: String, default: '#151515'},
    viewBox: {type: String, default: '0 0 50 50'},
    fill: {type: String, default: 'none'}
  }
}
</script>