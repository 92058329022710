<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M12.7489 19.9791C12.7489 21.649 11.379 23 9.68562 23H4.06327C2.36991 23 1 21.649 1 19.9791V4.0209C1 2.35096 2.36991 1 4.06327 1H9.68562C11.379 1 12.7489 2.35096 12.7489 4.0209" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.6798 11.9678H8.34326" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.2874 16.583L22.7205 12.6614C23.0916 12.3331 23.0916 11.7702 22.7301 11.4324L18.2969 7.40771" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>

  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>