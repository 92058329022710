<template>
  <div>
    <v-list-item
      v-for="(directory, index) in folders"
      :key="`${type}-${index}`"
      class="directory">
      <v-list-item-content>
        <router-link
          class="folder-link d-flex"
          :to="{ name: linkTo, params: { id: directory.id } }">
          <div class="sidebar-counter">
            {{ directory.count }}
          </div>
          {{ directory.name }}
        </router-link>
        <div class="folder-btn-bar">
          <v-btn
            icon
            @click="$emit('folder-edit', { type, directory })"
            :ripple="false">
            <IconPencil size="18" />
          </v-btn>
          <v-btn
            icon
            @click="$emit('folder-delete', { type, directory })"
            :ripple="false">
            <IconDelete size="18" />
          </v-btn>
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import IconDelete from '@/components/icons/IconDelete.vue'
import IconPencil from '@/components/icons/IconPencil.vue'

export default {
  name: 'FolderList',
  components: {
    IconDelete,
    IconPencil,
  },
  props: {
    type: String,
    folders: Array,
  },
  computed: {
    linkTo() {
      if (this.type == 'letter') {
        return 'directory-letter'
      }

      if (this.type == 'template') {
        return 'directory-template'
      }

      if (this.type == 'custom') {
        return 'directory-letter-custom'
      }

      return ''
    },
  },
}
</script>
