<template>
  <header class="header-base">
    <v-btn
      class="header-sidebar-toggle btn-small mr-4"
      :class="{ 'btn-menu-active': sidebarShow }"
      :ripple="false"
      outlined
      @click="toggleSidebar()">
      {{ $t('menu') }}
      <v-icon right size="25">mdi-chevron-down</v-icon>
    </v-btn>
    <router-link class="d-flex" :to="{ name: 'letter-index' }">
      <IconLetterosLogo />
    </router-link>
    <div class="mr-auto ml-auto duplicate-menu"></div>
    <!--    <HeaderNotification />-->
    <slot name="project-menu" />
    <slot name="user-menu" />
  </header>
</template>

<script>
import IconLetterosLogo from '../icons/IconLetterosLogo.vue'

export default {
  name: 'HeaderAnalytics',
  components: {
    IconLetterosLogo,
  },
  props: {
    sidebarShow: Boolean,
  },
  data() {
    return {
      menu: null,
    }
  },
  computed: {
    projects() {
      return this.$store.state.userStore.user.accounts
    },
    account() {
      return this.$store.getters.accountLight
    },
    userNameLastName() {
      return `${this.$store.state.userStore.user.firstname} ${this.$store.state.userStore.user.lastname}`
    },
    userEmail() {
      return this.$store.state.userStore.user.email
    },
    userAvatar() {
      return this.$store.state.userStore.user.avatarUrl ?? '/icons/avatar.svg'
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar')
    },
  },
}
</script>

<style lang="scss">
.header-base {
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
}
.duplicate-menu {
  a.duplicate-link {
    margin: 0 15px;
    text-decoration: none;
    color: #151515;
    &:hover {
      color: #001dff;
    }
    &.duplicate-active {
      color: #001dff;
    }
  }
}
</style>
<i18n>
{
  "ru": {
    "menu": "Меню"
  },
  "en": {
    "menu": "Menu"
  }
}
</i18n>
