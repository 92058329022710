import itemsStore from '@/store/dynamic/items.js'

export default {
  beforeCreate() {
    if (!this.$store.hasModule('itemsStore')) {
      this.$store.registerModule('itemsStore', itemsStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('itemsStore')) {
      this.$store.registerModule('itemsStore', itemsStore)
    }
  },
  beforeDestroy() {
    // console.log('destroyed letter')
    if (this.$store.hasModule('itemsStore')) {
      this.$store.unregisterModule('itemsStore')
    }
  },
}
