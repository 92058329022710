<template>
  <v-main class="user-profile">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="8" sm="10" cols="12">
          <h1 class="title-h1 text-center mb-8">
            {{ $t('userProfile.my-profile') }}
          </h1>

          <div v-if="isUserExist">
            <ProfileBasic />
            <ProfilePassword />
            <ProfileData />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import 'simple-notify/dist/simple-notify.min.css'
import ProfileBasic from '@/components/profile/ProfileBasic.vue'
import ProfilePassword from '@/components/profile/ProfilePassword.vue'
import ProfileData from '@/components/profile/ProfileData.vue'

export default {
  name: 'UserProfile',
  components: {
    ProfileData,
    ProfilePassword,
    ProfileBasic,
  },
  computed: {
    isUserExist() {
      return this.$store.state.userStore.user.id
    },
  },
  watch: {
    isUserExist(newValue) {
      if (newValue !== null) this.$loader('hide')
    },
  },
  created() {
    document.title = this.$t('userProfile.my-profile')
    if (this.isUserExist === null) this.$loader('show')
  },
}
</script>

<style lang="scss">
.user-profile {
  .file-note {
    width: 250px;
    margin: 0 auto;
  }
}
</style>
