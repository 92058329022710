<template>
  <div
    class="code-editor-wrap"
    :class="{ 'code-editor-expanded': codeEditorExpanded }">
    <div class="code-editor-panel">
      <p class="code-editor-title mb-0 mr-5 pb-0">Html</p>
      <v-btn
        v-if="module"
        class="mr-2"
        icon
        color="white"
        :ripple="false"
        @click="toggleReadonly">
        <IconLock v-if="editorReadOnly" />
        <IconUnlock v-else />
      </v-btn>
      <v-btn
        class="mr-2"
        icon
        color="white"
        :ripple="false"
        @click="toggleExpand">
        <IconCollapse v-if="codeEditorExpanded" />
        <IconExpand v-else />
      </v-btn>
      <v-btn v-if="!module" class="mx-auto" :ripple="false" @click="sendModule">
        {{ $t('ModuleCodeEditor.save') }}
      </v-btn>
    </div>
    <div class="editor" ref="editor">
      <div id="editorCode" ref="editor-code"></div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import * as monaco from 'monaco-editor'

import IconExpand from '@/components/icons/IconExpand.vue'
import IconCollapse from '@/components/icons/IconCollapse.vue'
import IconLock from '@/components/icons/IconLock.vue'
import IconUnlock from '@/components/icons/IconUnlock.vue'

export default {
  name: 'ModuleCodeEditor',
  components: {
    IconExpand,
    IconCollapse,
    IconLock,
    IconUnlock,
  },
  props: {
    module: [Element, null],
  },
  data() {
    return {
      editor: false,
      html: '',
      editorReadOnly: true,
      codeEditorExpanded: false,
    }
  },
  created() {
    if (this.module) this.html = this.module.innerHTML
  },
  mounted() {
    this.create()
    this.run()
  },
  beforeDestroy() {
    this.editor.dispose()
  },
  methods: {
    create() {
      let codeContainer = this.$refs['editor-code']
      this.editor = monaco.editor.create(codeContainer, {
        value: this.html,
        language: 'html',
        minimap: { enabled: false },
        automaticLayout: true,
        contextmenu: false,
        fontSize: 12,
        scrollbar: {
          useShadows: true,
          vertical: 'visible',
          horizontal: 'hidden',
          horizontalScrollbarSize: 12,
          verticalScrollbarSize: 12,
        },
        theme: 'vs-dark',
        tabSize: 2,
        wordWrap: 'on',
        scrollBeyondLastLine: false,
        autoIndent: true,
        formatOnPaste: true,
        formatOnType: true,
        hover: false,
        readOnly: false,
      })
    },
    run() {
      // Прослушка изменений контента
      this.editor.onDidChangeModelContent(() => {
        if (this.module) {
          this.module.innerHTML = this.editor.getValue()
          this.$store.commit('editor/toggleModuleOnceRefresh', true)
        }
      })

      // хак для форматирования при первой инициализации, пока разрабы не сделают нормальный хук
      new Promise((resolve) => {
        setTimeout(() => {
          this.editor.focus()
          resolve()
        }, 250)
      }).then(async () => {
        await this.editor.getAction('editor.action.formatDocument').run()

        if (this.module) {
          this.editor.updateOptions({
            readOnly: this.editorReadOnly,
          })
        }
      })
    },
    // Переключение режим чтения
    toggleReadonly() {
      this.editorReadOnly = !this.editorReadOnly
      this.editor.updateOptions({
        readOnly: this.editorReadOnly,
      })
    },
    // Переключение высота редактора
    toggleExpand() {
      this.codeEditorExpanded = !this.codeEditorExpanded
      if (this.codeEditorExpanded) {
        this.$refs['editor'].classList.add('full')
      } else {
        this.$refs['editor'].classList.remove('full')
      }
    },
    sendModule() {
      // this.$emit('new-module', '')
      console.log(this.editor.getValue())
    },
  },
}
</script>
<style lang="scss">
.editor {
  width: 100%;
  max-width: 100vw;
  margin: 0;
  height: 325px;
  display: flex;
  position: relative;
  overflow: hidden;
  &.full {
    height: calc(100vh - 50px);
  }
}

.editor > * {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
