import tableStore from '@/store/dynamic/table.js'

export default {
  beforeCreate() {
    if (!this.$store.hasModule('tableStore')) {
      this.$store.registerModule('tableStore', tableStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('tableStore')) {
      this.$store.registerModule('tableStore', tableStore)
    }
  },
  beforeDestroy() {
    // console.log('destroyed letter')
    if (this.$store.hasModule('tableStore')) {
      this.$store.unregisterModule('tableStore')
    }
  },
}
