<template>
  <tr class="table-row">
    <td class="table-checkbox-wrapper table-checkbox-wrapper--analytics">
      <v-checkbox
        :value="$store.state.itemsStore.selected[index]"
        class="table-checkbox"
        @change="
          $store.commit('itemsStore/changeSelected', {
            index,
            value: $event,
          })
        " />
    </td>
    <td class="table-info">
      <!-- [Переписать|editMode] -->
      <input
        :ref="`rename${index}`"
        :disabled="
          !(
            $store.state.tableStore.editMode === 'rename' &&
            $store.state.tableStore.currentEditId === item.id
          )
        "
        class="name font-weight-medium"
        :value="item.name"
        @blur="renameHandler" />
    </td>
    <td class="table-info">
      {{ item.subscribers || '?' }}
    </td>
    <td class="table-info">
      {{ item.open }}
      <div class="time">{{ item.open_percent || '?' }}%</div>
    </td>
    <td class="table-info">
      {{ item.click }}
      <div class="time">{{ item.click_percent || '?' }}%</div>
    </td>
    <td class="table-action-menu">
      <ElementMenuAnalytics :item="item" />
    </td>
  </tr>
</template>

<script>
// [Переписать] убрать обращения к стору внутри template
import ElementMenuAnalytics from '@/components/home/element/ElementMenuAnalytics.vue'

export default {
  name: 'ItemAnalytics',
  components: {
    ElementMenuAnalytics,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    renameHandler(e) {
      this.$store.dispatch('itemsStore/renameHandler', {
        id: this.item.id,
        name: e.target.value,
      })
      this.$store.commit('tableStore/changeEditMode', '')
    },
  },
}
</script>
