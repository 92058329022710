<template>
  <div>
    <v-data-table
      class="table-simple"
      :items="items"
      hide-default-header
      hide-default-footer>
      <template #item="{ item }">
        <ItemRow :item="item" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ItemRow from '@/components/home/items/ItemRow.vue'

export default {
  name: 'RowContainer',
  components: {
    ItemRow,
  },
  computed: {
    items() {
      return this.$store.state.itemsStore.items
    },
  },
}
</script>
