<template>
  <v-card class="folder-move">
    <v-card-title class="text-h5 justify-space-between">
      {{ $t('documentTranslate.translate-options') }}
      <v-btn depressed :ripple="false" icon @click="$emit('close')">
        <IconClose />
      </v-btn>
    </v-card-title>
    <v-card-text class="justify-center">
      <label>{{ $t('documentTranslate.translate-driver') }}</label>
      <v-radio-group v-model="selectedDriver" @change="selectedLang = 'en'">
        <v-radio
          v-for="(lang, index) in langList"
          :key="index"
          :label="lang.title"
          :value="lang.name" />
      </v-radio-group>
    </v-card-text>
    <v-card-text class="justify-center">
      <label>{{ $t('documentTranslate.target-language') }}</label>
      <v-select
        v-model="selectedLang"
        :items="driverLang"
        item-text="name"
        item-value="code"
        outlined />
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        depressed
        outlined
        x-large
        class="mr-3 px-8 black-hover"
        :ripple="false"
        @click="$emit('close')">
        {{ $t('documentTranslate.cancel') }}
      </v-btn>
      <v-btn
        depressed
        color="primary px-8"
        x-large
        :ripple="false"
        @click="translate">
        {{ $t('documentTranslate.translate') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable */
import IconClose from '@/components/icons/IconClose.vue'

import { checkIfLettersLimitReached } from '@/utilities/project'
import { translateLetter } from '@/utilities/letter'

import { yandex, google } from './lang.js'

import project from '@/mixins/project.mixin'

export default {
  name: 'DocumentTranslate',
  components: { IconClose },
  mixins: [project],
  data() {
    return {
      langList: [
        {
          name: 'yandex',
          title: this.$t('documentTranslate.yandex'),
          values: yandex,
        },
        {
          name: 'google',
          title: this.$t('documentTranslate.google'),
          values: google,
        },
      ],
      selectedDriver: 'yandex',
      selectedLang: 'en',
    }
  },
  computed: {
    itemId() {
      return this.$store.state.tableStore.currentEditId
    },
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    routeLink() {
      return this.pageType == 'letter' ? 'letter-editor' : 'letter-custom'
    },
    driverLang() {
      return this.langList.find((n) => n.name == this.selectedDriver).values
    },
    langObject() {
      return this.driverLang.find((n) => n.code === this.selectedLang)
    },
  },
  methods: {
    async translate() {
      const options = {
        id: this.itemId,
        lang: this.langObject,
        driver: this.selectedDriver === 'yandex' ? 0 : 1,
      }

      // [Переписать]
      if (checkIfLettersLimitReached(this.$store.state.project.full)) {
        const answer = await this.$parent.$refs.paymentForExtraLetter.open()
        if (answer === false) return
      }

      // [Переписать] в случае ошибки запроса выводим уведомление
      const result = await translateLetter(options)
      this.$router.push({ name: this.routeLink, params: { id: result.id } })

      // [Переписать]
      await this.loadProject()
    },
  },
}
</script>
