/* eslint-disable */
// [Переписать] подключить стор динамически пример подключения можно найти в mixins/letterCustom/dynamicStore.mixin.js

import axios from '@/plugins/axios_and_toast.js'

const state = () => ({
  registrationStep: 1,
  email: '',
  password: '',
})

const getters = {}

const mutations = {
  changeEmail(state, payload) {
    state.email = payload
  },
  changePassword(state, payload) {
    state.password = payload
  },
  changeRegistrationStep(state, n) {
    state.registrationStep = n
  },
}

const actions = {
  async login({}, payload) {
    try {
      const result = await axios.post('/auth/login?expand=accounts', payload)
      return result
    } catch (e) {
      return false
    }
  },
  async signup({ state }, payload) {
    try {
      const result = await axios.post('/user/signup', {
        email: state.email,
        password: state.password,
      })
      return result
    } catch (e) {
      return false
    }
  },
  async activate({}, payload) {
    try {
      const result = await axios.put(
        `/user/update?expand=accounts&token=${payload.token}`,
        payload.data
      )
      return result
    } catch (e) {
      return false
    }
  },
  async forgot({}, payload) {
    try {
      const result = await axios.put(`/user/forgot`, payload)
      return result.data
    } catch (e) {
      return false
    }
  },
  async recovery({}, payload) {
    try {
      const result = await axios.put(
        `/user/change-password?expand=accounts&token=${payload.token}`,
        {
          password: payload.password,
        }
      )
      return result
    } catch (e) {
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
