<template>
  <div
    class="template-editor"
    :class="{ 'dark-theme': enableDarkTheme }"
    :style="{
      'background-color': enableDarkTheme ? '#000000' : '#ffffff',
    }">
    <SidebarEditor
      :class="{ active: sidebarEditorShow }"
      :text-editor="textEditor" />
    <div class="workarea-wrapper">
      <article
        id="workarea"
        class="workarea"
        :style="viewPortCss"
        v-if="groups">
        <div
          v-for="group in groups"
          :key="`group-${group.id}`"
          class="module-group">
          <h3 class="mb-2 module-group-title">
            {{ group.name }}
          </h3>

          <section
            v-for="module in group.modules"
            :id="`module-${module.id}`"
            :key="module.id"
            :data-uuid="module.id"
            class="editor-module"
            :class="{ active: selectedModuleUuid == module.id }"
            @mousedown.stop="moduleClickHandler($event, module.id, group.id)">
            <div v-show="selectedModuleUuid === module.id" class="btn-bar">
              <ModuleControls :uuid="module.id" />
            </div>
            <div
              class="module-code"
              v-intersect="onIntersect"
              v-html="module.html" />
          </section>

          <v-btn
            class="btn-small btn-black-border"
            :ripple="false"
            outlined
            link
            large
            @click.stop="showDialogForNewModule(group.id)">
            <IconPlus class="mr-2" />
            {{ $t('templateEditor.add-module') }}
          </v-btn>
        </div>
      </article>
    </div>
    <v-dialog
      v-model="codeEditorModalShow"
      width="100%"
      content-class="code-editor"
      :transition="false">
      <ModuleCodeEditor
        v-if="codeEditorModalShow"
        :module="selectedElementCode"
        @new-module="addNewModule" />
      <v-btn
        class="code-editor-close"
        :ripple="false"
        icon
        color="white"
        @click="closeCodeEditor">
        <IconClose />
      </v-btn>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { displayNotify } from '@/utilities/helpers'
import { v4 as uuidv4 } from 'uuid'

import ModuleCodeEditor from '@/components/editor/ModuleCodeEditor.vue'
import ModuleControls from '@/components/editor/ModuleControls.vue'
import SidebarEditor from '@/components/sidebars/SidebarEditor.vue'

import IconClose from '@/components/icons/IconClose.vue'
import IconPlus from '@/components/icons/IconPlus'

import dynamicStore from '@/mixins/TemplateEditor/dynamicStore.mixin.js'

import selectableMixin from '@/mixins/selectable.mixin.js'
import resizableMixin from '@/mixins/resizable.mixin.js'
import moduleMixin from '@/mixins/module.mixin.js'
import editorMixin from '@/mixins/editor.mixin.js'
import emojiMixin from '@/mixins/emoji.mixin.js'

export default {
  name: 'TemplateEditor',
  mixins: [
    dynamicStore,
    selectableMixin,
    resizableMixin,
    moduleMixin,
    editorMixin,
    emojiMixin,
  ],
  components: {
    IconPlus,
    IconClose,
    SidebarEditor,
    ModuleControls,
    ModuleCodeEditor,
  },
  data() {
    return {
      textEditor: null,
      gridWidthMobile: 375,
    }
  },
  computed: {
    groups() {
      return this.$store.state.template.groups
    },
    gridWidth() {
      return parseInt(this.$store.state.template.config.grid) ?? 600
    },
    device() {
      return this.$store.state.template.deviceType
    },
    modules() {
      return this.$store.getters['template/getModulesWithUuid']
    },
    selectedModuleUuid() {
      return this.$store.state.editor.selectedModuleUuid
    },
    selectedModule() {
      return this.$store.state.editor.selectedModule
    },
    selectedElement() {
      return this.$store.state.editor.selectedElement
    },
    selectedGroupId() {
      return this.$store.state.editor.selectedGroupId
    },
    // [Переписать] [LATER]
    viewPortCss() {
      if (this.device !== 'desktop')
        return {
          'max-width': `${this.gridWidthMobile}px`,
          width: `${this.gridWidthMobile}px`,
        }

      let grid = this.gridWidth
      if (grid === undefined || !grid)
        return {
          'max-width': `${this.gridWidth}px`,
          width: `${this.gridWidth}px`,
        }

      // из-за непоследовательности на беке, есть шаблоны и с px и без
      if (typeof grid === 'number') grid += 'px'
      return {
        'max-width': grid,
        width: grid,
      }
    },
    selectedElementCode() {
      return this.selectedModule
        ? this.selectedModule.querySelector('.module-code')
        : null
    },
    codeEditorModalShow: {
      get() {
        return this.$store.state.editor.codeEditorModalShow
      },
      set() {
        this.$store.commit('editor/toggleCodeEditor')
      },
    },
    sidebarEditorShow() {
      return !!this.selectedModule || !!this.selectedElement
    },
    enableDarkTheme() {
      return this.$store.state.template.enableDarkTheme
    },
    emojiPickerId() {
      return this.$store.getters['editor/getEmojiPickerId']
    },
  },
  created() {
    this.loadTemplate()
  },
  mounted() {
    document
      .getElementById('app')
      .addEventListener('mousedown', this.outsideClickHandler)
    if (!this.emojiPickerId) {
      this.initEmojiPicker()
    }
  },
  beforeDestroy() {
    document
      .getElementById('app')
      .removeEventListener('mousedown', this.outsideClickHandler)
    if (this.emojiPickerId) {
      document.getElementById(this.emojiPickerId).remove()
    }
  },
  methods: {
    // Загрузка шаблона
    async loadTemplate() {
      this.$loader('show')

      // [Переписать] проверить запрос [NEW_API]
      const result = await this.$store.dispatch(
        'template/loadTemplate',
        this.$route.params.id
      )

      if (!result) {
        this.$loader('hide')
        displayNotify('При загрузке шаблона произошла ошибка', 'error')
        return
      }

      const transformedGroupModules = result.modulesGroups.map((group) => {
        const transformedModules = group.modules.map((module) =>
          this.getHTMLModule(module)
        )
        return { ...group, modules: transformedModules }
      })

      const template = {
        ...result,
        modulesGroups: transformedGroupModules,
      }

      this.$store.commit('template/setTemplate', template)

      this.$loader('hide')
    },
    // Закрытие редактора кода
    closeCodeEditor() {
      this.$store.commit('editor/toggleCodeEditor')
    },
    // Открытие модалки для добавления нового модуля
    showDialogForNewModule(id) {
      this.$store.commit('editor/updateGroupId', id)
      this.$store.commit('editor/clearSelected')
      this.$store.commit('editor/toggleCodeEditor')
    },

    // Добавление нового модуля в стейт [Переписать] провалидировать html и вынести в стор [NEW_API]
    addNewModule(e) {
      console.log(this.selectedGroupId, e, 'save new module popup')
      // try {
      //   const groupId = this.groups[this.selectedGroupIndex].id
      //   const variantNumber =
      //     this.groups[this.selectedGroupIndex].modules.length + 1
      //   const module = {
      //     name: `${this.$t('variant')} ${variantNumber}`,
      //     groupId: groupId,
      //     uuid: uuidv4(),
      //     html: e,
      //   }
      //   // this.groups[this.selectedGroupIndex].modules.push(module)
      // } catch (e) {
      //   console.log('new Module Handler', e)
      // }
    },
  },
}
</script>

<style lang="scss">
.template-editor {
  position: relative;
  top: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  &.dark-theme {
    .btn-black-border {
      filter: invert(100%);
    }
    .btn-gray-border {
      .v-btn__content {
        filter: invert(100%);
      }
    }
    .module-group-title,
    .module-code:before {
      filter: invert(100%);
    }
    [letteros-element]:not([letteros-system]) {
      filter: invert(100%);
      img {
        filter: invert(100%);
      }
    }
  }

  .workarea-wrapper {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-flow: column nowrap;

    .module-group {
      width: 100%;
      margin: 0 0 1.5rem 0;
    }

    .editor-module {
      display: flex;
      flex-flow: row nowrap;
      position: relative;
      margin: 0 0 10px 0;

      td[mailfit-resizable-height],
      td[letteros-resizable-height],
      td[letteros-resizable-new] {
        position: relative;
      }

      .resizable-ui {
        position: absolute;
        bottom: -9px;
        left: 50%;
        width: 60px;
        height: 8px;
        font-size: 10px;
        line-height: 13px;
        text-align: center;
        color: #a9a9a9;
        background: $color-white;
        transform: translate(-50%, 0%);
        border-radius: 2px;
        z-index: 1;
        box-shadow: 0 0 0 1px rgb(0 0 0 / 14%), 0px 5px 3px rgb(0 0 0 / 5%);
        cursor: s-resize;
        opacity: 0;
        transition: all 0.2s ease;
        pointer-events: none;
        .resizable-arrow {
          color: $color-black;
          background: rgba(255, 255, 255, 0.5);
          opacity: 0.5;
          display: none;
          position: absolute;
          top: 0;
          width: 60px;
          z-index: 111;
          border-radius: 2px;
        }
        .resizable-arrow.active {
          display: block;
        }
      }
      .resizable-ui:before {
        content: '';
        display: block;
        position: absolute;
        top: 60%;
        left: 50%;
        width: 11px;
        background: #9a9a9a;
        height: 1px;
        transform: translate(-50%, 0%);
        z-index: 1;
      }
      .resizable-ui:after {
        content: '';
        display: block;
        position: absolute;
        top: 30%;
        left: 50%;
        width: 11px;
        background: #9a9a9a;
        height: 1px;
        transform: translate(-50%, 0%);
        z-index: 1;
      }
      .resizable-ui.top {
        bottom: auto !important;
        top: 2px !important;
        .resizable-arrow {
          transform: translateY(100%);
        }
      }
      .resizable-ui.bottom {
        top: auto !important;
        bottom: 2px !important;
        .resizable-arrow {
          transform: translateY(-100%);
        }
      }
      .resizable-ui:hover {
        .resizable-arrow {
          display: block;
        }
      }

      .module-code {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        p {
          cursor: auto;
          padding-bottom: 0;
        }
      }
    }

    .editor-module.active {
      .btn-bar {
        display: inline-flex;

        .v-btn {
          display: flex;
        }
      }

      .resizable-ui {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .module-code:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid $color-dark;
      pointer-events: none;
      z-index: 1;
      [letteros-type='text'] {
        cursor: text;
      }
    }

    .editor-module.active {
      .module-code:before {
        border: 1px solid $color-red;
      }
    }
    .btn-bar {
      display: inline-flex;
      justify-content: space-between;
      width: 88px;
      position: absolute;
      left: -100px;
    }
  }
}
</style>
