<template>
  <div class="flex-grow-1 pb-8">
    <p class="title-h6 mb-4">
      {{ $t('testLink.checking-links') }}
    </p>
    <v-progress-linear
      v-if="progress > 1 && progress < 100"
      :value="progress"
      color="dark"
      height="36">
      <template v-slot:default="{ value }">
        <strong class="white--text">{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
    <div class="d-flex" v-else-if="total">
      <v-btn
        class="btn-small mr-4 flex-grow-1 btn-black-border"
        :ripple="false"
        depressed
        outlined
        @click="openResultModal">
        {{ $t('testLink.result') }}
      </v-btn>
      <v-btn
        class="btn-small btn-black-border"
        :ripple="false"
        depressed
        outlined
        icon
        @click="startCheck">
        <IconRefresh />
      </v-btn>
    </div>
    <v-btn
      v-else
      class="btn-small btn-black-border"
      :ripple="false"
      depressed
      block
      outlined
      @click="startCheck">
      {{ $t('testLink.start') }}
    </v-btn>
    <v-dialog v-model="resultShow" width="auto">
      <v-card class="result-links">
        <v-card-title>{{ $t('testLink.result-checking-links') }}</v-card-title>
        <v-divider></v-divider>
        <v-btn-toggle v-model="selectedTab">
          <v-btn value="valid" class="noborder" :ripple="false">
            {{ $t('testLink.valid-links') }} {{ validUrls.length }}
          </v-btn>
          <v-btn value="invalid" class="noborder" :ripple="false">
            {{ $t('testLink.invalid-links') }} {{ invalidUrls.length }}
          </v-btn>
          <v-btn value="other" class="noborder" :ripple="false">
            {{ $t('testLink.other-links') }} {{ otherUrls.length }}
          </v-btn>
        </v-btn-toggle>
        <v-divider></v-divider>
        <v-list height="500" class="overflow-y-auto">
          <v-list-item
            v-for="(item, index) in urls"
            :key="index"
            @click="scrollToLink(item)">
            {{ index + 1 }}. {{ item.url }}
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { checkLinks } from '@/utilities/letter.js'
import IconRefresh from '@/components/icons/IconRefresh.vue'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'TestLink',
  components: { IconRefresh },
  data() {
    return {
      total: null,
      checked: null,
      selectedTab: 'invalid',
      validUrls: [],
      invalidUrls: [],
      otherUrls: [],
      resultShow: false,
      progress: null,
      complete: false,
    }
  },
  computed: {
    urls() {
      return this.selectedTab === 'invalid'
        ? this.invalidUrls
        : this.selectedTab === 'valid'
        ? this.validUrls
        : this.otherUrls
    },
    pageType() {
      return this.$route.name == 'letter-custom-editor' ? 'custom' : 'default'
    },
  },
  methods: {
    async startCheck() {
      this.resetData()
      this.updateProgress()
      const hrefs =
        this.pageType == 'custom' ? this.getFrameLinks() : this.getModuleLinks()

      if (hrefs.length) {
        const urlForApiCheck = this.prepareData(hrefs)
        if (urlForApiCheck.length) await this.checkByAPI(urlForApiCheck)
      }
      this.total = hrefs.length
      this.complete = true
    },
    resetData() {
      this.complete = false
      this.progress = null
      this.validUrls = []
      this.invalidUrls = []
      this.otherUrls = []
    },
    updateProgress() {
      let timerId = setInterval(() => {
        if (this.progress < 90) this.progress += 10
        if (this.complete) this.progress = 100

        if (this.progress >= 100) {
          clearInterval(timerId)
          displayNotify(this.$t('testLink.end'))
        }
      }, 100)
    },
    getModuleLinks() {
      const elements = document.querySelectorAll('.module-code a[href]')
      if (elements.length) {
        return this.linksLoop(elements)
      }
      return []
    },
    getFrameLinks() {
      const iframe = document.querySelector('.tox-edit-area__iframe')
      const body = iframe.contentWindow.document.body
      const links = body.querySelectorAll('a[href]')

      if (links.length) {
        return this.linksLoop(links)
      }

      return []
    },
    linksLoop(elements) {
      let hrefs = []
      elements.forEach((url) => hrefs.push(url.getAttribute('href')))

      const unique = (value, index, self) => {
        return self.indexOf(value) === index
      }
      hrefs = hrefs.filter(unique)

      return hrefs
    },
    prepareData(hrefs) {
      let urlForApiCheck = []
      for (const url of hrefs) {
        try {
          urlForApiCheck.push(this.validateUrl(url))
        } catch (e) {
          const elements = this.findElementsByUrl(url)
          elements.forEach((element) => {
            this.otherUrls.push({ element: element, url: url })
          })
        }
      }
      return urlForApiCheck
    },
    async checkByAPI(urlForApiCheck) {
      try {
        const result = await checkLinks(urlForApiCheck)
        // [Переписать] добавить уведомление в случае ошибки [LATER]
        const { success, error } = { ...result }
        if (success && success.length) {
          for (const url of success) {
            const elements = this.findElementsByUrl(url)
            elements.forEach((element) => {
              this.validUrls.push({ element: element, url: url })
            })
          }
        }
        if (error && error.length) {
          for (const url of error) {
            const elements = this.findElementsByUrl(url)
            elements.forEach((element) => {
              this.invalidUrls.push({ element: element, url: url })
            })
          }
        }
      } catch (e) {
        console.log('checkByAPI error', e)
        this.$loader('hide')
      }
    },
    validateUrl(url) {
      return new URL(url)
    },
    findElementsByUrl(url) {
      try {
        url = url.replace(/\/$/, '')
        const selector = `a[href="${url}"]`
        const place =
          this.pageType == 'custom'
            ? document.querySelector('.tox-edit-area__iframe').contentWindow
                .document.body
            : document
        return place.querySelectorAll(selector)
      } catch (e) {
        console.log('findElementsByUrl', e)
      }
    },
    openResultModal() {
      this.resultShow = true
    },
    scrollToLink(item) {
      item.element.scrollIntoView({ block: 'center' })
      this.resultShow = false
      item.element.classList.add('accent-link')
      if (this.pageType == 'custom') this.iframeCssAppend()
      setTimeout(() => {
        item.element.classList.remove('accent-link')
        if (this.pageType == 'custom') this.iframeCssRemove()
      }, 2500)
    },
    iframeCssAppend() {
      const iframe = document.querySelector('.tox-edit-area__iframe')
      const head = iframe.contentWindow.document.head
      const style = document.createElement('style')
      const cssRules =
        '.accent-link {box-shadow: 0 5px 15px 0 #f07746; transition: box-shadow 0.3s ease-in; display: inline-block;}'

      head.appendChild(style)
      style.id = 'letteros-link-style'
      style.appendChild(document.createTextNode(cssRules))
    },
    iframeCssRemove() {
      const iframe = document.querySelector('.tox-edit-area__iframe')
      const head = iframe.contentWindow.document.head
      const style = head.querySelector('#letteros-link-style')
      style.remove()
    },
  },
}
</script>

<style lang="scss">
.accent-link {
  box-shadow: 0 5px 15px 0 #f07746;
  transition: box-shadow 0.3s ease-in;
}
.result-links {
  .noborder {
    border: 0 !important;
    box-shadow: 0 0 0 !important;
    border-radius: 0 !important;
  }
}
</style>
