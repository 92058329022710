import Dexie from 'dexie'

let db = new Dexie('documentDb')
db.version(1).stores({
  documents: '++id, type, documentId, documentContent', // Primary key and indexed props
})

async function dbAdd(type, documentId, content) {
  try {
    // console.log('db add')
    return await db.documents.add({
      type: type,
      documentId: documentId,
      documentContent: content,
    })
  } catch (e) {
    console.log('dbAdd', e)
  }
}
// Только в undo-redo
async function dbGet(id) {
  return await db.documents.get(id)
}
// Не используется
async function dbCount() {
  return await db.documents.count()
}
// Не используется
async function dbClear() {
  return await db.documents.clear()
}
// Не используется
async function dbLast() {
  return await db.documents.orderBy('id').last()
}
// 1 раз в layoutLetter
async function dbRefresh() {
  db.delete()
  db = new Dexie('documentDb')
  db.version(1).stores({
    documents: '++id, type, documentId, documentContent', // Primary key and indexed props
  })
}

export { db, dbAdd, dbGet, dbCount, dbClear, dbRefresh, dbLast }
