<template>
  <v-dialog
      v-model="dialog"
      :width="options.width"
      :style="{ zIndex: options.zIndex }"
  >
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: "InsertTheCodeDialogWrapper",
  data() {
    return {
      dialog: false,
      options: {
        width: 600,
        zIndex: 200
      },
      resolve: null,
      reject: null
    }
  },
  provide: function() {
    return { agree: this.agree, cancel: this.cancel }
  },
  methods: {
    open(options) {
      this.dialog = true;
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  },
}
</script>

<style lang="scss">

</style>
