<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <g clip-path="url(#clip0_5829_257)">
    <path d="M35.2874 11.8779L35.2874 11.8779L35.2917 11.8823C35.368 11.9588 35.4381 12.0139 35.5 12.056V36.6119C35.5 38.2202 34.2369 39.5 32.7122 39.5H9.3816C7.8569 39.5 6.59375 38.2202 6.59375 36.6119V3.40166C6.59375 1.80074 7.85654 0.519622 9.37622 0.513552C9.37645 0.513551 9.37667 0.51355 9.37689 0.513549L24.2629 0.500077C24.3044 0.565395 24.3582 0.636273 24.4314 0.711684L24.4317 0.712019L35.2874 11.8779Z" fill="white" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.125 1.18132L34.7661 11.5H27.1366C25.4592 11.5 24.125 10.1851 24.125 8.59864V1.18132Z" fill="#151515" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="3" y="17.0215" width="29" height="12.9574" rx="2.46809" fill="#151515"/>
    <path d="M7.31909 26.2758V20.7227H8.5052V22.9201H10.846V20.7227H12.0321V26.2758H10.846V23.9197H8.5052V26.2758H7.31909Z" fill="white"/>
    <path d="M14.5753 26.2758V21.7222H12.8158V20.7227H17.5288V21.7222H15.7692V26.2758H14.5753Z" fill="white"/>
    <path d="M18.3115 26.2758V20.7227H19.529L21.1629 23.7769L22.781 20.7227H23.9671V26.2758H22.781V22.7535L21.4928 25.1652H20.7858L19.4976 22.7535V26.2758H18.3115Z" fill="white"/>
    <path d="M25.223 26.2758V20.7227H26.4091V25.2763H28.9148V26.2758H25.223Z" fill="white"/>
  </g>
    <defs>
      <clipPath id="clip0_5829_257">
        <rect width="40" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 40},
    height: {type: [Number, String], default: 40},
    stroke: {type: String, default: '#151515'},
    viewBox: {type: String, default: '0 0 40 40'},
    fill: {type: String, default: 'none'}
  }
}
</script>