<template>
  <svg width="24" height="24" id="googleplay" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.9305 1.28037C4.36373 0.939197 3.68826 0.908689 3.09915 1.19272L12.9765 11.0911L16.206 7.84649L4.9305 1.28037ZM2.19164 2.10882C2.06703 2.37265 2 2.66613 2 2.9682V21.0746C2 21.3779 2.06746 21.6671 2.19336 21.9253L12.0668 12.005L2.19164 2.10882ZM12.9778 12.9177L3.11638 22.826C3.37806 22.9471 3.6565 23 3.93451 23C4.27784 23 4.6203 22.9179 4.93054 22.7314L16.2082 16.1554L12.9778 12.9177ZM20.5004 10.3473C20.4966 10.3447 20.4923 10.3421 20.4884 10.34L17.3577 8.51681L13.887 12.0038L17.3598 15.4838C17.3598 15.4838 20.4966 13.6551 20.5004 13.6529C21.0827 13.2993 21.4303 12.6814 21.4303 11.9999C21.4303 11.3184 21.0827 10.7005 20.5004 10.3473Z" fill="#151515"></path> </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 32},
    height: {type: [Number, String], default: 26},
    viewBox: {type: String, default: '0 0 32 26'},
    fill: {type: String, default: '#151515'}
  }
}
</script>
