<template>
  <div
    class="code-editor-wrap"
    :class="{ 'code-editor-expanded': codeEditorExpanded }">
    <div class="code-editor-panel">
      <p class="code-editor-title mb-0 mr-5 pb-0">
        {{ $t('ModuleCodeEditor.wrap-title') }}
      </p>
      <v-btn
        class="mr-2"
        icon
        color="white"
        :ripple="false"
        @click="toggleExpand">
        <IconCollapse v-if="codeEditorExpanded" />
        <IconExpand v-else />
      </v-btn>
      <v-btn class="mx-auto" :ripple="false" @click="save">
        {{ $t('ModuleCodeEditor.save') }}
      </v-btn>
    </div>
    <div class="editor" ref="editor">
      <div id="editorCode" ref="editor-code"></div>
    </div>
  </div>
</template>

<script>
import * as monaco from 'monaco-editor'

import IconExpand from '@/components/icons/IconExpand.vue'
import IconCollapse from '@/components/icons/IconCollapse.vue'

import { wrapper } from '@/utilities/template'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'ModuleWrapUpdate',
  components: {
    IconExpand,
    IconCollapse,
  },
  data() {
    return {
      editor: false,
      html: '',
      codeEditorExpanded: false,
    }
  },
  async mounted() {
    await this.get()
  },
  beforeDestroy() {
    this.editor.dispose()
  },
  methods: {
    async get() {
      const result = await wrapper({
        type: 'get',
        id: this.$route.params.id,
      })

      if (result.document) {
        this.html = result.document
        this.create()
        this.run()
      }
    },
    create() {
      let codeContainer = this.$refs['editor-code']
      this.editor = monaco.editor.create(codeContainer, {
        value: this.html,
        language: 'html',
        minimap: { enabled: false },
        automaticLayout: true,
        contextmenu: false,
        fontSize: 12,
        scrollbar: {
          useShadows: true,
          vertical: 'visible',
          horizontal: 'hidden',
          horizontalScrollbarSize: 12,
          verticalScrollbarSize: 12,
        },
        theme: 'vs-dark',
        tabSize: 2,
        wordWrap: 'on',
        scrollBeyondLastLine: false,
        autoIndent: true,
        formatOnPaste: true,
        formatOnType: true,
        hover: false,
        readOnly: false,
      })
    },
    run() {
      // хак для форматирования при первой инициализации, пока разрабы не сделают нормальный хук
      new Promise((resolve) => {
        setTimeout(() => {
          this.editor.focus()
          resolve()
        }, 250)
      }).then(async () => {
        await this.editor.getAction('editor.action.formatDocument').run()
      })
    },
    async save() {
      await wrapper({
        type: 'set',
        id: this.$route.params.id,
        document: this.editor.getValue(),
      })

      displayNotify(this.$t('update-success'))
    },
    // Переключение высота редактора
    toggleExpand() {
      this.codeEditorExpanded = !this.codeEditorExpanded
      if (this.codeEditorExpanded) {
        this.$refs['editor'].classList.add('full')
      } else {
        this.$refs['editor'].classList.remove('full')
      }
    },
  },
}
</script>

<style lang="scss">
.editor {
  width: 100%;
  max-width: 100vw;
  margin: 0;
  height: 325px;
  display: flex;
  position: relative;
  overflow: hidden;
  &.full {
    height: calc(100vh - 50px);
  }
}

.editor > * {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
