<template>
  <div class="table-actions-menu">
    <v-btn
      class="list-card-main d-flex"
      :ripple="false"
      x-large
      color="primary"
      depressed
      @click="redirect">
      {{ $t('tableElementRedirectBtn.redirect-to-old') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ElementMenuRedirect',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    project() {
      return this.$store.state.project.full
    },
    directories() {
      return this.pageType === 'letter'
        ? this.project.lettersFolders
        : this.project.templatesFolders
    },
  },
  methods: {
    // Перенаправение в app.letteros для писем не из new.letteros
    redirect() {
      const linkType = this.pageType === 'template' ? 'templates' : 'mailings'

      const link = `${
        (window.oldAppUrl ?? process.env.VUE_APP_OLDAPP_HOST) ||
        'https://app.letteros.com'
      }/${linkType}/editor/${this.id}`

      window.location.href = link
    },
  },
}
</script>

<style lang="scss" scoped>
.table-actions-menu {
  display: flex;
  min-width: 223px;
  width: 100%;

  .list-card-main {
    flex: 3;
  }

  .list-card-dropdown {
    flex: 1;
  }

  .menu-item-header {
    background-color: $color-white;
    padding: 1rem;
  }

  .list-project {
    list-style: none;
    max-height: 375px;
    padding: 0;
    background-color: $color-white;

    .list-project-item {
      display: flex;
      flex-flow: row nowrap;
      margin: 1rem 0 0 0;
      cursor: pointer;

      &:hover {
        color: #001dff;
      }
    }
  }
}

.menu-blue {
  .v-btn {
    color: $color-white;
    background-color: $color-primary;
  }
}
</style>
