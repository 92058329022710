<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M7.8645 1V3.37457C7.8645 3.87075 8.38585 4.27833 9.03091 4.27833H14.6243C15.2694 4.27833 15.7907 3.87961 15.7907 3.37457V1" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.832 16.8511C14.0476 16.8511 15.8438 15.0501 15.8438 12.8285C15.8438 10.6069 14.0476 8.80591 11.832 8.80591C9.61642 8.80591 7.82031 10.6069 7.82031 12.8285C7.82031 15.0501 9.61642 16.8511 11.832 16.8511Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 4.17225V19.8639C1 21.6005 2.59055 23.0093 4.54339 23.0093H19.4504C21.4121 23.0093 22.9938 21.6005 22.9938 19.8639V7.23792C22.9938 6.40505 22.6227 5.60762 21.9511 5.01398L18.4607 1.92172C17.7891 1.32808 16.8878 1.00024 15.9423 1.00024L4.53456 1.02683C2.58171 1.03569 1 2.43562 1 4.17225Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>