<template>
  <v-menu
    content-class="project-menu"
    nudge-bottom="4"
    offset-y
    min-width="265"
    left>
    <template #activator="{ on, attrs }">
      <v-btn
        class="profile-avatar"
        :ripple="false"
        icon
        v-bind="attrs"
        v-on="on">
        <IconAvatarSmall v-if="!userAvatar" />
        <div
          class="user-avatar"
          :style="'background-image: url(' + userAvatar + ');'" />
      </v-btn>
    </template>
    <div class="project-menu-head d-flex">
      <div
        class="user-avatar mr-4 mt-1"
        :style="'background-image: url(' + userAvatar + ');'" />
      <div>
        <p class="font-weight-medium mb-0">
          {{ userNameLastName }}
        </p>
        <p class="gray--text mb-n3">
          {{ userEmail }}
        </p>
      </div>
    </div>
    <v-list class="pt-0">
      <v-list-item
        class="project-menu-item project-menu-settings"
        :ripple="false"
        :href="`${oldAppUrl}`"
        @click="changeVersion">
        <icon-arrow-up class="mr-4" />
        {{ $t('back-to-old-app') }}
      </v-list-item>
      <v-list-item
        class="project-menu-item project-menu-settings"
        :ripple="false"
        to="/user-profile">
        <icon-settings class="mr-4" />
        {{ $t('profile-settings') }}
      </v-list-item>
      <v-list-item
        class="project-menu-item project-menu-exit"
        :ripple="false"
        @click="logOut">
        <icon-exit class="mr-4" />
        {{ $t('log-out') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import IconExit from '@/components/icons/IconExit.vue'
import IconArrowUp from '@/components/icons/IconArrowUp'
import IconSettings from '@/components/icons/IconSettings.vue'
import IconAvatarSmall from '@/components/icons/IconAvatarSmall.vue'

import { setCookie } from '@/utilities/helpers'

export default {
  name: 'HeaderMenuUser',
  components: {
    IconAvatarSmall,
    IconExit,
    IconSettings,
    IconArrowUp,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {
          accounts: [],
          auth_key: null,
          avatar: null,
          createdAt: 0,
          email: 'nouser@letteros.com',
          errors: false,
          firstname: 'NoLogin',
          id: 0,
          lastname: 'Empty',
          name: 'User',
          phone: null,
          subscribeNews: false,
          superUser: false,
          updatedAt: 0,
          verifyToken: null,
        }
      },
    },
  },
  data() {
    return {
      oldAppUrl: `${
        window?.oldAppUrl ?? process.env.VUE_APP_OLDAPP_HOST
      }?account_id=${localStorage.getItem(
        'account_id'
      )}&auth_key=${localStorage.getItem('jwt_key')}`,
    }
  },
  computed: {
    userNameLastName() {
      return `${this.$store.state.userStore.user.firstname} ${this.$store.state.userStore.user.lastname}`
    },
    userEmail() {
      return this.$store.state.userStore.user.email
    },
    userAvatar() {
      return this.$store.state.userStore.user.avatar ?? '/icons/avatar.svg'
    },
  },
  methods: {
    logOut() {
      this.$store.commit('userStore/clearUser')
      localStorage.removeItem('jwt_key')
      localStorage.removeItem('refresh')
      localStorage.removeItem('refreshTokenInProcess')
      localStorage.removeItem('user_id')
      // todo put in jwt
      localStorage.removeItem('account_id')
      this.$router.push({ name: 'login' })
    },
    changeVersion(e) {
      e.preventDefault()
      const _self = e.target
      const path = _self.href
      const host = location.host
      let domain

      if (host != 'localhost') {
        domain = host.split('.')
        domain = domain[1] + '.' + domain[2]
        setCookie('current_version', 'old', { domain: domain })
      }

      location.href = path
    },
  },
}
</script>

<style lang="scss">
.profile-avatar {
  &:hover {
    .v-btn__content {
      border-radius: 14px;
      background-color: #cccccd;
    }
  }

  &:before {
    display: none !important;
  }
}
.profile-avatar {
  .user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 14px;
    background-size: 36px;
    background-position: center center;
  }
}

.project-menu-head {
  .user-avatar {
    width: 42px;
    height: 42px;
    border-radius: 14px;
    cursor: pointer;
    background-size: 40px;
    background-position: center center;
  }
}
</style>
<i18n>
{
  "ru": {
    "menu": "Меню",
    "back": "Назад",
    "to-the-main-page": "На главную",
    "back-to-old-app": "В старый интерфейс",
    "registration": "Регистрация",
    "login": "Вход",
    "notifications": "Уведомления",
    "mark-everything-as-read": "Пометить все как прочитанные",
    "left-a-comment-in-male": "оставил комментарий в",
    "left-a-comment-in-female": "оставила комментарий в",
    "now": "Сейчас",
    "hours-ago": "| {n} час назад | {n} часа назад | {n} часов назад",
    "change-the-tariff": "Сменить тариф",
    "back-to-classic": "Перейти в классический дизайн",
    "project-settings": "Настройки проекта",
    "search": "Поиск",
    "new-project": "Новый проект",
    "profile-settings": "Настройки профиля",
    "log-out": "Выйти",
    "cancel": "Отменить"
  },
  "en": {
    "menu": "Menu",
    "back": "Back",
    "to-the-main-page": "To home page",
    "back-to-old-app": "Back to Letteros 1.0",
    "registration": "Registration",
    "login": "Login",
    "notifications": "Notifications",
    "mark-everything-as-read": "Mark all as read",
    "left-a-comment-in-male": "left a comment on",
    "left-a-comment-in-female": "left a comment on",
    "now": "Now",
    "hours-ago": "| {n} hour ago | {n} hours ago",
    "change-the-tariff": "Change the plan",
    "back-to-classic": "To standard design",
    "project-settings": "Project settings",
    "search": "Search",
    "new-project": "New project",
    "profile-settings": "Profile settings",
    "log-out": "Exit",
    "cancel": "Cancel"
  }
}
</i18n>
