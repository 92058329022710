<template>
  <v-app>
    <router-view name="header" class="header">
      <template #project-menu>
        <HeaderMenuProject />
      </template>
      <template #user-menu>
        <HeaderMenuUser />
      </template>
    </router-view>
    <router-view name="sidebar" class="sidebar" />
    <router-view class="page" :class="{ overlay: sidebarShow }" />
  </v-app>
</template>

<script>
import HeaderMenuProject from '@/components/header/HeaderMenuProject.vue'
import HeaderMenuUser from '@/components/header/HeaderMenuUser.vue'

import tableStore from '@/mixins/Home/tableStore.mixin.js'
import itemsStore from '@/mixins/Home/itemsStore.mixin.js'

export default {
  name: 'LayoutMain',
  mixins: [tableStore, itemsStore],
  components: {
    HeaderMenuUser,
    HeaderMenuProject,
  },
  computed: {
    sidebarShow() {
      return this.$store.state.tableStore.sidebarShow
    },
  },
}
</script>
