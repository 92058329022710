<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <path :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" d="M18.9563 23H5.04369C2.80891 23 1 21.1908 1 18.9595V5.04441C1 2.80924 2.80891 1 5.04369 1H18.9563C21.1872 1 23 2.80924 23 5.04441V18.9595C23 21.1908 21.1872 23 18.9563 23Z" />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>