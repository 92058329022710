<template>
  <div class="checker">
    <!-- Шаблон создается -->
    <v-btn
      v-if="pageType === 'template' && loading"
      class="list-card-main primary-btn dropdown-link d-flex"
      :ripple="false"
      x-large
      color="gray"
      depressed
      disabled>
      {{ $t('tableElementMenuBtnCheck.data-loading') }}
    </v-btn>
    <!-- Шаблон создан -->
    <v-btn
      v-else-if="pageType === 'template' && !loading"
      class="list-card-main primary-btn dropdown-link d-flex"
      :ripple="false"
      x-large
      color="primary"
      depressed
      @click="createLetter">
      {{ $t('tableElementMenuBtnCheck.create-a-letter') }}
    </v-btn>
    <!-- Письма -->
    <v-btn
      v-else
      class="list-card-main primary-btn dropdown-link d-flex"
      :ripple="false"
      x-large
      color="primary"
      depressed
      :to="{ name: 'letter-editor', params: { id } }">
      {{ $t('tableElementMenuBtnCheck.edit') }}
    </v-btn>
  </div>
</template>

<script>
import { checkIfLettersLimitReached } from '@/utilities/project'
import { createLetter } from '@/utilities/letter'

export default {
  name: 'ElementMenuLink',
  props: {
    id: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
  },
  methods: {
    async createLetter() {
      // [Переписать]
      if (checkIfLettersLimitReached(this.$store.state.project.full)) {
        const answer = await this.$refs.$parent.paymentForExtraLetter.open()
        if (answer === false) return
      }

      try {
        // [Переписать] [NEW_API]
        const type = `${this.pageType}_id`
        const result = await createLetter({ [type]: this.id })
        this.$router.push({ name: 'letter-editor', params: { id: result.id } })
      } catch (e) {
        // [Переписать] в случае ошибки выводим уведомление
        console.log('createLetter error', e)
      }
    },
  },
}
</script>

<style scoped>
.checker {
  flex: 1 0 auto;
}

.primary-btn {
  padding: 0 1rem !important;
}
</style>
