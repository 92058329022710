<template>
  <v-main class="signup">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="7" sm="7" cols="10">
          <div v-if="!isSent">
            <h1 class="title-h3 text-center mb-2">
              {{ $t('forgotForm.forgot') }}
            </h1>

            <form @submit.prevent="forgotHandler">
              <label class="label">
                {{ $t('forgotForm.email') }}
              </label>
              <v-text-field
                v-model="email"
                placeholder="email@mail.com"
                outlined
                :class="{ error: error }" />
              <div class="text-center">
                <v-btn
                  type="submit"
                  class="pl-10 pr-10 white--text"
                  :ripple="false"
                  x-large
                  depressed
                  color="primary">
                  {{ $t('forgotForm.continue') }}
                </v-btn>
              </div>
            </form>
          </div>

          <div v-else>
            <h1>
              {{ $t('theForgot.reset-passwd-token-send') }}
            </h1>
            <p>
              {{ $t('theForgot.validation-token-sent-get-it-in-your-email') }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'Forgot',
  data() {
    return {
      email: '',
      error: false,
      isSent: false,
    }
  },
  created() {
    document.title = this.$t('theForgot.forgot')
  },
  methods: {
    async forgotHandler() {
      const result = await this.$store.dispatch('auth/forgot', {
        email: this.email,
      })
      // Приходит в случае успеха null :)
      if (result !== false) {
        this.isSent = true
      } else {
        this.error = true
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // Чтобы при повторном открытие страницы показывалась форма
    this.isSent = false
    next()
  },
}
</script>

<style lang="scss">
.no-border {
  border: none;
}
</style>
