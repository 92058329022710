import shareStore from '@/store/dynamic/share.js'

export default {
  created() {
    if (!this.$store.hasModule('share')) {
      this.$store.registerModule('share', shareStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('share')) {
      this.$store.registerModule('share', shareStore)
    }
  },
  destroyed() {
    if (this.$store.hasModule('share')) {
      this.$store.unregisterModule('share')
    }
  },
}
