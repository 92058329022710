<template>
  <v-main class="table-index" v-if="!loading">
    <EmptyBox v-if="!itemsExist" />
    <ContentBox v-else />
  </v-main>
</template>

<script>
/* eslint-disable */
import EmptyBox from '@/components/home/EmptyBox.vue'
import ContentBox from '@/components/home/ContentBox.vue'

export default {
  name: 'Home',
  components: {
    EmptyBox,
    ContentBox,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    itemsExist() {
      return this.$store.state.itemsStore.items.length
    },
    pageName() {
      const route = this.$route.name
      let name = ''

      switch (true) {
        case route == 'template-index':
          name = 'template'
          break
        case route == 'letter-index':
          name = 'letter'
          break
        case route == 'letter-custom-index':
          name = 'letter-custom'
          break
        case route == 'directory-template':
          name = 'template'
          break
        case route == 'directory-letter':
          name = 'letter'
          break
        case route == 'directory-letter-custom':
          name = 'letter-custom'
          break
      }

      return name
    },
  },
  watch: {
    $route: 'init',
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      this.$store.commit('tableStore/changePageType', this.pageName)
      this.switchTitle()
      await this.$store.dispatch('itemsStore/getData')
      this.loading = false
    },
    switchTitle() {
      if (this.pageName === 'letter') {
        return (document.title = this.$t('tableIndex.letters'))
      }

      if (this.pageName === 'letter-custom') {
        return (document.title = this.$t('tableIndex.custom-letters'))
      }

      if (this.pageName === 'analytics') {
        this.$store.commit('tableStore/changeView', 'row')
        return (document.title = this.$t('tableIndex.analytics'))
      }

      return (document.title = this.$t('tableIndex.templates'))
    },
  },
}
</script>

<style lang="scss">
.table-index {
  .template-index-header {
    position: relative;
  }

  .template-card-list {
    display: flex;
    flex-flow: row wrap;
  }

  .table-simple {
    .table-logo {
      text-align: center;
      width: 56px;
      .logo {
        max-width: 56px;
        max-height: 56px;
        vertical-align: middle;
        border: 1px solid $color-gray;
        border-radius: 12px;
        object-fit: cover;
      }
    }

    .table-analytics {
      border-bottom: 0 !important;
      color: #a9a9a9 !important;
      font-size: 16px;
      font-weight: normal;
    }

    .table-info {
      .name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .time {
        color: $color-gray;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .table-checkbox-wrapper {
      width: 35px;
      padding-right: 0 !important;
      .table-checkbox {
        margin: auto;
      }
      &.table-checkbox-wrapper--analytics {
        width: 50px;
      }
    }
  }
  .theme--light.v-pagination .v-pagination__item {
    color: #a9a9a9;
    font-size: 18px;
    border-radius: 10px;
    box-shadow: 0 0 0;
    width: 36px;
    height: 36px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .theme--light.v-pagination .v-pagination__item--active {
    color: #151515;
    background: #eaeaea !important;
    border-color: #eaeaea !important;
    box-shadow: 0 0 0;
  }
  .theme--light.v-pagination .v-pagination__navigation {
    background: transparent;
    border-radius: 0;
    box-shadow: 0 0 0;
    width: auto;
    height: auto;
    margin-left: 40px;
    margin-right: 40px;
  }
  .theme--light.v-pagination {
    .v-pagination__navigation {
      .v-icon.mdi-chevron-left:before,
      .v-icon.mdi-chevron-right:before {
        font-size: 25px;
      }
    }
  }
}
</style>
