<template>
  <v-card class="folder-create">
    <v-card-title class="text-h5 justify-space-between">
      {{ folderId ? $t('rename-folder') : $t('create-folder') }}
      <v-btn icon :ripple="false" depressed class="" @click="$emit('close')">
        <IconClose />
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="name" outlined :error="$v.name.$error" />
      <template v-if="$v.name.$error">
        <ValidateErrorHint :display="!$v.name.required" />
      </template>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        depressed
        outlined
        x-large
        class="mr-3 px-8 black-hover"
        :ripple="false"
        @click="$emit('close')">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        depressed
        color="primary px-8 black-hover"
        x-large
        :ripple="false"
        @click="updateFolder">
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// [Переписать] полностью отрефакторить
import { required } from 'vuelidate/lib/validators'
import ValidateErrorHint from '@/components/tools/ValidateErrorHint'

import IconClose from '@/components/icons/IconClose.vue'

import { createFolder, updateFolder } from '@/utilities/folder'
import { displayNotify } from '@/utilities/helpers'

import project from '@/mixins/project.mixin.js'

export default {
  name: 'FolderEdit',
  components: { IconClose, ValidateErrorHint },
  mixins: [project],
  props: {
    folderType: String,
    folderName: String,
    folderId: Number,
  },
  data() {
    return {
      name: this.folderName,
    }
  },
  watch: {
    folderId(value) {
      this.name = this.folderName
      if (!value) this.$v.$reset()
    },
  },
  methods: {
    async updateFolder() {
      this.$v.$touch()
      if (this.$v.$invalid) return false

      this.$loader('show')
      let result = null
      let type = null

      if (this.folderId) {
        // Редактирование
        try {
          // [Переписать] [NEW_API]
          result = await updateFolder(this.folderId, { name: this.name })
          type = 'edit'
        } catch (e) {
          displayNotify(this.$t('folder-not-updated'), 'error')
        }
      } else {
        // Создание
        try {
          // [Переписать] [NEW_API]
          result = await createFolder({
            name: this.name,
            type: this.folderType,
          })
          type = 'create'
        } catch (e) {
          displayNotify(this.$t('folder-not-created'), 'error')
        }
      }

      // [Переписать] Удалить запрос после переноса апи папок [NEW_API]
      if (result) {
        await this.loadProject()
        if (type == 'edit') {
          displayNotify(this.$t('folder-updated', { name: this.name }))
        } else if (type == 'create') {
          displayNotify(this.$t('folder-created', { name: this.name }))
          this.name = ''
          this.$v.$reset()
        }
      }
      this.$loader('hide')
      this.$emit('close')
    },
  },
  validations: {
    name: {
      required,
    },
  },
}
</script>

<style lang="scss">
.folder-create {
}
</style>
<i18n>
{
  "ru": {
    "create-folder": "Новая папка",
    "rename-folder": "Новое название папки",
    "save": "Сохранить",
    "cancel": "Отмена",
    "folder-updated": "Название папки обновлено на \"{name}\"",
    "folder-created": "Папка \"{name}\" создана",
    "folder-not-updated": "Не удалось обновить название папки",
    "folder-not-created": "Не удалось создать папку"
  },
  "en": {
    "create-folder": "New folder",
    "rename-folder": "New name",
    "save": "Save",
    "cancel": "Cancel",
    "folder-updated": "Folder renamed to \"{name}\"",
    "folder-created": "Folder \"{name}\" is created",
    "folder-not-updated": "Folder wasn't updated",
    "folder-not-created": "Folder wasn't created"
  }
}
</i18n>
