<template>
  <v-main class="signup">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="7" sm="7" cols="10">
          <h1 class="title-h3 text-center mb-2">
            {{ $t('theSignup.registration') }}
          </h1>

          <SignupForm v-show="regStep === 1" />
          <div v-show="regStep === 2">
            <p class="text text-center mt-5">
              {{ $t('signupSuccess.we-have-sent-a-confirmation-email-to') }}
              {{ email }}.
            </p>
            <p class="text text-center mt-5">
              {{
                $t('signupSuccess.confirm-the-email-to-activate-the-profile')
              }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import SignupForm from '@/components/signup/SignupForm.vue'

export default {
  name: 'TheSignUp',
  components: { SignupForm },
  created() {
    document.title = this.$t('theSignup.registration')
  },
  computed: {
    regStep() {
      return this.$store.state.auth.registrationStep
    },
    email() {
      return this.$store.state.auth.email
    },
  },
}
</script>
