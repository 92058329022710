<template>
  <v-navigation-drawer
    :value="sidebarShow"
    :hide-overlay="true"
    fixed
    stateless
    :overlay-color="'#cacacc'"
    :overlay-opacity="0.5"
    width="290"
    style="top: 57px"
    temporary
    height="calc(100% - 57px)">
    <v-list class="sidebar-main directory-sidebar">
      <v-list-item class="mt-6 mb-3">
        <h2 class="title-h5">
          {{ $t('sidebarMain.modular-editor') }}
        </h2>
      </v-list-item>

      <!-- Письма -->
      <FolderHeader type="letter" />

      <FolderList
        type="letter"
        :folders="lettersDirectories"
        @folder-edit="showFolderEdit($event.type, $event.directory)"
        @folder-delete="showFolderDelete($event.type, $event.directory)" />

      <FolderButtons type="letter" @folder-edit="showFolderEdit($event.type)" />
      <v-divider />

      <!-- Шаблоны -->
      <FolderHeader type="template" />

      <FolderList
        type="template"
        :folders="templateDirectories"
        @folder-edit="showFolderEdit($event.type, $event.directory)"
        @folder-delete="showFolderDelete($event.type, $event.directory)" />

      <FolderButtons
        type="template"
        @folder-edit="showFolderEdit($event.type)" />
      <v-divider />

      <!-- Кастомные письма -->
      <v-list-item class="mt-5 mb-2">
        <h2 class="title-h5 mr-auto">
          {{ $t('sidebarMain.html-editor') }}
        </h2>
        <v-chip color="dark" text-color="white" small>PRO</v-chip>
      </v-list-item>

      <FolderHeader type="custom" />

      <FolderList
        type="custom"
        :folders="lettersCustomDirectories"
        @folder-edit="showFolderEdit($event.type, $event.directory)"
        @folder-delete="showFolderDelete($event.type, $event.directory)" />

      <FolderButtons type="custom" @folder-edit="showFolderEdit($event.type)" />
    </v-list>

    <!-- [Переписать] ??? -->
    <v-dialog v-model="folderEditShow" max-width="500">
      <FolderEdit
        :folder-type="folderType"
        :folder-id="folderId"
        :folder-name="folderName"
        @close="folderEditShow = false" />
    </v-dialog>
    <v-dialog v-model="folderDeleteShow" max-width="600">
      <FolderDelete
        :folder-id="folderId"
        :folder-name="folderName"
        @close="folderDeleteShow = false" />
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import FolderEdit from '@/components/folder/FolderEdit.vue'
import FolderDelete from '@/components/folder/FolderDelete.vue'

import FolderHeader from '@/components/sidebars/SidebarFolders/FolderHeader.vue'
import FolderList from '@/components/sidebars/SidebarFolders/FolderList.vue'
import FolderButtons from '@/components/sidebars/SidebarFolders/FolderButtons.vue'

export default {
  name: 'SidebarMain',
  components: {
    FolderDelete,
    FolderEdit,
    FolderHeader,
    FolderList,
    FolderButtons,
  },
  data() {
    return {
      folderEditShow: false,
      folderDeleteShow: false,
      folderType: '',
      folderId: null,
      folderName: '',
    }
  },
  computed: {
    project() {
      return this.$store.state.project.full
    },
    projectId() {
      return this.$store.state.project.id
    },
    lettersDirectories() {
      return this.project.lettersFolders
    },
    lettersCustomDirectories() {
      return this.project.customFolders
    },
    templateDirectories() {
      return this.project.templatesFolders
    },
    sidebarShow: {
      get() {
        const customs = ['letter-custom-create', 'letter-custom-settings']
        const templates = [
          'template-create',
          'template-form',
          'template-load',
          'template-design',
          'template-editor',
          'template-shop',
        ]

        if (customs.includes(this.$route.name)) {
          return this.$store.state.customStore.sidebarVisible
        } else if (templates.includes(this.$route.name)) {
          return this.$store.state.template.sidebarShow
        } else {
          return this.$store.state.tableStore.sidebarShow
        }
      },
      set(newValue) {
        this.$store.commit('tableStore/changeSidebarShow', newValue)
      },
    },
  },
  methods: {
    showFolderEdit(type, directory) {
      if (directory) {
        // Редактирование
        this.folderId = directory.id
        this.folderName = directory.name
      } else {
        // Создание
        this.folderId = null
        this.folderName = ''
      }
      this.folderType = type
      this.folderEditShow = true
    },
    showFolderDelete(type, directory) {
      if (directory) {
        this.folderId = directory.id
        this.folderName = directory.name
        this.folderType = type
        this.folderDeleteShow = true
      }
    },
  },
}
</script>

<style lang="scss">
.sidebar-main.directory-sidebar {
  .directory.v-list-item--active {
    color: $color-primary;

    &::before {
      opacity: 0;
    }
  }
  .v-list-item {
    height: 36px !important;
  }

  .directory {
    .folder-link {
      color: $color-dark;
      text-decoration: none;
    }

    .folder-link.router-link-exact-active {
      color: $color-primary;
    }

    .folder-btn-bar {
      display: none;
      flex-flow: row nowrap;
      position: absolute;
      background-color: $color-white;
      right: 0;
      top: 0;
    }
  }

  .directory:hover {
    .folder-link {
      color: $color-primary;
    }

    .folder-btn-bar {
      display: flex;
    }
  }
}
</style>
