<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill" :stroke="stroke">
    <path :stroke="stroke" d="M4.33203 2.34863L17.1893 2.34863" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path :stroke="stroke" d="M4.33203 9.05078L17.1893 9.05078" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path :stroke="stroke" d="M4.33203 15.6138L17.1893 15.6138" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path :fill="stroke" :stroke="stroke" d="M1.28088 2.62011C1.436 2.62011 1.56176 2.48135 1.56176 2.31018C1.56176 2.13901 1.436 2.00024 1.28088 2.00024C1.12575 2.00024 1 2.13901 1 2.31018C1 2.48135 1.12575 2.62011 1.28088 2.62011Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path :fill="stroke" :stroke="stroke" d="M1.28088 9.43798C1.436 9.43798 1.56176 9.29922 1.56176 9.12805C1.56176 8.95688 1.436 8.81812 1.28088 8.81812C1.12575 8.81812 1 8.95688 1 9.12805C1 9.29922 1.12575 9.43798 1.28088 9.43798Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path :fill="stroke" :stroke="stroke" d="M1.28088 15.9241C1.436 15.9241 1.56176 15.7853 1.56176 15.6141C1.56176 15.443 1.436 15.3042 1.28088 15.3042C1.12575 15.3042 1 15.443 1 15.6141C1 15.7853 1.12575 15.9241 1.28088 15.9241Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>
