<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path
      v-if="rounded"
      d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
      :stroke="stroke"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M7.61011 12.1065L11.2109 15.7074L17.2232 8.12842"
      :stroke="stroke"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  props: {
    rounded: { type: Boolean, default: true },
    iconName: { type: String, default: 'box' },
    width: { type: [Number, String], default: 24 },
    height: { type: [Number, String], default: 24 },
    stroke: { type: String, default: 'currentColor' },
    viewBox: { type: String, default: '0 0 24 24' },
    fill: { type: String, default: 'none' },
  },
}
</script>
