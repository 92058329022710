<template>
  <v-main class="template-load">
    <v-container>
      <v-row justify="center" class="mb-7">
        <v-col lg="10" md="9" sm="10" cols="12">
          <div
            class="text-lg-h2 text-md-h2 text-sm-h3 text-h4 text-center font-weight-medium">
            {{ title }}
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="6" md="9" sm="10" cols="12">
          <a
            class="load-template-docs dark--text mb-12"
            target="_blank"
            href="https://app.letteros.com/documentation">
            <IconDocument class="load-template-docs-icon" />
            {{
              $t('templateLoad.documentation-on-template-markup-for-letteros')
            }}
          </a>
          <v-text-field
            id="letteros-new-template-name"
            v-model="templateName"
            class="mb-2"
            outlined
            :error="$v.templateName.$error" />
          <template v-if="$v.templateName.$error">
            <ValidateErrorHint :display="!$v.templateName.required" />
          </template>
          <form>
            <FileUploader
              :accept="'.zip,.rar,.7zip'"
              :immediately="false"
              @file-loaded="file = $event" />
            <span class="file-note gray--text mt-2">
              {{ $t('templateLoad.allowed-file-types') }}
            </span>
            <v-btn
              class="load-template-btn px-8 white&#45;&#45;text d-table mx-auto mt-7"
              :ripple="false"
              x-large
              depressed
              color="primary"
              @click="sendTemplate">
              {{ createBtnName }}
            </v-btn>
          </form>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
/*
  [TODO] async перекинуть в стор 
*/
/* eslint-disable */
import { required } from 'vuelidate/lib/validators'
import { displayNotify } from '@/utilities/helpers'

import IconDocument from '@/components/icons/IconDocument.vue'

import ValidateErrorHint from '@/components/tools/ValidateErrorHint.vue'
import FileUploader from '@/components/FileUploader.vue'

import project from '@/mixins/project.mixin.js'

export default {
  name: 'TemplateLoad',
  components: {
    ValidateErrorHint,
    FileUploader,
    IconDocument,
  },
  mixins: [project],
  data() {
    return {
      file: null,
      templateName: this.$t('templateLoad.default-template-name'),
    }
  },
  computed: {
    title() {
      return this.$route.params.templateId
        ? this.$t('templateLoad.refresh-template')
        : this.$t('templateLoad.loading-a-ready-made-template')
    },
    createBtnName() {
      return this.$route.params.templateId
        ? this.$t('templateLoad.refresh-template')
        : this.$t('templateLoad.create-a-template')
    },
  },
  async created() {
    // [Переписать] проверить [LATER]
    await this.loadProject()
  },
  methods: {
    async sendTemplate() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      if (!this.file) {
        displayNotify(this.$t('templateLoad.no-template-provided'), 'error')
        return
      }

      this.$loader('show')
      let formData = new FormData()
      formData.append('file', this.file)
      formData.append('outlook', true)
      formData.append('name', this.templateName)

      const result = await this.$store.dispatch('template/uploadZip', {
        data: formData,
        id: this.$route.params.templateId,
      })

      if (result) {
        this.$router.push({ name: 'template-index' })
      } else {
        this.$loader('hide')
        displayNotify(this.$t('templateLoad.template-upload-error'), 'error')
      }
    },
  },
  validations: {
    templateName: {
      required,
    },
  },
}
</script>

<style lang="scss">
.template-load {
  .load-template-title {
    @media (max-width: 767px) {
      padding: 5px 0 0;
      line-height: 1.1 !important;
    }
  }

  .file-uploader {
    padding: 30px;
  }

  .load-template-docs {
    text-underline-offset: 4px;
  }

  .load-template-container {
    max-width: 610px;
    margin: 0 auto;
  }

  .load-template-docs {
    position: relative;
    display: table;
    margin: 0 auto;
    padding-left: 45px;

    &:hover {
      text-decoration: none;
    }
  }

  .load-template-docs-icon {
    position: absolute;
    top: -8px;
    left: 0;
  }

  .load-template-btn {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
