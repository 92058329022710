const state = () => ({
  selectedModule: null,
  selectedElement: null,
  selectedModuleUuid: null,
  codeEditorModalShow: false,
  triggerSidebarOptions: false,
  selectedModuleOptions: [],
  selectedElementOptions: [],
  selectedGroupId: null,
  moduleRefresh: null,
  isChanged: false,
  emojiPickerId: null,
  emojiTarget: null,
  moduleOnceRefresh: null,
})

const getters = {
  getElement: (state) => {
    return state.selectedElement
  },
  getEmojiPickerId: (state) => {
    return state.emojiPickerId
  },
}

const mutations = {
  clearSelected(state) {
    state.selectedModule = null
    state.selectedElement = null
    state.selectedModuleUuid = null
  },
  clearSelectedElement(state) {
    state.selectedElement = null
  },
  updateSelected(state, payload) {
    state.selectedModule = payload.module
    state.selectedModuleUuid = payload.uuid
  },
  updateSelectedElement(state, payload) {
    state.selectedElement = payload
  },
  toggleCodeEditor(state) {
    state.codeEditorModalShow = !state.codeEditorModalShow
  },
  toggleSidebarOptions(state, payload) {
    state.triggerSidebarOptions = payload
  },
  setSelectedModuleOptions(state, payload) {
    state.selectedModuleOptions = payload
  },
  pushSelectedElementOptions(state, payload) {
    state.selectedElementOptions.push(payload)
  },
  clearSelectedElementOptions(state) {
    state.selectedElementOptions = []
  },
  toggleModuleRefresh(state, uuid) {
    state.moduleRefresh = uuid
  },
  toggleModuleOnceRefresh(state, payload) {
    state.moduleOnceRefresh = payload
  },
  toggleChanged(state, payload) {
    state.isChanged = payload
  },
  updateGroupId(state, payload) {
    state.selectedGroupId = payload
  },
  setEmojiPickerId(state, payload) {
    state.emojiPickerId = payload
  },
  setEmojiTarget(state, payload) {
    state.emojiTarget = payload
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
