<template>
  <v-main class="render-preview">
    <v-container v-if="active">
      <v-row justify="center" align="center" class="mb-10">
        <v-btn :ripple="false" large depressed @click.prevent="prev" icon>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
        <h2 class="render-preview-title text-center">
          {{ active.name }} {{ active.os }} {{ active.browser }}
        </h2>
        <v-btn :ripple="false" large depressed @click.prevent="next" icon>
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          class="render-preview-close"
          icon
          :ripple="false"
          @click.prevent="back">
          <v-icon size="21">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-responsive max-width="600">
          <img
            class="render-preview-img"
            :src="active.full"
            width="600"
            alt="" />
        </v-responsive>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { getRenders } from '@/utilities/acid.js'

export default {
  name: 'LetterRenderPreview',
  data() {
    return {
      active: null,
      items: [],
    }
  },
  computed: {
    type() {
      return this.$route.name == 'letter-custom-render-preview'
        ? 'custom'
        : 'default'
    },
    letterID() {
      return this.$route.params.id
    },
    rendersRoute() {
      return this.type == 'custom' ? 'letter-custom-renders' : 'letter-renders'
    },
  },
  mounted() {
    this.run()
  },
  methods: {
    async run() {
      const preview_id = localStorage.getItem('render_preview')
      if (!preview_id) return false

      const options = {
        type: 'letter',
        id: this.letterID,
      }

      // [Переписать] [LATER]
      const result = await getRenders(options)
      if (!result.state) this.run()

      for (let list in result.items) {
        this.items.push(...result.items[list])
      }

      this.getActive(preview_id)
    },
    getActive(id) {
      this.active = this.items.find((k) => k.id == id)
    },
    next() {
      let activeIndex

      this.items.forEach((k, i) => {
        if (k.id == this.active.id) {
          activeIndex = i + 1
        }
      })

      if (!this.items[activeIndex]) return false
      this.getActive(this.items[activeIndex].id)
    },
    prev() {
      let activeIndex

      this.items.forEach((k, i) => {
        if (k.id == this.active.id) {
          activeIndex = i - 1
        }
      })

      if (!this.items[activeIndex]) return false
      this.getActive(this.items[activeIndex].id)
    },
    back() {
      this.$router.push({
        name: this.rendersRoute,
        params: { id: this.$route.params.id },
      })
    },
  },
}
</script>

<style lang="scss">
.render-preview-title {
  font-style: 24px;
  font-weight: bold;
  margin-bottom: 2px;
}

.render-preview-close {
  position: absolute;
  top: 26px;
  right: 36px;
}

.render-preview-img {
  display: block;
  margin: 0 0 20px;
  border-radius: 15px;
}
</style>
