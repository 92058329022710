/* eslint-disable */
import Notify from 'simple-notify'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function calcLimit() {
  const width = window.innerWidth
  return width > 1440 ? 8 : width > 992 ? 6 : width > 768 ? 4 : 2
}

function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      '(?:^| )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

function setCookie(name, value, options) {
  options = options || {}

  var expires = options.expires

  if (typeof expires == 'number' && expires) {
    var d = new Date()
    d.setTime(d.getTime() + expires * 1000)
    expires = options.expires = d
  }

  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString()
  }

  value = encodeURIComponent(value)

  var updatedCookie = name + '=' + value

  for (var propName in options) {
    updatedCookie += ' ' + propName
    var propValue = options[propName]
    if (propValue !== true) {
      updatedCookie += '=' + propValue
    }
  }

  document.cookie = updatedCookie
}

function toIsoString(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      return (num < 10 ? '0' : '') + num
    }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  )
}

function validateURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

function isValidHttpUrl(string) {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

function RGBAToHex(rgb) {
  const rgbMatch = rgb.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  )

  return rgbMatch && rgbMatch.length === 4
    ? '#' +
        ('0' + parseInt(rgbMatch[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgbMatch[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgbMatch[3], 10).toString(16)).slice(-2)
    : rgb
}

function displayNotify(
  text,
  status = 'success',
  title = null,
  displayTime = 4000
) {
  new Notify({
    status: status,
    title: title,
    text: text,
    effect: 'fade',
    speed: 300,
    customClass: null,
    customIcon: null,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: displayTime,
    gap: 20,
    distance: 20,
    type: 1,
    position: 'right bottom',
  })
}

function stringHasOneOfTheseElements(string, array) {
  return array.some((element) => string.includes(element))
}

async function copyToClipBoard(text) {
  // non-deprecated alternative to document.execCommand('copy')
  await navigator.clipboard.writeText(text)
}

// есть дубликат в moduleParser, но по другому написан. todo сравнить, оставить 1
function rgb2hex(rgb) {
  let parsedRgb = rgb.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  )

  return parsedRgb && parsedRgb.length === 4
    ? '#' +
        ('0' + parseInt(parsedRgb[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(parsedRgb[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(parsedRgb[3], 10).toString(16)).slice(-2)
    : ''
}

function getExtension(url) {
  return url.split('.').pop()
}

export {
  sleep,
  calcLimit,
  getCookie,
  setCookie,
  toIsoString,
  isValidHttpUrl,
  validateURL,
  RGBAToHex,
  displayNotify,
  stringHasOneOfTheseElements,
  copyToClipBoard,
  rgb2hex,
  getExtension,
}
