<template>
  <div v-if="pageType === 'template'" class="table-actions-menu mt-2">
    <v-btn
      class="list-card-main d-flex"
      :ripple="false"
      x-large
      color="primary"
      depressed
      @click="migrate">
      {{ $t('tableElementMigrateBtn.migrate-template') }}
    </v-btn>
  </div>
</template>

<script>
import { migrateTemplate, migrateState } from '@/utilities/template.js'

export default {
  name: 'ElementMenuMigrate',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    project() {
      return this.$store.state.project.full
    },
    directories() {
      return this.pageType === 'letter'
        ? this.project.lettersFolders
        : this.project.templatesFolders
    },
  },
  methods: {
    async migrate() {
      try {
        // [Переписать] вынести в стор, а в случае ошибки выводить уведомление
        const result = await migrateTemplate(this.item.id)
        const state_id = result.item.queue

        setTimeout(async () => {
          await this.migrateCheck(state_id)
        }, 1000)
      } catch (e) {
        console.log('migrate error', e)
      }
    },
    async migrateCheck(id) {
      const stateData = await migrateState(id)

      if (stateData.status) {
        await this.$store.dispatch('itemsStore/getData')
      } else {
        setTimeout(async () => {
          await this.migrateCheck()
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-actions-menu {
  display: flex;
  min-width: 223px;
  width: 100%;

  .list-card-main {
    flex: 3;
  }

  .list-card-dropdown {
    flex: 1;
  }

  .menu-item-header {
    background-color: $color-white;
    padding: 1rem;
  }

  .list-project {
    list-style: none;
    max-height: 375px;
    padding: 0;
    background-color: $color-white;

    .list-project-item {
      display: flex;
      flex-flow: row nowrap;
      margin: 1rem 0 0 0;
      cursor: pointer;

      &:hover {
        color: #001dff;
      }
    }
  }
}

.menu-blue {
  .v-btn {
    color: $color-white;
    background-color: $color-primary;
  }
}
</style>
