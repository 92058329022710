<template>
  <v-card class="document-delete">
    <v-card-title
      class="text-h5 justify-space-between"
      style="margin-bottom: 12px">
      {{ title }}
      <v-btn icon :ripple="false" @click="$emit('close')">
        <IconClose />
      </v-btn>
    </v-card-title>
    <v-card-actions class="justify-start align-center">
      <v-select
        v-model="currentAccount"
        class="mr-2 mb-0"
        :items="accountListWithoutCurrent"
        :menu-props="{ offsetY: true }"
        :placeholder="$t('documentShare.choose')"
        item-text="name"
        item-value="id"
        rounded
        filled
        return-object
        :hide-details="true"
        append-icon="mdi-chevron-down"
        :disabled="false" />
      <v-btn
        color="primary px-8"
        elevation="0"
        x-large
        :ripple="false"
        @click="shareEvent">
        {{ $t('documentShare.share') }}
      </v-btn>
      <v-btn
        x-large
        elevation="0"
        outlined
        class="mr-3 px-8 black-hover"
        :ripple="false"
        @click="$emit('close')">
        {{ $t('documentShare.cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue'
import { share as shareLetter } from '@/utilities/letter'
import { share as shareTemplate } from '@/utilities/template'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'DocumentShare',
  components: { IconClose },
  data() {
    return {
      currentAccount: null,
    }
  },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    title() {
      if (this.pageType === 'template')
        return this.$t('documentShare.share-template')
      if (this.pageType === 'letter')
        return this.$t('documentShare.share-letter')

      return 'error'
    },
    accountListWithoutCurrent() {
      return this.$store.state.userStore.user.adminOrOwnerAccounts.filter(
        (account) => account.id !== +localStorage.getItem('account_id')
      )
    },
  },
  methods: {
    async shareEvent() {
      if (!this.currentAccount) return false

      try {
        const data = {
          object_id: this.$store.state.tableStore.currentEditId,
          account_id: this.currentAccount.id,
        }

        if (this.pageType === 'letter') {
          displayNotify(this.$t('documentShare.letter-shared'))
          await shareLetter(data)
        }

        if (this.pageType === 'template') {
          displayNotify(this.$t('documentShare.template-shared'))
          await shareTemplate(data)
        }
      } catch (e) {
        displayNotify(this.$t('documentShare.error'))
      }
    },
  },
}
</script>
