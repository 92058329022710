<template>
  <v-main class="pricing">
    <section class="pricing">
      <div class="container" style="padding: 3.75rem">
        <h1 class="pricing__title h1">Настройте тариф</h1>
        <div class="pricing__tabs row">
          <button
            class="pricing__tab btn btn--tab"
            :class="{ active: activeTab === 'month' }"
            @click="selectMonth()">
            Оплата за&nbsp;месяц
          </button>
          <button
            class="pricing__tab btn btn--tab"
            :class="{ active: activeTab === 'year' }"
            @click="selectYear()">
            Оплата за&nbsp;год&nbsp;
            <span class="pricing__tabs-discount">-20%</span>
          </button>
          <button
            class="pricing__tab btn btn--tab"
            :class="{ active: activeTab === 'custom' }"
            @click="selectCustom()">
            По&nbsp;количеству писем
          </button>
        </div>
        <p class="pricing__text">
          Мультиаккаунты помогут вести работу сразу с&nbsp;несколькими
          заказчикам. При необходимости, установим софт на&nbsp;ваши сервера.
        </p>
        <div
          class="pricing__tabs-content"
          :class="{ active: activeTab === 'month' }">
          <ul class="pricing-tariffs">
            <li
              class="pricing-tariffs__item"
              :class="{
                'pricing-tariffs__item--recommend':
                  activeTab === 'month' && planName === 'free',
              }">
              <input
                id="month-free"
                v-model="selectedPlan"
                class="visually-hidden pricing-tariffs__input"
                name="tariff"
                type="radio"
                value="month-free"
                @click="planName = 'free'" />
              <div class="pricing-tariffs__card">
                <h2 class="pricing-tariffs__name">Free</h2>
                <p class="pricing-tariffs__text">
                  Лучший способ протестировать конструктор с&nbsp;использованием
                  бесплатных шаблонов из&nbsp;галереи.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price"></p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">0</span>
                      ₽
                    </span>
                  </p>
                  <label
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    for="month-free">
                    {{ selectedPlan === 'month-free' ? 'Выбрано' : 'Выбрать' }}
                  </label>
                </div>
              </div>
            </li>
            <li
              class="pricing-tariffs__item"
              :class="{
                'pricing-tariffs__item--recommend':
                  activeTab === 'month' && planName === 'start',
              }">
              <input
                id="month-start"
                v-model="selectedPlan"
                class="visually-hidden pricing-tariffs__input"
                name="tariff"
                type="radio"
                value="month-start"
                @click="planName = 'start'" />
              <div class="pricing-tariffs__card">
                <h2 class="pricing-tariffs__name">Start</h2>
                <p class="pricing-tariffs__text">
                  Для фрилансеров и&nbsp;небольших компаний, которые начинают
                  развивать email-маркетинг и&nbsp;ещё не&nbsp;разработали
                  брендированный мастер-шаблон.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price"></p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">5 000</span>
                      ₽
                    </span>
                    в&nbsp;месяц / без НДС
                  </p>
                  <label
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    for="month-start">
                    {{ selectedPlan === 'month-start' ? 'Выбрано' : 'Выбрать' }}
                  </label>
                </div>
              </div>
            </li>
            <li
              class="pricing-tariffs__item"
              :class="{
                'pricing-tariffs__item--recommend':
                  activeTab === 'month' && planName === 'pro',
              }">
              <input
                v-model="selectedPlan"
                id="month-pro"
                class="visually-hidden pricing-tariffs__input"
                name="tariff"
                type="radio"
                value="month-pro"
                @click="planName = 'pro'" />
              <div class="pricing-tariffs__card">
                <h2 class="pricing-tariffs__name">Pro</h2>
                <p class="pricing-tariffs__text">
                  Работайте вместе с&nbsp;командой в&nbsp;одном аккаунте
                  и&nbsp;собирайте письма по&nbsp;брендированному шаблону.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price"></p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">10 000</span>
                      ₽
                    </span>
                    в&nbsp;месяц / без НДС
                  </p>
                  <label
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    for="month-pro">
                    {{ selectedPlan === 'month-pro' ? 'Выбрано' : 'Выбрать' }}
                  </label>
                </div>
              </div>
            </li>
            <li
              class="pricing-tariffs__item"
              :class="{
                'pricing-tariffs__item--recommend':
                  activeTab === 'month' && planName === 'business',
              }">
              <input
                id="month-business"
                class="visually-hidden pricing-tariffs__input"
                name="tariff"
                type="radio"
                value="month-business"
                v-model="selectedPlan"
                @click="planName = 'business'" />
              <div class="pricing-tariffs__card">
                <h2 class="pricing-tariffs__name">Business</h2>
                <p class="pricing-tariffs__text">
                  Для компаний, где стабильно работает email-маркетинг,
                  и&nbsp;письма создаются каждый день.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price"></p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">24 000</span>
                      ₽
                    </span>
                    в&nbsp;месяц / без НДС
                  </p>
                  <label
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    for="month-business">
                    {{
                      selectedPlan === 'month-business' ? 'Выбрано' : 'Выбрать'
                    }}
                  </label>
                </div>
              </div>
            </li>
            <li class="pricing-tariffs__item">
              <div class="pricing-tariffs__card">
                <p class="pricing-tariffs__caption">Рекомендуем</p>
                <h2 class="pricing-tariffs__name">Enterprise</h2>
                <p class="pricing-tariffs__text">
                  Безграничные возможности и&nbsp;отсутствие ограничений
                  по&nbsp;письмам, пользователям и&nbsp;шаблонам. Все правки
                  берем на&nbsp;себя.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price"></p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">
                        от 90 000
                      </span>
                      ₽
                    </span>
                    в&nbsp;месяц / без НДС
                  </p>
                  <a
                    href="https://letteros.com/demo/"
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    style="text-decoration: none; color: #151515"
                    target="_blank">
                    Заказать демо
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="pricing__tabs-content"
          :class="{ active: activeTab === 'year' }">
          <ul class="pricing-tariffs">
            <li
              class="pricing-tariffs__item"
              :class="{
                'pricing-tariffs__item--recommend':
                  activeTab === 'year' && planName === 'free',
              }">
              <input
                id="year-free"
                class="visually-hidden pricing-tariffs__input"
                name="tariff"
                type="radio"
                value="year-free"
                v-model="selectedPlan"
                @click="planName = 'free'" />
              <div class="pricing-tariffs__card">
                <h2 class="pricing-tariffs__name">Free</h2>
                <p class="pricing-tariffs__text">
                  Лучший способ протестировать конструктор с&nbsp;использованием
                  бесплатных шаблонов из&nbsp;галереи.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">0</span>
                      ₽
                    </span>
                  </p>
                  <label
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    for="year-free">
                    {{ selectedPlan === 'year-free' ? 'Выбрано' : 'Выбрать' }}
                  </label>
                </div>
              </div>
            </li>
            <li
              class="pricing-tariffs__item"
              :class="{
                'pricing-tariffs__item--recommend':
                  activeTab === 'year' && planName === 'start',
              }">
              <input
                id="year-start"
                class="visually-hidden pricing-tariffs__input"
                name="tariff"
                type="radio"
                value="year-start"
                v-model="selectedPlan"
                @click="planName = 'start'" />
              <div class="pricing-tariffs__card">
                <h2 class="pricing-tariffs__name">Start</h2>
                <p class="pricing-tariffs__text">
                  Для фрилансеров и&nbsp;небольших компаний, которые начинают
                  развивать email-маркетинг и&nbsp;ещё не&nbsp;разработали
                  брендированный мастер-шаблон.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price">60 000 ₽</p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">48 000</span>
                      ₽
                    </span>
                    в&nbsp;год / без НДС
                  </p>
                  <label
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    for="year-start">
                    {{ selectedPlan === 'year-start' ? 'Выбрано' : 'Выбрать' }}
                  </label>
                </div>
              </div>
            </li>
            <li
              class="pricing-tariffs__item"
              :class="{
                'pricing-tariffs__item--recommend':
                  activeTab === 'year' && planName === 'pro',
              }">
              <input
                id="year-pro"
                class="visually-hidden pricing-tariffs__input"
                name="tariff"
                type="radio"
                value="year-pro"
                v-model="selectedPlan"
                @click="planName = 'pro'" />
              <div class="pricing-tariffs__card">
                <h2 class="pricing-tariffs__name">Pro</h2>
                <p class="pricing-tariffs__text">
                  Работайте вместе с&nbsp;командой в&nbsp;одном аккаунте
                  и&nbsp;собирайте письма по&nbsp;брендированному шаблону.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price">120 000 ₽</p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">96 000</span>
                      ₽
                    </span>
                    в&nbsp;год / без НДС
                  </p>
                  <label
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    for="year-pro">
                    {{ selectedPlan === 'year-pro' ? 'Выбрано' : 'Выбрать' }}
                  </label>
                </div>
              </div>
            </li>
            <li
              class="pricing-tariffs__item"
              :class="{
                'pricing-tariffs__item--recommend':
                  activeTab === 'year' && planName === 'business',
              }">
              <input
                v-model="selectedPlan"
                id="year-business"
                class="visually-hidden pricing-tariffs__input"
                name="tariff"
                type="radio"
                value="year-business"
                @click="planName = 'business'" />
              <div class="pricing-tariffs__card">
                <h2 class="pricing-tariffs__name">Business</h2>
                <p class="pricing-tariffs__text">
                  Для компаний, где стабильно работает email-маркетинг,
                  и&nbsp;письма создаются каждый день.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price">288 000 ₽</p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">
                        230 400
                      </span>
                      ₽
                    </span>
                    в&nbsp;год / без НДС
                  </p>
                  <label
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    for="year-business">
                    {{
                      selectedPlan === 'year-business' ? 'Выбрано' : 'Выбрать'
                    }}
                  </label>
                </div>
              </div>
            </li>
            <li class="pricing-tariffs__item">
              <div class="pricing-tariffs__card">
                <p class="pricing-tariffs__caption">Рекомендуем</p>
                <h2 class="pricing-tariffs__name">Enterprise</h2>
                <p class="pricing-tariffs__text">
                  Безграничные возможности и&nbsp;отсутствие ограничений
                  по&nbsp;письмам, пользователям и&nbsp;шаблонам. Все правки
                  берем на&nbsp;себя.
                </p>
                <div class="pricing-tariffs__item-bottom">
                  <p class="pricing-tariffs__old-price">от 1 080 000 ₽</p>
                  <p class="pricing-tariffs__price">
                    <span class="pricing-tariffs__amount">
                      <span class="pricing-tariffs__amount-number">
                        от 864 000
                      </span>
                      ₽
                    </span>
                    в&nbsp;год / без НДС
                  </p>
                  <a
                    href="https://letteros.com/demo/"
                    class="pricing-tariffs__btn btn btn--tr btn--s-l"
                    style="text-decoration: none; color: #151515"
                    target="_blank">
                    Заказать демо
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="pricing__tabs-content"
          :class="{ active: activeTab === 'custom' }">
          <div class="tariffs-calc">
            <div class="tariffs-main">
              <div class="tariffs-range">
                <div class="tariffs-range-top">
                  <h3
                    class="tariffs-range-title tariffs-range-title-letters h3">
                    Писем в&nbsp;месяц:
                  </h3>
                  <input
                    v-show="sliderCount < 1001"
                    v-model="sliderCount"
                    class="tariffs-range-input tariffs-range-input-letters js-tariffs-input" />
                  <div class="tariffs-range-info">
                    <span v-if="letterPrice !== 'Индивидуальная цена'">
                      {{ letterPrice.toFixed() }}&nbsp;руб.&nbsp;за&nbsp;письмо
                    </span>
                    <span v-else>
                      {{ letterPrice }}
                    </span>
                  </div>
                </div>
                <div class="tariffs-range-slider-block">
                  <div
                    @mouseover="
                      setThumbColor(
                        sliderColor,
                        hoverThumbColor,
                        sliderMaxValue,
                        sliderCount
                      )
                    "
                    @mouseout="
                      setThumbColor(
                        sliderColor,
                        defaultThumbColor,
                        sliderMaxValue,
                        sliderCount
                      )
                    ">
                    <!-- todo add this if enable unlimited -->
                    <!-- append-icon="mdi-infinity" -->
                    <v-slider
                      v-model="sliderCount"
                      thumb-label="always"
                      dense
                      :height="10"
                      min="1"
                      :max="sliderMaxValue"
                      :step="1"
                      track-color="#d6d9ed"
                      :thumb-color="sliderColor.color"
                      @end="
                        setThumbColor(
                          sliderColor,
                          hoverThumbColor,
                          sliderMaxValue,
                          sliderCount
                        )
                      ">
                      <template #thumb-label>
                        {{ getThumbLabel() }}
                      </template>
                    </v-slider>
                  </div>
                  <div class="tariffs-range-slider-max infinite">∞</div>
                </div>
              </div>
              <v-expansion-panels
                accordion
                flat
                multiple
                tile
                class="tariffs-params">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="tariffs-params-btn accordeon-btn">
                    <span>Я&nbsp;не&nbsp;знаю, сколько мне нужно писем</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h3 class="tariffs-params-title h3">Количество писем</h3>
                    <p class="tariffs-params-text">
                      Если вы&nbsp;не&nbsp;знаете, сколько писем в&nbsp;месяц
                      вам нужно создать — выберите настройки по&nbsp;умолчанию.
                      В&nbsp;конце месяца мы&nbsp;посчитаем стоимость созданных
                      писем по&nbsp;калькулятору и&nbsp;вышлем счет
                      за&nbsp;дополнительные письма, если вы&nbsp;превысите
                      лимит.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-show="activeTab !== 'custom'"
      class="pricing-options pricing-options--features">
      <div class="container">
        <div class="pricing-options__inner">
          <table class="pricing-options__table">
            <tbody>
              <tr class="pricing-options__row">
                <th class="pricing-options__name pricing-options__name"></th>
                <th class="pricing-options__th">Free</th>
                <th class="pricing-options__th">Start</th>
                <th class="pricing-options__th">Pro</th>
                <th class="pricing-options__th">Business</th>
                <th class="pricing-options__th">Enterprise</th>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Количество писем</td>
                <td class="pricing-options__value">1</td>
                <td class="pricing-options__value">6</td>
                <td class="pricing-options__value">14</td>
                <td class="pricing-options__value">40</td>
                <td class="pricing-options__value infinite">∞</td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Количество пользователей</td>
                <td class="pricing-options__value">1</td>
                <td class="pricing-options__value">1</td>
                <td class="pricing-options__value">5</td>
                <td class="pricing-options__value">10</td>
                <td class="pricing-options__value infinite">∞</td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Докупить 1&nbsp;письмо</td>
                <td class="pricing-options__value">900 ₽</td>
                <td class="pricing-options__value">800 ₽</td>
                <td class="pricing-options__value">700 ₽</td>
                <td class="pricing-options__value">600 ₽</td>
                <td class="pricing-options__value infinite">∞</td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Загрузка своего шаблона</td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value infinite">∞</td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">
                  Включено доработок шаблона в&nbsp;месяц
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">1</td>
                <td class="pricing-options__value">2</td>
                <td class="pricing-options__value">10</td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">
                  Включено вёрсток шаблонов в&nbsp;год
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">1</td>
                <td class="pricing-options__value">1</td>
                <td class="pricing-options__value">3</td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">
                  Включено интеграций с&nbsp;шаблонизатором в&nbsp;год
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">1</td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">
                  Включено дизайнов шаблонов в&nbsp;год
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">
                  <IconClose stroke="#d22138" :width="22" :height="22" />
                </td>
                <td class="pricing-options__value">1</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section class="pricing-options">
      <div class="container">
        <h2 class="pricing-options__title h2">Бесплатные опции</h2>
        <div class="pricing-options__inner">
          <table class="pricing-options__table">
            <tbody>
              <tr class="pricing-options__row">
                <th class="pricing-options__th" colspan="6"></th>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Оптимизация веса картинок</td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Проверка грамматики</td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Типограф</td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Таймер</td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Оптимизация под Outlook</td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Редактор кода</td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
              </tr>
              <tr class="pricing-options__row">
                <td class="pricing-options__name">Минификатор кода</td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
                <td class="pricing-options__value">
                  <IconCheck
                    stroke="#3cca5c"
                    :rounded="false"
                    :width="38"
                    :height="38" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section class="pricing-cart" :class="{ active: plan.name !== 'error' }">
      <div class="container pricing-cart__container">
        <div class="pricing-cart__inner row">
          <div class="pricing-cart__top">
            <h2 class="pricing-cart__title h2">Информация по&nbsp;тарифу</h2>
            <div class="pricing-cart__tariff">
              <p class="pricing-cart__caption">Тариф</p>
              <p class="pricing-cart__tariff-name">{{ plan.name }}</p>
              <p class="pricing-cart__tariff-type"></p>
            </div>
            <div class="pricing-cart__letters">
              <p class="pricing-cart__caption">Кол-во писем</p>
              <p class="pricing-cart__letters-number"></p>
            </div>
          </div>
          <div class="pricing-cart__bottom row">
            <div class="pricing-cart__bottom-inner">
              <div class="pricing-cart__total">
                <p class="pricing-cart__caption">Общая стоимость</p>
                <p v-if="letterPrice !== 'Индивидуальная цена'">
                  <span class="pricing-cart__total-cost">
                    <span class="pricing-cart__total-amount">
                      {{ currentSum.toLocaleString() }}
                    </span>
                    ₽
                  </span>
                  без НДС
                </p>
                <p v-else>
                  {{ letterPrice }}
                </p>
              </div>
              <button
                class="pricing-cart__btn btn btn--main btn--s-l"
                type="button"
                @click="updateTarif">
                Заказать
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="payment">
      <div class="container">
        <h2 class="payment-title h2">Любые способы оплаты</h2>
        <ul class="payment-tabs row">
          <li class="payment-tabs-item btn btn--tabicon btn--s-m">
            <IconBank />
            <span>Банковский перевод</span>
          </li>
          <li class="payment-tabs-item btn btn--tabicon btn--s-m">
            <IconWallet />
            <span>Онлайн-кошельки</span>
          </li>
          <li class="payment-tabs-item btn btn--tabicon btn--s-m">
            <IconApple />
            <span>Apple Pay</span>
          </li>
          <li class="payment-tabs-item btn btn--tabicon btn--s-m">
            <IconGoogle />
            <span>Google Pay</span>
          </li>
          <li class="payment-tabs-item btn btn--tabicon btn--s-m">
            <IconVisaWithViewBox />
            <span>VISA</span>
          </li>
          <li class="payment-tabs-item btn btn--tabicon btn--s-m">
            <IconMasterCardWithViewBox />
            <span>Mastercard</span>
          </li>
        </ul>
      </div>
    </section>
    <section class="banner">
      <div class="container">
        <div class="banner-block">
          <h2 class="banner-title h2">Настроим ваш шаблон в&nbsp;Letteros</h2>
          <p class="banner-subtitle">
            И&nbsp;подарим месяц бесплатного обслуживания
          </p>
          <ul class="banner-params">
            <li class="banner-param row">
              <IconFile />
              <span>
                Бесплатно перенесем ваш шаблон для рассылок из&nbsp;Figma,
                Photoshop, Sketch, Tilda, Stripo или HTML. Добавим все
                переменные и&nbsp;динамические блоки в&nbsp;библиотеку.
              </span>
            </li>
            <li class="banner-param row">
              <IconClock />
              <span>
                Научим создавать сложные письма за&nbsp;10&nbsp;минут.
              </span>
            </li>
            <li class="banner-param row">
              <IconPlatform />
              <span>
                Подключим вашу платформу рассылок к&nbsp;Letteros для
                моментального экспорта.
              </span>
            </li>
          </ul>
          <div class="banner-btns row">
            <a
              class="banner-btn btn btn--main btn--s-l"
              href="https://letteros.com/migration/"
              target="_blank">
              Заполнить заявку
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="faq">
      <div class="container">
        <h2 class="faq-title h2">Отвечаем на&nbsp;вопросы</h2>
        <v-expansion-panels accordion flat multiple tile class="faq-list">
          <v-expansion-panel class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <span class="faq-item-title h3">
                Чтобы готовить письма, нужно знать HTML?
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                Нет, вы&nbsp;создаете письмо в&nbsp;интуитивном редакторе.
                Знания HTML-вёрстки писем не&nbsp;требуются.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <span class="faq-item-title h3">
                У&nbsp;меня уже есть свой шаблон. Я&nbsp;могу его использовать?
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                Да, вы&nbsp;можете импортировать шаблон из&nbsp;ZIP архива
                и&nbsp;редактировать в&nbsp;Letteros.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <span class="faq-item-title h3">
                Я&nbsp;могу отправить рассылку прямо из&nbsp;Letteros?
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                Нет, письмо нужно выгрузить в&nbsp;любую привычную вам
                платформу.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <span class="faq-item-title h3">
                С&nbsp;какой платформы можно сделать рассылку?
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                SendPulse, GetResponse, Mailchimp&nbsp;и&nbsp;другие популярные
                платформы.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <span class="faq-item-title h3">
                Как проверить адаптивность письма?
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Посмотрите превью на&nbsp;десктопе и&nbsp;мобильном.</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <span class="faq-item-title h3">
                Как узнать, что письмо отлично смотрится и&nbsp;не&nbsp;режется
                на&nbsp;разных почтовиках?
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                Отправить тестовое письмо и&nbsp;протестировать
                на&nbsp;9&nbsp;почтовых клиентах.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <span class="faq-item-title h3">
                Сколько ссылок делать в&nbsp;письме?
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                Много ссылок только запутают читателя и&nbsp;не&nbsp;принесут
                желаемого результата. Добавляйте несколько ссылок,
                но&nbsp;сделайте один главный акцент. Лучше в&nbsp;виде кнопки.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="accordion-item">
            <v-expansion-panel-header class="font-weight-medium">
              <span class="faq-item-title h3">
                Какой размер письма будет оптимальным, чтобы не&nbsp;попасть
                в&nbsp;спам?
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                Сообщения меньше 100&nbsp;Кб&nbsp;успешно проходят
                антиспам-проверку. При увеличении размера могут быть проблемы:
                один или несколько спам-фильтров не&nbsp;пропускают сообщение
                во&nbsp;«Входящие». Для этого мы&nbsp;встроили в&nbsp;платформу
                инструменты сжатия кода и&nbsp;изображений.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </section>
    <InfoMessage
      :show-message="showErrorTarif"
      :header="$t('projectTariff.error')"
      :message="errorMessage"
      @close-message="showErrorTarif = false" />
    <PromiseDialogWrapper ref="topUpBalance">
      <PromiseDialogFrame
        question-code="not-enough-money"
        agree-code="yes"
        cancel-code="cancel" />
    </PromiseDialogWrapper>
  </v-main>
</template>

<script>
// [Переписать] Вынести template & scss по аналогии с ProjectBalance
import IconCheck from '@/components/icons/IconCheck'
import IconClose from '@/components/icons/IconClose'
import IconBank from '@/components/icons/IconBank'
import IconWallet from '@/components/icons/IconWallet'
import IconVisaWithViewBox from '@/components/icons/IconVisaWithViewBox'
import IconMasterCardWithViewBox from '@/components/icons/IconMasterCardWithViewBox'
import IconGoogle from '@/components/icons/IconGoogle'
import IconApple from '@/components/icons/IconApple'
import IconPlatform from '@/components/icons/IconPlatform'
import IconClock from '@/components/icons/IconClock'
import IconFile from '@/components/icons/IconFile'
import { updateTarif } from '@/utilities/project'
import InfoMessage from '@/components/InfoMessage'
import PromiseDialogWrapper from '@/components/PromiseDialogWrapper'
import PromiseDialogFrame from '@/components/PromiseDialogFrame'

// [Переписать] Над методами и компьютед свойствами добавить краткое описание
export default {
  name: 'Pricing',
  components: {
    PromiseDialogFrame,
    PromiseDialogWrapper,
    InfoMessage,
    IconFile,
    IconClock,
    IconPlatform,
    IconApple,
    IconGoogle,
    IconMasterCardWithViewBox,
    IconVisaWithViewBox,
    IconWallet,
    IconBank,
    IconCheck,
    IconClose,
  },
  data() {
    const sliderMaxValue = 1000 // change to 1001 to enable unlimited
    return {
      sliderMaxValue,
      activeTab: 'month',
      defaultThumbColor: '#011cfd',
      hoverThumbColor: '#0015bc',
      limitThumbColor: '#151516',
      sliderCount: 1,
      sliderColor: { color: '#011cfd' },
      selectedPlan: null,
      planName: '',
      billingType: 'month',
      showErrorTarif: false,
      errorMessage: '',
    }
  },
  computed: {
    letterPrice() {
      const count = this.sliderCount
      if (count < 51) return -10 * count + 1000
      if (count < 151) return 500
      if (count < 501) return 75000 / count
      if (count < 1001) return 100000 / count
      return 'Индивидуальная цена'
    },
    plan() {
      if (this.activeTab === 'custom') {
        if (this.letterPrice === 'Индивидуальная цена')
          return { sum: 'Индивидуальная цена', name: 'Выборочный' }
        return { sum: this.letterPrice * this.sliderCount, name: 'Выборочный' }
      }

      switch (this.selectedPlan) {
        case 'month-free':
          return { sum: 0, name: 'Free месяц' }
        case 'month-start':
          return { sum: 5000, name: 'Start месяц' }
        case 'month-pro':
          return { sum: 10000, name: 'Pro месяц' }
        case 'month-business':
          return { sum: 24000, name: 'Business месяц' }
        case 'month-enterprise':
          return { sum: 90000, name: 'Enterprise месяц' }
        case 'year-free':
          return { sum: 0, name: 'Free год' }
        case 'year-start':
          return { sum: 48000, name: 'Start год' }
        case 'year-pro':
          return { sum: 96000, name: 'Pro год' }
        case 'year-business':
          return { sum: 230400, name: 'Business год' }
        case 'year-enterprise':
          return { sum: 864000, name: 'Enterprise год' }
        default:
          break
      }
      return { sum: 0, name: 'error' }
    },
    currentSum() {
      return this.plan.sum
    },
  },
  methods: {
    setThumbColor(colorModel, color, limit = 0, value = 0) {
      if (limit === value) {
        colorModel.color = this.limitThumbColor
        return
      }
      colorModel.color = color
    },
    getThumbLabel() {
      if (this.sliderCount > 1000) return '∞'
      return this.sliderCount
    },
    selectYear() {
      this.activeTab = 'year'
      this.billingType = 'year'
      this.selectedPlan = this.selectedPlan.replace('month', 'year')
    },
    selectMonth() {
      this.activeTab = 'month'
      this.billingType = 'month'
      this.selectedPlan = this.selectedPlan.replace('year', 'month')
    },
    selectCustom() {
      this.activeTab = 'custom'
      this.selectedPlan = null
      this.billingType = 'month'
      this.planName = 'custom'
    },
    async updateTarif() {
      try {
        const data = { name: this.planName, type: this.billingType }
        if (this.planName === 'custom') data.count = this.sliderCount

        // [Переписать] вынести в стор и проработать уведомление в случае ошибки
        const response = await updateTarif(data)

        if (!response.data?.state) {
          if (response.data.reason === 'not-enough-money') {
            const result = await this.$refs.topUpBalance.open()
            if (result) {
              await this.$router.push({
                name: 'project-balance',
                params: {
                  id: this.$route.params.id,
                  sum: response.data.diff,
                },
              })
              return
            }
          }
          this.showErrorTarif = true
          this.errorMessage =
            this.$t('projectTariff.tarif-error') +
            this.$t('projectTariff.reason-' + response.data.reason)
          setTimeout(() => {
            this.showErrorTarif = false
          }, 2500)
          return
        }
        await this.$router.push('/project/' + this.$route.params.id)
      } catch (e) {
        this.showErrorTarif = true
        this.errorMessage =
          this.$t('projectTariff.tarif-error') +
          this.$t('projectTariff.reason-unknown')
        setTimeout(() => {
          this.showErrorTarif = false
        }, 2500)
      }
    },
  },
}
</script>

<style lang="scss">
.pricing {
  .container {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px;
  }
  .range-title {
    font-size: 24px;
    font-weight: 500;

    @media (max-width: 767px) {
      margin-right: 20px;
      font-size: 16px;
    }
  }

  .range-slider {
    @media (max-width: 767px) {
      margin-top: 32px;

      .noUi-value {
        margin-top: 3px;
      }
    }
  }

  .options-result-price {
    font-size: 36px;
    font-weight: 500;
  }

  .options-result-info {
    line-height: 1.4;
  }

  .options-result-btn {
    @media (max-width: 767px) {
      flex-basis: 100%;
      order: 1;
      margin-top: 22px;
    }
  }

  .options-subscription {
    max-width: 534px;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .options-subscription-title {
    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  .options-subscription-btn {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .v-slider__thumb {
    width: 45px;
    height: 30px;
    left: -22px;
    top: 50%;
    border-radius: 9px;
    transform: translateY(-50%);
    cursor: pointer;
    //color:  !important;
  }

  .v-slider__track-fill.primary {
    background-color: #151515 !important;
  }

  .v-slider__thumb:before {
    opacity: 0;
  }

  .v-slider__thumb:before {
    opacity: 0;
  }

  .v-slider__track-background {
    height: 3px;
    cursor: pointer;
  }

  .v-slider__track-fill {
    height: 3px;
    cursor: pointer;
  }

  .v-slider__thumb-label {
    font-size: 0.95rem;
    width: 16px !important;
    height: 16px !important;
    cursor: pointer;
    transform: translateY(8px) translateX(-50%) rotate(45deg) !important;

    div {
      span {
        font-weight: 500;
      }
    }
  }

  *,
  ::after,
  ::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  .pricing__title {
    margin: 0 0 46px;
  }

  .h1 {
    font-size: 64px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
  }

  .pricing__tabs {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 -15px 17px;
  }

  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .btn--tab.active {
    color: #fff;
    background: #151515;
  }

  [type='reset'],
  [type='submit'],
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }

  .pricing__tab {
    margin: 0 15px 10px;
  }

  .btn--tab {
    height: 32px;
    padding: 0 15px;
    border-radius: 10px;
  }

  .pricing__tabs-discount {
    color: #001dff;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }

  .pricing__text {
    max-width: 600px;
    margin: 0 auto 40px;
    text-align: center;
  }

  .pricing-tariffs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    margin: 0 0 58px;
  }

  ul {
    list-style-type: none !important;
  }

  .pricing-tariffs__card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    padding: 45px 15px 30px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }

  .pricing-tariffs__btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-weight: 600;
  }

  .btn--s-l {
    height: 56px;
    font-size: 18px;
    border-radius: 15px;
  }

  .btn--tr {
    -webkit-box-shadow: inset 0 0 0 1px #151515;
    box-shadow: inset 0 0 0 1px #151515;
  }

  .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 24px;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 15px;
    outline: 0;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }

  .pricing-tariffs__name {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .pricing-tariffs__item-bottom {
    margin-top: auto;
  }

  .pricing-tariffs__amount {
    display: block;
    margin: 0 0 3px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.1;
  }

  .pricing-tariffs__price {
    min-height: 52px;
    margin: 0 0 16px;
  }

  .pricing-tariffs__item--recommend .pricing-tariffs__card {
    padding-top: 18px;
    color: #fff;
    background: #151515;
    border-color: #151515;
    .pricing-tariffs__btn {
      -webkit-box-shadow: inset 0 0 0 1px #fff;
      box-shadow: inset 0 0 0 1px #fff;
    }
  }

  .pricing-tariffs__btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-weight: 600;
  }

  .btn--s-l {
    height: 56px;
    font-size: 18px;
    border-radius: 15px;
  }

  .pricing-tariffs__input:checked + .pricing-tariffs__card {
    border-color: #151515;
  }

  .pricing__tabs-content.active {
    display: block;
  }

  .pricing__tabs-content {
    display: none;
  }

  .pricing-options {
    margin: 0 0 60px;
  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  .pricing-options__table {
    width: 100%;
    border-spacing: 0;
  }

  .pricing-options__name {
    position: sticky;
    left: 0;
    z-index: 1;
    padding: 15px 25px 15px 0;
    background: #fff;
  }

  .pricing-options__th {
    padding: 0 0 15px;
    font-size: 18px;
    font-weight: 500;
  }

  .pricing-options__row:nth-child(2) .pricing-options__value {
    border-top-width: 1px;
  }

  .pricing-options__value {
    width: 198px;
    text-align: center;
    border: solid #e1e1e1;
    border-width: 0 1px 0 0;
  }

  .pricing-options__value.infinite {
    font-size: 32px;
  }

  .pricing-options__row:last-child .pricing-options__value:nth-child(2) {
    border-radius: 0 0 0 15px;
  }

  .pricing-options__row:nth-child(odd) .pricing-options__value {
    background: #fafafa;
  }

  .pricing-options__row:last-child .pricing-options__value {
    border-bottom-width: 1px;
  }

  .pricing-options__value:nth-child(2) {
    border-left-width: 1px;
  }

  .pricing-options__value {
    width: 198px;
    text-align: center;
    border: solid #e1e1e1;
    border-width: 0 1px 0 0;
  }

  .pricing-tariffs__old-price {
    min-height: 26px;
    margin: 0 0 2px;
    font-size: 18px;
    font-weight: 500;
    color: #a9a9a9;
    text-decoration: line-through;
  }

  .pricing__compare-btn {
    display: none;
    margin: 0 auto 30px;
    color: #001dff;
  }

  .pricing-options {
    margin: 0 0 60px;
  }

  .h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
  }

  .paid-options__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  .paid-options__checkbox {
    display: block;
    height: 100%;
  }

  .checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .checkbox-input {
    height: 0;
    font-size: 0;
    opacity: 0;
    position: absolute;
  }

  [type='checkbox'],
  [type='radio'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: #151515;
    background-color: transparent;
    background-image: none;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  input,
  textarea {
    font-family: Golos, -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  .checkbox--big .checkbox-label {
    padding-left: 49px;
    color: #151515;
  }

  .paid-options__label {
    display: block;
    height: 100%;
    padding: 16px 66px 17px 15px !important;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }

  .checkbox-label {
    position: relative;
    padding-left: 26px;
    color: #a9a9a9;
    cursor: pointer;
  }

  .checkbox--big .checkbox-label::after,
  .checkbox--big .checkbox-label::before {
    top: calc(50% - 18px);
    width: 36px;
    height: 36px;
    border-radius: 10px;
  }

  .paid-options__label::after,
  .paid-options__label::before {
    top: 23px !important;
    right: 15px;
    left: auto;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }

  .checkbox-label::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #a9a9a9;
    border-radius: 4px;
    background: #fff;
  }

  .checkbox--big .checkbox-label::after {
    background-size: 16px 13px;
  }

  .checkbox--big .checkbox-label::after,
  .checkbox--big .checkbox-label::before {
    top: calc(50% - 18px);
    width: 36px;
    height: 36px;
    border-radius: 10px;
  }

  .paid-options__label::after,
  .paid-options__label::before {
    top: 23px !important;
    right: 15px;
    left: auto;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }

  .checkbox-label::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    z-index: 15;
    width: 18px;
    height: 18px;
    opacity: 0;
    //background: url(../../img/new/icon-check.svg) no-repeat 50%/10px 8px;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  .paid-options__name {
    display: block;
    margin: 0 0 5px;
    font-weight: 500;
  }

  .pricing-cart {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 14px 0 18px;
    visibility: hidden;
    background: #fafafa;
    border-top: 1px solid #e1e1e1;
    opacity: 0;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  .payment {
    padding-bottom: 40px;
  }

  .payment-title {
    margin-bottom: 33px;
  }

  .h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.1;
    text-align: center;
  }

  .payment-tabs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 -8px -16px;
  }

  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  ul {
    list-style-type: none;
  }

  .payment-tabs-item {
    margin: 0 8px 16px;
    font-size: 16px;
    font-weight: 400;
    cursor: auto;
  }

  .btn--s-m {
    height: 56px;
    border-radius: 15px;
  }

  .btn--tabicon {
    background: #fafafa;
  }

  .banner {
    padding-bottom: 55px;
  }

  .banner-block {
    overflow: hidden;
    max-width: 610px;
    margin: 0 auto;
    padding: 38px 40px 0;
    border-radius: 20px;
    background: #151515 url(/img/banner-bg-1.png) no-repeat 100% 0/572px 492px;
    color: #fff;
  }

  .banner-title {
    max-width: 350px;
    margin: 0 auto 17px;
    line-height: 1;
  }

  .banner-subtitle {
    margin-bottom: 33px;
    text-align: center;
  }

  .banner-params {
    margin-bottom: 30px;
    padding: unset;
  }

  .banner-param {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }

  .faq {
    padding-bottom: 30px;
  }

  .faq-title {
    margin-bottom: 50px;
  }

  .faq-list {
    max-width: 610px;
    margin: 0 auto;
  }

  .faq-item {
    border-top: 1px solid #e1e1e1;
  }

  .faq-item-question {
    position: relative;
    padding: 32px 50px 36px 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    cursor: pointer;
  }

  .h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.1;
  }

  .faq-item-answer {
    display: none;
    margin-top: -28px;
    padding: 0 75px 37px 0;
  }

  .active .pricing__tabs-discount {
    color: #fff;
  }

  .tariffs-range-title {
    font-size: 24px;
    font-weight: 500;
  }

  .tariffs-main {
    max-width: 1030px;
    margin: 0 auto;
  }

  .tariffs-range {
    margin-bottom: 55px;
  }

  .tariffs-range-top {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 29px;
  }

  .tariffs-range-info {
    margin-left: auto;
  }

  .tariffs-params {
    margin-top: -25px;
    margin-bottom: 48px;
  }

  .tariffs-params-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #001dff;
    cursor: pointer;
  }

  .tariffs-params-content {
    padding-top: 23px;
  }

  .tariffs-params-title {
    margin-bottom: 6px;
  }

  .tariffs-range-input {
    width: 70px;
    height: 32px;
    padding: 0 10px;
    margin: -5px 0 -5px 10px;
    font-weight: 500;
    text-align: center;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: #151515;
    background-color: transparent;
    background-image: none;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  input,
  textarea {
    font-family: Golos, -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  .tariffs-range-slider-max.infinite {
    bottom: -30px;
    font-size: 28px;
  }

  .tariffs-range-slider-max {
    position: absolute;
    right: 0;
    bottom: -23px;
    color: #919298;
  }

  .mdi-infinity {
    transform: translateY(17px) translateX(-28px);
  }

  .tariffs-params-btn.v-expansion-panel-header
    > *:not(.v-expansion-panel-header__icon) {
    flex: none;
  }

  .visually-hidden {
    display: none !important;
  }

  .paid-options__checkbox {
    display: block;
    height: 100%;
  }

  .paid-options__title {
    margin: 0 0 30px;
  }

  .paid-options {
    margin: 0 0 60px;
  }

  .paid-options__input:checked + .paid-options__label::before {
    border-color: #151515;
  }

  .checkbox--big .checkbox-label::after,
  .checkbox--big .checkbox-label::before {
    top: calc(50% - 18px);
    width: 36px;
    height: 36px;
    border-radius: 10px;
  }

  .paid-options__label::after,
  .paid-options__label::before {
    top: 23px !important;
    right: 15px;
    left: auto;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }

  .checkbox-label::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #a9a9a9;
    border-radius: 4px;
    background: #fff;
  }

  .checkbox-input:checked + .checkbox-label::after,
  .checkbox-input:checked + label + .checkbox-label::after {
    opacity: 1;
  }
  .checkbox--big .checkbox-label::after {
    background-size: 16px 13px;
  }
  .checkbox--big .checkbox-label::after,
  .checkbox--big .checkbox-label::before {
    top: calc(50% - 18px);
    width: 36px;
    height: 36px;
    border-radius: 10px;
  }
  .paid-options__label::after,
  .paid-options__label::before {
    top: 23px !important;
    right: 15px;
    left: auto;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }
  .checkbox-label::after {
    content: '';
    position: absolute;
    top: 3px;
    z-index: 15;
    width: 18px;
    height: 18px;
    opacity: 0;
    background: url(/icons/check.svg) no-repeat 50%/10px 8px;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  .paid-options__input:checked + .paid-options__label {
    border-color: #151515;
  }
  .checkbox--big .checkbox-label {
    padding-left: 49px;
    color: #151515;
  }
  .paid-options__label {
    display: block;
    height: 100%;
    padding: 16px 66px 17px 15px !important;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }
  .checkbox-label {
    position: relative;
    padding-left: 26px;
    color: #a9a9a9;
    cursor: pointer;
  }

  .banner-param svg {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: -2px;
    margin-right: 15px;
    stroke-width: 2;
  }
  svg:not(:root) {
    overflow: hidden;
  }

  .banner-param {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }

  .banner-params {
    margin-bottom: 30px;
    padding: unset;
  }

  .banner-btns {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 45px;
  }

  .btn--s-l {
    height: 56px;
    font-size: 18px;
    border-radius: 15px;
  }

  .btn--main {
    color: #fff;
    background: #001dff;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .pricing-cart.active {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .pricing-cart {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 22px 0 18px;
    visibility: hidden;
    background: #fafafa;
    border-top: 1px solid #e1e1e1;
    opacity: 0;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  .pricing-cart__inner {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    max-width: 1192px;
    margin: 0 auto;
  }

  .pricing-cart__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .pricing-cart__title {
    display: none;
    margin: 15px 0 20px;
    text-align: left;
  }

  .pricing-cart__tariff {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 186px;
  }

  .pricing-cart__caption {
    margin: 0 0 4px;
    color: #a9a9a9;
  }

  .pricing-cart__tariff-name {
    margin: 0 0 1px;
  }

  .pricing-cart__letters.show {
    display: block;
  }

  .pricing-cart__letters {
    display: none;
    width: 210px;
  }

  .pricing-cart__options {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 40px 0 0;
  }

  .pricing-cart__options-has {
    display: none;
    margin: 0 0 1px;
  }
  [type='reset'],
  [type='submit'],
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }
  .pricing-cart__options-more {
    -ms-flex-item-align: end;
    align-self: flex-end;
    height: 30px;
    padding: 0 10px;
    margin: 0 0 -3px 14px;
  }

  .btn--s-s {
    height: 34px;
    padding: 0 20px;
    border-radius: 10px;
  }
  .btn--outlined {
    border: 1px solid #151515;
  }

  [type='reset'],
  [type='submit'],
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }
  .pricing-cart__options-all {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    color: #001dff;
  }

  [type='reset'],
  [type='submit'],
  button,
  html [type='button'] {
    -webkit-appearance: button;
  }

  .pricing-cart__btn-info {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0 0 20px auto;
    color: #001dff;
  }

  .pricing-cart__total {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 40px 0 0;
  }

  .pricing-cart__bottom-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .pricing-cart__total-cost {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.1;
  }
  .pricing-cart__option {
    margin: 0 0 1px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  .pricing-cart__options-list {
    padding-left: 12px;
  }
}
</style>
