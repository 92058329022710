<template>
  <v-main class="letter-renders">
    <v-container>
      <!-- Заголовок страницы -->
      <v-row justify="center">
        <v-col cols="12">
          <h1 class="title-h1 text-center">
            {{ $t('letterShareRender.renders') }}
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" lg="6" md="8" sm="8">
          <v-row>
            <!-- Поиск -->
            <v-col cols="12">
              <label class="d-sr-only">
                {{ $t('letterShareRender.search') }}
              </label>
              <v-text-field
                class="input-search select-text"
                outlined
                :placeholder="$t('letterShareRender.search')"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                :hide-details="true" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Раздел под табы -->
      <v-row justify="center" v-if="renderList.length">
        <v-col cols="10">
          <v-expansion-panels multiple class="render-panels" v-model="panel">
            <v-expansion-panel
              class="render-panel"
              v-for="(list, index) in filteredList"
              :key="index">
              <v-expansion-panel-header class="letter-renders-title pl-1">
                {{ list.os }}
                <template v-slot:actions>
                  <v-icon x-large>mdi-chevron-up</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Карточка рендера -->
                <div class="row">
                  <template v-for="item in list.items">
                    <v-col
                      lg="3"
                      md="4"
                      sm="6"
                      cols="12"
                      v-if="item.isVisible"
                      :key="item.id">
                      <RenderCard :item="item" :type="'public'" />
                    </v-col>
                  </template>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import RenderCard from '@/components/renders/RenderCard.vue'

import { displayNotify } from '@/utilities/helpers'

import { mapGetters } from 'vuex'

export default {
  name: 'LetterShareRender',
  components: {
    RenderCard,
  },
  data() {
    return {
      search: '',
      panel: [0, 1, 2, 3],
      renderList: [],
    }
  },
  computed: {
    ...mapGetters('share', { renders: 'getRenders' }),
    filteredList() {
      return this.renderList.filter((list) => {
        return list.items.filter((item) => {
          if (item.name.toLowerCase().includes(this.search.toLowerCase())) {
            item.isVisible = true
          } else {
            item.isVisible = false
          }

          return item
        })
      })
    },
    isRendersLoaded() {
      return this.$store.state.share.isRendersLoaded
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    async get() {
      const options = {
        type: 'letter',
        id: this.$route.params.id,
      }
      await this.$store.dispatch('share/getPublicRenders', options)
      if (!this.isRendersLoaded) {
        displayNotify(this.$t('letterShareRender.loading-error'), 'error')
      }
      if (!this.renders) return false

      this.draw(this.renders)
    },
    draw(result) {
      for (let itemIndex in result) {
        let objectDevices = { os: '', items: [] }
        let osName = itemIndex

        switch (itemIndex) {
          case 'windows':
            osName = 'Windows'
            break
          case 'android':
            osName = 'Android'
            break
          case 'web':
            osName = 'Web'
            break
          case 'other':
            osName = 'Остальные'
            break
        }

        objectDevices.os = osName
        this.renders[itemIndex].forEach((item) => {
          let device_name = `<b>${item.name}</b> ${item.os}`
          if (item.browser) {
            device_name += `&nbsp;[${item.browser}]`
          }

          objectDevices.items.push({
            id: item.id,
            name: device_name,
            full: item.full,
            thumb: item.thumb,
          })
        })

        this.renderList.push(objectDevices)
      }
    },
  },
}
</script>

<style lang="scss">
.letter-renders {
  .render-panels {
    border-radius: 0;
  }
  .render-panel {
    &:before {
      box-shadow: 0 0 0;
    }
  }
  .input-search {
    font-size: 18px;
  }
  .theme--light.v-pagination .v-pagination__item {
    color: #a9a9a9;
    font-size: 18px;
    border-radius: 10px;
    box-shadow: 0 0 0;
    width: 36px;
    height: 36px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .theme--light.v-pagination .v-pagination__item--active {
    color: #151515;
    background: #eaeaea !important;
    border-color: #eaeaea !important;
    box-shadow: 0 0 0;
  }
  .theme--light.v-pagination .v-pagination__navigation {
    background: transparent;
    border-radius: 0;
    box-shadow: 0 0 0;
    width: auto;
    height: auto;
    margin-left: 40px;
    margin-right: 40px;
  }
  .theme--light.v-pagination
    .v-pagination__navigation
    .v-icon.mdi-chevron-left:before {
    font-size: 25px;
  }
  .theme--light.v-pagination
    .v-pagination__navigation
    .v-icon.mdi-chevron-left:after {
    content: 'Назад';
    color: #151515;
    height: auto;
    border-radius: 0;
    left: auto;
    position: relative;
    pointer-events: auto;
    opacity: 1;
    font-size: 18px;
    font-family: 'Golos Text';
    top: auto;
    transform: scale(1);
    font-style: normal;
    background: transparent;
  }
  .theme--light.v-pagination
    .v-pagination__navigation
    .v-icon.mdi-chevron-right:before {
    content: 'Вперед';
    color: #151515;
    height: auto;
    border-radius: 0;
    left: auto;
    position: relative;
    pointer-events: auto;
    opacity: 1;
    font-size: 18px;
    font-family: 'Golos Text';
    top: auto;
    transform: scale(1);
    font-style: normal;
  }
  .theme--light.v-pagination
    .v-pagination__navigation
    .v-icon.mdi-chevron-right:after {
    content: '\F0142';
    border-radius: 0;
    background-color: transparent;
    left: auto;
    top: auto;
    opacity: 1;
    pointer-events: auto;
    position: relative;
    transform: scale(1);
    font-style: normal;
    width: auto;
    display: inline-block;
    font: normal normal normal 24px/1 'Material Design Icons';
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    font-size: 25px;
  }
}

.letter-renders-title {
  font-style: 24px;
  font-weight: 500;
  padding-top: 18px;
  padding-bottom: 18px;
}

.list-card {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .list-card-preview {
    position: relative;
    border-radius: 15px;
    overflow: hidden;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
      border: 1px solid $color-gray;
      overflow-x: hidden;
    }
    .list-card-image-wrapper {
      width: 245px;
      height: 330px;
      overflow-y: hidden;
      .list-card-image {
        width: 100%;
        border-radius: 15px;
      }
    }
  }

  .list-card-bar {
    position: absolute;
    z-index: 1;
    bottom: 22px;
    left: 0;
    width: 100%;
    display: none;
    justify-content: center;
  }

  .list-card-info {
    margin-top: 1rem;
    width: 245px;

    .name {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .time {
      color: $color-gray;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .list-card-checkbox {
    display: none;
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 16px;
  }
}

.list-card:hover,
.list-card.selected {
  .list-card-preview::before {
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
  }

  .list-card-checkbox {
    display: flex;
  }

  .list-card-bar {
    display: flex;
  }
}

.loading::after {
  position: relative;
  background-image: 'img/letterLoading.png';
  left: calc(64px - 50%);
  top: 25%;
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
}

.list-card {
  .v-input--selection-controls__input {
    margin-right: 0;
  }
  .v-input--checkbox.v-input--selection-controls {
    .v-icon__component {
      color: $color-white;
    }

    &.text--dark {
      .v-label {
        color: $color-white;
      }
    }
    &:hover {
      .v-icon__component {
        color: $color-white;
      }
    }
  }
}
</style>
