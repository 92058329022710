<template>
  <div class="list-card" :class="{ selected: selected }">
    <div class="list-card-preview">
      <div class="list-card-bar">
        <!-- Шаблон загружается -->
        <div v-if="isDataLoading" class="table-actions-menu">
          {{ $t('theCard.data-loading') }}
        </div>

        <!-- Шаблон/Письмо создано -->
        <ElementMenu
          v-else-if="!item.isAppLetterosCom"
          :item="item"
          class="menu-blue" />

        <!-- Шаблон/Письмо из версии 1.0 -->
        <template v-else>
          <div class="list-card__buttons">
            <ElementMenuRedirect :id="item.id" />
            <ElementMenuMigrate :item="item" />
          </div>
        </template>
      </div>

      <v-checkbox
        v-model="selected"
        color="#fff"
        class="list-card-checkbox checkbox-white ml-auto" />
      <div class="list-card-image-wrapper">
        <img
          class="list-card-image"
          :src="item.preview"
          :alt="item.name"
          width="245"
          @load="previewLoaded"
          onerror="this.src=`/img/letterPreview.png`" />
      </div>
    </div>

    <div class="list-card-info">
      <input
        ref="rename"
        :disabled="isDisabled"
        class="name font-weight-medium"
        :value="item.name"
        @blur="renameHandler" />

      <div class="time">
        {{ $t('theCard.updated') }}
        {{ item.updatedAt }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import ElementMenu from '@/components/home/element/ElementMenu.vue'
import ElementMenuRedirect from '@/components/home/element/ElementMenuRedirect.vue'
import ElementMenuMigrate from '@/components/home/element/ElementMenuMigrate.vue'
import itemMixin from '@/mixins/Home/item.mixin.js'

export default {
  name: 'ItemCard',
  mixins: [itemMixin],
  components: {
    ElementMenu,
    ElementMenuRedirect,
    ElementMenuMigrate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.list-card {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &__buttons {
    max-width: 100%;
  }

  .list-card-preview {
    position: relative;
    border-radius: 15px;
    overflow: hidden;

    &:hover {
      .list-card-image.loaded {
        animation-name: scrollImage;
        animation-duration: 9s;
      }
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
      border: 1px solid $color-gray;
      overflow-x: hidden;
      z-index: 1;
    }

    .list-card-image-wrapper {
      width: 245px;
      height: 330px;
      overflow-y: hidden;
      .list-card-image {
        width: 100%;
        border-radius: 15px;
      }
    }
  }

  .list-card-bar {
    position: absolute;
    bottom: 22px;
    left: 50%;
    display: none;
    justify-content: center;
    width: 100%;
    max-width: 223px;
    z-index: 1;
    transform: translateX(-50%);
  }

  .list-card-info {
    margin-top: 1rem;
    width: 245px;

    .name {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .time {
      color: $color-gray;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .list-card-checkbox {
    display: none;
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 16px;
  }
}

.list-card.selected {
  .list-card-preview::before {
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
  }

  .list-card-checkbox {
    display: flex;
  }
}

.list-card:hover {
  .list-card-preview::before {
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
  }

  .list-card-checkbox {
    display: flex;
  }

  .list-card-bar {
    display: flex;
  }
}

.list-card {
  .v-input--selection-controls__input {
    margin-right: 0;
  }
  .v-input--checkbox.v-input--selection-controls {
    .v-icon__component {
      color: $color-white;
    }

    &.text--dark {
      .v-label {
        color: $color-white;
      }
    }
    &:hover {
      .v-icon__component {
        color: $color-white;
      }
    }
  }
}

@keyframes scrollImage {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% + 330px));
  }
}
</style>
