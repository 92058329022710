<template>
  <div class="option-device">
    <label class="option__label">
      {{ $t('optionVisibility.show-module') }}
    </label>
    <v-btn-toggle
      v-model="mode"
      mandatory
      class="d-flex mb-2"
      @change="changeMode">
      <v-btn
        class="btn-small"
        depressed
        outlined
        value="all"
        width="33.3%"
        :ripple="false">
        {{ $t('optionVisibility.everywhere') }}
      </v-btn>
      <v-btn
        class="btn-small"
        depressed
        outlined
        value="desktop"
        width="33.3%"
        :ripple="false">
        <IconDesktop />
      </v-btn>
      <v-btn
        class="btn-small px-6"
        :ripple="false"
        depressed
        outlined
        value="mobile"
        width="33.3%">
        <IconMobile />
      </v-btn>
    </v-btn-toggle>
    <v-checkbox
      v-model="isImage"
      :label="$t('optionVisibility.turn-to-image')"
      @change="convertToImage" />
    <div class="option-element" v-show="isImage">
      <v-text-field
        v-model="link"
        class="mb-n2"
        :placeholder="$t('optionVisibility.link-of-image')"
        outlined
        dense
        @focusout="setImageLink" />
    </div>
  </div>
</template>

<script>
import IconDesktop from '@/components/icons/IconDesktop.vue'
import IconMobile from '@/components/icons/IconMobile.vue'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'OptionVisibility',
  components: {
    IconDesktop,
    IconMobile,
  },
  props: {
    element: [HTMLElement, null],
  },
  filters: {
    stringToBool: function (string) {
      if (string === 'true') {
        return true
      } else {
        return false
      }
    },
  },
  data() {
    return {
      mode: 'all',
      isImage: false,
      link: '',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const modeType = this.element.getAttribute('data-visibility')

      if (!modeType) {
        // При добавлении модуля из библиотеки, у него еще не задан режим
        this.element.setAttribute('data-visibility', 'all')
      } else {
        // При повторном открытие меняем режим в сайдбаре
        this.mode = modeType
      }

      this.isImage = this.$options.filters.stringToBool(
        this.element.getAttribute('letteros-image-replace')
      )

      this.link = this.element.getAttribute('letteros-image-replace-href')
    },
    changeMode() {
      this.element.setAttribute('data-visibility', this.mode)
      displayNotify(this.$t('optionVisibility.visibility-set'))
    },
    convertToImage() {
      this.element.setAttribute('letteros-image-replace', this.isImage)
      if (this.isImage) {
        displayNotify(this.$t('optionVisibility.option-set'))
      }
    },
    setImageLink() {
      this.element.setAttribute('letteros-image-replace-href', this.link)
      displayNotify(this.$t('optionVisibility.link-set'))
    },
  },
}
</script>

<style lang="scss">
.option-device {
  button.v-item--active {
    background-color: #dfdfe0;
  }
}
</style>
