<template>
  <div>
    <!-- Заголовок -->
    <v-list-item>
      <v-list-item-content class="font-weight-medium">
        {{ localeTitle }}
      </v-list-item-content>
    </v-list-item>

    <!-- Первый пункт (Статичный) -->
    <v-list-item
      :to="{ name: linkTo }"
      class="directory"
      :ripple="false"
      :exact="true">
      <div class="d-flex">
        <div class="sidebar-counter">
          {{ itemSize }}
        </div>
        {{ $t('sidebarMain.all') }}
      </div>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'FolderHeader',
  props: {
    type: String,
  },
  computed: {
    localeTitle() {
      if (this.type == 'letter' || this.type == 'custom') {
        return this.$t('sidebarMain.my-letters')
      }

      if (this.type == 'template') {
        return this.$t('sidebarMain.my-templates')
      }

      return ''
    },
    linkTo() {
      if (this.type == 'letter') {
        return 'letter-index'
      }

      if (this.type == 'template') {
        return 'template-index'
      }

      if (this.type == 'custom') {
        return 'letter-custom-index'
      }

      return ''
    },
    itemSize() {
      if (this.type == 'letter') {
        return this.$store.state.project.full.lettersTotal
      }

      if (this.type == 'template') {
        return this.$store.state.project.full.templatesTotal
      }

      if (this.type == 'custom') {
        return this.$store.state.project.full.customTotal
      }

      return ''
    },
  },
}
</script>
