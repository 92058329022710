<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <g>
      <path style=" stroke:none;fill-rule:nonzero;" d="M 24.75 9.027344 L 22.480469 6.75 L 11.25 18 L 22.480469 29.25 L 24.75 26.972656 L 15.800781 18 Z M 24.75 9.027344 "/>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 24},
    viewBox: {type: String, default: '0 0 36 36'},
    fill: {type: String, default: '#000'}
  }
}
</script>