const state = () => ({
  triggeredEvent: null
})

const getters = {}

const mutations = {
  setEvent(state, payload) {
    state.triggeredEvent = payload
  },
  clearEvent(state) {
    state.triggeredEvent = null
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
