<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M19.5296 23H4.47042C2.55815 23 1 21.4501 1 19.5282V4.47182C1 2.55878 2.5493 1 4.47042 1H19.5296C21.4419 1 23 2.54992 23 4.47182V19.537C22.9911 21.4501 21.4419 23 19.5296 23Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.59521 17.7481L11.5353 5.80934C11.6681 5.4905 12.1196 5.4905 12.2524 5.80048L17.3872 17.7481" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.74658 13.0273H15.1917" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>