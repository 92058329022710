<template>
  <div class="modal-user">
    <header
      class="modal-header d-flex justify-space-between font-weight-medium text-h5 mb-3">
      {{ $t('editing-a-participant') }}
      <v-btn icon class="" @click="$emit('close')" :ripple="false">
        <IconClose />
      </v-btn>
    </header>
    <div class="d-flex flex-column justify-center mb-3">
      <ImageUploader :immediately="true" />
      <span class="mt-3 text-caption text-center grey--text">
        {{ $t('acceptable-file-formats') }}
      </span>
    </div>
    <v-row>
      <v-col>
        <label class="label">{{ $t('name') }}</label>
        <v-text-field
          class=""
          value="Имя"
          outlined
          :hide-details="true"
          v-model="user.firstname" />
      </v-col>
      <v-col>
        <label class="label">{{ $t('surname') }}</label>
        <v-text-field
          class=""
          outlined
          value="Фамилия"
          :hide-details="true"
          v-model="user.lastname" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label class="label">{{ $t('email') }}</label>
        <v-text-field
          class=""
          outlined
          value="email"
          :hide-details="true"
          v-model="user.email" />
      </v-col>
      <v-col>
        <label class="label">{{ $t('access-level') }}</label>
        <v-select
          class=""
          :items="roles"
          :menu-props="{ offsetY: true }"
          :placeholder="$t('choose')"
          item-text="label"
          return-object
          :hide-details="true"
          v-model="user.role"
          outlined
          append-icon="mdi-chevron-down" />
      </v-col>
    </v-row>
    <footer class="modal-footer d-flex flex-nowrap justify-end mt-8">
      <v-btn
        class="mr-3 black-hover"
        outlined
        x-large
        @click="$emit('remove-user')"
        :ripple="false">
        {{ $t('delete-a-participant') }}
      </v-btn>
      <v-btn
        class=""
        color="primary"
        depressed
        x-large
        @click="$emit('save-user', user)"
        :ripple="false">
        {{ $t('save') }}
      </v-btn>
    </footer>
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue'
import ImageUploader from '@/components/tools/ImageUploader.vue'

export default {
  name: 'ModalUser',
  components: { ImageUploader, IconClose },
  props: {
    userData: Object,
    roles: Array,
  },
  data() {
    return {
      user: {
        firstname: '',
        lastname: '',
        avatarUrl: '',
        email: '',
        role: {
          label: '',
          slug: '',
        },
      },
    }
  },
  computed: {
    avatar() {
      return this.user.avatarUrl
        ? `${window.appBaseUrl ?? process.env.VUE_APP_BACKEND_HOST}/${
            this.user.avatarUrl
          }`
        : `/icons/userLarge.svg`
    },
  },
  created() {
    this.loadUser()
  },
  methods: {
    loadUser() {
      this.user = { ...this.userData }
    },
  },
}
</script>

<style lang="scss">
.modal-user {
  background-color: $color-white;
  padding: 20px;
}
</style>

<i18n>
{
  "ru": {
    "owner": "Владелец",
    "member": "Участник",
    "admin": "Админ",
    "choose": "Выбрать",
    "editing-a-participant": "Редактирование участника",
    "replace-the-image": "Заменить изображение",
    "name": "Имя",
    "surname": "Фамилия",
    "email": "Почта",
    "access-level": "Уровень доступа",
    "delete-a-participant": "Удалить участника",
    "acceptable-file-formats": "Допустимые форматы файлов — png, jpg или gif. Размер файла до 1 мб",
    "save": "Сохранить"
  },
  "en": {
    "owner": "Owner",
    "member": "Member",
    "admin": "Admin",
    "choose": "Choose",
    "editing-a-participant": "Editing a member",
    "replace-the-image": "Replace the image",
    "name": "Name",
    "surname": "Last name",
    "email": "Email",
    "access-level": "Access level",
    "delete-a-participant": "Delete a member",
    "acceptable-file-formats": "Allowed file formats are png, jpg or gif. File size up to 1 MB",
    "save": "Save"
  }
}
</i18n>
