<template>
  <v-card class="document-delete">
    <v-card-title class="text-h5 justify-space-between">
      {{ title }}
      <v-btn icon class="" @click="$emit('close')" :ripple="false">
        <IconClose />
      </v-btn>
    </v-card-title>
    <v-card-text class="justify-center mb-5">
      {{ item.name }}
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        x-large
        elevation="0"
        outlined
        class="mr-3 px-8 black-hover"
        @click="$emit('close')"
        :ripple="false">
        {{ $t('documentDelete.cancel') }}
      </v-btn>
      <v-btn
        color="primary px-8"
        elevation="0"
        x-large
        :ripple="false"
        @click="remove">
        {{ $t('documentDelete.delete') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue'
import { displayNotify } from '@/utilities/helpers'
import project from '@/mixins/project.mixin'

export default {
  name: 'DocumentDelete',
  components: { IconClose },
  mixins: [project],
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    selectedId() {
      return this.$store.state.tableStore.currentEditId
    },
    title() {
      if (this.pageType === 'template')
        return this.$t('documentDelete.delete-template')
      if (this.pageType === 'letter')
        return this.$t('documentDelete.delete-letter')
      if (this.pageType === 'templates')
        return this.$t('documentDelete.delete-templates')
      if (this.pageType === 'letters')
        return this.$t('documentDelete.delete-letters')

      return ''
    },
    item() {
      return this.$store.getters['itemsStore/itemById'](this.selectedId)
    },
  },
  methods: {
    async remove() {
      try {
        await this.$store.dispatch('itemsStore/removeItem', this.item.id)
        this.$store.commit('itemsStore/unselectAll')

        await this.$store.dispatch('itemsStore/getData')
        displayNotify(this.$t('documentDelete.success-delete'))
        await this.loadProject()
      } catch (e) {
        displayNotify(this.$t('documentDelete.delete-error'))
      }

      this.$emit('close')
    },
  },
}
</script>
