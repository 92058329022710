<template>
  <v-main class="editor-html">
    <div class="editor-wrapper">
      <div class="editor">
        <div class="editor__placeholder" ref="editor-placeholder">
          //Добавьте html код
        </div>
        <div class="editor__code">
          <div id="editorCode" ref="editor-code"></div>
        </div>

        <div class="editor__preview">
          <iframe
            id="editorPreview"
            ref="editor-preview"
            frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import loader from '@monaco-editor/loader'

export default {
  name: 'LetterEditorCustom',
  data() {
    return {
      editor: false,
    }
  },
  computed: {
    html() {
      return this.$store.state.customStore.content
    },
  },
  created() {
    loader.init().then((monaco) => {
      this.run(monaco)
    })
  },
  methods: {
    run(monaco) {
      let codeContainer = this.$refs['editor-code']
      let previewContainer = this.$refs['editor-preview'].contentWindow.document
      let placeholderContainer = this.$refs['editor-placeholder']

      this.editor = monaco.editor.create(codeContainer, {
        value: this.html,
        language: 'html',
        minimap: { enabled: false },
        automaticLayout: true,
        contextmenu: false,
        fontSize: 12,
        scrollbar: {
          useShadows: true,
          vertical: 'visible',
          horizontal: 'hidden',
          horizontalScrollbarSize: 12,
          verticalScrollbarSize: 12,
        },
        theme: 'vs-dark',
        tabSize: 2,
        wordWrap: 'on',
        scrollBeyondLastLine: false,
      })

      previewContainer.body.innerHTML = this.html

      this.editor.onDidChangeModelContent(() => {
        this.$store.commit('customStore/updateContent', this.editor.getValue())

        if (this.html != '') {
          placeholderContainer.style.display = 'none'
        } else {
          placeholderContainer.style.display = 'block'
        }

        previewContainer.body.innerHTML = this.html
      })
    },
  },
}
</script>

<style lang="scss">
.editor-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .editor {
    width: 100%;
    max-width: 100vw;
    margin: 0;
    height: calc(100vh - 57px);
    display: flex;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.1), 0 -2px 3px rgba(0, 0, 0, 0.125);
  }

  .editor__placeholder {
    position: absolute;
    display: block;
    white-space: pre-wrap;
    top: 0px;
    left: 65px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
    font-family: Consolas, 'Courier New', monospace;
    pointer-events: none;
    user-select: none;
    z-index: 1;
  }

  .editor__code {
    position: relative;
    border: none;
    flex-basis: 50%;
  }

  .editor__preview {
    position: relative;
    flex-basis: 50%;
  }

  .editor__code > *,
  .editor__preview > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
