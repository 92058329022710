<template>
  <svg :width="size" :height="size" :viewBox="viewBox">
    <g>
      <path :style="{stroke:stroke}" :fill="stroke" d="M 13 7.828125 L 13 20 L 11 20 L 11 7.828125 L 5.636719 13.191406 L 4.222656 11.777344 L 12 4 L 19.777344 11.777344 L 18.363281 13.191406 Z M 13 7.828125 "/>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>