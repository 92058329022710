<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <g clip-path="url(#clip0_5831_267)">
      <path d="M16.879 16.8937C16.2739 16.5198 15.5103 16.5166 14.9022 16.8855L2.78795 24.2345C1.15073 25.2277 1.85477 27.7457 3.76969 27.7457H27.7763C29.683 27.7457 30.3933 25.245 28.7713 24.2426L16.879 16.8937Z" fill="#151515" :stroke="stroke" stroke-width="0.946419" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.84995 31.0759H5.1443C4.09892 31.0759 3.25146 31.9234 3.25146 32.9688V40.9848C3.25146 42.0301 4.09892 42.8776 5.14431 42.8776H6.84995C7.89533 42.8776 8.74279 42.0301 8.74279 40.9848V32.9688C8.74279 31.9234 7.89533 31.0759 6.84995 31.0759Z" fill="#151515" :stroke="stroke" stroke-width="0.946419" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.4325 31.0759H14.7268C13.6814 31.0759 12.834 31.9234 12.834 32.9688V40.9848C12.834 42.0301 13.6814 42.8776 14.7268 42.8776H16.4325C17.4779 42.8776 18.3253 42.0301 18.3253 40.9848V32.9688C18.3253 31.9234 17.4779 31.0759 16.4325 31.0759Z" fill="#151515" :stroke="stroke" stroke-width="0.946419" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M29.0914 46.0837H2.34848C1.50449 46.0837 0.820312 46.7679 0.820312 47.6119C0.820312 48.4559 1.5045 49.1401 2.34848 49.1401H29.0914C29.9353 49.1401 30.6195 48.4559 30.6195 47.6119C30.6195 46.7679 29.9353 46.0837 29.0914 46.0837Z" fill="#151515" :stroke="stroke" stroke-width="0.946419" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M26.202 31.0759H24.4964C23.451 31.0759 22.6035 31.9234 22.6035 32.9688V40.9848C22.6035 42.0301 23.451 42.8776 24.4964 42.8776H26.202C27.2474 42.8776 28.0948 42.0301 28.0948 40.9848V32.9688C28.0948 31.9234 27.2474 31.0759 26.202 31.0759Z" fill="#151515" :stroke="stroke" stroke-width="0.946419" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M49.3266 10.74L49.3266 10.7401L49.3309 10.7445C49.3917 10.8075 49.4485 10.8553 49.5 10.8931V33.0167C49.5 34.4566 48.4082 35.5721 47.127 35.5721H26.7406C25.4595 35.5721 24.3677 34.4566 24.3677 33.0167V3.06763C24.3677 1.63461 25.459 0.517563 26.7361 0.512221C26.7363 0.51222 26.7366 0.512219 26.7368 0.512218L39.7054 0.500106C39.7404 0.553397 39.7842 0.610354 39.8405 0.670297L39.8408 0.670636L49.3266 10.74Z" fill="white" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M30.6799 16.5603H43.5598" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M30.6799 22.0803H43.5598" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M30.6799 27.6001H43.5598" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M34.305 6.36514H33.0987C33.0987 6.36514 31.3853 6.32288 31.3853 7.67521C31.3853 8.8585 33.0027 9.01346 33.0027 9.01346C33.0027 9.01346 34.4694 9.19659 34.4694 10.2249C34.4694 11.5632 32.989 11.4787 32.989 11.4787H31.3853" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M33.0574 5.98513V5.52026" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M33.0574 12.155V11.676" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40.2769 1.31986L48.7615 10.1256H42.6601C41.3615 10.1256 40.2769 9.02749 40.2769 7.63657V1.31986Z" fill="#151515" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_5831_267">
        <rect width="50" height="50" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 50},
    height: {type: [Number, String], default: 50},
    stroke: {type: String, default: '#151515'},
    viewBox: {type: String, default: '0 0 50 50'},
    fill: {type: String, default: 'none'}
  }
}
</script>