const state = () => ({
  sidebarShow: false,
  viewType: 'card',
  pageType: '',
  page: 1,
  limit: 8,
  letterType: null,
  templateType: null,
  meta: {
    totalCount: 0,
    pageCount: 0,
    currentPage: 1,
    perPage: 0,
  },
  editMode: '',
  currentEditId: null,
})

const getters = {
  limitAdaptive(state) {
    return state.viewType === 'card' ? state.limit : state.limit > 2 ? 9 : 4
  },
}

const mutations = {
  changeSidebarShow(state, payload) {
    state.sidebarShow = payload
  },
  changePageType(state, payload) {
    state.pageType = payload
  },
  changePage(state, payload) {
    state.page = payload
  },
  changeDataInsideLoop(state, payload) {
    state.analyticsData.items[payload.index].subscribers = payload.subscribers
    state.analyticsData.items[payload.index].open = payload.open
    state.analyticsData.items[payload.index].click = payload.click
    state.analyticsData.items[payload.index].open_percent = payload.open_percent
  },
  changeMeta(state, payload) {
    state.meta = payload
  },
  changeView(state, payload) {
    state.viewType = payload
  },
  changeLetterType(state, payload) {
    state.letterType = payload
  },
  changeTemplateType(state, payload) {
    state.templateType = payload
  },
  changeEditMode(state, payload) {
    state.editMode = payload
  },
  changeEditId(state, payload) {
    state.currentEditId = payload
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
