<template>
  <div class="flex-grow-1 pb-8">
    <p class="title-h6 mb-4">
      {{ $t('testTypography.check-typography') }}
    </p>
    <v-progress-linear
      v-if="progress > 1 && progress < 100"
      :value="progress"
      color="dark"
      height="36">
      <template v-slot:default="{ value }">
        <strong class="white--text">{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
    <v-btn
      v-else
      class="btn-small btn-black-border"
      :ripple="false"
      depressed
      block
      outlined
      @click="typoDocument">
      {{ $t('testTypography.start') }}
    </v-btn>
  </div>
</template>

<script>
import { typoElement, typoText } from '@/utilities/letter.js'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'TestTypography',
  props: {},
  data() {
    return {
      total: null,
      checked: null,
      progress: null,
    }
  },
  computed: {
    pageType() {
      return this.$route.name == 'letter-custom-editor' ? 'custom' : 'default'
    },
  },
  methods: {
    typoDocument() {
      this.progress = null
      this.updateProgress()
      if (this.pageType == 'default') this.typoModules()
      if (this.pageType == 'custom') this.typoFrame()
    },
    typoModules() {
      const textElements = document.querySelectorAll(
        '.module-code p, .module-code a'
      )
      this.total = textElements.length
      textElements.forEach((element) => {
        // [Переписать] Меняется html, но при этом tinymce нормально продолжает работать [LATER]
        typoElement(element)
        ++this.checked
      })
    },
    typoFrame() {
      const iframe = document.querySelector('.tox-edit-area__iframe')
      const body = iframe.contentWindow.document.body
      const elems = this.findTextNode(body)

      elems.forEach((el) => {
        el.textContent = typoText(el.textContent)
        ++this.checked
      })
    },
    findTextNode(el) {
      var n,
        a = [],
        walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false)
      while ((n = walk.nextNode())) a.push(n)
      return a
    },
    updateProgress() {
      let timerId = setInterval(() => {
        this.progress += 10
        if (this.progress >= 100) {
          clearInterval(timerId)
          displayNotify(this.$t('testTypography.end'))
        }
      }, 100)
    },
  },
}
</script>
