<template>
  <header class="header-template-editor">
    <div class="header-left">
      <router-link :to="{ name: 'letter-index' }" class="d-flex mt-5">
        <IconLetterosLogo />
      </router-link>
      <v-btn
        class="ml-5 mt-5"
        primary
        depressed
        color="primary"
        link
        :to="{ name: letterEditorRoute, params: { id: $route.params.id } }"
        :ripple="false">
        {{ $t('headerPreview.edit') }}
      </v-btn>
      <v-text-field
        v-model="emailToSend"
        outlined
        dense
        type="email"
        class="ml-2 mt-5"
        style="min-width: 280px" />
      <v-btn
        class="ml-2 mt-5 text-body-1 font-weight-medium"
        :ripple="false"
        color="primary"
        depressed
        @click="sendTestEmail">
        {{ $t('headerPreview.submit-a-test') }}
      </v-btn>
    </div>
    <div class="header-right">
      <slot name="share-menu" />
    </div>
  </header>
</template>

<script>
import IconLetterosLogo from '@/components/icons/IconLetterosLogo.vue'
import { sendEmail } from '@/utilities/letter'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'HeaderPreview',
  components: {
    IconLetterosLogo,
  },
  data() {
    return {
      emailToSend: '',
    }
  },
  computed: {
    letterEditorRoute() {
      return this.$store.state.share.letterType == 'custom'
        ? 'letter-custom-editor'
        : 'letter-editor'
    },
  },
  methods: {
    async sendTestEmail() {
      if (this.emailToSend) {
        const result = await sendEmail({
          letterId: this.$route.params.id,
          receiverEmail: this.emailToSend,
        })
        if (result.status) {
          displayNotify(this.$t('headerPreview.all-sent'))
        } else {
          displayNotify(this.$t('headerPreview.all-not-sent'), 'error')
        }
      } else {
        displayNotify(this.$t('headerPreview.email-is-empty'), 'error')
      }
    },
  },
}
</script>
