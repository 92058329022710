import {
  changePassword,
  updateUser,
  updateUserProfile,
  viewUser,
} from '@/utilities/user'

// [Переписать] привести к стайл гайду на примере /dynamic/custom.js
export const moduleUser = {
  state: () => ({
    user: {
      id: null,
      auth_key: '',
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      avatar: '',
      projects: [],
      adminOrOwnerAccounts: [],
      profile: null,
      superUser: false,
    },
    companySize: ['1-5', '5-50', '50-100', '100-500', '500-1000', '1000+'],
    companySpecialization: [
      'Дизайн',
      'Маркетинг',
      'Медиа',
      'Фин. сервисы',
      'Ритейл',
      'Трэвел',
      'Другое',
    ],
    userLoading: false,
  }),

  getters: {
    getUser(state) {
      return {
        id: state.user.id,
        firstname: state.user.firstname,
        lastname: state.user.lastname,
        email: state.user.email,
        subscribeNews: state.user.subscribeNews,
        phone: state.user.phone,
        avatar: state.user.avatar,
      }
    },
    getUserProfile(state) {
      return state.user.profile
    },
    searchUserProjects: (state) => (searchString) => {
      return state.user.projects.filter(
        (project) => project.name.toLowerCase().indexOf(searchString) > -1
      )
    },
  },

  mutations: {
    setUser(state, data) {
      // [Переписать] присваивать напрямую state.user.{key} = data.{key}
      const {
        id,
        username,
        email,
        auth_key,
        firstname,
        lastname,
        avatar,
        superUser,
        profile,
        phone,
        subscribeNews,
        accounts: projects,
        adminOrOwnerAccounts,
      } = { ...data }
      state.user.id = id ?? null
      state.user.username = username ?? ''
      state.user.email = email ?? ''
      state.user.auth_key = auth_key ?? ''
      state.user.firstname = firstname ?? ''
      state.user.lastname = lastname ?? ''
      state.user.avatar = avatar ?? ''
      state.user.projects = projects ?? []
      state.user.adminOrOwnerAccounts = adminOrOwnerAccounts ?? []
      state.user.phone = phone ?? ''
      state.user.subscribeNews = subscribeNews ?? false
      state.user.superUser = !!superUser
      state.user.profile = profile ?? null
    },
    clearUser(state) {
      state.user = {
        id: null,
        auth_key: '',
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        avatar: '',
        phone: '',
        superUser: false,
        projects: [],
        adminOrOwnerAccounts: [],
        subscribeNews: false,
      }
    },
    setUserLoading(state, payload) {
      state.userLoading = payload
    },
    addProjectToUser(state, data) {
      state.user.projects.push(data)
    },
  },

  actions: {
    async viewUser(context, payload) {
      return await viewUser(payload)
    },
    async changePassword(context, payload) {
      return await changePassword(payload.userId, {
        password: payload.password,
      })
    },
    async updateUser({ commit }, payload) {
      const result = await updateUser(payload.id, payload.data)
      commit('setUser', result)
      return result
    },
    async loadUserData({ state, commit }, userId) {
      if (!state.userLoading) {
        commit('setUserLoading', true)
        const userData = await viewUser(userId)
        commit('setUser', userData)
        commit('setUserLoading', false)
      }
    },
    async updateUserProfile(context, payload) {
      return await updateUserProfile(payload.id, payload.form)
    },
  },
}
