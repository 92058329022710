import axios from '../plugins/axios_and_toast.js'
import Typograf from 'typograf'

const tp = new Typograf({ locale: ['ru', 'en-US'] })

async function typoElement(element) {
  element.innerHTML = tp.execute(element.innerHTML)
}

function typoText(text) {
  return tp.execute(text)
}

async function checkLinks(data) {
  const url = `/services/link/check`
  const result = await axios.post(url, data)
  return result.data
}

// Устаревшая функция
async function sendEmailServices(data) {
  const url = `/services/letter/send?id=` + data.id + '&email=' + data.recipient
  const result = await axios.post(url)
  return result.data
}
async function sendEmail(data) {
  const url = '/mailer/send-test-email'
  const result = await axios.post(url, data)
  return result.data
}

async function checkGrammar(data) {
  const url = `/services/grammar/check`
  const result = await axios.post(url, data)
  return result.data
}

async function createLetter(data) {
  const url = `/letter/create`
  const result = await axios.post(url, data)
  return result.data
}

async function createCustomLetter() {
  const url = `/letter/create-custom`
  const result = await axios.post(url)
  return result.data
}

async function loadLetters(
  page = 1,
  limit = 10,
  orderBy = '-id',
  filter,
  letterType
) {
  const url =
    '/letter/index?fields=id,name,preview,type,updatedAt,isAppLetterosCom'
  let params = {}
  Object.entries({ page, limit, orderBy, ...filter }).forEach(
    ([key, value]) => {
      if (value !== undefined) {
        params[key] = value.toString()
      }
    }
  )

  if (letterType !== null) {
    params.is_app_letteros_com = letterType
  }

  const result = await axios.get(url, { params: { ...params } })
  return result.data
}

async function loadLetter(id) {
  const url = `/letter/view?expand=preheader,bgColor,htmlWrapper,settings,template,modules,folders&id=${id}`
  const result = await axios.get(url)
  return result.data
}

async function updateLetter(id, data) {
  const url = `/letter/update?expand=preheader,bgColor,htmlWrapper,settings,template,modules,folders&id=${id}`
  const result = await axios.put(url, data)
  return result.data
}

async function updateCustomLetter(id, data) {
  const url = `/letter/update-custom?id=${id}`
  const result = await axios.put(url, data)
  return result.data
}

async function exportLetter(id, type = 'zip') {
  const url = `/letter/export?id=${id}&type=${type}`
  const result = await axios.get(url)
  return result.data
}

async function deleteLetter(letterId) {
  const url = `/letter/delete?id=${letterId}`
  const result = await axios.delete(url)
  return result.data
}

async function updateLettersFolder(lettersId, folderId) {
  const url = `/letter/update-folders?id=${folderId}`
  const result = await axios.put(url, { lettersId: lettersId })
  return result.data
}

async function deleteLettersFromFolder(lettersId, folderId) {
  const url = `/letter/delete-letters-from-folder?id=${folderId}`
  const result = await axios.put(url, { lettersId: lettersId })
  return result.data
}

async function cloneLetter(letterId) {
  const url = `/letter/clone?id=${letterId}`
  const result = await axios.post(url)
  return result.data
}

async function translateLetter(options) {
  const url = `/letter/translate?id=${options.id}`
  const result = await axios.post(url, {
    targetLang: options.lang.code,
    targetLangName: options.lang.name,
    translateDriver: options.driver,
  })
  return result.data
}

async function getRutubePreview(id) {
  const url = `/letter/rutube-preview?id=${id}`
  const result = await axios.get(url)
  return result.data
}

async function getVkPreview(id) {
  const url = `/letter/vk-preview?id=${id}`
  const result = await axios.get(url)
  return result.data
}

async function createTestEmail(data) {
  const url = `/email/create`
  const result = await axios.post(url, data)
  return result.data
}

async function updateTestEmail(data) {
  const url = `/email/update?id=` + data.id
  const result = await axios.put(url, data)
  return result.data
}

async function getAnalytics(page) {
  const url = `/analytics/get`
  const result = await axios.post(url, { page })
  return result.data
}

async function getAnalyticOpen(id) {
  const url = `/analytics/get-opens`
  const result = await axios.post(url, { id })
  return result.data
}

async function getAnalyticOne(id) {
  const url = `/analytics/get-one`
  const result = await axios.post(url, { id })
  return result.data
}

async function setAnalyticSubscribers(data) {
  const url = `/analytics/set-subscribers`
  const result = await axios.post(url, data)
  return result.data
}

async function getMap(id) {
  const url = `/analytics/get-map`
  const result = await axios.post(url, { id })
  return result.data
}

async function getLetterFullHtml(id) {
  const url = `/letter/export?id=${id}&type=html`
  const result = await axios.get(url)
  return result.data
}

async function parseGoogleSpreadsheet(data, link) {
  const url = `/worksheet/google-sheets`
  const result = await axios.post(url, { data: data, url: link })
  return result.data
}

async function parseProduct(data) {
  const url = `/worksheet/parse-product`
  const result = await axios.post(url, data)
  return result.data
}

async function share(options) {
  const url = `/letter/share`
  const result = await axios.post(url, options)
  return result.data
}

export {
  typoElement,
  createLetter,
  sendEmail,
  sendEmailServices,
  createCustomLetter,
  loadLetters,
  loadLetter,
  updateLetter,
  exportLetter,
  deleteLetter,
  updateLettersFolder,
  deleteLettersFromFolder,
  updateCustomLetter,
  cloneLetter,
  checkLinks,
  checkGrammar,
  translateLetter,
  getRutubePreview,
  createTestEmail,
  updateTestEmail,
  getAnalytics,
  getAnalyticOpen,
  getAnalyticOne,
  setAnalyticSubscribers,
  getMap,
  getVkPreview,
  getLetterFullHtml,
  parseGoogleSpreadsheet,
  parseProduct,
  share,
  typoText,
}
