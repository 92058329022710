<template>
  <v-expansion-panel class="accordion-item">
    <v-expansion-panel-header class="font-weight-medium">
      <v-img
        class="integrations-items-logo mr-4"
        src="/icons/default_integration.svg"
        max-width="36" />
      {{ $t('projectIntegrations.s3.storage-s3') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.s3.login')"
        outlined
        v-model="integrationData.login" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.s3.password')"
        outlined
        v-model="integrationData.password" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.s3.container-name')"
        outlined
        v-model="integrationData.containerName" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.s3.container-url')"
        outlined
        v-model="integrationData.containerUrl" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.s3.region')"
        outlined
        v-model="integrationData.region" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.s3.endpoint-url')"
        outlined
        v-model="integrationData.endpointUrl" />
      <v-btn
        class=""
        :ripple="false"
        x-large
        depressed
        color="primary"
        @click="saveData(integrationData, 's3')">
        {{ $t('projectIntegrations.save') }}
      </v-btn>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ProjectS3',
  data() {
    return {
      integrationData: {
        login: '',
        password: '',
        containerName: '',
        containerUrl: '',
        region: '',
        endpointUrl: '',
      },
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('project', ['saveIntegration']),
    loadData() {
      // [Переписать] после правок в структуре стора, этих записей быть не должно
      const options = this.$store.state.project.integrationS3
      for (const key in this.integrationData) {
        this.integrationData[key] = options[key] ?? ''
      }
    },
    async saveData(data, type) {
      await this.saveIntegration({ data, type })
      // [Переписать] в случае ошибки выводить уведомление
    },
  },
}
</script>

<style lang="scss"></style>
