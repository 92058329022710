<template>
  <v-main class="letter-analytics">
    <v-container>
      <div class="analytics-main analytics-main--full">
        <h1 class="title-h2 text-sm-center mb-14">Карта кликов</h1>
        <iframe
          ref="map-frame"
          frameborder="0"
          width="100%"
          height="0"
          style="border-radius: 15px"></iframe>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import { getMap } from '@/utilities/letter.js'

export default {
  name: 'LetterAnalyticsMap',
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const result = await getMap(this.$route.params.id)
      const iframe = this.$refs['map-frame']

      iframe.contentWindow.document.open()
      iframe.contentWindow.document.write(result.html)
      iframe.contentWindow.document.close()

      iframe.onload = () => {
        iframe.style.height =
          iframe.contentWindow.document.body.scrollHeight + 'px'

        iframe.contentWindow.document.body.style.overflowY = 'hidden'

        //run set label for links
        const links = iframe.contentWindow.document.body.querySelectorAll(
          'a[mailfit-analytics]'
        )
        this.prepareLinks(links, result.data)
      }
    },
    prepareLinks(links, data) {
      const labelList = []

      links.forEach(function (item) {
        const position = item.getAttribute('mailfit-analytics')
        const value = data.unique[position]

        if (value != undefined) {
          const offset = item.getBoundingClientRect()

          const y = offset.top + offset.height / 2
          const x = offset.left + offset.width / 2

          const point = {
            x: Math.floor(x),
            y: Math.floor(y),
            value: Math.floor(value),
          }

          labelList.push(point)
        }
      })

      if (labelList.length) {
        labelList.forEach((k) => this.setLabel(k))
      }
    },
    setLabel(label) {
      const iframe = this.$refs['map-frame']

      const style = `position: absolute; top: ${label.y}px; left: ${label.x}px; background-color: #ffffff; color: #151515; border-radius: 50%; width: 30px; height: 30px; transform: translate(-50%, -50%); text-align: center; line-height: 30px; font-size: 16px; border: 1px solid #A9A9A9; font-family: sans-serif; font-weight: 500;`
      const tmp = document.createElement('div')

      tmp.style.cssText = style
      tmp.innerHTML = label.value

      iframe.contentWindow.document.body.appendChild(tmp)
    },
  },
}
</script>

<style lang="scss">
.letter-analytics {
  .analytics-main.analytics-main--full {
    max-width: 1000px;
  }
}
</style>
