/* eslint-disable */
import {
  createLetter,
  getLetter,
  saveLetter,
  cloneLetter,
} from '@/utilities/custom.js'

const state = () => ({
  id: null,
  sidebarVisible: false,
  workAreaDeviceType: 'desktop',
  isLetterCreate: false,
  isLetterSave: false,
  isLetterLoaded: false,
  testApproval: false,
  name: '',
  subject: '',
  preheader: '',
  bgColor: '#ffffff',
  settings: {
    madeInLetteros: true,
    minify: false,
    tinify: false,
    outlookDisabled: false,
    dontOptimizeLinks: false,
    analyticShow: false,
    analyticRecipients: null,
    analyticRange: [10, 20],
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmCustom: '',
  },
  htmlWrapper: '',
  content: '',
  shared: false,
})

const getters = {}

const mutations = {
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  showSidebar(state) {
    state.sidebarVisible = true
  },
  hideSidebar(state) {
    state.sidebarVisible = false
  },

  changeDeviceType(state, payload) {
    state.workAreaDeviceType = payload
  },

  toggleLetterCreate(state, payload) {
    state.isLetterCreate = payload
  },
  toggleLetterLoad(state, payload) {
    state.isLetterLoaded = payload
  },
  toggleLetterSave(state, payload) {
    state.isLetterSave = payload
  },

  toggleTestApproval(state) {
    state.testApproval = !state.testApproval
  },
  updateSetting(state, payload) {
    state.name = payload.name
    state.subject = payload.subject
    state.preheader = payload.preheader
    state.bgColor = payload.bgColor
    state.htmlWrapper = payload.htmlWrapper
    state.settings = { ...state.settings, ...payload.settings }
  },
  updateContent(state, payload) {
    state.content = payload
  },
  setId(state, id) {
    state.id = +id
  },
  updateSharedStatus(state, payload) {
    state.shared = payload
  },
}

const actions = {
  async createLetter({ state, commit }) {
    try {
      const result = await createLetter(state.content)
      if (result.status) {
        commit('toggleLetterCreate', true)
        return result
      } else {
        throw new Error(result.error)
      }
    } catch (e) {
      commit('toggleLetterCreate', false)
      return false
    }
  },
  async getLetter({ state, commit }, id) {
    try {
      const result = await getLetter(id)
      if (result.status) {
        commit('setId', id)
        commit('updateSetting', {
          name: result.letter.name,
          subject: result.letter.subject,
          preheader: result.letter.preheader,
          bgColor: result.letter.bg_color,
          htmlWrapper: result.letter.htmlWrapper,
          settings: result.settings,
          shared: result.shared,
        })
        commit('updateContent', result.letter.content)
        commit('toggleLetterLoad', true)
      } else {
        throw new Error(result.error)
      }
    } catch (e) {
      commit('toggleLetterLoad', false)
      return false
    }
  },
  async saveLetter({ state, commit }) {
    try {
      const payload = {
        letter: {
          name: state.name,
          subject: state.subject,
          preheader: state.preheader,
          bg_color: state.bgColor,
          content: state.content,
          htmlWrapper: state.htmlWrapper,
        },
        settings: state.settings || {},
      }
      const result = await saveLetter(state.id, payload)
      if (result.status) {
        commit('toggleLetterSave', true)
      } else {
        throw new Error(result.error)
      }
    } catch (e) {
      commit('toggleLetterSave', false)
    }
  },
  async saveSettings({ state, commit }) {
    try {
      const payload = {
        letter: {
          name: state.name,
          subject: state.subject,
          preheader: state.preheader,
          bg_color: state.bgColor,
        },
        settings: state.settings || {},
      }
      const result = await saveLetter(state.id, payload)
      if (result.status) {
        commit('toggleLetterSave', true)
      } else {
        throw new Error(result.error)
      }
    } catch (e) {
      commit('toggleLetterSave', false)
    }
  },
  async cloneLetter({ state, commit }) {
    try {
      const result = await cloneLetter(state.id)
      return result
    } catch (e) {
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
