<template>
  <div class="option-file">
    <v-checkbox
      v-model="dontResize"
      :label="$t('optionImage.dont-resize')"
      class="ml-n7"
      @change="setDontResize" />
    <label class="option__label">{{ label }}</label>
    <label class="file-uploader" :style="{ 'background-image': preview }">
      <input
        ref="fileInput"
        class="d-sr-only"
        type="file"
        :accept="'image/jpeg, image/png, image/gif'"
        @change="sendFile" />
      <v-btn
        class="upload-btn white font-weight-medium btn-black-border"
        depressed
        outlined
        :ripple="false"
        @click="$refs.fileInput.click()">
        {{ $t('optionImage.upload') }}
      </v-btn>
    </label>
    <p class="gray--text mt-1 caption font-weight-light mb-4">
      {{
        $t('optionImage.upload-info', {
          width: imageWidth,
          height: imageHeight,
        })
      }}
    </p>
    <label class="option__label">{{ $t('optionImage.image-url') }}</label>
    <v-text-field
      v-model="imageUrl"
      placeholder=""
      outlined
      dense
      @change="changeImageByUrl" />
    <label class="option__label">{{ $t('optionImage.image-width') }}</label>
    <v-text-field
      v-model="imageWidth"
      placeholder=""
      outlined
      dense
      @change="changeImageWidth" />
    <label class="option__label">{{ $t('optionImage.image-alt') }}</label>
    <v-text-field v-model="altText" placeholder="" outlined dense />
    <v-btn
      depressed
      outlined
      class="flex-grow-1 black-hover"
      :ripple="false"
      @click="saveAltText">
      {{ $t('optionImage.save') }}
    </v-btn>
  </div>
</template>

<script>
// [Переписать] отрефакторить
import { uploadFile } from '@/utilities/fileHandler.js'
import { displayNotify, getExtension } from '@/utilities/helpers'

export default {
  name: 'OptionImage',
  components: {},
  props: {
    element: [HTMLElement, null],
    label: String,
    value: String,
  },
  data() {
    return {
      altText: '',
      preview: '',
      imageUrl: '',
      imageWidth: 0,
      imageHeight: 0,
      imageType: '',
      dontResize: false,
    }
  },
  computed: {
    pageType() {
      return this.$route.name.includes('letter') ? 'letter' : 'template'
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.imageUrl = this.value
      this.preview = `url(${this.element.src})`
      this.altText = this.element.getAttribute('alt')
      this.imageHeight = this.element.clientHeight
      this.imageWidth = this.element.clientWidth
      this.imageType = getExtension(this.imageUrl)

      if (this.element.getAttribute('letteros-image-dont-resize') !== 'true')
        this.dontResize = false
      else this.dontResize = true
    },
    // Загрузка файла
    async sendFile(event) {
      const file = event.target.files[0]
      try {
        const result = await uploadFile(
          file,
          'import/image/upload',
          this.pageType,
          this.imageWidth,
          this.dontResize
        )

        this.imageUrl = result.url
        this.preview = `url(${result.url})`
        this.element.setAttribute('src', this.imageUrl)
        this.imageType = getExtension(result.url)

        this.gifOperations()
      } catch (e) {
        console.log('sendFile error', { error: e })
      }
    },
    // Загрузка по url
    changeImageByUrl() {
      this.element.setAttribute('src', this.imageUrl)
      this.preview = `url(${this.imageUrl})`
      this.imageType = getExtension(this.imageUrl)

      this.gifOperations()
    },
    // Изменение ширины
    changeImageWidth() {
      console.log(this.imageType)
      this.element.setAttribute('width', this.imageWidth)
      const style = this.element.style

      if (style.width && style.width.indexOf('%') === -1)
        style.width = this.imageWidth + 'px'

      if (style['max-width'] && style['max-width'].indexOf('%') === -1)
        style['max-width'] = this.imageWidth + 'px'

      this.imageHeight = this.element.clientHeight
      this.gifOperations()

      displayNotify(this.$t('optionImage.image-width-set'))
    },
    // Исключительно для gif
    gifOperations() {
      if (this.imageType === 'gif') {
        this.element.setAttribute('height', this.element.clientHeight)
        this.element.style.height = 'auto'
      } else {
        this.element.removeAttribute('height')
        this.element.style.height = ''
      }
    },
    // При сохранении меняется только alt-текст
    saveAltText() {
      this.element.setAttribute('alt', this.altText)
      displayNotify(this.$t('optionImage.image-successfully-set'))
    },
    setDontResize() {
      this.element.setAttribute('letteros-image-dont-resize', this.dontResize)
      if (this.dontResize) {
        displayNotify(this.$t('optionImage.image-wont-be-resized'))
      }
    },
  },
}
</script>

<style lang="scss">
.option-file {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  .option-label {
    width: 100%;
  }

  .file-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed;
    border-radius: 15px;
    width: 250px;
    height: 130px;
    background-position: center;
    background-size: contain;
    position: relative;
  }

  label.file-uploader::before {
    border-radius: 15px;
    opacity: 0.3;
    background-color: #000000;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .module-option__background {
    background-color: #ffffff;
    border: 1px solid #a9a9a9;
    border-radius: 15px;
    text-align: center;
    padding: 0 0 1rem 0;
  }

  .upload-btn {
    border-radius: 10px;
    &:focus:before {
      opacity: 0;
    }
    &:hover {
      background-color: #151515 !important;
      border-color: #151515 !important;
      color: #fff;
      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
