<template>
  <div class="option-timer">
    <label class="option__label">{{ label }}</label>
    <div class="option-cell mb-3">
      <label class="option__label">{{ $t('optionTimer.date-complete') }}</label>
      <DatePicker
        class="timer-picker"
        value-type="date"
        v-model="dateFinish"
        @input="changeImage" />
    </div>
    <div class="option-cell mb-3">
      <label class="option__label">{{ $t('optionTimer.time-complete') }}</label>
      <DatePicker
        type="time"
        class="timer-picker"
        v-model="timeFinish"
        @input="changeImage" />
    </div>
    <div class="option-cell mb-3">
      <label class="option__label">{{ $t('optionTimer.font') }}</label>
      <v-select
        :attach="'.option-timer'"
        :items="fonts"
        solo
        item-text="name"
        dense
        flat
        outlined
        :hide-details="true"
        v-model="timerOption.font"
        @change="changeImage"></v-select>
    </div>
    <div class="option-cell mb-3">
      <label class="option__label">{{ $t('optionTimer.font-size') }}</label>
      <v-text-field
        v-model="timerOption.fz"
        outlined
        dense
        :hide-details="true"
        @input="changeImage" />
    </div>
    <div class="option-cell mb-3">
      <label class="option__label">{{ $t('optionTimer.font-color') }}</label>
      <OptionColor
        :element="timerBox"
        style-name="timer"
        :value="timerOption.color"
        @change-color="changeColor($event, 'color')" />
    </div>
    <div class="option-cell mb-3">
      <label class="option__label">
        {{ $t('optionTimer.timer-background') }}
      </label>
      <OptionColor
        :element="timerBox"
        style-name="timer"
        :value="timerOption.bg"
        @change-color="changeColor($event, 'bg')" />
    </div>

    <div v-if="timerWithImage" class="option-cell mb-3">
      <label class="option__label">
        {{ $t('optionTimer.background-image-timer') }}
      </label>
      <OptionBackgroundImage :element="timerBox" />
    </div>
    <div v-else class="option-cell mb-3">
      <label class="option__label">
        {{ $t('optionTimer.background-color') }}
      </label>
      <OptionColor :element="timerBox" style-name="backgroundColor" />
    </div>
    <div class="option-cell d-flex align-center mb-3">
      <v-checkbox
        v-model="timerOption.zd"
        :false-value="0"
        :true-value="1"
        @change="changeImage" />
      <label class="">{{ $t('optionTimer.zero-day') }}</label>
    </div>
    <div class="option-cell d-flex align-center mb-3">
      <v-checkbox
        v-model="timerOption.sep"
        :false-value="0"
        :true-value="1"
        @change="changeImage" />
      <label class="">{{ $t('optionTimer.show-colon') }}</label>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import {
  fromUnixTime,
  addDays,
  getUnixTime,
  setHours,
  getHours,
  setMinutes,
  getMinutes,
} from 'date-fns'

import { calcUrl } from '@/utilities/timer.js'
import OptionColor from '@/components/moduleOptions/OptionColor.vue'
import OptionBackgroundImage from '@/components/moduleOptions/OptionBackgroundImage'

export default {
  name: 'OptionTimer',
  components: { OptionBackgroundImage, OptionColor, DatePicker },
  props: {
    element: [HTMLElement, null],
    label: String,
  },
  data() {
    return {
      startDate: '',
      dateInitial: '',
      timeInitial: '',
      dateFinish: '',
      timeFinish: '',
      timerOption: {
        fz: 72, //font size
        font: 'tahoma', //font family
        bg: '0f133a', //background color
        color: 'ffffff', //font color
        pd: 75, //  Отступы по ширине блока
        ofs: 50, // Отступы по высоте блока
        lh: 25, // Интервал между строками блока
        sep: 0, // insert colon
        zd: 0, // zero day
        ln: 'ru', // i18n
        time: '', //time
      },
      timerBox: null,
      timerWithImage: false,
      fonts: [
        { value: 'roboto-regular', name: 'Roboto Regular' },
        { value: 'roboto-bold', name: 'Roboto Bold' },
        { value: 'roboto-light', name: 'Roboto Light' },
        { value: 'proximanova-regular', name: 'Proxima Nova Regular' },
        { value: 'proximanova-bold', name: 'Proxima: Nova Bold' },
        { value: 'proximanova-light', name: 'Proxima: Nova Light' },
        { value: 'ptsans-caption', name: 'PTSans :Caption' },
        { value: 'ptsans-captionbold', name: 'PTSans :Caption Bold' },
        { value: 'matias', name: 'Matias' },
        { value: 'frenchpress', name: 'Frenchp:ress' },
        { value: 'tahoma', name: 'Tahoma' },
      ],
    }
  },
  computed: {
    time() {
      const hour = getHours(this.timeFinish)
      const minute = getMinutes(this.timeFinish)
      const x = setHours(this.dateFinish, hour)
      return setMinutes(x, minute)
    },
    unixTime() {
      return getUnixTime(this.time)
    },
    moduleUuid() {
      return this.$store.state.editor.selectedModuleUuid
    },
    moduleData() {
      return this.$store.getters['letter/getLetterModuleByUuid'](
        this.moduleUuid
      )
    },
  },
  watch: {},
  created() {
    this.initDate()
  },
  methods: {
    initDate() {
      this.startDate = new Date()
      this.dateFinish = addDays(new Date(), 2)
      this.timeFinish = new Date()
      if (this.moduleData?.options?.timer) {
        this.timerOption = { ...this.moduleData.options.timer }
        this.timerOption.color = `#${this.moduleData.options.timer.color}`
        this.timerOption.bg = `#${this.moduleData.options.timer.bg}`
        this.dateFinish = fromUnixTime(this.moduleData.options.timer.time)
        this.timeFinish = fromUnixTime(this.moduleData.options.timer.time)
      }
      this.timerBox = this.element.querySelector('.letteros-timer-box')
      this.timerWithImage =
        this.timerBox.getAttribute('letteros-timer-bgi') === 'true'
    },

    changeColor(e, type) {
      this.timerOption[type] = e
      this.changeImage()
    },
    changeImage() {
      let color = this.timerOption.color.replace('#', '')
      let bg = this.timerOption.bg.replace('#', '')
      console.log('changeImage', { color: color.length, bg: bg.length })
      color =
        color.length > 6
          ? color.substring(0, color.length - 3).toLocaleLowerCase()
          : color
      bg =
        bg.length > 6 ? bg.substring(0, bg.length - 3).toLocaleLowerCase() : bg
      const imageElement = this.element.querySelector('.letteros-timer-image')
      imageElement.src = calcUrl(
        { ...this.timerOption, bg: bg, color: color, time: this.unixTime },
        this.timerWithImage
      )
      this.updateOptionHandler({
        ...this.timerOption,
        bg: bg,
        color: color,
        time: this.unixTime,
      })
    },
    // Обновление опций модуля, подключено только в OptionTimer
    updateOptionHandler(option) {
      // console.log('update Option Handler')
      try {
        this.$store.commit('letter/updateModuleOptions', {
          uuid: this.moduleUuid,
          option: { timer: option },
        })
      } catch (e) {
        console.log('update Option Handler error', e)
      }
    },
  },
}
</script>

<style lang="scss">
.option-timer {
  .timer-picker {
    width: 100%;
  }

  .option-cell {
    .v-input--selection-controls.v-input--checkbox {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
