<template>
  <div class="option-gpa">
    <div class="option-cell mb-3">
      <label class="option__label">{{ $t('optionGPA.date-end') }}</label>
      <DatePicker
        class="timer-picker"
        value-type="date"
        v-model="dateEnd"
        @change="changeDate" />
    </div>

    <div class="option-cell d-flex align-center mb-3">
      <v-checkbox
        v-model="hide.date"
        :false-value="0"
        :true-value="1"
        @change="toggleDate" />
      <label class="">{{ $t('optionGPA.hide-date') }}</label>
    </div>
    <div class="option-cell d-flex align-center mb-3">
      <v-checkbox
        v-model="hide.sale"
        :false-value="0"
        :true-value="1"
        @change="toggleSale" />
      <label class="">{{ $t('optionGPA.hide-sale') }}</label>
    </div>
    <div class="option-cell d-flex align-center mb-3">
      <v-checkbox
        v-model="hide.code"
        :false-value="0"
        :true-value="1"
        @change="toggleCode" />
      <label class="">{{ $t('optionGPA.hide-code') }}</label>
    </div>
    <div class="option-cell d-flex align-center mb-3">
      <v-checkbox
        v-model="hide.banner"
        :false-value="0"
        :true-value="1"
        @change="toggleBanner" />
      <label class="">{{ $t('optionGPA.hide-banner') }}</label>
    </div>

    <p class="option__label">{{ $t('optionGPA.banner-image') }}</p>
    <label class="file-uploader" :style="{ backgroundImage: bannerImage }">
      <input
        ref="fileInputBanner"
        class="d-sr-only"
        type="file"
        name="banner"
        :accept="'image/jpeg, image/png, image/gif'"
        @change="fileHandler" />
      <v-btn
        depressed
        outlined
        :ripple="false"
        class="btn-black-border"
        @click="$refs.fileInputBanner.click()">
        {{ $t('optionGPA.upload') }}
      </v-btn>
    </label>
    <p class="gray--text mt-1">{{ $t('optionGPA.upload-info') }}</p>

    <p class="option__label">{{ $t('optionGPA.logo-image') }}</p>
    <label class="file-uploader" :style="{ backgroundImage: logoImage }">
      <input
        ref="fileInputLogo"
        class="d-sr-only"
        type="file"
        name="logo"
        :accept="'image/jpeg, image/png, image/gif'"
        @change="fileHandler" />
      <v-btn
        depressed
        outlined
        :ripple="false"
        class="btn-black-border"
        @click="$refs.fileInputLogo.click()">
        {{ $t('optionGPA.upload') }}
      </v-btn>
    </label>
    <p class="gray--text mt-1">{{ $t('optionGPA.upload-info') }}</p>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from 'vue2-datepicker'
import { uploadFile } from '@/utilities/fileHandler'

export default {
  name: 'OptionGpa',
  components: { DatePicker },
  props: {
    element: [HTMLElement, null],
  },
  data() {
    return {
      dateEnd: '',
      hide: {
        date: 0,
        sale: 0,
        code: 0,
        banner: 0,
      },
      bannerImage: '',
      logoImage: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const items = {
        date: this.element.querySelector('[letteros-gpromo-date]'),
        sale: this.element.querySelector('[letteros-gpromo-text]'),
        code: this.element.querySelector('[letteros-gpromo-code]'),
        banner: this.element.querySelector('[letteros-gpromo-banner]'),
        logo: this.element.querySelector('[letteros-gpromo-logo]'),
      }

      this.dateEnd =
        items.date.innerText == 'завтра'
          ? new Date()
          : new Date(items.date.innerText)

      this.hide.date = items.date.getAttribute('letteros-gpromo-hide') ? 1 : 0
      this.hide.sale = items.sale.getAttribute('letteros-gpromo-hide') ? 1 : 0
      this.hide.code = items.code.getAttribute('letteros-gpromo-hide') ? 1 : 0
      this.hide.banner = items.banner.getAttribute('letteros-gpromo-hide')
        ? 1
        : 0

      this.bannerImage = `url(${items.banner.src})`
      this.logoImage = `url(${items.logo.src})`
    },
    changeDate() {
      const date = new Intl.DateTimeFormat('ru-RU')
        .format(this.dateEnd)
        .split(' ')
        .join('.')
        .split('.')
        .reverse()
        .join('-')

      this.element.querySelector('[letteros-gpromo-date]').innerText = date
    },
    toggleDate() {
      const item = this.element.querySelector('[letteros-gpromo-date]')
      if (this.hide.date) {
        item.style.display = 'none'
        item.setAttribute('letteros-gpromo-hide', true)
        item.parentElement.style.display = 'none'
      } else {
        item.style.display = ''
        item.removeAttribute('letteros-gpromo-hide')
        item.parentElement.style.display = ''
      }
    },
    toggleSale() {
      const item = this.element.querySelector('[letteros-gpromo-text]')
      if (this.hide.sale) {
        item.style.display = 'none'
        item.setAttribute('letteros-gpromo-hide', true)
      } else {
        item.style.display = ''
        item.removeAttribute('letteros-gpromo-hide')
      }
    },
    toggleCode() {
      const item = this.element.querySelector('[letteros-gpromo-code]')
      if (this.hide.code) {
        item.style.display = 'none'
        item.setAttribute('letteros-gpromo-hide', true)
        item.previousElementSibling.style.display = 'none'
      } else {
        item.style.display = ''
        item.removeAttribute('letteros-gpromo-hide')
        item.previousElementSibling.style.display = ''
      }
    },
    toggleBanner() {
      const item = this.element.querySelector('[letteros-gpromo-banner]')
      if (this.hide.banner) {
        item.style.display = 'none'
        item.setAttribute('letteros-gpromo-hide', true)
      } else {
        item.style.display = ''
        item.removeAttribute('letteros-gpromo-hide')
      }
    },
    fileHandler(event) {
      const file = event.target.files ? event.target.files[0] : undefined
      if (file) {
        this.sendFile(file, event.target.name)
      }
    },
    async sendFile(file, type) {
      try {
        const documentType = this.$route.name.includes('letter')
          ? 'letter'
          : 'template'
        const result = await uploadFile(
          file,
          'import/image/upload',
          documentType
        )

        if (type == 'banner') {
          this.bannerImage = `url(${result.url})`
          this.element.querySelector('[letteros-gpromo-banner]').src =
            result.url
        } else {
          this.logoImage = `url(${result.url})`
          this.element.querySelector('[letteros-gpromo-logo]').src = result.url
        }
      } catch (e) {
        console.log('send File error', { error: e })
      }
    },
  },
}
</script>

<style lang="scss">
.option-gpa {
  .timer-picker {
    width: 100%;
  }

  .option-cell {
    .v-input--selection-controls.v-input--checkbox {
      margin: 0;
      padding: 0;
    }
  }

  .file-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed;
    border-radius: 15px;
    width: 250px;
    height: 130px;
    background-position: center;
    background-size: contain;
  }
}

.letteros-module-option__value-field {
  border: 1px solid;
  border-radius: 10px;
}

.module-option__background {
  background-color: #ffffff;
  border: 1px solid #a9a9a9;
  border-radius: 15px;
  text-align: center;
  padding: 0 0 1rem 0;
}
</style>
