<template>
  <tr class="table-row">
    <td class="table-checkbox-wrapper">
      <v-checkbox v-model="selected" class="table-checkbox" />
    </td>
    <td class="table-logo">
      <img
        class="logo"
        :src="item.preview"
        width="56"
        height="56"
        @load="previewLoaded"
        onerror="this.src=`/img/letterPreview.png`" />
    </td>
    <td class="table-info">
      <input
        ref="rename"
        :disabled="isDisabled"
        class="name font-weight-medium"
        :value="item.name"
        @blur="renameHandler" />
      <div class="time">{{ $t('tableRow.updated') }} {{ item.updatedAt }}</div>
    </td>
    <td class="table-user-avatar">
      {{ item.avatarUrl }}
    </td>

    <td class="table-action-menu">
      <ElementMenu :item="item" />
    </td>
  </tr>
</template>

<script>
import ElementMenu from '@/components/home/element/ElementMenu.vue'
import itemMixin from '@/mixins/Home/item.mixin.js'

export default {
  name: 'ItemRow',
  mixins: [itemMixin],
  components: {
    ElementMenu,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
