<template>
  <v-main class="signup">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="7" sm="7" cols="10">
          <div>
            <h1 class="title-h3 text-center mb-2">
              {{ $t('theRecovery.recovery') }}
            </h1>

            <form @submit.prevent="recoveryHandler">
              <label class="label">
                {{ $t('theRecovery.password') }}
              </label>
              <v-text-field
                v-model="password"
                name="password"
                type="password"
                :placeholder="$t('theRecovery.new')"
                outlined
                tabindex="1" />
              <div class="text-center">
                <v-btn
                  class="pl-10 pr-10 white--text"
                  :ripple="false"
                  x-large
                  type="submit"
                  depressed
                  color="primary"
                  tabindex="3">
                  {{ $t('theRecovery.continue') }}
                </v-btn>
              </div>
            </form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import authMixin from '@/mixins/Auth/auth.mixin.js'

export default {
  name: 'Recovery',
  mixins: [authMixin],
  data() {
    return {
      token: this.$route.query.token,
      password: null,
    }
  },
  created() {
    document.title = this.$t('forgot')
  },
  methods: {
    async recoveryHandler() {
      const response = await this.$store.dispatch('auth/recovery', {
        password: this.password,
        token: this.token,
      })

      if (!response) {
        return false
      }

      this.setAuthData(response)
      this.$store.commit('userStore/setUser', response.data)
      this.accountHandler(response.data)
    },
  },
}
</script>
