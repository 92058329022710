// [Переписать] подключить стор динамически
import { createLetter } from '@/utilities/letter'

const state = () => ({
  letterData: {
    name: '',
    subject: '',
    preheader: '',
    template_id: null,
  },
})

const mutations = {
  changeLetterData(state, payload) {
    state.letterData = { ...state.letterData, ...payload }
  },
}

const actions = {
  async createLetter({ state }) {
    try {
      return await createLetter(state.letterData)
    } catch (e) {
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
