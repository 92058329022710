// Ресайз отступов сверх/снизу у модуля
export default {
  data() {
    return {
      resizableModule: {
        element: null,
        elementHeight: 0,
        elementMouseY: 0,
        minSize: 0,
      },
    }
  },
  methods: {
    enableResize(element) {
      const ui = element.querySelectorAll('[letteros-resizable-new]')
      ui.forEach((el) =>
        el.addEventListener('mousedown', this.dragResizeHandler)
      )
    },
    disableResize(element) {
      const ui = element.querySelectorAll('[letteros-resizable-new]')
      ui.forEach((el) =>
        el.removeEventListener('mousedown', this.dragResizeHandler)
      )
    },
    // Запуск отслеживания событий
    dragResizeHandler(event) {
      event.preventDefault()
      this.resizableModule.element = event.target.closest(
        '[letteros-resizable-new]'
      )
      this.resizableModule.elementHeight = parseFloat(
        getComputedStyle(this.resizableModule.element, null)
          .getPropertyValue('height')
          .replace('px', '')
      )
      this.resizableModule.elementMouseY = event.clientY

      window.addEventListener('mousemove', this.startResize)
      window.addEventListener('mouseup', this.stopResize)
    },
    // При изменение размера отступа
    startResize(event) {
      try {
        let { element, elementHeight, elementMouseY, minSize } = {
          ...this.resizableModule,
        }
        let height = elementHeight

        if (element) {
          const resizeUi = element.querySelector('.resizable-ui')
          const resizeArrow = resizeUi.querySelector('.resizable-arrow')
          const resizeSpan = resizeArrow.querySelector('span')

          const diff = event.clientY - elementMouseY
          if (resizeUi.classList.contains('top')) {
            height -= diff
          } else {
            height += diff
          }

          resizeArrow.classList.add('active')

          if (height > minSize) {
            resizeSpan.textContent = `${height}px`

            element.style.height = height + 'px'
            element.style.lineHeight = height + 'px'
            element.setAttribute('height', height)
          } else {
            resizeSpan.textContent = `${minSize}px`

            element.style.height = minSize + 'px'
            element.style.lineHeight = minSize + 'px'
            element.setAttribute('height', minSize)
          }
        }
      } catch (e) {
        console.log('startResize error', { error: e, event: event })
      }
    },
    // При завершение
    stopResize() {
      this.resizableModule.element
        .querySelector('.resizable-arrow')
        .classList.remove('active')

      window.removeEventListener('mousemove', this.startResize)
      window.removeEventListener('mouseup', this.stopResize)

      this.resizableModule.element = null
      this.resizableModule.elementHeight = 0
      this.resizableModule.elementMouseY = 0
    },
  },
}
