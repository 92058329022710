<template>
  <v-app>
    <router-view
      name="header"
      class="header"
      :sidebar-show="sidebarShow"
      @toggle-sidebar="sidebarShow = !sidebarShow">
      <template #project-menu>
        <HeaderMenuProject />
      </template>
      <template #user-menu>
        <HeaderMenuUser />
      </template>
    </router-view>
    <router-view
      v-click-outside="sidebarOutsideClickHandler"
      name="sidebar"
      class="sidebar"
      :sidebar-show="sidebarShow" />
    <router-view class="page" />
  </v-app>
</template>

<script>
// [Переписать] убрать пропсы и прослушку toggle-sidebar из template и затестить
import HeaderMenuProject from '@/components/header/HeaderMenuProject.vue'
import HeaderMenuUser from '@/components/header/HeaderMenuUser.vue'

export default {
  name: 'LayoutProject',
  components: {
    HeaderMenuUser,
    HeaderMenuProject,
  },
  data() {
    return {
      sidebarShow: false,
      sidebarType: '',
      project: null,
    }
  },
  methods: {
    sidebarOutsideClickHandler(e) {
      if (this.sidebarShow) {
        const target = e.target.closest('.header-sidebar-toggle')
        if (target === null) this.sidebarShow = false
      }
    },
  },
}
</script>

<style scoped>
</style>
