<template>
  <v-data-table
    class="table-simple"
    :headers="$store.state.itemsStore.headersAnalytics"
    :items="$store.state.itemsStore.analyticsData.items"
    hide-default-footer>
    <template #item="{ item, index }">
      <ItemAnalytics :item="item" :index="index" />
    </template>
  </v-data-table>
</template>

<script>
// [Переписать] убрать обращения к стору внутри template
import ItemAnalytics from '@/components/home/items/ItemAnalytics.vue'

export default {
  name: 'AnalyticsContainer',
  components: {
    ItemAnalytics,
  },
}
</script>
