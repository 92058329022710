<template>
  <header class="header-letter-editor">
    <div class="header-left">
      <v-btn
        v-if="showMenu"
        class="header-sidebar-toggle btn-small mr-4"
        :class="{ 'btn-menu-active': sidebarState }"
        outlined
        :ripple="false"
        @click="toggleSideBarWithTestItems">
        {{ $t('headerLetterEditor.menu') }}
        <v-icon right size="25">mdi-chevron-down</v-icon>
      </v-btn>
      <slot name="project-menu" />
    </div>
    <div v-if="headerExpanded" class="header-middle">
      <div class="header-mode-bar">
        <v-divider class="mx-3" vertical />
        <v-btn
          outlined
          :ripple="false"
          icon
          class="btn-small mr-3 black-hover"
          :class="{ active: workAreaDeviceType === 'desktop' }"
          @click="changeDeviceType('desktop')">
          <IconDesktop />
        </v-btn>
        <v-btn
          outlined
          :ripple="false"
          icon
          class="btn-small black-hover"
          :class="{ active: workAreaDeviceType === 'mobile' }"
          @click="changeDeviceType('mobile')">
          <IconMobile />
        </v-btn>
        <v-divider class="mx-3" vertical />
      </div>
    </div>
    <div class="header-right d-flex flex-row">
      <div class="header-nav-bar mr-4">
        <v-btn
          class="btn-small btn-black-border mr-4 ml-auto"
          outlined
          :ripple="false"
          :to="{
            name: $route.name.includes('custom')
              ? 'letter-custom-settings'
              : 'letter-settings',
            params: { id: $route.params.id },
          }">
          <IconSettings class="ml-n1 mr-2" />
          {{ $t('headerLetterEditor.settings') }}
        </v-btn>
        <v-btn
          class="btn-small btn-black-border"
          :class="testApproval ? 'button-approved' : ''"
          outlined
          :ripple="false"
          @click="saveAndGoToTest">
          <IconListTest class="ml-n1 mr-2" />
          {{ $t('headerLetterEditor.test') }}
        </v-btn>
      </div>
      <slot name="letter-menu" />
      <v-btn
        class="btn-small btn-black-border ml-4"
        text
        :ripple="false"
        @click="exitWithoutSave">
        <IconExit />
      </v-btn>
    </div>
  </header>
</template>

<script>
/* eslint-disable */
import IconSettings from '@/components/icons/IconSettings.vue'
import IconListTest from '@/components/icons/IconListTest.vue'
import IconDesktop from '@/components/icons/IconDesktop.vue'
import IconMobile from '@/components/icons/IconMobile.vue'
import IconExit from '@/components/icons/IconExit.vue'

import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'HeaderLetterCustomEditor',
  components: {
    IconSettings,
    IconListTest,
    IconDesktop,
    IconMobile,
    IconExit,
  },
  computed: {
    headerExpanded() {
      return ['letter-custom-editor'].includes(this.$route.name)
    },
    sidebarState() {
      return this.$store.state.customStore.sidebarVisible
    },
    showMenu() {
      return !['letter-custom-devices'].includes(this.$route.name)
    },
    testApproval() {
      return this.$store.state.customStore.testApproval
    },
    workAreaDeviceType() {
      return this.$store.state.customStore.workAreaDeviceType
    },
    isLetterSave() {
      return this.$store.state.customStore.isLetterSave
    },
  },
  methods: {
    // Смена ширины отображения модуля в редактора
    changeDeviceType(type) {
      this.$store.commit('customStore/changeDeviceType', type)
    },
    // Показ в сайдбаре тестовых фич (типограф, и т.п)
    toggleSideBarWithTestItems() {
      this.$store.commit('customStore/toggleSidebar')
    },
    // Выход из письма и запуск хука на сохранение
    exitWithoutSave() {
      this.$router.push({ name: 'letter-index' })
      // -> Далее запускается сценарий из beforeRouteLeave в letterLayout
    },
    // Сохранение письма и переход на страницу тестирования [Переписать]
    async saveAndGoToTest() {
      // Если на странице выбора девайсов
      if (!this.showMenu) {
        return this.$router.push({
          name: 'letter-custom-test',
          params: { id: this.$route.params.id },
        })
      }

      this.$loader('show')
      await this.$store.dispatch('customStore/saveLetter')
      if (this.isLetterSave) {
        this.$router.push({
          name: 'letter-custom-test',
          params: { id: this.$route.params.id },
        })
        displayNotify(this.$t('letterLayout.letter-saved'))
      } else {
        displayNotify(this.$t('letterLayout.letter-not-saved'), 'error')
      }
      this.$loader('hide')
    },
  },
}
</script>

<style lang="scss">
.header-letter-editor {
  .header-middle {
    position: absolute;
    left: calc(50% - 97px);
  }
  .button-approved {
    background-color: #2d8962 !important;
  }
  .invert-color {
    &:hover {
      color: #151515 !important;
      background-color: #ffffff !important;
      svg {
        transform: rotate(180deg);
      }
    }
    &.active {
      &:hover {
        color: #ffffff !important;
        background-color: #151515 !important;
        svg {
          transform: rotate(180deg);
        }
      }
      svg {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }
  }
}
</style>
