<template>
  <v-card class="folder-create">
    <v-card-title class="text-h5 justify-space-between">
      {{ $t('want-delete') }}
      <v-btn icon :ripple="false" depressed class="" @click="$emit('close')">
        <IconClose />
      </v-btn>
    </v-card-title>
    <v-card-text>
      {{ folderName }}
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        depressed
        outlined
        x-large
        class="mr-3 px-8 black-hover"
        :ripple="false"
        @click="$emit('close')">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        depressed
        color="primary px-8"
        x-large
        :ripple="false"
        @click="deleteItem">
        {{ $t('delete') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// [Переписать] полностью отрефакторить
import IconClose from '@/components/icons/IconClose.vue'
import { displayNotify } from '@/utilities/helpers'
import { deleteFolder } from '@/utilities/folder'

import project from '@/mixins/project.mixin.js'

export default {
  name: 'FolderDelete',
  components: { IconClose },
  mixins: [project],
  props: {
    folderId: {
      type: Number,
      default: null,
    },
    folderName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {
    async deleteItem() {
      this.$loader('show')
      try {
        // [Переписать] [NEW_API]
        await deleteFolder(this.folderId)
        // [Переписать] Удалить запрос после переноса апи папок [NEW_API]
        await this.loadProject()
        displayNotify(
          this.$t('folder-deleted', { folderName: this.folderName })
        )
      } catch (e) {
        displayNotify(
          this.$t('folder-not-deleted', { folderName: this.folderName }),
          'error'
        )
      } finally {
        this.$emit('close')
        this.$loader('hide')
      }
    },
  },
}
</script>

<i18n>
{
  "ru": {
    "want-delete": "Вы действтельно хотите удалить папку?",
    "delete": "Удалить",
    "cancel": "Отмена",
    "folder-deleted": "Папка \"{folderName}\" удалена",
    "folder-not-deleted": "Ошибка при удалении папки \"{folderName}\","
  },
  "en": {
    "want-delete": "Are you sure you want to delete the group?",
    "delete": "Delete",
    "cancel": "Cancel",
    "folder-deleted": "Folder \"{folderName}\" is deleted",
    "folder-not-deleted": "Error while deleting folder \"{folderName}\","
  }
}
</i18n>
