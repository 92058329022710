export const yandex = [
  { code: 'en', name: 'Английский' },
  { code: 'de', name: 'Немецкий' },
  { code: 'es', name: 'Испанский' },
  { code: 'it', name: 'Итальянский' },
  { code: 'fr', name: 'Французский' },
  { code: 'zh', name: 'Китайский' },
  { code: 'ar', name: 'Арабский' },
  { code: 'ru', name: 'Русский' },
  { code: 'af', name: 'Африкаанс' },
  { code: 'am', name: 'Амхарский' },
  { code: 'az', name: 'Азербайджанский' },
  { code: 'ba', name: 'Башкирский' },
  { code: 'be', name: 'Белорусский' },
  { code: 'bg', name: 'Болгарский' },
  { code: 'bn', name: 'Бенгальский' },
  { code: 'bs', name: 'Боснийский' },
  { code: 'ca', name: 'Каталанский' },
  { code: 'cs', name: 'Чешский' },
  { code: 'cy', name: 'Валлийский' },
  { code: 'da', name: 'Датский' },
  { code: 'el', name: 'Греческий' },
  { code: 'eo', name: 'Эсперанто' },
  { code: 'et', name: 'Эстонский' },
  { code: 'eu', name: 'Баскский' },
  { code: 'fa', name: 'Персидский' },
  { code: 'fi', name: 'Финский' },
  { code: 'ga', name: 'Ирландский' },
  { code: 'gd', name: 'Шотландский (гэльский)' },
  { code: 'gl', name: 'Галисийский' },
  { code: 'gu', name: 'Гуджарати' },
  { code: 'he', name: 'Иврит' },
  { code: 'hi', name: 'Хинди' },
  { code: 'hr', name: 'Хорватский' },
  { code: 'ht', name: 'Гаитянский' },
  { code: 'hu', name: 'Венгерский' },
  { code: 'hy', name: 'Армянский' },
  { code: 'id', name: 'Индонезийский' },
  { code: 'is', name: 'Исландский' },
  { code: 'ja', name: 'Японский' },
  { code: 'jv', name: 'Яванский' },
  { code: 'ka', name: 'Грузинский' },
  { code: 'kk', name: 'Казахский' },
  { code: 'km', name: 'Кхмерский' },
  { code: 'kn', name: 'Каннада' },
  { code: 'ko', name: 'Корейский' },
  { code: 'ky', name: 'Киргизский' },
  { code: 'la', name: 'Латынь' },
  { code: 'lb', name: 'Люксембургский' },
  { code: 'lo', name: 'Лаосский' },
  { code: 'lt', name: 'Литовский' },
  { code: 'lv', name: 'Латышский' },
  { code: 'mg', name: 'Малагасийский' },
  { code: 'mi', name: 'Маори' },
  { code: 'mk', name: 'Македонский' },
  { code: 'ml', name: 'Малаялам' },
  { code: 'mn', name: 'Монгольский' },
  { code: 'mr', name: 'Маратхи' },
  { code: 'ms', name: 'Малайский' },
  { code: 'mt', name: 'Мальтийский' },
  { code: 'my', name: 'Бирманский' },
  { code: 'ne', name: 'Непальский' },
  { code: 'nl', name: 'Нидерландский' },
  { code: 'no', name: 'Норвежский' },
  { code: 'pa', name: 'Панджаби' },
  { code: 'pl', name: 'Польский' },
  { code: 'pt', name: 'Португальский' },
  { code: 'ro', name: 'Румынский' },
  { code: 'si', name: 'Сингальский' },
  { code: 'sk', name: 'Словацкий' },
  { code: 'sl', name: 'Словенский' },
  { code: 'sq', name: 'Албанский' },
  { code: 'sr', name: 'Сербский' },
  { code: 'su', name: 'Сунданский' },
  { code: 'sv', name: 'Шведский' },
  { code: 'sw', name: 'Суахили' },
  { code: 'ta', name: 'Тамильский' },
  { code: 'te', name: 'Телугу' },
  { code: 'tg', name: 'Таджикский' },
  { code: 'th', name: 'Тайский' },
  { code: 'tl', name: 'Тагальский' },
  { code: 'tr', name: 'Турецкий' },
  { code: 'tt', name: 'Татарский' },
  { code: 'uk', name: 'Украинский' },
  { code: 'ur', name: 'Урду' },
  { code: 'uz', name: 'Узбекский' },
  { code: 'vi', name: 'Вьетнамский' },
  { code: 'xh', name: 'Коса' },
  { code: 'yi', name: 'Идиш' },
]

export const google = [
  { code: 'en', name: 'Английский' },
  { code: 'de', name: 'Немецкий' },
  { code: 'es', name: 'Испанский' },
  { code: 'it', name: 'Итальянский' },
  { code: 'fr', name: 'Французский' },
  { code: 'ar', name: 'Арабский' },
  { code: 'ru', name: 'Русский' },
  { code: 'af', name: 'Африкаанс' },
  { code: 'sq', name: 'Албанский' },
  { code: 'am', name: 'Амхарский' },
  { code: 'hy', name: 'Армянский' },
  { code: 'az', name: 'Азербайджанский' },
  { code: 'eu', name: 'Баскский' },
  { code: 'be', name: 'Белорусский' },
  { code: 'bn', name: 'Бенгальский' },
  { code: 'bs', name: 'Боснийский' },
  { code: 'bg', name: 'Болгарский' },
  { code: 'ca', name: 'Каталанский' },
  { code: 'ceb', name: 'Себуанский' },
  { code: 'zh-CN', name: 'Китайский (упрощенный)' },
  { code: 'zh-TW', name: 'Китайский (традиционный)' },
  { code: 'co', name: 'Корсиканский' },
  { code: 'hr', name: 'Хорватский' },
  { code: 'cs', name: 'Чешский' },
  { code: 'da', name: 'Датский' },
  { code: 'nl', name: 'Голландский' },
  { code: 'eo', name: 'Эсперанто' },
  { code: 'et', name: 'Эстонский' },
  { code: 'fil', name: 'Филиппинский (тагальский)' },
  { code: 'fi', name: 'Финский' },
  { code: 'fy', name: 'Фризский' },
  { code: 'gl', name: 'Галисийский' },
  { code: 'ka', name: 'Грузинский' },
  { code: 'el', name: 'Греческий' },
  { code: 'gu', name: 'Гуджарати' },
  { code: 'ht', name: 'Гаитянский креольский' },
  { code: 'ha', name: 'Хауса' },
  { code: 'haw', name: 'Гавайская' },
  { code: 'he', name: 'Иврит' },
  { code: 'hi', name: 'Hindi' },
  { code: 'hmn', name: 'Хмонг' },
  { code: 'hu', name: 'Венгерский' },
  { code: 'is', name: 'Исландский' },
  { code: 'ig', name: 'Игбо' },
  { code: 'id', name: 'Индонезийский' },
  { code: 'ga', name: 'Ирландский' },
  { code: 'ja', name: 'Японский' },
  { code: 'jv', name: 'Яванский' },
  { code: 'kn', name: 'Каннада' },
  { code: 'kk', name: 'Казахский' },
  { code: 'km', name: 'Кхмерский' },
  { code: 'rw', name: 'Киньяруанда' },
  { code: 'ko', name: 'Корейский' },
  { code: 'ku', name: 'Курдский' },
  { code: 'ky', name: 'Кыргызский' },
  { code: 'lo', name: 'Лаосский' },
  { code: 'la', name: 'Латинский' },
  { code: 'lv', name: 'Латышский' },
  { code: 'lt', name: 'Литовский' },
  { code: 'lb', name: 'Люксембургский' },
  { code: 'mk', name: 'Македонский' },
  { code: 'mg', name: 'Малагасийский' },
  { code: 'ms', name: 'Малайский' },
  { code: 'ml', name: 'Малаялам' },
  { code: 'mt', name: 'Мальтийский' },
  { code: 'mi', name: 'Маори' },
  { code: 'mr', name: 'Маратхи' },
  { code: 'mn', name: 'Монгольский' },
  { code: 'my', name: 'Мьянма (бирманский)' },
  { code: 'ne', name: 'Непальский' },
  { code: 'no', name: 'Норвежский' },
  { code: 'ny', name: 'Ньянджа (Чичева)' },
  { code: 'or', name: 'Odia (Ория)' },
  { code: 'ps', name: 'Пушту' },
  { code: 'fa', name: 'Персидский' },
  { code: 'pl', name: 'Польский' },
  { code: 'pt', name: 'Португальский (Португалия, Бразилия)' },
  { code: 'pa', name: 'Панджабский' },
  { code: 'ro', name: 'Румынский' },
  { code: 'sm', name: 'Самоанский' },
  { code: 'gd', name: 'Шотландский гэльский' },
  { code: 'sr', name: 'Сербский' },
  { code: 'st', name: 'Сесото' },
  { code: 'sn', name: 'Шона' },
  { code: 'sd', name: 'Синдхи' },
  { code: 'si', name: 'Сингальский (сингальский)' },
  { code: 'sk', name: 'Словацкий' },
  { code: 'sl', name: 'Словенский' },
  { code: 'so', name: 'Сомалийский' },
  { code: 'su', name: 'Сунданский' },
  { code: 'sw', name: 'Суахили' },
  { code: 'sv', name: 'Шведский' },
  { code: 'tl', name: 'Тагальский (филиппинский)' },
  { code: 'tg', name: 'Таджикский' },
  { code: 'ta', name: 'Тамильский' },
  { code: 'tt', name: 'Татарский' },
  { code: 'te', name: 'Телугу' },
  { code: 'th', name: 'Тайки' },
  { code: 'tr', name: 'Турецкий' },
  { code: 'tk', name: 'Turkmen' },
  { code: 'uk', name: 'Украинский' },
  { code: 'ur', name: 'Урду' },
  { code: 'ug', name: 'Уйгурский' },
  { code: 'uz', name: 'Узбекский' },
  { code: 'vi', name: 'Вьетнамский' },
  { code: 'cy', name: 'Валлийский' },
  { code: 'xh', name: 'Xhosa' },
  { code: 'yi', name: 'Идиш' },
  { code: 'yo', name: 'Йоруба' },
  { code: 'zu', name: 'Зулусский' },
]
