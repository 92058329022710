<template>
  <div class="flex-grow-1 pb-8">
    <p class="title-h6 mb-4">
      {{ $t('testGrammar.checking-grammar') }}
    </p>
    <v-progress-linear
      v-if="progress > 1 && progress < 100"
      :value="progress"
      color="dark"
      height="36">
      <template v-slot:default="{ value }">
        <strong class="white--text">{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
    <div class="d-flex" v-else-if="checkUrl">
      <v-btn
        class="btn-small mr-4 flex-grow-1 btn-black-border"
        :ripple="false"
        depressed
        outlined
        target="_blank"
        :href="checkUrl">
        {{ $t('testGrammar.result') }}
      </v-btn>
      <v-btn
        class="btn-small btn-black-border"
        :ripple="false"
        depressed
        outlined
        icon
        @click="check">
        <IconRefresh />
      </v-btn>
    </div>
    <v-btn
      v-else
      class="btn-small btn-black-border"
      :ripple="false"
      depressed
      block
      outlined
      @click="check">
      {{ $t('testGrammar.start') }}
    </v-btn>
  </div>
</template>

<script>
import { checkGrammar } from '@/utilities/letter.js'
import IconRefresh from '@/components/icons/IconRefresh.vue'
import { displayNotify } from '@/utilities/helpers'

// [Переписать] после фикса на бэке [NEW_API]
export default {
  name: 'TestGrammar',
  components: { IconRefresh },
  props: {
    documentId: Number,
  },
  data() {
    return {
      checkUrl: '',
      progress: null,
    }
  },
  methods: {
    async check() {
      try {
        this.progress = null
        this.updateProgress()
        const modulesDOM = document.querySelectorAll('.module-code')
        let data = ''
        modulesDOM.forEach((module) => {
          data += `<div>${module.innerHTML}</div>`
        })
        const result = await checkGrammar({ id: this.documentId, html: data })
        this.checkUrl = result.url
      } catch (e) {
        this.$loader('hide')
      }
    },
    updateProgress() {
      let timerId = setInterval(() => {
        this.progress += 10
        if (this.progress >= 100) {
          clearInterval(timerId)
          displayNotify(this.$t('testGrammar.end'))
        }
      }, 100)
    },
  },
}
</script>
