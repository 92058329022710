import { createEmojiPicker, openEmojiPicker } from '@/utilities/emoji-picker.js'
import IconSmile from '@/components/icons/IconSmile.vue'

export default {
  components: {
    IconSmile,
  },
  methods: {
    // Инциализация emoji-пикера
    initEmojiPicker() {
      const emojiPickerId = createEmojiPicker()
      this.$store.commit('editor/setEmojiPickerId', emojiPickerId)
      const picker = document.getElementById(emojiPickerId)
      picker.addEventListener('emoji-click', (event) => {
        this.addSmile(event)
      })
    },
    // Добавление смайла в стейт
    addSmile(e) {
      const emojiTarget = this.$store.state.editor.emojiTarget
      if (emojiTarget == 'text-option-emoji-opener') {
        const smile = e.detail.unicode
        this.textEditor.activeEditor.execCommand(
          'mceInsertContent',
          false,
          smile
        )
      } else {
        const field = document.getElementById('last-focused-field-for-emoji')
        this.$store.commit('letter/addLetterEmoji', {
          fieldName: field.value,
          value: e.detail.unicode,
        })
      }
    },
    // Открытие emoji-пикера
    showEmojiPicker(buttonId) {
      const emojiPickerId = this.$store.state.editor.emojiPickerId
      if (emojiPickerId) {
        this.$store.commit('editor/setEmojiTarget', buttonId)
        openEmojiPicker(buttonId, emojiPickerId)
      } else {
        this.initEmojiPicker()
      }
    },
  },
}
