<template>
  <v-main class="project-create">
    <v-form>
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <h1 class="title-h3 text-center mb-2">
              {{ $t('projectCreate.new-project') }}
            </h1>
            <label class="label">{{ $t('projectCreate.company-name') }}</label>
            <v-text-field
              v-model="projectName"
              :placeholder="$t('projectCreate.spacex')"
              outlined />
            <label class="label mt-n2">
              {{ $t('projectCreate.website-address') }}
            </label>
            <v-text-field
              v-model="projectUrl"
              placeholder="site.com"
              outlined />
            <div class="text-center">
              <v-btn
                class="white--text px-8"
                :ripple="false"
                x-large
                depressed
                color="primary"
                @click="submit">
                {{ $t('projectCreate.create-a-new-project') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'
// [Переписать] форма должна отрабатывать не только по клику, но и по enter
export default {
  name: 'ProjectCreate',
  data() {
    return {
      projectName: '',
      projectUrl: '',
    }
  },
  methods: {
    ...mapActions('project', ['createProject']),
    async submit() {
      await this.createProject({
        name: this.projectName,
        url: this.projectUrl,
      })
      // [Переписать] добавить уведомление в случае ошибки, а в случае успеха перенаправлять
      this.$router.push({ name: 'template-index' })
    },
  },
}
</script>
