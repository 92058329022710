import customStore from '@/store/dynamic/custom.js'

export default {
  created() {
    if (!this.$store.hasModule('customStore')) {
      this.$store.registerModule('customStore', customStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('customStore')) {
      this.$store.registerModule('customStore', customStore)
    }
  },
  destroyed() {
    // console.log('destroyed letter')
    if (this.$store.hasModule('customStore')) {
      this.$store.unregisterModule('customStore')
    }
  },
}
