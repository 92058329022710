// Обработка перетаскивания модуля по рабочей области
/* eslint-disable */
import { getNextElement } from '@/utilities/editor.js'

export default {
  methods: {
    // Берем модуль
    dragStart(e) {
      let currentElement = e.target.classList.contains('editor-module')
        ? e.target
        : e.target.closest('.editor-module')

      currentElement.classList.add('selected')
    },
    // Отпускаем модуль
    dragEnd(e) {
      let currentElement = e.target.classList.contains('editor-module')
        ? e.target
        : e.target.closest('.editor-module')

      currentElement.classList.remove('selected')
      // При подключении структур пофиксить
      // if (document.querySelector('.element-menu')) {
      //   document
      //     .querySelector('table[letteros-element].selected')
      //     .appendChild(document.querySelector('.element-menu'))
      // }
    },
    // В момент перемещения
    dragOver(e) {
      // При подключении структур пофиксить
      // const isStructureElement = !!e.target.closest('.structure-table')

      let area = document.getElementById('workarea')
      const activeElement = area.querySelector('.selected')

      let currentElement = e.target.classList.contains('editor-module')
        ? e.target
        : e.target.closest('.editor-module')

      // При подключении структур пофиксить
      // if (
      //   isStructureElement &&
      //   !activeElement.classList.contains('editor-module')
      // ) {
      //   currentElement =
      //     e.target.closest('table[letteros-element]') ||
      //     e.target.closest('.module-structure-controls__wrapper')
      //   area = e.target.closest('td')
      // }

      // const isMovable =
      //   isStructureElement ||
      //   (activeElement !== currentElement &&
      //     currentElement.classList.contains('editor-module'))

      const isMoveable =
        activeElement !== currentElement &&
        currentElement.classList.contains('editor-module')

      if (!isMoveable) {
        return
      }

      const nextElement = getNextElement(e.clientY, currentElement)

      if (
        (nextElement && activeElement === nextElement.previousElementSibling) ||
        activeElement === nextElement
      ) {
        return
      }

      area.insertBefore(activeElement, nextElement)
      this.$store.commit('letter/updateModulesPosition')
    },
  },
}
