<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m14.057 11.36 1.835-10.318h2.866l-1.777 10.319h-2.924ZM27.358 1.156c-.574-.23-1.49-.459-2.637-.459-2.867 0-4.93 1.433-4.93 3.497 0 1.548 1.433 2.35 2.58 2.867 1.146.516 1.49.86 1.49 1.318 0 .688-.918 1.032-1.72 1.032-1.147 0-1.777-.172-2.752-.573l-.401-.172-.401 2.35c.688.287 1.949.573 3.267.573 3.038 0 5.045-1.433 5.045-3.611 0-1.204-.745-2.121-2.465-2.867-1.032-.515-1.663-.802-1.663-1.318 0-.459.516-.917 1.663-.917.974 0 1.662.172 2.178.401l.287.115.459-2.236ZM32.288 1.042c-.688 0-1.204.057-1.49.745l-4.3 9.574h3.096l.573-1.72h3.669l.344 1.72h2.752L34.523 1.042h-2.236Zm-1.318 6.88c.172-.517 1.146-3.04 1.146-3.04s.23-.63.401-1.031l.172.975s.574 2.58.688 3.153H30.97V7.92ZM8.784 8.264l-.287-1.49c-.516-1.72-2.178-3.612-4.013-4.529l2.58 9.172h3.096L14.803 1.1h-3.095L8.784 8.264Z"
      fill="#151515"
    />
    <path
      d="m.815 1.042.516.115c3.67.86 6.191 3.038 7.166 5.618L7.465 1.902c-.172-.688-.688-.86-1.318-.86H.815Z"
      fill="#888"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 27},
    height: {type: [Number, String], default: 12},
  }
}
</script>
