<template>
  <div>
    <v-list-item class="create-folder my-3">
      <v-btn
        class="btn-small black-hover"
        :ripple="false"
        depressed
        block
        outlined
        @click="$emit('folder-edit', { type })">
        {{ $t('sidebarMain.create-folder') }}
      </v-btn>
    </v-list-item>
    <v-list-item class="create-letter mt-3 mb-8">
      <v-btn
        class="btn-small"
        :ripple="false"
        color="primary"
        depressed
        block
        :to="{ name: linkTo }">
        {{ localeButton }}
      </v-btn>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'FolderButtons',
  props: {
    type: String,
  },
  computed: {
    localeButton() {
      if (this.type == 'letter') {
        return this.$t('sidebarMain.create-a-letter')
      }

      if (this.type == 'template') {
        return this.$t('sidebarMain.create-a-template')
      }

      if (this.type == 'custom') {
        return this.$t('sidebarMain.make-up-a-letter')
      }

      return ''
    },
    linkTo() {
      if (this.type == 'letter') {
        return 'letter-create'
      }

      if (this.type == 'template') {
        return 'template-create'
      }

      if (this.type == 'custom') {
        return 'letter-custom-create'
      }

      return ''
    },
  },
}
</script>
