// Глобальные события для модулей
/* eslint-disable */

export default {
  methods: {
    // Добавляем ui ресайза для модуля
    getHTMLModule(module, clear = false) {
      let element = document.createElement('div')
      element.classList.add('module-code')
      element.innerHTML = module.html
      if (!clear) {
        let item = this.formatTextNodes(element)
        item = this.addResizeModuleButton(item)
        return { ...module, html: item.innerHTML }
      } else {
        // Отрабатывает при сохранении письма/шаблона
        let item = this.unformatTextNodes(element)
        item = this.removeResizeUi(element)
        return { ...module, html: item.innerHTML }
      }
    },
    // Форматирование текстовых узлов
    formatTextNodes(module) {
      /**
       * Добавим тегам (p, a, span) атрибут letteros-type="text"
       * Теги td, внутри которых только текстовые узлы оборачиваем в обертку span
       * Перед экспортом/сохранением удаляем тег/атрибут
       * Добавление будет работать только для тех узлов, у которых есть текстовые ноды
       */

      // Обработка TD, а именно в которых только текстовые узлы
      module.querySelectorAll('td').forEach((el) => {
        let names = []
        el.childNodes.forEach((n) => names.push(n.nodeName))
        if (names.includes('TABLE')) return
        if (names.includes('DIV')) return
        if (names.includes('IMG')) return
        if (!names.length) return
        // Удаление пустых текстовых узлов
        el.childNodes.forEach((n) => {
          if (n.nodeType == 3 && !/[^\t\n\r ]/.test(n.nodeValue)) {
            n.remove()
          }
        })
        // Новый цикл нужен, чтобы отработали условия ниже
        names = []
        el.childNodes.forEach((n) => names.push(n.nodeName))
        // Если в 1 экземпляре, то пропускаем
        if (names.length == 1 && names.includes('SPAN')) return
        if (names.length == 1 && names.includes('A')) return
        if (names.length == 1 && names.includes('P')) return
        // [Переписать] после фикса на бэке убрать удаление атрибута, у TD никогда не должно быть этого атрибута
        // Сейчас его устанавливает бэк
        el.removeAttribute('letteros-type')

        // Преобразовываем дочерние теги p в span
        if (names.includes('P')) {
          el.childNodes.forEach((n) => {
            if (n.nodeType != 1) return
            if (n.tagName != 'P') return

            const newNode = document.createElement('span')
            newNode.setAttribute('style', n.getAttribute('style'))
            newNode.innerHTML = n.innerHTML
            n.after(newNode)
            n.remove()
          })
        }

        // Оборачиваем текстовые узлы в обертку, перед экспортом/сохранением удалить ее по атрибуту letteros-remove-tag
        el.innerHTML = `<span letteros-remove-tag>${el.innerHTML}</span>`
      })

      // Обработка span тегов, а именно, которые являются прямыми потомками td
      module.querySelectorAll('td > span').forEach((span) => {
        const parent = span.parentNode
        if (parent.classList.contains('resizable-arrow')) return
        if (span.textContent.trim() == '') return
        span.setAttribute('letteros-type', 'text')
      })

      // Обработка (p,a) тегов, а именно, которые являются прямыми потомками td
      module.querySelectorAll('p, a').forEach((el) => {
        const parent = el.parentNode
        if (parent.nodeName == 'TD' || parent.nodeName == 'DIV') {
          el.setAttribute('letteros-type', 'text')
        } else {
          // [Переписать] после фикса на бэке убрать удаление атрибута
          el.removeAttribute('letteros-type')
        }
      })

      return module
    },
    unformatTextNodes(module) {
      module.querySelectorAll('[letteros-type]').forEach((el) => {
        el.removeAttribute('letteros-type')
        if (el.hasAttribute('letteros-remove-tag')) {
          const parent = el.parentNode
          const html = el.innerHTML
          el.remove()
          parent.innerHTML = html
        }
      })

      return module
    },
    addResizeModuleButton(module) {
      let container = module.querySelector('[letteros-resizer]')

      // Если не задан атрибут letteros-rezier, будем подставлять ui в корень модуля
      if (!container) {
        container = module.querySelector('[letteros-element]')
      }

      const place = container.querySelector('tbody')
      const ui = place.querySelector('[letteros-resizable-new]')
      if (!ui) {
        this.generateResizerUI(place)
      }

      return module
    },
    removeResizeUi(module) {
      const moduleResizeElementCollection = module.querySelectorAll(
        'td[letteros-resizable-new]'
      )

      moduleResizeElementCollection.forEach((item) => item.parentNode.remove())

      return module
    },
    generateResizerUI(place) {
      const uiTop = document.createElement('tr')
      const uiBottom = document.createElement('tr')
      const tmpTop = this.getResizerTemplate('top')
      const tmpBottom = this.getResizerTemplate('bottom')

      uiTop.innerHTML = tmpTop
      uiBottom.innerHTML = tmpBottom

      place.prepend(uiTop)
      place.append(uiBottom)
    },
    getResizerTemplate(way) {
      return `
      <td letteros-resizable-new height="10px" style="font-size: 0; line-height: 10px; height: 10px;">
        <div class="resizable-ui ${way}">
          <div class="resizable-arrow">
            <span></span>
          </div>
        </div>
      </td>
      `
    },
  },
}
