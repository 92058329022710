<template>
  <header class="header-test">
    <div class="header-left">
      <v-btn
        class="header-sidebar-toggle btn-small mr-4"
        :class="{ 'btn-menu-active': sidebarState }"
        outlined
        :ripple="false"
        @click="toggleSideBarWithTestItems">
        {{ $t('headerLetterTest.menu') }}
        <v-icon right size="25">mdi-chevron-down</v-icon>
      </v-btn>
      <slot name="project-menu" />
    </div>
    <div class="header-middle">
      <div class="header-mode-bar">
        <v-divider class="mx-3" vertical />
        <v-btn
          outlined
          :ripple="false"
          icon
          class="btn-small mr-3 black-hover"
          :class="{ active: workAreaDeviceType === 'desktop' }"
          @click="changeDeviceType('desktop')">
          <IconDesktop />
        </v-btn>
        <v-btn
          outlined
          :ripple="false"
          icon
          class="btn-small black-hover"
          :class="{ active: workAreaDeviceType === 'mobile' }"
          @click="changeDeviceType('mobile')">
          <IconMobile />
        </v-btn>
        <v-divider class="mx-3" vertical />
        <v-btn
          class="btn-small black-hover"
          :ripple="false"
          icon
          outlined
          link
          target="_blank"
          :to="{
            name: 'letter-preview',
            params: { id: this.$route.params.id },
          }">
          <IconOut />
        </v-btn>
      </div>
    </div>
    <div class="header-right">
      <v-btn
        class="btn-small btn-black-border black-hover"
        outlined
        :ripple="false"
        exact
        :to="{ name: 'letter-editor', params: { id: this.$route.params.id } }">
        {{ $t('headerLetterTest.go-editor') }}
      </v-btn>
      <v-btn
        class="ml-3"
        depressed
        color="primary"
        link
        :ripple="false"
        @click="changeApproval">
        {{ approveButtonText }}
      </v-btn>
    </div>
  </header>
</template>

<script>
import IconDesktop from '@/components/icons/IconDesktop'
import IconMobile from '@/components/icons/IconMobile'
// import IconMode from '@/components/icons/IconMode'
import IconOut from '@/components/icons/IconOut'

export default {
  name: 'HeaderLetterTest',
  components: { IconOut, IconMobile, IconDesktop },
  computed: {
    sidebarState() {
      return this.$store.state.letter.sidebarVisible
    },
    approveButtonText() {
      return this.$store.getters['letter/getTestApproval']
        ? this.$t('headerLetterTest.cancel-approval')
        : this.$t('headerLetterTest.approve-test')
    },
    workAreaDeviceType() {
      return this.$store.state.letter.workAreaDeviceType
    },
  },
  methods: {
    changeDeviceType(type) {
      this.$store.commit('letter/changeDeviceType', type)
    },
    // Показ в сайдбаре тестовых фич (типограф, и т.п)
    toggleSideBarWithTestItems() {
      this.$store.commit('letter/toggleSidebar')
    },
    // Утвердить тест (включить сохранение после фикса на бэке)
    async changeApproval() {
      this.$store.commit('letter/toggleTestApproval')
      // await this.$store.dispatch('letter/updateLetterSettings', {
      //   test_approval: this.$store.getters['letter/getTestApproval'],
      // })
    },
  },
}
</script>

<style lang="scss">
.header-test {
  .header-middle {
    position: absolute;
    left: calc(50% - 97px);
  }
  .invert-color {
    &:hover {
      color: #151515 !important;
      background-color: #ffffff !important;
      svg {
        transform: rotate(180deg);
      }
    }
    &.active {
      &:hover {
        color: #ffffff !important;
        background-color: #151515 !important;
        svg {
          transform: rotate(180deg);
        }
      }
      svg {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }
  }
}
</style>
