<template>
  <header class="header header-registration d-flex">
    <icon-letteros-logo class="mr-auto" />
    <v-btn
      v-if="regStep === 2"
      class="btn-small hidden-xs-only black-hover"
      outlined
      :ripple="false"
      @click="changeStep">
      {{ $t('headerRegistration.back') }}
    </v-btn>
    <v-btn
      class="btn-small ml-5 hidden-xs-only black-hover"
      outlined
      :ripple="false"
      @click="goToLanding">
      {{ $t('headerRegistration.to-the-main-page') }}
    </v-btn>

    <v-btn
      v-if="routeName === 'login'"
      class="btn-small ml-5 hidden-xs-only black-hover"
      :ripple="false"
      outlined
      to="/signup">
      {{ $t('headerLogin.registration') }}
    </v-btn>
    <v-btn
      v-else
      class="btn-small ml-5 hidden-xs-only black-hover"
      outlined
      to="/login"
      :ripple="false">
      {{ $t('headerRegistration.login') }}
    </v-btn>
  </header>
</template>

<script>
import IconLetterosLogo from '@/components/icons/IconLetterosLogo.vue'

export default {
  name: 'HeaderAuth',
  components: {
    IconLetterosLogo,
  },
  computed: {
    regStep() {
      return this.$store.state.auth.registrationStep
    },
    routeName() {
      return this.$route.name
    },
  },
  methods: {
    goToLanding() {
      window.location = process.env.VUE_APP_MAIN_HOST
    },
    changeStep() {
      this.$store.commit('changeRegistrationStep', 1)
    },
  },
}
</script>

<style lang='scss'>
.header-registration {
  width: 100%;
}
</style>
