<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M8.16625 9.80322L4.63315 11.858C4.19152 12.1148 4.20035 12.7614 4.65082 13.0093L8.16625 14.9401" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.835 14.9411L19.3681 12.8863C19.8097 12.6295 19.8009 11.9829 19.3504 11.735L15.835 9.8042" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5884 8.04102L10.179 17.2077" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.00439 4.17069V19.8559C1.00439 21.5918 2.59429 23 4.54633 23H19.4472C21.408 23 22.9891 21.5918 22.9891 19.8559V7.2351C22.9891 6.40258 22.6181 5.60548 21.9468 5.01208L18.4579 1.92109C17.7866 1.3277 16.8857 1 15.9406 1L4.53749 1.02657C2.58546 1.03543 1.00439 2.43478 1.00439 4.17069Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>