var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header-test"},[_c('div',{staticClass:"header-left"},[_c('v-btn',{staticClass:"header-sidebar-toggle btn-small mr-4",class:{ 'btn-menu-active': _vm.sidebarState },attrs:{"outlined":"","ripple":false},on:{"click":_vm.toggleSideBarTest}},[_vm._v(" "+_vm._s(_vm.$t('headerLetterTest.menu'))+" "),_c('v-icon',{attrs:{"right":"","size":"25"}},[_vm._v("mdi-chevron-down")])],1),_vm._t("project-menu")],2),_c('div',{staticClass:"header-middle"},[_c('div',{staticClass:"header-mode-bar"},[_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-btn',{staticClass:"btn-small mr-3 black-hover",class:{ active: _vm.workAreaDeviceType === 'desktop' },attrs:{"outlined":"","ripple":false,"icon":""},on:{"click":function($event){return _vm.changeDeviceType('desktop')}}},[_c('IconDesktop')],1),_c('v-btn',{staticClass:"btn-small black-hover",class:{ active: _vm.workAreaDeviceType === 'mobile' },attrs:{"outlined":"","ripple":false,"icon":""},on:{"click":function($event){return _vm.changeDeviceType('mobile')}}},[_c('IconMobile')],1),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-btn',{staticClass:"btn-small black-hover",attrs:{"ripple":false,"icon":"","outlined":"","link":"","target":"_blank","to":{
          name: 'letter-preview',
          params: { id: this.$route.params.id },
        }}},[_c('IconOut')],1)],1)]),_c('div',{staticClass:"header-right"},[_c('v-btn',{staticClass:"btn-small btn-black-border black-hover",attrs:{"outlined":"","ripple":false,"exact":"","to":{
        name: 'letter-custom-editor',
        params: { id: this.$route.params.id },
      }}},[_vm._v(" "+_vm._s(_vm.$t('headerLetterTest.go-editor'))+" ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","color":"primary","link":"","ripple":false},on:{"click":_vm.changeApproval}},[_vm._v(" "+_vm._s(_vm.approveButtonText)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }