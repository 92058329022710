import testStore from '@/store/dynamic/test.js'

export default {
  created() {
    if (!this.$store.hasModule('testStore')) {
      this.$store.registerModule('testStore', testStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('testStore')) {
      this.$store.registerModule('testStore', testStore)
    }
  },
  destroyed() {
    // console.log('destroyed letter')
    if (this.$store.hasModule('testStore')) {
      this.$store.unregisterModule('testStore')
    }
  },
}
