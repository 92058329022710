import editorStore from '@/store/dynamic/editor.js'

export default {
  data() {
    return {
      unwatch: null,
    }
  },
  beforeCreate() {
    // console.log('created editor')
    if (!this.$store.hasModule('editor')) {
      this.$store.registerModule('editor', editorStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('editor')) {
      this.$store.registerModule('editor', editorStore)
    }

    // Отслеживаем клонирование
    this.unwatch = this.$store.watch(
      (state) => state.editor.moduleRefresh,
      (newUuid) => {
        if (newUuid !== null) {
          this.$nextTick(function () {
            // Запускаем текстовый редактор для нового модуля
            this.initEditorOnce(newUuid)
            // Очищаем триггер прослушки
            this.$store.commit('editor/toggleModuleRefresh', null)
          })
        }
      }
    )
  },
  beforeDestroy() {
    // console.log('destroyed editor')
    if (this.$store.hasModule('editor')) {
      this.$store.unregisterModule('editor')
    }
    this.unwatch()
  },
}
