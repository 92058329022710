<template>
  <div class="option-text-editor">
    <!-- Редактирование ссылки -->
    <label class="option__label">{{ $t('optionTextEditor.link') }}</label>
    <div class="option-element">
      <v-text-field
        ref="editor-link"
        class="mb-n2"
        placeholder=""
        outlined
        dense
        :value="link" />
    </div>

    <!-- Кнопка генерации текста -->
    <div v-if="textElement">
      <label class="option__label">
        {{ $t('optionTextEditor.button-text') }}
      </label>
      <div class="option-element mb-0">
        <v-text-field
          ref="editor-text-button"
          outlined
          dense
          :value="buttonText"
          @input="changeButtonText" />
      </div>
      <div class="option-element mb-9">
        <v-btn
          depressed
          block
          outlined
          class="btn-small btn-black-border"
          :ripple="false"
          @click="generateRandomText">
          {{ $t('optionTextEditor.generate-text') }}
        </v-btn>
      </div>
    </div>

    <!-- Форматирование текста -->
    <label class="option__label">{{ $t('optionTextEditor.text-style') }}</label>

    <!-- Bold/Italic -->
    <div class="option-element">
      <div class="option-label">{{ $t('optionTextEditor.drawing') }}</div>
      <div class="btn-bar">
        <v-btn
          :class="{ 'btn-active': bold }"
          :ripple="false"
          icon
          small
          @click="toggleFormat('Bold')">
          <IconBold />
        </v-btn>
        <v-btn
          :class="{ 'btn-active': italic }"
          :ripple="false"
          icon
          small
          @click="toggleFormat('Italic')">
          <IconItalic />
        </v-btn>
      </div>
    </div>

    <!-- Underline/Strike -->
    <div class="option-element">
      <div class="option-label">{{ $t('optionTextEditor.decoration') }}</div>
      <div class="btn-bar">
        <v-btn
          :class="{ 'btn-active': underline }"
          :ripple="false"
          icon
          small
          @click="toggleFormat('Underline')">
          <IconUnderline />
        </v-btn>
        <v-btn
          :class="{ 'btn-active': strike }"
          :ripple="false"
          icon
          small
          @click="toggleFormat('Strikethrough')">
          <IconStrike />
        </v-btn>
      </div>
    </div>

    <!-- Sup/Sub -->
    <div class="option-element">
      <div class="option-label">{{ $t('optionTextEditor.register') }}</div>
      <div class="btn-bar">
        <v-btn
          :class="{ 'btn-active': supstring }"
          :ripple="false"
          icon
          small
          @click="toggleFormat('Superscript')">
          T
          <sup>1</sup>
        </v-btn>
        <v-btn
          :class="{ 'btn-active': substring }"
          :ripple="false"
          icon
          small
          @click="toggleFormat('Subscript')">
          T
          <sub>1</sub>
        </v-btn>
      </div>
    </div>

    <!-- OL/UL -->
    <div class="option-element">
      <div class="option-label">{{ $t('optionTextEditor.list') }}</div>
      <div class="btn-bar">
        <v-btn
          :class="{ 'btn-active': listOl }"
          :ripple="false"
          icon
          small
          @click="toggleList('ol')">
          <IconListOl />
        </v-btn>
        <v-btn
          :class="{ 'btn-active': listUl }"
          :ripple="false"
          icon
          small
          @click="toggleList('ul')">
          <IconListUl />
        </v-btn>
      </div>
    </div>

    <!-- Вставка Emoji -->
    <div class="option-element">
      <div class="option-label">{{ $t('optionTextEditor.emoji') }}</div>
      <div class="btn-bar">
        <v-btn
          id="text-option-emoji-opener"
          class="black-hover emoji-opener"
          outlined
          icon
          :ripple="false"
          @click="showEmojiPicker('text-option-emoji-opener')">
          <IconSmile size="18" />
        </v-btn>
      </div>
    </div>

    <!-- Размер текста -->
    <div class="option-element option-element--select">
      <div class="btn-bar">
        <v-select
          v-model="fontSize"
          :items="fontSizeList"
          placeholder="Выберите размер шрифта"
          outlined
          append-icon="mdi-chevron-down"
          class="select-editor"
          @change="toggleFontSize()" />
      </div>
    </div>

    <!-- Межстрочный интервал -->
    <div class="option-element option-element--select">
      <div class="btn-bar">
        <v-select
          v-model="lineHeight"
          :items="lineHeightList"
          placeholder="Выберите межстрочный интервал"
          outlined
          append-icon="mdi-chevron-down"
          class="select-editor"
          @change="toggleLineHeight()" />
      </div>
    </div>

    <!-- Цвет текста -->
    <div class="option-element option-element--select">
      <div class="btn-bar">
        <v-text-field
          v-model="fontColor"
          class="option__value color-editor"
          placeholder=""
          outlined>
          <template v-slot:prepend-inner>
            <v-btn
              v-show="fontColor"
              icon
              plain
              :ripple="false"
              :color="fontColor"
              @click="showPicker = !showPicker">
              <IconCircle :stroke="fontColor" />
            </v-btn>
          </template>
        </v-text-field>
      </div>
    </div>

    <!-- Сохранение ссылки -->
    <div class="option-element mt-4">
      <v-btn
        depressed
        block
        outlined
        class="btn-small btn-black-border"
        :ripple="false"
        @click="saveLink">
        {{ $t('optionTextEditor.save') }}
      </v-btn>
    </div>

    <!-- Компонент колорпикера -->
    <ColorPicker
      class="color-picker__item"
      v-if="showPicker"
      v-click-outside="outsideColorPickerHandler"
      :label="$t('optionTextEditor.text-color')"
      :color="fontColor"
      @close="showPicker = false"
      @update="toggleColor" />
  </div>
</template>

<script>
/* eslint-disable */
import 'emoji-picker-element'
import ColorPicker from '@/components/tools/ColorPicker.vue'

import IconBold from '@/components/icons/IconBold.vue'
import IconItalic from '@/components/icons/IconItalic.vue'
import IconUnderline from '@/components/icons/IconUnderline.vue'
import IconStrike from '@/components/icons/IconStrike.vue'
import IconListOl from '@/components/icons/IconListUl.vue'
import IconListUl from '@/components/icons/IconListOl.vue'
import IconCircle from '@/components/icons/IconCircle.vue'

import { displayNotify } from '@/utilities/helpers'
import emojiMixin from '@/mixins/emoji.mixin.js'
import colorFilters from '@/mixins/filters/color.mixin.js'

import {
  fontSizeData,
  lineHeightData,
  buttonTextData,
} from '@/components/moduleOptions/textEditor.config'

export default {
  name: 'OptionTextEditor',
  mixins: [emojiMixin, colorFilters],
  components: {
    IconListUl,
    IconListOl,
    IconStrike,
    IconUnderline,
    IconItalic,
    IconBold,
    ColorPicker,
    IconCircle,
  },
  props: {
    element: [HTMLElement, null],
    textEditor: Object,
  },
  data() {
    return {
      linkElement: null,
      bold: false,
      italic: false,
      underline: false,
      strike: false,
      supstring: false,
      substring: false,
      listOl: false,
      listUl: false,
      fontSize: null,
      fontSizeList: fontSizeData,
      lineHeight: null,
      lineHeightList: lineHeightData,
      emojiPickerId: null,
      fontColor: null,
      showPicker: false,
      textElement: null,
      buttonText: '',
      buttonTextValues: buttonTextData,
    }
  },
  computed: {
    link() {
      if (this.linkElement) {
        return this.linkElement.getAttribute('href')
      } else {
        const parentLinkElement = this.element.closest('a')
        return parentLinkElement ? parentLinkElement.getAttribute('href') : ''
      }
    },
    config() {
      if (this.$route.name == 'template-editor') {
        return this.$store.state.template.config
      } else {
        return this.$store.state.letter.letter.template?.config
      }
    },
  },
  mounted() {
    // [Переписать] запускается при каждом клике (см. selectable.mixin.js - selectTextElement())
    // Отрабатывает при клике внутри элемента
    if (this.element) {
      this.element.addEventListener('click', this.checkFormat)
    }
  },
  beforeDestroy() {
    // Очистка событий при перекликивание между элементами
    this.element.removeEventListener('click', this.checkFormat)
  },
  methods: {
    // Определение состояния активного выделеного элемента
    checkFormat(e) {
      const selection = this.textEditor.activeEditor?.selection
      if (!selection) return false

      this.recursiveFindColor(selection.getNode())

      this.fontSize = selection.getNode().style.fontSize
      this.lineHeight = selection.getNode().style.lineHeight
      this.bold = this.textEditor.activeEditor.formatter.match('bold')
      this.italic = this.textEditor.activeEditor.formatter.match('italic')
      this.underline = this.textEditor.activeEditor.formatter.match('underline')
      this.strike =
        this.textEditor.activeEditor.formatter.match('strikethrough')
      this.supstring =
        this.textEditor.activeEditor.formatter.match('superscript')
      this.substring = this.textEditor.activeEditor.formatter.match('subscript')

      // костыль определение списка
      this.listOl = !!this.element?.querySelector('ol')
      this.listUl = !!this.element?.querySelector('ul')

      this.linkElement = selection.getNode().closest('a')

      // поиск кнопки
      if (selection.getNode().closest('[letteros-box-button]')) {
        this.textElement = selection.getNode()
        this.buttonText = this.textElement.innerText
      }
    },
    // Поиск цвета текста выбранного элемента
    recursiveFindColor(node) {
      this.fontColor = node.style.color
      let i = 0
      while (node.parentNode && !this.fontColor && i < 7) {
        node = node.parentNode
        this.fontColor = node.style.color
        i++
      }

      if (this.fontColor) {
        this.fontColor = this.$options.filters.rgbaToHex(this.fontColor)
      } else {
        this.fontColor = '#000000'
      }
    },
    // Форматирование текста bold, italic, underline, strike, sub, sup
    toggleFormat(command) {
      this.textEditor.activeEditor.execCommand(command)
    },
    // Установка размера текста
    toggleFontSize() {
      this.textEditor.activeEditor.execCommand('FontSize', false, this.fontSize)
    },
    // Установка межстрочного интервала
    toggleLineHeight() {
      this.textEditor.activeEditor.execCommand(
        'LineHeight',
        false,
        this.lineHeight
      )
    },
    // Установка цвета текста
    toggleColor(data) {
      this.fontColor = data.color
      this.showPicker = false

      this.textEditor.activeEditor.execCommand(
        'ForeColor',
        false,
        this.fontColor
      )
    },
    // Форматирование списков
    toggleList(type) {
      this.textEditor.activeEditor.execCommand('RemoveList')
      if ((type === 'ul' && this.listUl) || (type === 'ol' && this.listOl)) {
        this.listOl = false
        this.listUl = false
        return null
      }
      let styleAttributes = this.element.getAttribute('style')
      this.textEditor.activeEditor.execCommand(
        type === 'ul' ? 'InsertUnorderedList' : 'InsertOrderedList',
        false,
        {
          'list-style-type': type === 'ul' ? 'disc' : 'decimal',
          'list-item-attributes': {},
          'list-attributes': { style: styleAttributes },
        }
      )
      this.checkFormat()
    },
    // Сохранение ссылки
    saveLink(event) {
      const value = this.$refs['editor-link'].lazyValue
      if (this.linkElement) {
        this.linkElement.href = value
      } else {
        this.textEditor.activeEditor.execCommand('mceInsertLink', false, value)
        const linkElement = this.element?.querySelector('a:not([style])')
        linkElement.style = 'text-decoration:none;'

        //Добавление цвета из letteros-link
        if (this.config.hasOwnProperty('link')) {
          linkElement.style.color = this.config.link
        }

        linkElement.target = '_blank'
      }
      displayNotify(this.$t('optionTextEditor.text-settings-saved'))
    },
    // Изменение текста в DOM, так как невозможно использовать 2way-binding
    changeButtonText(value) {
      this.textElement.innerText = value
    },
    // Генерация текста для кнопки
    generateRandomText() {
      if (!this.textElement) return

      const textValues = this.buttonTextValues.filter(
        (value) => value !== this.buttonText
      )

      const randomIndex = Math.floor(Math.random() * textValues.length)
      const actualValue = textValues[randomIndex]

      this.buttonText = actualValue
      this.textElement.innerText = actualValue
    },
    // Закрытие колорпикера
    outsideColorPickerHandler(e) {
      if (!this.showPicker) return false

      const target = e.target.closest('.color-picker__item')

      if (!target) {
        this.showPicker = false
      }
    },
  },
}
</script>

<style lang="scss">
.option-text-editor {
  .option-element {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1rem 0;

    &.option-element--select {
      margin-bottom: 0;
      .btn-bar {
        width: 100%;
      }
    }
  }
  .btn-active {
    background: #e1e1e1;
  }
  .color-picker__container .quad {
    width: 22px;
    height: 22px;
    border-radius: 8px;
    border: 1px solid black;
    margin-right: 5px;
    position: relative;
    top: -1px;
  }

  .color-picker {
    &__container {
      position: relative;
    }
  }

  .color-picker__item {
    position: fixed;
    top: 10px;
    left: 300px;
    transform: translate(0%, 0%);
    z-index: 15;
  }
}
.btn-bar sub {
  bottom: -0.5em;
}
.select-editor.v-text-field.v-text-field--enclosed {
  border-radius: 10px;
  .v-input__slot {
    margin-bottom: 2px;
  }
  .v-text-field__details {
    margin-bottom: 0;
  }
  .v-select__selection--comma {
    margin: 0 4px 0 0;
  }
  .v-select__selections input {
    padding: 0;
  }
  .v-input__append-inner {
    margin-top: 6px;
  }
  .v-input__slot {
    min-height: 36px;
  }
}
.color-editor.v-text-field.v-text-field--enclosed {
  border-radius: 10px;
  .v-input__slot {
    min-height: 36px;
  }
  .v-input__prepend-inner {
    margin-top: 6px;
    .v-btn__content {
      justify-content: flex-start;
    }
  }
}
</style>
