<template>
  <div class="option-link">
    <label class="option__label">{{ label }}</label>
    <v-text-field v-model="link" placeholder="" outlined dense />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'OptionLink',
  props: {
    element: [HTMLElement, null],
    label: String,
  },
  computed: {
    link: {
      get() {
        return this.element.getAttribute('href')
      },
      set(value) {
        return this.element.setAttribute('href', value)
      },
    },
  },
}
</script>
