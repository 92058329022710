<template>
  <svg width="39" height="24" id="mastercard" viewBox="0 0 39 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="1" y="1" width="22" height="22" rx="11" fill="#151515"></rect> <rect opacity="0.8" x="16.2308" y="1" width="22" height="22" rx="11" fill="#A6A6A6"></rect> </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 32},
    height: {type: [Number, String], default: 26},
    viewBox: {type: String, default: '0 0 32 26'},
    fill: {type: String, default: '#151515'}
  }
}
</script>
