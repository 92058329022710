<template>
  <div class="option-border-radius">
    <label class="option__label">
      {{ $t('optionBorderRadius.rounding-the-edges') }}
    </label>
    <v-row no-gutters>
      <v-col cols="6">
        <v-text-field
          id="letter-editor-sidebar-border-radius"
          class="mr-3"
          v-model="topLeftRadius"
          outlined
          dense
          type="number"
          @input="update" />
      </v-col>
      <v-col cols="6">
        <v-text-field
          class="mr-3 text-right"
          v-model="topRightRadius"
          outlined
          dense
          type="number"
          @input="update" />
      </v-col>
      <v-col cols="6">
        <v-text-field
          class="mr-3"
          v-model="bottomLeftRadius"
          outlined
          dense
          type="number"
          @input="update" />
      </v-col>
      <v-col cols="6">
        <v-text-field
          class="mr-3 text-right"
          v-model="bottomRightRadius"
          outlined
          dense
          type="number"
          @input="update" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'OptionBorderRadius',
  props: {
    element: [HTMLElement, null],
  },
  data() {
    return {
      topLeftRadius: 0,
      topRightRadius: 0,
      bottomRightRadius: 0,
      bottomLeftRadius: 0,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.topLeftRadius = parseInt(this.element.style.borderTopLeftRadius || 0)
      this.topRightRadius = parseInt(
        this.element.style.borderTopRightRadius || 0
      )
      this.bottomRightRadius = parseInt(
        this.element.style.borderBottomRightRadius || 0
      )
      this.bottomLeftRadius = parseInt(
        this.element.style.borderBottomLeftRadius || 0
      )
    },
    update() {
      this.element.style.borderTopLeftRadius = `${this.topLeftRadius}px`
      this.element.style.borderTopRightRadius = `${this.topRightRadius}px`
      this.element.style.borderBottomRightRadius = `${this.bottomRightRadius}px`
      this.element.style.borderBottomLeftRadius = `${this.bottomLeftRadius}px`
    },
  },
}
</script>
