<template>
  <v-card>
    <v-card-title
      class="d-flex flex-row justify-space-between align-center py-2"
      style="cursor: grab">
      <div class="color-picker-title">
        {{ label }}
      </div>
      <v-btn icon :ripple="false" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="color-picker__actions">
      <v-card-actions>
        <v-color-picker
          :value="colorValue"
          @input="inputPickerColor"
          mode="hexa"
          modes="hex"
          hide-mode-switch
          canvas-height="300"
          class="mt-n2" />
      </v-card-actions>
      <v-card-actions>
        <div class="v-color-picker__edit-override">
          <div class="v-color-picker__input-override">
            <v-text-field
              :value="colorValue"
              @input="inputFieldColor"
              class="input-hex"
              outlined>
              <template v-slot:prepend-inner>
                <IconCircle :stroke="colorValue" />
              </template>
            </v-text-field>
          </div>
        </div>
      </v-card-actions>
      <v-card-actions>
        <div v-if="type === 'worksheet-loading'" class="color-picker-saved">
          <small>{{ $t('colorPicker.loading-text') }}</small>
        </div>
        <div v-else-if="colorOptions.length" class="color-picker-saved">
          {{ $t('colorPicker.saved-colors') }}
          <div class="color-picker-saved-colors d-flex flex-wrap">
            <button
              v-for="(savedColor, index) in colorOptions"
              :key="index"
              :style="`background: ${savedColor}`"
              :ripple="false"
              class="color-picker-color"
              type="button"
              @click="colorValue = savedColor" />
          </div>
        </div>
      </v-card-actions>
      <v-card-actions>
        <v-btn
          class="color-picker-btn btn-small px-7 btn-black-border"
          :ripple="false"
          outlined
          @click="apply">
          {{ $t('colorPicker.apply') }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import IconCircle from '@/components/icons/IconCircle'
import colorFilters from '@/mixins/filters/color.mixin.js'

export default {
  name: 'ColorPicker',
  components: { IconCircle },
  mixins: [colorFilters],
  props: {
    type: String,
    label: String,
    color: {
      type: String,
      default: '#FFFFFF',
    },
    colorOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      colorValue: '',
    }
  },
  mounted() {
    this.colorValue = this.color
  },
  methods: {
    inputPickerColor(value) {
      this.colorValue = value
    },
    inputFieldColor(value) {
      if (this.$options.filters.isValidHex(value)) {
        this.colorValue = value
      }
    },
    apply() {
      this.$emit('update', {
        color: this.colorValue.toLowerCase(),
      })

      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.color-pick {
  border: 1px solid $color-gray;
  border-radius: 15px;
  background-color: #fff;
  text-align: center;
}
.v-color-picker__dot {
  display: none;
}
.color-picker__actions {
  overflow: auto;
  max-height: 675px;
  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-gray;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-dark;
  }
}
.color-picker__item {
  border: 1px solid #d0d0d0 !important;
  max-width: none;
  overflow: hidden;
  .v-card__actions {
    padding-right: 0;
    padding-left: 0;
  }

  .v-card__title {
    button {
      margin-right: -10px;
    }
  }
}

.v-color-picker {
  border-radius: 0;

  canvas {
    border-radius: 0;
  }
  .v-color-picker__edit {
    display: none;
  }
}

// сорри за это творчество, я с бэкенда пришел)
.v-color-picker__edit-override {
  margin-top: -10px !important;
  margin-left: 10px !important;

  .v-input {
    height: 36px !important;
  }

  .v-input__prepend-inner {
    margin-top: 6px;
  }

  .input-hex {
    width: 100%;
    .v-input__control {
      border-radius: 15px;

      fieldset {
        color: #d2d2d3 !important;
      }
    }
  }

  .input-opacity {
    margin-left: -1px !important;
    max-width: 70px !important;

    .v-input__control {
      border-radius: 0 15px 15px 0;

      fieldset {
        color: #d2d2d3 !important;
      }
    }
  }

  .v-color-picker__input-override {
    max-width: 280px !important;
    display: flex !important;
  }

  .v-text-field--filled > .v-input__control > .v-input__slot,
  .v-text-field--full-width > .v-input__control > .v-input__slot,
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    align-items: stretch;
    min-height: 36px !important;
  }
}
</style>
