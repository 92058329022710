<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M1.57861 7.56299H16.2587C19.9793 7.56299 23.0001 10.6015 23.0001 14.3441C23.0001 18.0866 19.9793 21.1251 16.2587 21.1251H1.57861" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.80203 1.875L1 7.71117L6.44286 13.1861" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>