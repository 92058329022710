<template>
  <v-row justify="center">
    <v-col cols="12" class="header-name">
      <div></div>
      <h1 class="title-h1 text-center">
        {{ pageName }}
      </h1>
      <v-btn-toggle
        v-if="pageType !== 'analytics'"
        class="table-tile-switch"
        tile
        group
        mandatory
        :value="pageType"
        @change="changeView">
        <v-btn
          class="mr-1 icon-hover"
          icon
          plain
          small
          value="card"
          :ripple="false">
          <IconTile />
        </v-btn>
        <v-btn class="icon-hover" icon plain small value="row" :ripple="false">
          <IconList />
        </v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>

<script>
import IconTile from '@/components/icons/IconTile.vue'
import IconList from '@/components/icons/IconList.vue'

export default {
  name: 'ControlHeader',
  components: {
    IconTile,
    IconList,
  },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    pageName() {
      // Поиск названия папки
      if (this.$route.name.includes('directory')) {
        let folderType = 'templatesFolders'
        if (this.pageType == 'letter') folderType = 'lettersFolders'
        if (this.pageType == 'letter-custom') folderType = 'customFolders'

        const folder = this.$store.state.project.full[folderType].find(
          (item) => item.id === +this.$route.params.id
        )

        return folder?.name || ''
      }

      // Поиск названия страницы
      if (this.pageType == 'template') {
        return this.$t('tableHeader.templates')
      } else {
        return this.$t('tableHeader.letters')
      }
    },
  },
  methods: {
    changeView(value) {
      this.$store.commit('tableStore/changeView', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-index-header {
  .header-name {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: space-between;

    .title-h1 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 40px;
      width: 100%;
    }

    .table-tile-switch {
      .v-btn__content {
        svg {
          color: $color-gray;
        }
      }

      .v-btn--active {
        .v-btn__content {
          svg {
            color: $color-dark;
          }
        }
      }
    }
  }
}
</style>
