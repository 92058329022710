<template>
  <v-main class="integrations">
    <v-container>
      <h1 class="title-h1 text-center mb-8">
        {{ $t('projectIntegrations.integrations') }}
      </h1>
      <v-expansion-panels
        v-if="this.$store.state.project.showIntegrations"
        accordion
        flat
        multiple
        tile
        class="integrations-items">
        <ProjectAmoHook />
        <ProjectS3 />
        <ProjectFTP />
        <ProjectDomain />
      </v-expansion-panels>
    </v-container>
  </v-main>
</template>

<script>
import ProjectS3 from '@/components/project/integrations/ProjectS3'
import ProjectFTP from '@/components/project/integrations/ProjectFTP'
import ProjectDomain from '@/components/project/integrations/ProjectDomain'
import ProjectAmoHook from '@/components/project/integrations/ProjectAmoHook'
import { mapActions } from 'vuex'

// [Переписать] избавиться от обращения к стору внутри template и перенести в computed

export default {
  name: 'ProjectIntegrations',
  components: {
    ProjectDomain,
    ProjectFTP,
    ProjectS3,
    ProjectAmoHook,
  },
  async created() {
    await this.loadIntegrations(this.$route.params.id)
    // [Переписать] проверить, нужно ли тут какое-то уведомление в интерфейсе, в случае ошибки запроса
    document.title = this.$t('projectIntegrations.integrations')
  },
  methods: {
    ...mapActions('project', ['loadIntegrations']),
  },
}
</script>

<style lang="scss">
.integrations {
  .container {
    max-width: 634px;
  }
}

.integrations-items {
  margin: 0 0 20px;

  .accordion-item {
    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-lighten;
    }

    .v-expansion-panel-header {
      padding: 28px 0;
      transition: margin-bottom 0.3s;

      &--active {
        margin-bottom: -12px;
      }
    }
  }
}

.integrations-items-logo {
  transition: filter 0.3s;
  filter: grayscale(1);

  .v-expansion-panel-header--active & {
    filter: grayscale(0);
  }
}

.integrations-new-input {
  margin-right: 16px;
  margin-bottom: -14px;
}

.integrations-new-input,
.integrations-new-btn {
  @media (max-width: 630px) {
    flex-basis: 100%;
    margin-right: 0;
  }
}
</style>
