<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill" >
    <path :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" d="M22 6H2" />
    <path :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" d="M16.9817 5.43303V2.64574C16.9817 1.7325 16.2358 1 15.3275 1H8.67249C7.75457 1 7.01831 1.74201 7.01831 2.64574V5.43303" />
    <path :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" d="M3 9V20.2151C3 21.7585 4.42055 23 6.16525 23H17.8347C19.589 23 21 21.7501 21 20.2151V9" />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>