<template>
  <v-app>
    <HeaderAuth />

    <router-view class="page" />
  </v-app>
</template>

<script>
import HeaderAuth from '@/components/header/HeaderAuth.vue'

export default {
  name: 'LayoutRegistration',
  components: { HeaderAuth },
}
</script>

<style lang="scss">
.page {
  height: calc(100% - 51px);

  .container {
    height: 100%;
    padding: 3.75rem;
  }
}
</style>
