<template>
  <v-app>
    <router-view name="header" class="header">
      <template #project-menu>
        <HeaderMenuProject />
      </template>
      <template #user-menu>
        <HeaderMenuUser />
      </template>
    </router-view>
    <router-view
      v-click-outside="sidebarOutsideClickHandler"
      name="sidebar"
      class="sidebar" />
    <router-view class="page" />
    <PromiseDialogWrapper ref="leaveDialog">
      <PromiseDialogFrame />
    </PromiseDialogWrapper>
  </v-app>
</template>

<script>
import HeaderMenuProject from '@/components/header/HeaderMenuProject.vue'
import HeaderMenuUser from '@/components/header/HeaderMenuUser.vue'

import PromiseDialogWrapper from '@/components/PromiseDialogWrapper.vue'
import PromiseDialogFrame from '@/components/PromiseDialogFrame.vue'

import dynamicStore from '@/mixins/TemplateLayout/dynamicStore.mixin.js'

export default {
  name: 'LayoutTemplate',
  components: {
    HeaderMenuUser,
    HeaderMenuProject,
    PromiseDialogFrame,
    PromiseDialogWrapper,
  },
  mixins: [dynamicStore],
  computed: {
    sidebarShow() {
      return this.$store.state.template.sidebarShow
    },
  },
  methods: {
    // Объеденить в миксин из всех компонентов
    sidebarOutsideClickHandler(e) {
      if (!this.sidebarShow) return false
      const target = e.target.closest('.header-sidebar-toggle, .sidebar')

      if (!target) {
        this.$store.commit('template/hideSidebar')
      }
    },
  },
}
</script>

<style lang="scss">
.v-application p {
  margin-bottom: 0;
}
</style>
