<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <g clip-path="url(#clip0_5829_255)">
      <path d="M34.2874 11.8777L34.2874 11.8777L34.2918 11.8821C34.368 11.9587 34.438 12.0137 34.4999 12.0559V36.6119C34.4999 38.2207 33.2377 39.5 31.7151 39.5H8.40592C6.88332 39.5 5.62109 38.2207 5.62109 36.6119V3.40166C5.62109 1.8003 6.88296 0.519619 8.40056 0.513552C8.40078 0.513551 8.401 0.51355 8.40123 0.513549L23.2732 0.500077C23.3147 0.565343 23.3684 0.636165 23.4414 0.711518L23.4417 0.711854L34.2874 11.8777Z" fill="white" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.3047 18.083H27.7086" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.3047 23.9207H27.7086" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.3047 30.0317H27.7086" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.5239 17.6886L13.0102 19.178L15.6221 16.5715" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.5239 23.7228L13.0102 25.2232L15.6221 22.6167" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.5239 29.5819L13.0102 31.0713L15.6221 28.4648" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M23.1365 1.18208L33.7671 11.5H26.1449C24.4697 11.5 23.1365 10.1856 23.1365 8.59864V1.18208Z" fill="#151515" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_5829_255">
        <rect width="40" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 40},
    height: {type: [Number, String], default: 40},
    stroke: {type: String, default: '#151515'},
    viewBox: {type: String, default: '0 0 40 40'},
    fill: {type: String, default: 'none'}
  }
}
</script>