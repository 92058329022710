<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M6.78947 17.9167L1 11.9286L6.73189 6.00008" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.2105 6L23 11.9881L17.2681 17.9167" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>