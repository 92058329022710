/* eslint-disable */
// [Переписать] что тут происходит
import { getCookie, setCookie } from '../utilities/helpers'

export default (to, from, next) => {
  const version = getCookie('current_version')
  if (!version) next()

  /*
    C болью в сердце вставляю этот мерзкий костыль,
    чтобы пофиксить кейс, когда нового пользователя бросает в старую версию
    (за подробностями к Кате Головкиной).
    todo рассмотреть возмонжость хранения выбора не в куке, а в бд,
    дабы привязка шла к аккаунту/юзеру, а не к текущей пользовательской среде
   */
  if (from.name === '/signup') {
    const host = location.host
    let domain

    if (host !== 'localhost') {
      domain = host.split('.')
      domain = domain[1] + '.' + domain[2]
      setCookie('current_version', 'new', { domain: domain })
    }
    next()
    return
  }

  if (version === 'old') {
    location.href = process.env.VUE_APP_OLDAPP_HOST
  } else {
    next()
  }
}
