<template>
  <v-main class="editor-html">
    <editor
      v-if="isLetterLoaded"
      api-key="b6evd6vjvr8lgqrzcbaxyl96yxhcjrx100uqc1ogp9rpzvh2"
      :init="{
        resize: false,
        menubar: false,
        branding: false,
        contextmenu: false,
        forced_root_block: false,
        contextmenu_never_use_native: true,
        plugins: ['lists autolink link image table'],
        language: 'ru',
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | image link removeformat',
        convert_urls: false,
        table_grid: false,
        relative_urls: false,
        remove_script_host: false,
        visual: false,
        images_upload_handler: loadImage,
      }"
      @onInit="initEditor"
      @onChange="changeContent" />
  </v-main>
</template>

<script>
/*
  По поводу API-KEY и лицензии
  https://www.tiny.cloud/blog/tinymce-free-wysiwyg-html-editor/
  В данный момент ключ зарегестрирован на почту nikita.yadgarov@letteros.com

  дока тут - https://www.tiny.cloud/docs/tinymce/6/vue-ref/#installing-the-tinymce-vuejs-integration-using-npm
  У нас стоит 3 версия tinymce-vue под vue 2 и судя по исполнению в этот пакет завернута 5 версия редактора

  https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#getbody - методы инстанса
*/

/* eslint-disable */
import Editor from '@tinymce/tinymce-vue'
import { uploadFile } from '@/utilities/fileHandler'

export default {
  name: 'LetterEditorCustom',
  components: {
    Editor,
  },
  data() {
    return {
      editor: null,
    }
  },
  computed: {
    html() {
      return this.$store.state.customStore.content
    },
    device() {
      return this.$store.state.customStore.workAreaDeviceType
    },
    isLetterLoaded() {
      return this.$store.state.customStore.isLetterLoaded
    },
  },
  watch: {
    device() {
      this.toggleViewport()
    },
  },
  async created() {
    this.$loader('show')
    // Проверка isLetterLoaded нужна, так как загрузка может произойти на странице настроек
    if (!this.isLetterLoaded) {
      await this.$store.dispatch('customStore/getLetter', this.$route.params.id)
      // [Переписать] проработать ошибки [NEW_API]
    }
    this.$loader('hide')
  },
  mounted() {
    this.$store.commit('customStore/hideSidebar')
  },
  methods: {
    // Установка содержимого и расчет высоты
    initEditor(e, instance) {
      this.editor = instance
      const id = instance.id

      instance.setContent(this.html)

      // calculate editor height
      const windoHeight = window.innerHeight
      const offsetHeader = document.querySelector('.header').clientHeight
      const boxEl = document.querySelector('.v-main__wrap')
      const paddingOfsset =
        parseInt(
          window.getComputedStyle(boxEl, null).getPropertyValue('padding-top')
        ) +
        parseInt(
          window
            .getComputedStyle(boxEl, null)
            .getPropertyValue('padding-bottom')
        )
      const editorHeight = windoHeight - offsetHeader - paddingOfsset - 3

      document
        .getElementById(id + '_ifr')
        .closest('.tox-tinymce').style.height = editorHeight + 'px'
    },
    // Сохранение изменений редактора
    changeContent(e, instance) {
      this.$store.commit(
        'customStore/updateContent',
        instance.getBody().innerHTML
      )
    },
    // Загрузка изображения
    async loadImage(blobInfo, success, failure) {
      const formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())

      const url = 'import/image/upload'
      const cdnFolder = 'letter-custom'

      try {
        const result = await uploadFile(formData.get('file'), url, cdnFolder)
        // [Переписать] проработать ошибки [NEW_API]
        success(result.url)
      } catch (e) {
        failure(this.$t('letterLayout.image-not-loaded'))
      }
    },
    // Смена ширины содержимого iframe
    toggleViewport() {
      if (this.device == 'mobile') {
        this.editor.contentDocument.body.style.width = '375px'
        this.editor.contentDocument.body.style.marginLeft = 'auto'
        this.editor.contentDocument.body.style.marginRight = 'auto'
      } else {
        this.editor.contentDocument.body.style.width = ''
        this.editor.contentDocument.body.style.marginLeft = ''
        this.editor.contentDocument.body.style.marginRight = ''
      }
    },
  },
}
</script>

<style lang="scss">
.editor-html {
  .v-main__wrap {
    padding: 1rem;
  }
  .dark-theme {
    .btn-black-border {
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
    .btn-gray-border {
      .v-btn__content {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }
    [letteros-element]:not([letteros-system]) {
      -webkit-filter: invert(100%);
      filter: invert(100%);
      img {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }
  }
}
</style>
