import { getPublicRenders } from '@/utilities/acid'
import { getHTML } from '@/utilities/test.js'

const state = () => ({
  showSuccessCopyMessage: false,
  html: '',
  letterType: '',
  isHtmlLoaded: null,
  activeRenderId: '',
  isRendersLoaded: false,
  renders: {},
})

const getters = {
  getRendersArray: (state) => {
    let renders = []
    for (let list in state.renders) {
      renders.push(...state.renders[list])
    }
    return renders
  },
  getActiveRender: (state, getters) => {
    return getters.getRendersArray.find(
      (render) => render.id == state.activeRenderId
    )
  },
  getNextRenderId: (state, getters) => {
    let activeIndex
    getters.getRendersArray.forEach((k, i) => {
      if (k.id == state.activeRenderId) {
        activeIndex = i + 1
      }
    })
    if (!getters.getRendersArray[activeIndex]) return false
    return getters.getRendersArray[activeIndex].id
  },
  getPrevRenderId: (state, getters) => {
    let activeIndex
    getters.getRendersArray.forEach((k, i) => {
      if (k.id == state.activeRenderId) {
        activeIndex = i - 1
      }
    })
    if (!getters.getRendersArray[activeIndex]) return false
    return getters.getRendersArray[activeIndex].id
  },
  getRenders: (state) => {
    return state.renders
  },
}

const mutations = {
  openMessage(state) {
    state.showSuccessCopyMessage = true
  },
  closeMessage(state) {
    state.showSuccessCopyMessage = false
  },
  setPublicRenders(state, payload) {
    state.renders = payload
  },
  setActiveRenderId(state, payload) {
    state.activeRenderId = payload
  },
  clearHtmlLoaded(state) {
    state.isHtmlLoaded = null
  },
  toggleHtmlLoaded(state, payload) {
    state.isHtmlLoaded = payload
  },
  setHTML(state, payload) {
    state.html = payload
  },
  setLetterType(state, payload) {
    state.letterType = payload
  },
  clearRendersLoaded(state) {
    state.isRendersLoaded = false
  },
  setRendersLoaded(state) {
    state.isRendersLoaded = true
  },
}

const actions = {
  async getPublicRenders({ state, commit }, payload) {
    if (!state.isRendersLoaded) {
      try {
        const result = await getPublicRenders(payload)
        if (result.state) {
          commit('setPublicRenders', result.items)
          commit('setRendersLoaded')
          return true
        } else {
          throw new Error(result.error)
        }
      } catch (e) {
        commit('clearRendersLoaded')
        return false
      }
    }
  },
  async getHTML({ commit }, id) {
    try {
      const result = await getHTML(id)
      if (result.status) {
        commit('setHTML', result.webVersion)
        commit('setLetterType', result.type)
        commit('toggleHtmlLoaded', true)
        return true
      } else {
        throw new Error(result.error)
      }
    } catch (e) {
      commit('toggleHtmlLoaded', false)
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
