<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M20.8844 11.6844V18.5143C20.8844 21.2419 18.65 23.4582 15.8999 23.4582H5.98454C3.23445 23.4582 1 21.2419 1 18.5143V8.67974C1 5.95207 3.23445 3.73584 5.98454 3.73584H12.3441" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.6391 1.97775L8.21882 12.3131L6.23145 18.152L12.0969 16.1169C12.0969 16.1169 21.3784 6.911 22.4957 5.80288C23.7955 4.51363 23.6881 2.85146 22.646 1.82858C21.604 0.805706 20.0356 0.592606 18.6391 1.97775Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>

  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>