<template src="./ProjectBalance/index.vue"></template>

<script>
import IconBankCard from '@/components/icons/IconBankCard.vue'
import IconBankAccount from '@/components/icons/IconBankAccount.vue'
import IconApplePay from '@/components/icons/IconApplePay.vue'
import IconGooglePay from '@/components/icons/IconGooglePay.vue'
import IconYandexPay from '@/components/icons/IconYandexPay.vue'
import IconMir from '@/components/icons/IconMir.vue'
import IconVisa from '@/components/icons/IconVisa.vue'
import IconMasterCard from '@/components/icons/IconMasterCard.vue'
import IconEdit from '@/components/icons/IconEdit.vue'
import { getRobokassaParams, createInvoice } from '@/utilities/payment'
import { downloadFile } from '@/utilities/fileHandler.js'

export default {
  name: 'ProjectBalance',
  components: {
    IconBankCard,
    IconBankAccount,
    IconApplePay,
    IconGooglePay,
    IconYandexPay,
    IconMir,
    IconVisa,
    IconMasterCard,
    IconEdit,
  },
  data() {
    return {
      isUserLoggedIn: true,
      isChangeDetails: false,
      isAutoCompletion: false,
      showModalEditEmail: false,
      paymentMethod: 1,
      paymentStep: 1,
      paymentSum: null,
      robokassaParams: [],
    }
  },
  computed: {
    paymentData() {
      return this.$store.getters['project/getProjectProfile']
    },
  },
  created() {
    if (!this.$route.params.sum) return
    this.paymentSum = +this.$route.params.sum
  },
  methods: {
    async toPayPage() {
      if (!this.paymentSum) return

      //Онлайн оплата = 1
      if (this.paymentMethod == 1) {
        this.$loader('show')
        // [Переписать] вынести в стор
        this.robokassaParams = await getRobokassaParams({
          sum: this.paymentSum,
        })
        // [Переписать] добавить уведомление в случае ошибки запроса, если необходимо
        setTimeout(() => {
          const submitButton = document.getElementById('robokassa-submit')
          submitButton.click()
          this.$loader('hide')
        }, 250)
      }

      //Счет для юрлиц = 2
      if (this.paymentMethod == 2) {
        this.paymentStep = 2
      }
    },
    eventToggle(cb) {
      if (this.paymentStep == 1) {
        // [Переписать] добавить краткое описание, какие методы в этом колбэке могут вызваться
        cb()
      }

      return false
    },
    async generateInvoice() {
      const request = {
        price: this.paymentSum,
        bank: '',
        name: '',
        ...this.paymentData,
      }

      // [Переписать] вынести в стор
      const response = await createInvoice(request)
      // [Переписать] добавить уведомление в случае ошибки запроса, если необходимо
      if (!response.state) return false
      const result = response.result

      await downloadFile(
        `/account/get-invoice?p=${result.file}&n=${result.name}`
      )
      // [Переписать] добавить уведомление в случае ошибки запроса, если необходимо
    },
  },
}
</script>

<style lang="scss" src="./ProjectBalance/index.scss"></style>
