<template>
  <div class="loader-wrapper" :class="{'over-top': loading}">
    <div class="loader" v-if="loading">
      <div class="stripes">
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalLoader",
  props: {},
  data: () => ({
    loading: false,
  }),
  computed: {},
  methods: {
    showLoader() {
      this.loading = true
    },
    hideLoader() {
      this.loading = false
    }
  },
}
</script>

<style lang="scss">
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  .loader {
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}
.loader-wrapper.over-top {
  z-index: 1000;
}

.stripes {
  width: 50px;
  text-align: center;

  span {
    display: inline-block;
    width: 5px;
    height: 20px;
    background-color: $color-primary;
    margin-right: 3px;
  }

  span:nth-child(1) {
    animation: grow 1s ease-in-out infinite;
  }

  span:nth-child(2) {
    animation: grow 1s ease-in-out 0.15s infinite;
  }

  span:nth-child(3) {
    animation: grow 1s ease-in-out 0.30s infinite;
  }

  span:nth-child(4) {
    animation: grow 1s ease-in-out 0.45s infinite;
  }
}

@keyframes grow {
  0%, 100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}
</style>
