<template>
  <svg :viewBox="viewBox" :width="width" :height="height">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 19.5929C1 20.8419 2.03819 21.8538
        3.3196 21.8538H18.6804C19.9618 21.8538 21 20.8419 21 19.5929V9.71495C21 8.466 19.9618
        7.4541 18.6804 7.4541H1V19.5929ZM19.0906 14.6546C19.0906 15.7907 18.1457 16.7117
        16.9801 16.7117C15.8144 16.7117 14.8695 15.7907 14.8695 14.6546C14.8695 13.5185
        15.8144 12.5975 16.9801 12.5975C18.1457 12.5975 19.0906 13.5185 19.0906 14.6546Z"
        :fill="fill"
    />
    <path d="M13.4693 2.6329L2.33754 5.89477C2.11574 5.95976 2.16265 6.2867 2.39378
        6.2867H16.3126C16.4455 6.2867 16.5414 6.15941 16.5048 6.03162L15.9055 3.93965C15.6131
        2.916 14.5136 2.3263 13.4693 2.6329Z" :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 32},
    height: {type: [Number, String], default: 26},
    viewBox: {type: String, default: '0 0 32 26'},
    fill: {type: String, default: '#151515'}
  }
}
</script>
