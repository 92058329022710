<template>
  <svg :width="size" :height="size" :viewBox="viewBox" >
    <g>
      <path :stroke="stroke" :fill="stroke" d="M 13 16.171875 L 18.363281 10.808594 L 19.777344 12.222656 L 12 20 L 4.222656 12.222656 L 5.636719 10.808594 L 11 16.171875 L 11 4 L 13 4 Z M 13 16.171875 "/>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>