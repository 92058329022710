<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <path :fill="stroke" d="M3.6001 15.9995V1.99951H9.7401C10.5668 1.99951 11.2734 2.08618 11.8601 2.25951C12.4601 2.43285 12.9534 2.67951 13.3401 2.99951C13.7268 3.31951 14.0068 3.71285 14.1801 4.17951C14.3668 4.63285 14.4601 5.15285 14.4601 5.73951C14.4601 6.45951 14.2668 7.07951 13.8801 7.59951C13.5068 8.10618 13.0068 8.47285 12.3801 8.69951C13.2201 8.88618 13.8734 9.25951 14.3401 9.81951C14.8201 10.3795 15.0601 11.1195 15.0601 12.0395C15.0601 12.6395 14.9668 13.1862 14.7801 13.6795C14.5934 14.1595 14.2934 14.5728 13.8801 14.9195C13.4668 15.2662 12.9401 15.5328 12.3001 15.7195C11.6601 15.9062 10.8934 15.9995 10.0001 15.9995H3.6001ZM6.6201 13.6195H9.4601C9.95343 13.6195 10.3668 13.5795 10.7001 13.4995C11.0334 13.4062 11.3001 13.2862 11.5001 13.1395C11.7001 12.9795 11.8401 12.7862 11.9201 12.5595C12.0001 12.3328 12.0401 12.0662 12.0401 11.7595C12.0401 11.4528 11.9934 11.1862 11.9001 10.9595C11.8201 10.7195 11.6801 10.5195 11.4801 10.3595C11.2801 10.1995 11.0134 10.0795 10.6801 9.99951C10.3468 9.91951 9.93343 9.87951 9.4401 9.87951H6.6201V13.6195ZM6.6201 7.71951H9.1401C9.59343 7.71951 9.96676 7.67951 10.2601 7.59951C10.5534 7.51951 10.7868 7.40618 10.9601 7.25951C11.1334 7.11285 11.2534 6.93951 11.3201 6.73951C11.4001 6.53951 11.4401 6.30618 11.4401 6.03951C11.4401 5.77285 11.4001 5.53951 11.3201 5.33951C11.2534 5.12618 11.1334 4.95285 10.9601 4.81951C10.7868 4.68618 10.5534 4.57951 10.2601 4.49951C9.96676 4.41951 9.6001 4.37951 9.1601 4.37951H6.6201V7.71951Z" />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>