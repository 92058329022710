export default {
  filters: {
    rgbaToHex: function (rgb) {
      const rgbMatch = rgb.match(
        /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
      )

      return rgbMatch && rgbMatch.length === 4
        ? '#' +
            ('0' + parseInt(rgbMatch[1], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgbMatch[2], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgbMatch[3], 10).toString(16)).slice(-2)
        : rgb
    },
    isValidHex(hex) {
      return /^#[0-9A-F]{6}$/i.test(hex)
    },
  },
}
