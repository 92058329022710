<template>
  <v-main class="project-history-payment">
    <v-form>
      <v-container class="pt-9 pt-sm-10">
        <h1 class="title-h1 text-center mb-7 mb-sm-8 mb-md-3 mt-n4">
          {{ $t('projectHistoryPayment.payment-history') }}
        </h1>
        <label class="d-sr-only" for="letteros-payment-history-search">
          {{ $t('projectHistoryPayment.search') }}
        </label>
        <v-text-field
          id="letteros-payment-history-search"
          v-model="typeSearch"
          class="input-search flex-grow-0 mb-2"
          outlined
          :placeholder="$t('projectHistoryPayment.search')"
          prepend-inner-icon="mdi-magnify"
          @input="loadPaymentHistory(false)" />
        <div class="d-flex flex-wrap mb-4 mb-sm-5">
          <v-select
            v-model="operationType"
            class="payment-history-select mr-4"
            :items="operationTypes"
            item-text="label"
            item-value="value"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('projectHistoryPayment.type-of-operation')"
            outlined
            append-icon="mdi-chevron-down"
            @change="loadPaymentHistory" />
          <div class="payment-history-select btn-select">
            <v-menu offset-y nudge-bottom="4" :close-on-content-click="false">
              <template #activator="{ attrs, on }">
                <v-btn
                  class="px-4"
                  x-large
                  outlined
                  block
                  v-bind="attrs"
                  v-on="on">
                  <span class="flex-grow-1 text-left">
                    {{ $t('projectHistoryPayment.period') }}
                  </span>
                  <v-icon right size="25">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="paymentHistoryRanges"
                range
                first-day-of-week="1"
                color="primary"
                no-title
                width="530"
                :weekday-format="getDayOfWeek"
                show-adjacent-months
                @change="loadPaymentHistory" />
            </v-menu>
          </div>
          <!-- todo implement export -->
          <!-- <v-btn -->
          <!--     class="payment-history-export black-hover" -->
          <!--     :ripple="false" -->
          <!--     depressed -->
          <!--     outlined -->
          <!--     x-large -->
          <!-- > -->
          <!--   {{ $t('projectHistoryPayment.export') }} -->
          <!-- </v-btn> -->
        </div>
        <v-simple-table
          v-show="meta.totalCount"
          class="payment-history-table mb-n6">
          <thead>
            <tr>
              <th
                class="col-6 col-sm-4 font-weight-regular gray--text pb-6 pb-sm-10">
                {{ $t('projectHistoryPayment.the-amount') }}
              </th>
              <th
                class="col-4 font-weight-regular gray--text pb-6 pb-sm-10 d-none d-sm-table-cell">
                {{ $t('projectHistoryPayment.type-of-operation') }}
              </th>
              <th
                class="col-6 col-sm-4 font-weight-regular gray--text pb-6 pb-sm-10 text-right">
                {{ $t('projectHistoryPayment.date-and-time') }}
              </th>
            </tr>
          </thead>
          <tbody style="display: table-caption; caption-side: bottom">
            <tr
              v-for="(item, index) in paymentHistory"
              :key="index"
              class="d-flex d-sm-table-row flex-wrap">
              <td
                class="payment-history-table-amount col-12 col-sm-4 pb-sm-16 pr-5">
                <div
                  :class="{
                    'payment-history-amount-plus': item.amount > 0,
                    'payment-history-amount-minus': item.amount < 0,
                    'payment-history-amount-change': item.amount === 0,
                  }">
                  {{ item.amount | formatNumber }}
                  {{ $t('projectHistoryPayment.rub') }}
                </div>
              </td>
              <td class="col-6 col-sm-4 pb-10 pb-sm-16 pr-5">
                <div class="d-flex align-center">
                  {{ item.type }}
                </div>
              </td>
              <td class="col-6 col-sm-4 pb-10 pb-sm-16 text-right">
                {{ item.date }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div v-show="!meta.totalCount" class="title-h2 text-center">
          {{ $t('projectHistoryPayment.nothing-found') }}
        </div>
        <v-btn
          v-show="meta.totalCount > page * meta.perPage"
          class="btn-more mt-5 mb-12"
          :ripple="false"
          x-large
          depressed
          color="gray-lightest"
          @click="showMore">
          {{ $t('projectHistoryPayment.show-more') }}
        </v-btn>
        <v-row v-if="meta.pageCount > limit" justify="center">
          <v-col cols="8">
            <v-pagination
              v-model="page"
              class="my-4"
              :length="meta.pageCount"
              :total-visible="7"
              :next-aria-label="$t('projectHistoryPayment.forward')"
              :previous-aria-label="$t('projectHistoryPayment.back')"
              @input="loadPaymentHistory" />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-main>
</template>

<script>
import { getPaymentHistory } from '@/utilities/project'

export default {
  name: 'ProjectHistoryPayment',
  components: {},
  data() {
    return {
      page: 1,
      paymentHistoryRanges: null,
      paymentHistory: [],
      meta: {
        totalCount: 0,
        pageCount: 0,
        currentPage: 1,
        perPage: 0,
      },
      limit: 8,
      operationTypes: [
        {
          label: this.$t('projectHistoryPayment.replenishment-of-the-balance'),
          value: 'plus',
        },
        {
          label: this.$t('projectHistoryPayment.debiting-from-the-balance'),
          value: 'minus',
        },
        {
          label: this.$t('projectHistoryPayment.no-changes'),
          value: 'no-changes',
        },
      ],
      typeSearch: '',
      operationType: '',
    }
  },
  created() {
    this.loadPaymentHistory()
  },
  methods: {
    async loadPaymentHistory(loader = true) {
      if (loader) this.$loader('show')
      // [Переписать] вынести в стор
      const result = await getPaymentHistory(this.getFilterParams())
      // [Переписать] проработать ошибки запроса
      this.paymentHistory = result.items
      this.meta = result._meta
      this.$loader('hide')
    },
    async showMore() {
      this.$loader('show')
      this.page++
      // [Переписать] вынести в стор
      const result = await getPaymentHistory(this.getFilterParams())
      // [Переписать] проработать ошибки запроса
      for (const item of result.items) {
        this.paymentHistory.push(item)
      }

      this.meta = result._meta
      this.$loader('hide')
    },
    getFilterParams() {
      return {
        page: this.page,
        typeSearch: this.typeSearch,
        operationType: this.operationType,
        dateFrom: this.paymentHistoryRanges
          ? this.paymentHistoryRanges[0]
          : null,
        dateTo: this.paymentHistoryRanges ? this.paymentHistoryRanges[1] : null,
      }
    },
    getDayOfWeek(date) {
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
      let i = new Date(date).getDay()
      return daysOfWeek[i]
    },
  },
}
</script>

<style lang="scss">
.project-history-payment {
  .container {
    max-width: 730px;
  }
  .payment-history-select {
    width: 226px;

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0 !important;

      &.v-select {
        margin-top: -2px;
        margin-bottom: -14px;
      }

      &.btn-select {
        margin-bottom: 16px;
      }
    }
  }

  .payment-history-export {
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 17px;
    }
  }

  .payment-history-table {
    th,
    td {
      padding-right: 0 !important;
      padding-left: 0 !important;
      border: none !important;
    }

    @media (max-width: 767px) {
      table {
        table-layout: fixed;
      }
    }
  }

  .payment-history-table-amount {
    width: 120px;

    @media (max-width: 767px) {
      width: auto;
    }
  }

  .payment-history-amount-plus {
    color: $color-green;
  }

  .payment-history-amount-minus {
    color: $color-red;
  }

  .payment-history-amount-change {
    color: $color-yellow;
  }

  .theme--light.v-pagination .v-pagination__item {
    color: #a9a9a9;
    font-size: 18px;
    border-radius: 10px;
    box-shadow: 0 0 0;
    width: 36px;
    height: 36px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .theme--light.v-pagination .v-pagination__item--active {
    color: #151515;
    background: #eaeaea !important;
    border-color: #eaeaea !important;
    box-shadow: 0 0 0;
  }
  .theme--light.v-pagination .v-pagination__navigation {
    background: transparent;
    border-radius: 0;
    box-shadow: 0 0 0;
    width: auto;
    height: auto;
    margin-left: 40px;
    margin-right: 40px;
  }
  .theme--light.v-pagination
    .v-pagination__navigation
    .v-icon.mdi-chevron-left:before {
    font-size: 25px;
  }
  .theme--light.v-pagination
    .v-pagination__navigation
    .v-icon.mdi-chevron-left:after {
    content: 'Назад';
    color: #151515;
    height: auto;
    border-radius: 0;
    left: auto;
    position: relative;
    pointer-events: auto;
    opacity: 1;
    font-size: 18px;
    font-family: 'Golos Text', serif;
    top: auto;
    transform: scale(1);
    font-style: normal;
    background: transparent;
  }
  .theme--light.v-pagination
    .v-pagination__navigation
    .v-icon.mdi-chevron-right:before {
    content: 'Вперед';
    color: #151515;
    height: auto;
    border-radius: 0;
    left: auto;
    position: relative;
    pointer-events: auto;
    opacity: 1;
    font-size: 18px;
    font-family: 'Golos Text', serif;
    top: auto;
    transform: scale(1);
    font-style: normal;
  }
  .theme--light.v-pagination
    .v-pagination__navigation
    .v-icon.mdi-chevron-right:after {
    content: '\F0142';
    border-radius: 0;
    background-color: transparent;
    left: auto;
    top: auto;
    opacity: 1;
    pointer-events: auto;
    position: relative;
    transform: scale(1);
    width: auto;
    display: inline-block;
    font: normal normal normal 24px/1 'Material Design Icons';
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    font-size: 25px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .project-history-payment {
    .container {
      max-width: 580px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .payment-history-table tbody td {
    height: auto !important;
  }
}
</style>
