<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <rect x="0.5" y="0.5" width="10.1999" height="10.1999" rx="4.73256" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="0.5" y="13.2998" width="10.1999" height="10.1999" rx="4.73256" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="13.2998" y="0.5" width="10.1999" height="10.1999" rx="4.73256" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="13.2998" y="13.2998" width="10.1999" height="10.1999" rx="4.73256" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>