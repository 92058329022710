import { updateLetter, loadLetter } from '@/utilities/letter.js'
/* eslint-disable */

//Временные функции
const helpers = {
  // Используется при сохранение письма, калькуляция атрибутов аутлука
  calculateSizeForOutlookSupport() {
    const elementsNeedSupportOutlook = document.querySelectorAll(
      `[letteros-background-outlook], [letteros-outlook-btn]`
    )
    elementsNeedSupportOutlook.forEach((element) => {
      element.setAttribute('letteros-calculated-width', element.clientWidth)
      element.setAttribute('letteros-calculated-height', element.clientHeight)
    })
  },
  // Подготовка модулей перед сохранением в обход стора
  prepareModule(modules) {
    modules.forEach((module) => {
      const clone = helpers.clearModule(module.uuid)
      module.html = clone.innerHTML
    })

    return modules
  },
  clearModule(uuid) {
    const element = document.querySelector(
      `.editor-module[data-uuid="${uuid}"]`
    )
    let clone = element.querySelector('.module-code').cloneNode(true)

    // Очистка от атрибутов tinymce
    clone.querySelectorAll('.active-element').forEach((el) => {
      el.classList.remove('active-element')
    })
    clone.querySelectorAll('.mce-content-body').forEach((el) => {
      el.classList.remove('mce-content-body')
      el.removeAttribute('id')
    })
    clone.querySelectorAll('.mce-edit-focus').forEach((el) => {
      el.classList.remove('mce-edit-focus')
    })
    // Очистка изображений
    clone.querySelectorAll('[data-mce-style]').forEach((el) => {
      el.removeAttribute('data-mce-style')
      el.removeAttribute('data-mce-src')
    })
    // Очистка текстовых узлов
    clone.querySelectorAll('[letteros-type]').forEach((el) => {
      el.removeAttribute('contenteditable')
      el.removeAttribute('spellcheck')
      if (el.getAttribute('class') == '') el.removeAttribute('class')
    })
    // Очистка от системных атрибутов после letteros-page
    clone.querySelectorAll('[data-page-image-id]').forEach((el) => {
      el.removeAttribute('data-page-image-id')
    })
    clone.querySelectorAll('[data-page-text-id]').forEach((el) => {
      el.removeAttribute('data-page-text-id')
    })

    clone = helpers.unformatTextNodes(clone)

    return clone
  },
  // [Переписать] вырезано из module.mixin.js, объеденить (Там используется только при сохранение шаблона)
  unformatTextNodes(module) {
    module.querySelectorAll('[letteros-type]').forEach((el) => {
      el.removeAttribute('letteros-type')
      if (el.hasAttribute('letteros-remove-tag')) {
        const parent = el.parentNode
        const html = el.innerHTML
        el.remove()
        parent.innerHTML = html
      }
    })

    return module
  },
  getModulesPositionFromUI() {
    const elements = document.querySelectorAll(`.editor-module`)
    const result = []
    elements.forEach((el, ix) => {
      result.push({ uuid: el.dataset.uuid, position: ix })
    })

    return result
  },
}

const state = () => ({
  sidebarVisible: false,
  versionId: null,
  versionCount: null,
  letter: null,
  enableDarkTheme: false,
  workAreaDeviceType: 'desktop',
  isLetterSave: false,
})

const getters = {
  getLetterGrid(state) {
    return parseInt(state.letter.template.config.grid)
  },
  getTestApproval(state) {
    return state.letter.test_approval
  },
  getLetterID(state) {
    return state.letter.id
  },
  getLetterModules(state) {
    return state.letter.modules
  },
  getLetterModuleByUuid: (state) => (uuid) => {
    return state.letter.modules.find((module) => module.uuid == uuid)
  },
  getTemplateModules(state) {
    return state.letter.template.modulesGroups
  },
  getLetterModulesSize(state) {
    return state.letter.modules.length
  },
}

const mutations = {
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  showSidebar(state) {
    state.sidebarVisible = true
  },
  hideSidebar(state) {
    state.sidebarVisible = false
  },
  setLetter(state, payload) {
    state.letter = payload
  },
  updateModules(state, payload) {
    state.letter.modules = payload
  },
  updateModuleOptions(state, payload) {
    const module = state.letter.modules.find(
      (module) => module.uuid == payload.uuid
    )
    module.options = { ...module.options, ...payload.option }
  },
  cloneModule(state, payload) {
    state.letter.modules.splice(payload.index, 0, payload.module)
  },
  deleteModule(state, payload) {
    state.letter.modules = state.letter.modules.filter(
      (module) => module.uuid != payload.uuid
    )
  },
  addModule(state, payload) {
    state.letter.modules.push(payload.module)
  },
  toggleTestApproval(state) {
    state.letter.test_approval = !state.letter.test_approval
  },
  updateSharedStatus(state, payload) {
    state.letter.shared = payload
  },
  updateSetting(state, payload) {
    state.letter.name = payload.name
    state.letter.subject = payload.subject
    state.letter.preheader = payload.preheader
    state.letter.bgColor = payload.bgColor
    state.letter.settings = payload.settings
  },
  clearLetterSubject(state) {
    state.letter.subject = ''
  },
  clearLetterName(state) {
    state.letter.name = ''
  },
  clearLetterPreheader(state) {
    state.letter.preheader = ''
  },
  updateVersionId(state, payload) {
    state.versionId = payload
  },
  updateVersionCount(state, payload) {
    state.versionCount = payload
  },
  toggleDarkTheme(state) {
    state.enableDarkTheme = !state.enableDarkTheme
  },
  changeDeviceType(state, payload) {
    state.workAreaDeviceType = payload
  },
  addLetterEmoji(state, payload) {
    state.letter[payload.fieldName] += payload.value
  },
  updateLetterFields(state, payload) {
    state.letter[payload.fieldName] = payload.value
  },
  toggleLetterSave(state, payload) {
    state.isLetterSave = payload
  },
  updateModulesPosition(state) {
    const realPositions = helpers.getModulesPositionFromUI()
    state.letter.modules.forEach((module) => {
      module.position = realPositions.find(
        (item) => item.uuid == module.uuid
      ).position
    })
  },
  incrementPositionBeforeClone(state, currentPosition) {
    state.letter.modules.forEach((module) => {
      if (module.position >= currentPosition) {
        module.position++
      }
    })
  },
  sortModules(state) {
    state.letter.modules = state.letter.modules.sort(function (a, b) {
      let keyA = a.position,
        keyB = b.position

      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
  },
}

const actions = {
  async getLetter({ commit }, id) {
    const result = await loadLetter(id)
    commit('setLetter', result)
  },
  async updateLetterSettings({ state }, payload) {
    await updateLetter(state.letter.id, payload)
  },
  // [Переписать] в mixin [LATER]
  // Запускается при переходе со страницы редактора письма
  refreshModules({ state, commit }) {
    const modules = helpers.prepareModule(
      JSON.parse(JSON.stringify(state.letter.modules))
    )

    commit('updateModules', modules)
  },
  // Клонирование модуля // [Переписать] [LATER]
  cloneModule({ state, commit }, payload) {
    payload.module.html = helpers.clearModule(payload.oldUuid).innerHTML
    commit('incrementPositionBeforeClone', payload.module.position)
    commit('cloneModule', payload)
  },
  // Сохранение письма
  async saveLetter({ state, dispatch, commit }, payload) {
    // console.log(payload.autosave)
    try {
      commit('updateModulesPosition')
      // [Переписать] в mixin [LATER]
      helpers.calculateSizeForOutlookSupport()
      const modules = helpers.prepareModule(
        JSON.parse(JSON.stringify(state.letter.modules))
      )

      const data = {
        name: state.letter.name || 'Название',
        preheader: state.letter.preheader || 'Прехедер',
        subject: state.letter.subject || 'Тема',
        settings: state.letter.settings,
        modules: modules,
        template: state.letter.template?.id,
        updatedBy: state.letter.updatedBy?.id,
        bgColor: state.letter.bgColor,
        htmlWrapper: state.letter.htmlWrapper,
        shared: state.letter.shared,
      }
      await dispatch('updateLetterSettings', data)
      commit('toggleLetterSave', true)
      // if (!autosave) displayNotify(this.$t('letterLayout.letter-saved'))
      // return true
    } catch (e) {
      commit('toggleLetterSave', false)
      // if (!autosave)
      //   displayNotify(this.$t('letterLayout.letter-not-saved'), true)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
