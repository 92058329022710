var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"table-row"},[_c('td',{staticClass:"table-checkbox-wrapper table-checkbox-wrapper--analytics"},[_c('v-checkbox',{staticClass:"table-checkbox",attrs:{"value":_vm.$store.state.itemsStore.selected[_vm.index]},on:{"change":function($event){return _vm.$store.commit('itemsStore/changeSelected', {
          index: _vm.index,
          value: $event,
        })}}})],1),_c('td',{staticClass:"table-info"},[_c('input',{ref:`rename${_vm.index}`,staticClass:"name font-weight-medium",attrs:{"disabled":!(
          _vm.$store.state.tableStore.editMode === 'rename' &&
          _vm.$store.state.tableStore.currentEditId === _vm.item.id
        )},domProps:{"value":_vm.item.name},on:{"blur":_vm.renameHandler}})]),_c('td',{staticClass:"table-info"},[_vm._v(" "+_vm._s(_vm.item.subscribers || '?')+" ")]),_c('td',{staticClass:"table-info"},[_vm._v(" "+_vm._s(_vm.item.open)+" "),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.item.open_percent || '?')+"%")])]),_c('td',{staticClass:"table-info"},[_vm._v(" "+_vm._s(_vm.item.click)+" "),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.item.click_percent || '?')+"%")])]),_c('td',{staticClass:"table-action-menu"},[_c('ElementMenuAnalytics',{attrs:{"item":_vm.item}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }