<template>
  <div class="option-align">
    <label class="option__label">{{ optionName }}</label>
    <v-btn-toggle
      mandatory
      :value="alignData"
      class="d-flex mb-6 align-group"
      @change="updateAlign">
      <v-btn
        class="btn-small"
        depressed
        outlined
        value="left"
        width="33.3%"
        :ripple="false">
        <IconAlignLeft />
      </v-btn>
      <v-btn
        class="btn-small"
        depressed
        outlined
        value="center"
        width="33.3%"
        :ripple="false">
        <IconAlignCenter />
      </v-btn>
      <v-btn
        class="btn-small px-6"
        :ripple="false"
        depressed
        outlined
        value="right"
        width="33.3%">
        <IconAlignRight />
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import IconAlignLeft from '@/components/icons/IconAlignLeft.vue'
import IconAlignCenter from '@/components/icons/IconAlignCenter.vue'
import IconAlignRight from '@/components/icons/IconAlignRight.vue'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'OptionAlign',
  components: {
    IconAlignRight,
    IconAlignCenter,
    IconAlignLeft,
  },
  props: {
    element: [HTMLElement, null],
    label: String,
  },
  data() {
    return {
      alignData: '',
    }
  },
  computed: {
    optionName() {
      return this.label ? this.label : this.$t('optionAlign.label')
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.alignData = this.element.getAttribute('align')
    },
    updateAlign(value) {
      try {
        this.element.setAttribute('align', value)
      } catch (e) {
        displayNotify(this.$t('optionAlign.error'), 'error')
      }
    },
  },
}
</script>

<style lang="scss">
.align-group {
  .v-item--active {
    background: #001dff;
    color: #fff !important;
  }
}
</style>
