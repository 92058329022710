<template>
  <v-main class="letter-test">
    <div class="wrapper">
      <iframe ref="preview" frameborder="0"></iframe>
    </div>
  </v-main>
</template>

<script>
/* eslint-disable */
import dynamicStore from '@/mixins/LetterTest/dynamicStore.mixin.js'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'LetterTest',
  mixins: [dynamicStore],
  data() {
    return {
      gridWidth: 600, // Взять из стора [Переписать]
      gridWidthMobile: 375,
    }
  },
  computed: {
    pageType() {
      return this.$route.name == 'letter-custom-test' ? 'custom' : 'default'
    },
    device() {
      return this.pageType == 'custom'
        ? this.$store.state.customStore.workAreaDeviceType
        : this.$store.state.letter.workAreaDeviceType
    },
    html() {
      return this.$store.state.testStore.html
    },
    isHtmlLoaded() {
      return this.$store.state.testStore.isHtmlLoaded
    },
  },
  watch: {
    device() {
      this.toggleViewport()
    },
  },
  async created() {
    this.$loader('show')
    await this.$store.dispatch('testStore/getHTML', this.$route.params.id)
    if (this.isHtmlLoaded === true) this.insert()
    if (this.isHtmlLoaded === false) {
      displayNotify(this.$t('letterTest.loading-error'), 'error')
      this.$loader('hide')
    }
    this.$store.commit('testStore/clearHtmlLoaded')
  },
  methods: {
    insert() {
      const iframe = this.$refs['preview']
      iframe.contentWindow.document.open()
      iframe.contentWindow.document.write(this.html)
      iframe.contentWindow.document.close()

      iframe.onload = () => this.$loader('hide')
    },
    // Смена ширины содержимого iframe
    toggleViewport() {
      if (this.device == 'mobile') {
        this.$refs['preview'].style.maxWidth = '375px'
      } else {
        this.$refs['preview'].style.maxWidth = ''
      }
    },
  },
}
</script>

<style lang="scss">
.letter-test {
  position: relative;
  .wrapper {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    iframe {
      width: 100%;
      height: calc(100vh - 57px);
    }
  }
}
</style>
