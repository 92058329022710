<template>
  <v-main class="letter-preview">
    <div class="wrapper">
      <iframe ref="preview" frameborder="0"></iframe>
    </div>
  </v-main>
</template>

<script>
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'LetterPreview',
  computed: {
    html() {
      return this.$store.state.share.html
    },
    isHtmlLoaded() {
      return this.$store.state.share.isHtmlLoaded
    },
  },
  async created() {
    this.$loader('show')
    await this.$store.dispatch('share/getHTML', this.$route.params.id)
    if (this.isHtmlLoaded === true) this.insert()
    if (this.isHtmlLoaded === false) {
      displayNotify(this.$t('letterPreview.loading-error'), 'error')
      this.$loader('hide')
    }
    this.$store.commit('share/clearHtmlLoaded')
  },
  methods: {
    insert() {
      const iframe = this.$refs['preview']
      iframe.contentWindow.document.open()
      iframe.contentWindow.document.write(this.html)
      iframe.contentWindow.document.close()

      iframe.onload = () => this.$loader('hide')
    },
  }
}
</script>

<style lang="scss">
.letter-preview {
  position: relative;

  .wrapper {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    iframe {
      width: 100%;
      height: calc(100vh - 57px);
    }
  }
}
</style>
