<template>
  <div>
    <div class="option-link">
      <label class="option__label">{{ label }}</label>
      <v-text-field v-model="link" placeholder="" outlined dense />
    </div>
    <p class="gray--text mb-3 caption font-weight-light">
      {{ $t('optionVideo.upload-info') }}
    </p>
    <div class="option-element">
      <v-btn
        depressed
        block
        outlined
        class="btn-small btn-black-border"
        :ripple="false"
        @click="updateVideoLink">
        {{ $t('optionVideo.upload') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  getRutubePreview as loadRutubePreview,
  getVkPreview as loadVkPreview,
} from '@/utilities/letter'

export default {
  name: 'OptionVideo',
  props: {
    element: [HTMLElement, null],
    label: String,
  },
  computed: {
    link: {
      get() {
        return this.element.getAttribute('href')
      },
      set(value) {
        this.element.setAttribute('href', value)
      },
    },
  },
  methods: {
    async updateVideoLink() {
      if (!this.element.getAttribute('target'))
        this.element.setAttribute('target', '_blank')

      const urlObject = this.parseURL(this.link)
      const type = this.getVideoHostType(urlObject)
      const preview = await this.getPreview(this.link, type)
      const icon = this.element.querySelector('img')

      icon.src = this.getIcon(type)
      const videoBox = this.element.closest('.letteros-video-box')
      videoBox.style.backgroundImage = 'url(' + preview + ')'
    },
    async getPreview(url, type) {
      if (type === 'unknown') return '/img/videoPreview.png'
      if (type === 'youtube') return this.getYoutubePreview(url)
      if (type === 'vk') return await this.getVkPreview(url)
      if (type === 'rutube') return await this.getRutubePreview(url)
      // Функцию вызвали, но создать забыли :)
      // if (type === 'yandex') return this.getYandexPreview(url)
    },
    getYoutubePreview(url) {
      const videoId = this.youtubeParse(url)
      if (videoId) {
        return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
      } else return '/img/videoPreview.png'
    },
    async getVkPreview(url) {
      const videoId = this.vkParse(url)
      return await loadVkPreview(videoId)
    },
    async getRutubePreview(url) {
      const videoId = this.rutubeParse(url)
      return await loadRutubePreview(videoId)
    },
    //filter
    getIcon(type) {
      if (type === 'vk') return '/img/vk.png'
      if (type === 'rutube') return '/img/rutube.png'
      if (type === 'yandex') return '/img/yandex.png'
      return 'https://cdn.letteros.com/system/play.png'
    },
    //filter
    getVideoHostType(urlObject) {
      if (
        urlObject.host === 'www.youtube.com' ||
        urlObject.host === 'youtube.com'
      )
        return 'youtube'

      if (urlObject.host === 'rutube.ru' || urlObject.host === 'www.rutube.ru')
        return 'rutube'

      if (urlObject.host === 'vk.com' || urlObject.host === 'www.vk.com')
        return 'vk'

      return 'unknown'
    },
    //filter
    youtubeParse(url) {
      let regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      let match = url.match(regExp)
      return match && match[7].length == 11 ? match[7] : false
    },
    //filter
    rutubeParse(url) {
      let regExp = /rutube.ru\/video\/([^/?]*)/
      let match = url.match(regExp)
      return match ? match[1] : false
    },
    //filter
    vkParse(url) {
      // минус перед id видео берется не случайно, vk api хочет так
      let regExp = /vk.com\/video\?z=video(-[^?/&%]*)/
      let match = url.match(regExp)
      return match ? match[1] : false
    },
    //filter
    parseURL: function (url) {
      let parser = document.createElement('a'),
        searchObject = {},
        queries,
        split,
        i
      parser.href = url
      queries = parser.search.replace(/^\?/, '').split('&')
      for (i = 0; i < queries.length; i++) {
        split = queries[i].split('=')
        searchObject[split[0]] = split[1]
      }
      return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash,
      }
    },
  },
}
</script>
