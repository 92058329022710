<template>
  <header class="header-settings">
    <slot name="project-menu"></slot>
    <v-btn
      class="ml-auto mr-3 btn-black-border btn-header--exit"
      style="padding-bottom: 3px"
      depressed
      outlined
      :ripple="false"
      :to="{ name: 'letter-custom-editor', params: { id: $route.params.id } }">
      {{ $t('headerLetterSettings.exit') }}
    </v-btn>
    <v-btn
      style="padding-bottom: 3px"
      depressed
      color="primary"
      @click="saveSettings"
      :ripple="false">
      {{ $t('headerLetterSettings.save-settings') }}
    </v-btn>
  </header>
</template>

<script>
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'HeaderLetterCustomSettings',
  computed: {
    isLetterSave() {
      return this.$store.state.customStore.isLetterSave
    },
  },
  methods: {
    // Сохранение настроек письма
    async saveSettings() {
      await this.$store.dispatch('customStore/saveSettings')
      if (this.isLetterSave) {
        this.$router.push({
          name: 'letter-custom-editor',
          params: { id: this.$route.params.id },
        })
      } else {
        displayNotify(this.$t('letterLayout.letter-not-saved'), 'error')
      }
    },
  },
}
</script>

<style lang="scss">
</style>
