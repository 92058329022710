<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M9 17.1719C13.4657 17.1719 17.0859 13.5517 17.0859 9.08594C17.0859 4.6202 13.4657 1 9 1C4.53426 1 0.914062 4.6202 0.914062 9.08594C0.914062 13.5517 4.53426 17.1719 9 17.1719Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 9.08594V1C13.4351 1 17.0859 4.65086 17.0859 9.08594C17.0859 13.521 13.4351 17.1719 9 17.1719V9.08594Z" fill="#151515" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 18},
    height: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>