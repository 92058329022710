<template>
  <v-navigation-drawer
    v-bind:value="show"
    :hide-overlay="true"
    fixed
    stateless
    width="290"
    style="top: 57px"
    height="calc(100% - 57px)">
    <v-list class="sidebar-library">
      <v-list-item class="mt-6">
        <TestTypography />
      </v-list-item>
      <v-divider class="mb-6" />

      <v-list-item>
        <TestGrammar :document-id="documentId" />
      </v-list-item>
      <v-divider class="mb-8" />

      <v-list-item>
        <TestLink />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import TestTypography from '@/components/test/TestTypography.vue'
import TestLink from '@/components/test/TestLink.vue'
import TestGrammar from '@/components/test/TestGrammar.vue'

export default {
  name: 'SidebarLetter',
  components: { TestTypography, TestLink, TestGrammar },
  computed: {
    type() {
      return this.$route.name == 'letter-custom-editor' ? 'custom' : 'default'
    },
    show() {
      return this.type == 'custom'
        ? this.$store.state.customStore.sidebarVisible
        : this.$store.state.letter?.sidebarVisible
    },
    documentId() {
      return this.type == 'custom'
        ? this.$store.state.customStore.id
        : this.$store.state.letter?.letter.id
    },
  },
}
</script>

<style lang="scss">
.sidebar-library {
  .module-groups {
    padding-top: 10px;
    padding-bottom: 10px;
    .module-link {
      color: $color-dark;
      text-decoration: none;
    }
    .module-link.router-link-active {
      color: $color-primary;
    }
    .module-link:hover {
      color: $color-primary;
    }
  }

  .collapse-icon {
    margin-left: 1px;
    cursor: pointer;
    margin-top: 4px;
  }

  .collapse-icon::after {
    background-color: #fdfdfe;
  }

  .collapse-icon.clicked {
    transform: rotate(-180deg);
  }
}
</style>
