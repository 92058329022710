<template>
  <div class="range mb-12">
    <div ref="range" class="range-slider" />
  </div>
</template>

<script>
import noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css'

export default {
  name: 'RangeSlider',
  props: {
    range: Array,
  },
  data() {
    return {
      secondRange: [10, 20],
      rangeSlider: null,
    }
  },
  mounted() {
    this.initSlider()
  },
  beforeDestroy() {
    this.rangeSlider.off()
  },
  methods: {
    initSlider() {
      if (this.range) {
        this.secondRange = this.range
      }
      this.rangeSlider = noUiSlider.create(this.$refs.range, {
        start: this.secondRange,
        connect: [true, true, true],
        tooltips: [true, true],
        range: {
          min: 1,
          max: 30,
        },
        pips: {
          mode: 'steps',
          format: {
            to: function (value) {
              const strings = ['Min', 'Max']
              if (value < strings.length) {
                value = 0
              }
              if (value >= strings.length) {
                value = strings.length - 1
              }
              return strings[value]
            },
            from: String,
          },
        },
        format: {
          to: function (values) {
            return Math.round(values)
          },
          from: function (values) {
            return Math.round(values)
          },
        },
      })
      const $connects = this.$refs.range.querySelectorAll('.noUi-connect')
      const connectClasses = [
        'letter-settings-range-connect-red',
        'letter-settings-range-connect-yellow',
        'letter-settings-range-connect-green',
      ]
      $connects.forEach((item, index) => {
        item.classList.add(connectClasses[index])
      })
      const $handles = this.$refs.range.querySelectorAll('.noUi-handle')
      const handleClasses = [
        'letter-settings-range-handle-yellow',
        'letter-settings-range-handle-green',
      ]
      $handles.forEach((item, index) => {
        item.classList.add(handleClasses[index])
      })
      this.rangeSlider.on('update', (e) => {
        this.$emit('update-range', e)
      })
    },
  },
}
</script>

<style lang="scss">
.range {
  .noUi-connect {
    &.letter-settings-range-connect-red {
      background-color: $color-red;
    }

    &.letter-settings-range-connect-yellow {
      background-color: $color-yellow-lighten;
    }

    &.letter-settings-range-connect-green {
      background-color: $color-green;
    }
  }

  .noUi-handle {
    &.letter-settings-range-handle-yellow {
      background-color: $color-yellow-lighten;
    }

    &.letter-settings-range-handle-green {
      background-color: $color-green;
    }
  }
  .noUi-value-horizontal {
    margin-top: 15px;
  }

  .noUi-target {
    border: 0;
    box-shadow: 0 0 0;
  }
}
</style>
