<template>
  <v-form class="mb-14" @submit.prevent="updatePassword">
    <p class="title-h4">
      {{ $t('profilePassword.changing-the-password') }}
    </p>
    <LabelForRequiredField :content="$t('profilePassword.new-password')" />
    <v-text-field
      v-model="password"
      :placeholder="$t('profilePassword.come-up-with-a-password')"
      outlined
      type="password"
      :error="$v.password.$error" />
    <template v-if="$v.password.$error">
      <ValidateErrorHint :display="!$v.password.required" />
      <ValidateErrorHint
        field-name="password"
        error-type="minlen-8"
        :display="!$v.password.minLength" />
    </template>

    <LabelForRequiredField
      :content="$t('profilePassword.password-confirmation')" />
    <v-text-field
      v-model="passwordConfirm"
      :placeholder="$t('profilePassword.repeat-the-password')"
      outlined
      type="password" />
    <template v-if="$v.passwordConfirm.$error">
      <ValidateErrorHint
        field-name="password-confirm"
        error-type="same-as-password"
        :display="!$v.passwordConfirm.sameAs" />
    </template>
    <v-btn
      type="submit"
      class="user-settings-btn white--text px-7"
      :ripple="false"
      x-large
      depressed
      color="primary">
      {{ $t('profilePassword.change-password') }}
    </v-btn>
  </v-form>
</template>

<script>
import { minLength, required, sameAs } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

import LabelForRequiredField from '@/components/tools/LabelForRequiredField.vue'
import ValidateErrorHint from '@/components/tools/ValidateErrorHint.vue'

import { displayNotify } from '@/utilities/helpers.js'

export default {
  name: 'ProfilePassword',
  components: {
    ValidateErrorHint,
    LabelForRequiredField,
  },
  data() {
    return {
      password: '',
      passwordConfirm: '',
    }
  },
  computed: {
    ...mapGetters('userStore', {
      user: 'getUser',
    }),
  },
  methods: {
    async updatePassword() {
      this.$v.password.$touch()
      this.$v.passwordConfirm.$touch()
      if (this.$v.password.$invalid || this.$v.passwordConfirm.$invalid) return
      this.$loader('show')

      try {
        await this.$store.dispatch('userStore/changePassword', {
          userId: this.user.id,
          password: this.password,
        })
        displayNotify(this.$t('profilePassword.password-changed'))
      } catch (e) {
        displayNotify(this.$t('profilePassword.password-not-changed'), 'error')
      }
      this.$loader('hide')
    },
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    passwordConfirm: {
      sameAsPassword: sameAs('password'),
    },
  },
}
</script>
