<template>
  <header class="header-settings">
    <slot name="project-menu"></slot>
    <v-btn
      class="ml-auto mr-3 btn-black-border btn-header--exit"
      style="padding-bottom: 3px"
      depressed
      outlined
      :ripple="false"
      :to="{ name: 'letter-editor', params: { id: $route.params.id } }">
      {{ $t('headerLetterSettings.exit') }}
    </v-btn>
    <v-btn
      style="padding-bottom: 3px"
      depressed
      color="primary"
      @click="saveSettings"
      :ripple="false">
      {{ $t('headerLetterSettings.save-settings') }}
    </v-btn>
  </header>
</template>

<script>
export default {
  name: 'HeaderLetterSettings',
  methods: {
    // Сохранение настроек письма
    async saveSettings() {
      try {
        const formData = {
          name: this.$store.state.letter.letter.name || 'Название',
          subject: this.$store.state.letter.letter.subject || 'Тема',
          preheader: this.$store.state.letter.letter.preheader || 'Прехедер',
          bgColor: this.$store.state.letter.letter.bgColor,
          settings: this.$store.state.letter.letter.settings,
        }
        await this.$store.dispatch('letter/updateLetterSettings', formData)
        // [Переписать] проработать ошибки [NEW_API]
        this.$router.push({
          name: 'letter-editor',
          params: { id: this.$store.state.letter.letter.id },
        })
      } catch (e) {
        console.log('save Settings error', e)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
