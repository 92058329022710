<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M20.1701 22.9999H3.82119C2.26165 22.9999 1 21.7204 1 20.1388V6.24217C1 4.66059 2.26165 3.3811 3.82119 3.3811H20.1788C21.7384 3.3811 23 4.66059 23 6.24217V20.1477C22.9912 21.7204 21.7296 22.9999 20.1701 22.9999Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 8.17944H22.9912" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.23926 5.63813V1" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.3228 5.63813V1" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>