export const fontSizeData = [
  '8px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '30px',
  '32px',
  '34px',
]

export const lineHeightData = [
  '1',
  '1.1',
  '1.2',
  '1.3',
  '1.4',
  '1.5',
  '1.6',
  '1.7',
  '1.8',
  '1.9',
  '2',
  '2.1',
  '2.2',
  '2.3',
]

export const buttonTextData = [
  'Текст кнопки',
  'Купить',
  'Заказать',
  'Получить',
  'Забрать',
  'Выбрать',
  'Хочу!',
  'То что надо!',
  'Беру!',
  'Вау!',
  'Мне нравится',
  'Отлично!',
  'Уже хочу',
  'Покупаю',
  'Убедили, беру!',
  'Подробнее',
  'Узнать подробности',
  'В корзину',
  'Добавить в корзину',
  'Сразу в корзину',
  'Купить сразу',
  'Без лишних слов',
  'К покупкам',
  'Сделать заказ',
  'Создать заказ',
  'Ни слова больше!',
  'Заинтересовали',
  'Оплатить и получить',
  'К оплате',
  'Забрать себе',
  'Приобрести',
  'Тык',
  'Забираю',
  'Заверните',
  'Это мне нужно',
  'Ого!',
  'Посмотреть',
  'Перейти',
  'Больше предложений',
  'Уже бегу',
  'Добавить',
  'Добавить в заказ',
  'На шопинг',
  'Нужно брать',
  'Побаловать себя',
  'Подарить себе',
  'Оформить',
  'Это мне подходит',
  '+ 1 в корзину',
  'Хочу больше',
  'Это интересно',
  'Заинтриговали',
  'Положить в корзину',
  'Куда платить?',
  'Это по мне!',
  'Попробовать',
  'Оплатить',
  'Сэкономить',
  'Дайте два',
  'Я давно это ищу',
  'К корзине',
  'Смотреть',
  'Ухватить',
  'Изучить',
  'К предложениям',
  'На сайт',
  'Посмотреть подборку',
  'К подборке',
  'В магазин',
  'В приложение',
  'Читать',
  'Вдохновиться',
  'За вдохновением',
  'За приключениями',
  'Завершить покупку',
  'Воспользоваться',
  'Купить со скидкой',
  'Купить сейчас',
  'Зарегистрироваться',
  'Смотреть все товары',
  'Все предложения',
  'Успеть сэкономить',
  'За выгодой',
  'К выгодной покупке',
  'Оценить',
  'Это мне нравится',
  'Иду смотреть',
  'К вариантам',
  'За новинками',
  'Что-то новенькое',
  'Обновиться',
  'Успеть купить',
  'Использовать',
  'Удивиться',
  'К товарам',
  'Посмотреть всё',
  'Участвовать',
  'За подробностями',
  'Хорошие новости',
  'Узнать больше',
  'Заказать доставку',
]
