<template>
  <v-main class="letter-settings">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="9" sm="10" cols="12">
          <h1 class="title-h1 text-center mb-n2">
            {{ $t('letterSettings.letter-settings') }}
          </h1>
          <v-expansion-panels
            accordion
            flat
            tile
            class="letter-settings-items"
            multiple
            :value="expansionPanelStatus">
            <v-expansion-panel class="accordion-item">
              <v-expansion-panel-header class="font-weight-medium">
                {{ $t('letterSettings.general-settings') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <label class="d-sr-only">
                  {{ $t('letterSettings.the-name-of-the-letter') }}
                </label>
                <v-text-field
                  v-model="name"
                  outlined
                  :placeholder="$t('letterSettings.the-name-of-the-letter')"
                  @change="updateSettings" />
                <div style="display: flex">
                  <label class="d-sr-only">
                    {{ $t('letterSettings.subject') }}
                  </label>
                  <v-text-field
                    v-model="subject"
                    outlined
                    :placeholder="$t('letterSettings.subject')"
                    @change="updateSettings" />
                  <v-btn
                    class="btn-small black-hover ml-2"
                    style="height: 56px"
                    :ripple="false"
                    outlined
                    link
                    large
                    @click="generateRandomSubjectShow = true">
                    {{ $t('letterSettings.generate-text') }}
                  </v-btn>
                </div>
                <label class="d-sr-only">
                  {{ $t('letterSettings.preheader') }}
                </label>
                <v-text-field
                  v-model="preheader"
                  class="mb-n2"
                  outlined
                  :placeholder="$t('letterSettings.preheader')"
                  @change="updateSettings" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="accordion-item">
              <v-expansion-panel-header class="font-weight-medium">
                {{ $t('letterSettings.style') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="font-weight-medium mb-3">
                  {{ $t('letterSettings.background-color') }}
                </p>
                <v-text-field
                  v-model="bgColor"
                  class="option__value"
                  placeholder=""
                  outlined
                  @change="updateSettings">
                  <template v-slot:prepend-inner>
                    <v-btn
                      icon
                      plain
                      :color="bgColor"
                      @click="showPicker = !showPicker">
                      <IconCircle :stroke="bgColor" />
                    </v-btn>
                  </template>
                </v-text-field>
                <div class="d-flex">
                  <v-checkbox
                    v-model="settings.madeInLetteros"
                    class="mb-0"
                    :disabled="freeTariff"
                    :label="$t('letterSettings.made-in-letters')"></v-checkbox>
                  <v-menu
                    content-class="tooltip tooltip-white tooltip-arrow-bottom"
                    open-on-hover
                    top
                    offset-y
                    max-width="316"
                    nudge-left="143"
                    nudge-top="4"
                    close-delay="10"
                    transition="scale-transition"
                    origin="center center">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pa-2 align-self-start"
                        size="14"
                        v-bind="attrs"
                        v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    {{
                      $t('letterSettings.you-can-disable-the-made-in-letteros')
                    }}
                    <a href="#">
                      {{ $t('letterSettings.to-change-the-tariff') }}?
                    </a>
                  </v-menu>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="accordion-item">
              <v-expansion-panel-header class="font-weight-medium">
                {{ $t('letterSettings.export-settings') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex mb-1">
                  <v-checkbox
                    v-model="settings.minify"
                    :label="$t('letterSettings.compress-the-code')"
                    @change="updateSettings" />
                  <v-tooltip
                    content-class="tooltip tooltip-white tooltip-arrow-bottom"
                    top
                    max-width="316"
                    nudge-bottom="7">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="pa-2 align-self-start"
                        size="14"
                        v-bind="attrs"
                        v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    {{ $t('letterSettings.compress-the-code-tooltip') }}
                  </v-tooltip>
                </div>
                <div class="d-flex mb-1">
                  <v-checkbox
                    v-model="settings.tinify"
                    :label="$t('letterSettings.compress-images')"
                    @change="updateSettings" />
                  <v-tooltip
                    content-class="tooltip tooltip-white tooltip-arrow-bottom"
                    top
                    max-width="316"
                    nudge-bottom="7">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="pa-2 align-self-start"
                        size="14"
                        v-bind="attrs"
                        v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    {{ $t('letterSettings.compress-images-tooltip') }}
                  </v-tooltip>
                </div>
                <div class="d-flex mb-1">
                  <v-checkbox
                    v-model="settings.outlookDisabled"
                    :label="
                      $t('letterSettings.disable-optimization-for-outlook')
                    "
                    @change="updateSettings" />
                  <v-tooltip
                    content-class="tooltip tooltip-white tooltip-arrow-bottom"
                    top
                    max-width="316"
                    nudge-bottom="7">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="pa-2 align-self-start"
                        size="14"
                        v-bind="attrs"
                        v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    {{
                      $t(
                        'letterSettings.disable-optimization-for-outlook-tooltip'
                      )
                    }}
                  </v-tooltip>
                </div>
                <div class="d-flex mb-1">
                  <v-checkbox
                    v-model="settings.dontOptimizeLinks"
                    :label="$t('letterSettings.don-t-optimize-links')"
                    @change="updateSettings" />
                  <v-tooltip
                    content-class="tooltip tooltip-white tooltip-arrow-bottom"
                    top
                    max-width="316"
                    nudge-bottom="7">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="pa-2 align-self-start"
                        size="14"
                        v-bind="attrs"
                        v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    {{ $t('letterSettings.don-t-optimize-links-tooltip') }}
                  </v-tooltip>
                </div>
                <div class="d-flex mb-6">
                  <v-checkbox
                    v-model="settings.analyticShow"
                    color="black"
                    :label="$t('letterSettings.email-analytics')" />
                  <v-tooltip
                    content-class="tooltip tooltip-white tooltip-arrow-bottom"
                    top
                    max-width="316"
                    nudge-bottom="7">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="pa-2 align-self-start"
                        size="14"
                        v-bind="attrs"
                        v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    {{ $t('letterSettings.email-analytics-tooltip') }}
                  </v-tooltip>
                </div>

                <template v-if="settings.analyticShow">
                  <label class="label font-weight-regular">
                    {{ $t('letterSettings.approximate-number-of-recipients') }}
                  </label>
                  <v-text-field
                    class="mb-n2"
                    outlined
                    placeholder="100"
                    v-model.number="settings.analyticRecipients"
                    @change="updateSettings" />
                  <p class="mb-8">
                    {{
                      $t(
                        'letterSettings.set-subscriber-engagement-thresholds-in-seconds'
                      )
                    }}
                    <v-tooltip
                      content-class="tooltip tooltip-white tooltip-arrow-bottom"
                      top
                      max-width="316"
                      nudge-bottom="7">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="mt-n3 ml-n1 pa-2"
                          size="14"
                          v-bind="attrs"
                          v-on="on">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      {{
                        $t(
                          'letterSettings.set-subscriber-engagement-thresholds-in-seconds-tooltip'
                        )
                      }}
                    </v-tooltip>
                  </p>
                  <RangeSlider
                    :range="settings.analyticRange"
                    @update-range="rangeHandler" />
                  <ul class="letter-settings-legend">
                    <li class="letter-settings-legend-red">
                      {{ $t('letterSettings.disinterested') }}
                    </li>
                    <li class="letter-settings-legend-yellow">
                      {{ $t('letterSettings.reading') }}
                    </li>
                    <li class="letter-settings-legend-green">
                      {{ $t('letterSettings.involved') }}
                    </li>
                  </ul>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="accordion-item">
              <v-expansion-panel-header class="font-weight-medium">
                {{ $t('letterSettings.links-settings') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <label class="d-sr-only">utm_source</label>
                <v-text-field
                  outlined
                  placeholder="utm_source"
                  v-model="settings.utmSource"
                  @change="updateSettings" />
                <label class="d-sr-only">utm_medium</label>
                <v-text-field
                  outlined
                  placeholder="utm_medium"
                  v-model="settings.utmMedium"
                  @change="updateSettings" />
                <label class="d-sr-only">utm_campaign</label>
                <v-text-field
                  outlined
                  placeholder="utm_campaign"
                  v-model="settings.utmCampaign"
                  @change="updateSettings" />
                <label class="d-sr-only">
                  {{ $t('letterSettings.individual-get-parameters') }}
                </label>
                <v-text-field
                  outlined
                  :placeholder="$t('letterSettings.individual-get-parameters')"
                  v-model="settings.utmCustom"
                  @change="updateSettings" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="generateRandomSubjectShow" max-width="600">
      <GenerateRandomSubject
        @save="updateSubjectAndPreheader"
        @close="generateRandomSubjectShow = false" />
    </v-dialog>
    <div class="color-picker__container">
      <ColorPicker
        class="color-picker__item"
        v-if="showPicker"
        v-click-outside="outsideColorPickerHandler"
        :label="$t('letterSettings.background-color')"
        :color="bgColor"
        @close="showPicker = false"
        @update="updateColor" />
    </div>
  </v-main>
</template>

<script>
import IconCircle from '@/components/icons/IconCircle.vue'

import ColorPicker from '@/components/tools/ColorPicker.vue'
import RangeSlider from '@/components/RangeSlider.vue'
import GenerateRandomSubject from '@/components/tools/GenerateRandomSubject.vue'

export default {
  name: 'LetterSettings',
  components: { GenerateRandomSubject, RangeSlider, IconCircle, ColorPicker },
  data() {
    return {
      name: '',
      subject: '',
      preheader: '',
      bgColor: '#FFFFFF',
      settings: null,
      hasBackgroundImage: true,
      showPicker: false,
      expansionPanelStatus: [0, 1, 2, 3],
      generateRandomSubjectShow: false,
    }
  },
  computed: {
    project() {
      return this.$store.state.project.full
    },
    freeTariff() {
      return this.project?.tarif?.id === 5
    },
    isLetterLoaded() {
      if (this.type == 'custom') {
        return this.$store.state.customStore.isLetterLoaded
      } else {
        return false
      }
    },
    type() {
      return this.$route.name == 'letter-custom-settings' ? 'custom' : 'default'
    },
  },
  async created() {
    // Проверка isLetterLoaded нужна, так как загрузка может произойти на странице редактора
    if (this.type == 'custom' && !this.isLetterLoaded) {
      this.$loader('show')
      await this.$store.dispatch('customStore/getLetter', this.$route.params.id)
      // [Переписать] проработать ошибки [LATER]
      this.$loader('hide')
    }
    this.getData()
  },
  methods: {
    getData() {
      this.name =
        this.type == 'custom'
          ? this.$store.state.customStore.name
          : this.$store.state.letter.letter.name
      this.subject =
        this.type == 'custom'
          ? this.$store.state.customStore.subject
          : this.$store.state.letter.letter.subject
      this.preheader =
        this.type == 'custom'
          ? this.$store.state.customStore.preheader
          : this.$store.state.letter.letter.preheader
      this.bgColor =
        this.type == 'custom'
          ? this.$store.state.customStore.bgColor
          : this.$store.state.letter.letter.bgColor || '#FFFFFF'
      // Почему-то на бэке дублируются поля name,subject,preheader,bgcolor внутри settings [Переписать] [NEW_API]
      this.settings =
        this.type == 'custom'
          ? this.$store.state.customStore.settings
          : this.$store.state.letter.letter.settings
    },
    updateColor(data) {
      this.bgColor = data.color
      this.updateSettings()
    },
    rangeHandler(e) {
      this.settings.analyticRange = e
      this.updateSettings()
    },
    updateSubjectAndPreheader(event) {
      this.subject = event.subject
      this.preheader = event.preheader
      this.updateSettings()
      this.generateRandomSubjectShow = false
    },
    updateSettings() {
      const data = {
        name: this.name,
        subject: this.subject,
        preheader: this.preheader,
        bgColor: this.bgColor,
        settings: this.settings,
      }

      if (this.type == 'custom') {
        this.$store.commit('customStore/updateSetting', data)
      } else {
        this.$store.commit('letter/updateSetting', data)
      }
    },
    // Закрытие колорпикера
    outsideColorPickerHandler(e) {
      if (!this.showPicker) return false

      const target = e.target.closest('.color-picker__item')

      if (!target) {
        this.showPicker = false
      }
    },
  },
}
</script>

<style lang="scss">
.letter-settings {
  .accordion-item {
    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-lighten;
    }

    .v-expansion-panel-header {
      transition: margin-bottom 0.3s;
    }
  }

  .letter-settings-legend {
    display: flex;
    flex-wrap: wrap;
    //margin: 0 0 48px;
    padding: 0 !important;
    list-style: none;
    font-size: 18px;
    line-height: 1.4;

    li {
      position: relative;
      padding: 0 0 0 14px;

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    &-red {
      &::before {
        background-color: $color-red;
      }
    }

    &-yellow {
      &::before {
        background-color: $color-yellow-lighten;
      }
    }

    &-green {
      &::before {
        background-color: $color-green;
      }
    }
  }
  .color-picker__container .quad {
    width: 22px;
    height: 22px;
    border-radius: 8px;
    border: 1px solid black;
    margin-right: 5px;
    position: relative;
    top: -1px;
  }

  .color-picker {
    &__item {
      position: fixed;
      top: 50%;
      left: 15%;
      transform: translate(-50%, -50%);
      z-index: 15;
    }

    &__container {
      position: relative;
    }
  }
}
</style>
