<template>
  <div class="option-timer">
    <div class="option-cell mb-3">
      <label class="option__label">Количество колонок</label>
      <v-select
        v-model="columns"
        :attach="'.option-timer'"
        :items="$options.columns"
        solo
        item-text="name"
        dense
        flat
        outlined
        :hide-details="true"
        @change="changeColumns" />
    </div>
    <div class="option-border-radius">
      <label class="option__label">Соотношение колонок, %</label>
      <v-row no-gutters class="mr-n3">
        <v-col v-for="(column, idx) in columns" :key="idx" cols="4">
          <v-text-field
            id="letter-editor-sidebar-border-radius"
            v-model="columns[idx]"
            class="mr-3 mb-n4"
            outlined
            dense />
        </v-col>
      </v-row>
    </div>
    <div class="option-cell d-flex align-center mb-3">
      <v-checkbox v-model="isImg" :false-value="0" :true-value="1" />
      <label class="">Сделать картинкой</label>
    </div>
  </div>
</template>

<script>
// [Переписать] отрефакторить после структур
export default {
  name: 'OptionColumnsCount',
  // [Пререписать]
  columns: [
    { value: '1', name: '1 колонка' },
    { value: '2', name: '2 колонки' },
    { value: '3', name: '3 колонки' },
    { value: '4', name: '4 колонки' },
  ],
  data() {
    return {
      isImg: false,
    }
  },
  computed: {
    columns() {
      return [...Array(this.moduleData.options.columns).keys()].map(() =>
        parseInt(100 / this.moduleData.options.columns)
      )
    },
    moduleData() {
      return this.$store.getters['letter/getLetterModuleByUuid'](
        this.$store.state.editor.selectedModuleUuid
      )
    },
  },
  methods: {
    changeColumns() {},
  },
}
</script>

<style lang="scss">
.option-timer {
  .timer-picker {
    width: 100%;
  }

  .option-cell {
    .v-input--selection-controls.v-input--checkbox {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
