import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import 'simple-notify/dist/simple-notify.min.css'
import pkgInfo from './../../package.json'
import {displayNotify, stringHasOneOfTheseElements} from '../utilities/helpers'
import {getJwtToken, refreshToken} from '../utilities/user'
import router from '../router'
Vue.use(VueAxios, axios)
const { version } = pkgInfo

/*
  Sometimes you don't want to display default error message
  e.g.: you want to display customised one in some other part of app
  I use error message for this black list because error codes are too common
 */
const errorsStopList = [
  'Mailganner notify driver',
  'Template upload error'
]

const instance = axios.create({
  baseURL:
    (window.apiBaseUrl ??
      `${window.apiBaseUrl ?? process.env.VUE_APP_BACKEND_HOST}/api`) ||
    window.location.origin,
  headers: {
    'X-Client-SPA': 1.11,
    'Content-Type': 'application/json',
    'X-API-Version': version,
  },
  responseType: 'json',
  timeout: 0,
})

instance.interceptors.request.use(
  async function (request) {
    if (request.url.indexOf('refresh-token') !== -1) {
      request.headers.common['Refresh'] = localStorage.getItem('refresh')
    } else {
      request.headers.common['Authorization'] = await getJwtToken()
      // todo put in jwt
      request.headers.common['X-Account-ID'] = localStorage.getItem('account_id')
    }

    return request
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response?.status === 401) {
      try {
        if (
          localStorage.getItem('refreshTokenInProcess') === 'false'
          || localStorage.getItem('refreshTokenInProcess') === null
        ) {
          // get new jwt + refresh pair
          await refreshToken()
        }

        error.config.headers['Authorization'] = await getJwtToken()

        // redo request with new token
        return axios.request(error.config)
      } catch (e) {
        // in case of fail refresh
        await router.push('/login')
      }
    } else {
      if (!stringHasOneOfTheseElements(error.response.data?.message, errorsStopList)) {
        displayNotify(error.response.data?.message, 'error')
      }
    }
    return Promise.reject(error)
  }
)



export default instance
