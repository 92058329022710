<template>
  <v-form class="mb-14" @submit.prevent="saveUser">
    <!-- Загрузка аватара -->
    <div class="file user-settings-file text-center">
      <ImageUploader
        ref="imageUploader"
        document-type="user"
        immediately
        :placeholder-image="avatarUrl"
        @file-uploaded="uploadFileHandler">
        <v-btn
          class="mt-6 black-hover"
          :ripple="false"
          depressed
          outlined
          x-large
          @click="$refs.imageUploader.$refs.fileInput.click()">
          {{ $t('profileBasic.upload-an-image') }}
        </v-btn>
      </ImageUploader>

      <p class="file-note gray--text mt-3 mb-6">
        {{ $t('profileBasic.acceptable-file-formats') }}
      </p>
    </div>

    <!-- Поля формы -->
    <LabelForRequiredField :content="$t('profileBasic.firstname')" />
    <v-text-field
      v-model="userForm.firstname"
      :placeholder="$t('profileBasic.firstname')"
      outlined
      :error="$v.userForm.firstname.$error" />
    <template v-if="$v.userForm.firstname.$error">
      <ValidateErrorHint :display="!$v.userForm.firstname.required" />
    </template>

    <LabelForRequiredField :content="$t('profileBasic.lastname')" />
    <v-text-field
      v-model="userForm.lastname"
      :placeholder="$t('profileBasic.lastname')"
      outlined
      :error="$v.userForm.lastname.$error" />
    <template v-if="$v.userForm.lastname.$error">
      <ValidateErrorHint :display="!$v.userForm.lastname.required" />
    </template>

    <LabelForRequiredField :content="$t('profileBasic.email')" />
    <v-text-field
      v-model="userForm.email"
      placeholder="email@mail.com"
      outlined
      disabled
      :error="$v.userForm.email.$error" />
    <template v-if="$v.userForm.email.$error">
      <ValidateErrorHint :display="!$v.userForm.email.required" />
      <ValidateErrorHint
        field-name="email"
        error-type="email"
        :display="!$v.userForm.email.email" />
    </template>

    <LabelForRequiredField :content="$t('profileBasic.phone')" />
    <v-text-field
      v-model="userForm.phone"
      v-mask="'+7 (###) ### ## ##'"
      placeholder="+7 (999) 999 99 99"
      outlined
      :error="$v.userForm.phone.$error" />
    <template v-if="$v.userForm.phone.$error">
      <ValidateErrorHint :display="!$v.userForm.phone.required" />
    </template>

    <v-checkbox
      v-model="userForm.subscribeNews"
      class="user-settings-checkbox checkbox-small mb-5"
      :label="$t('profileBasic.subscribe-accept')" />
    <v-btn
      type="submit"
      class="user-settings-btn white--text px-8"
      :ripple="false"
      x-large
      depressed
      color="primary">
      {{ $t('profileBasic.save') }}
    </v-btn>
  </v-form>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

import ImageUploader from '@/components/tools/ImageUploader.vue'
import LabelForRequiredField from '@/components/tools/LabelForRequiredField.vue'
import ValidateErrorHint from '@/components/tools/ValidateErrorHint.vue'

import { displayNotify } from '@/utilities/helpers.js'

export default {
  name: 'ProfileBasic',
  components: {
    ImageUploader,
    LabelForRequiredField,
    ValidateErrorHint,
  },
  data() {
    return {
      userForm: {
        id: null,
        avatar: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        subscribeNews: false,
      },
    }
  },
  computed: {
    ...mapGetters('userStore', {
      user: 'getUser',
    }),
    avatarUrl() {
      return this.userForm.avatar || '/icons/userLarge.svg'
    },
  },
  created() {
    this.userForm = this.user
  },
  methods: {
    async uploadFileHandler(e) {
      this.userForm.avatar = e.url
      await this.saveUser()
    },
    async saveUser() {
      this.$v.userForm.$touch()
      if (this.$v.userForm.$invalid) return
      this.$loader('show')

      try {
        await this.$store.dispatch('userStore/updateUser', {
          id: this.userForm.id,
          data: this.userForm,
        })
        displayNotify(this.$t('profileBasic.user-updated'))
      } catch (e) {
        displayNotify(this.$t('profileBasic.user-not-updated'), 'error')
      } finally {
        this.$loader('hide')
      }
    },
  },
  validations: {
    userForm: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      phone: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },
}
</script>
