import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewareAuth from './middlewareAuth.js'
import middlewareSignup from './middlewareSignup.js'
// import middlewareAccountChange from './middlewareAccountChange.js'
// import middlewareSaveRoute from './middlewareSaveRoute.js'
import middlewareRedirect from './middlewareRedirect.js'

// Layouts list
import LayoutRegistration from '../layouts/LayoutRegistration.vue'
import LayoutMain from '../layouts/LayoutMain.vue'
import LayoutProject from '../layouts/LayoutProject.vue'
import LayoutTemplate from '../layouts/LayoutTemplate.vue'
import LayoutLetter from '../layouts/LayoutLetter.vue'
import LayoutCustom from '../layouts/LayoutCustom.vue'
import LayoutShare from '../layouts/LayoutShare.vue'
import LayoutAnalytics from '../layouts/LayoutAnalytics.vue'
import LayoutAnalyticsMap from '../layouts/LayoutAnalyticsMap.vue'

// Headers main route
import HeaderBase from '../components/header/HeaderBase.vue'
// Headers letter route
import HeaderLetterEditor from '../components/header/HeaderLetterEditor.vue'
import HeaderLetterSettings from '../components/header/HeaderLetterSettings.vue'
import HeaderLetterTest from '../components/header/HeaderLetterTest.vue'
import HeaderLetterRenders from '../components/header/HeaderLetterRenders.vue'
// Headers custom-letter route
import HeaderLetterCustomEditor from '../components/header/HeaderLetterCustomEditor.vue'
import HeaderLetterCustomCreate from '../components/header/HeaderLetterCustomCreate.vue'
import HeaderLetterCustomSettings from '../components/header/HeaderLetterCustomSettings.vue'
import HeaderLetterCustomTest from '../components/header/HeaderLetterCustomTest.vue'
// Headers template route
import HeaderTemplate from '../components/header/HeaderTemplate.vue'
import HeaderTemplateEditor from '../components/header/HeaderTemplateEditor.vue'
import HeaderLetterSave from '../components/header/HeaderLetterSave.vue'
// Headers share route
import HeaderPreview from '../components/header/HeaderPreview.vue'

// Sidebars list
import SidebarMain from '../components/sidebars/SidebarMain.vue' //Основной сайдбар (управление папками, письмами, шаблонами)
import SidebarLetter from '../components/sidebars/SidebarLetter.vue' //Сайдбар редактора писем (фильтры для текста)
import SidebarLibrary from '../components/sidebars/SidebarLibrary.vue' //Сайдбар модулей письма
import SideBarTest from '../components/sidebars/SideBarTest.vue' //Сайдбар с отправкой тестовых писем

// Registration View
import Login from '../views/login_registration/Login.vue'
import Signup from '../views/login_registration/Signup.vue'
import SignupComplete from '../views/login_registration/SignupComplete.vue'
import Forgot from '../views/login_registration/Forgot.vue'
import Recovery from '../views/login_registration/Recovery.vue'

// Main View
import Home from '../views/Home.vue' //Связывающая страница для большинства дочерних роутов в /
import UserProfile from '../views/UserProfile.vue' //Страница профиля пользователя
import LetterCreate from '../views/letter/LetterCreate.vue' //Страница создания письма по шаблону

// Project View
import ProjectCreate from '../views/project/ProjectCreate.vue'
import ProjectView from '../views/project/ProjectView.vue'
import ProjectIntegrations from '../views/project/ProjectIntegrations.vue'
import ProjectTariff from '../views/project/ProjectTariff.vue'
import ProjectBalance from '../views/project/ProjectBalance.vue'
import ProjectHistoryPayment from '../views/project/ProjectHistoryPayment.vue'
import ProjectOnboarding from '../views/project/ProjectOnboarding.vue'
import ProjectSelect from '../views/project/ProjectSelect.vue'

// Template View
import TemplateCreate from '../views/template/TemplateCreate.vue' //Страницы с выбором для создания шаблона
import TemplateForm from '../views/template/TemplateForm.vue' //Генерация шаблона
import TemplateLoad from '../views/template/TemplateLoad.vue' //Загрузка шаблона
import TemplateDesign from '../views/template/TemplateDesign.vue' //Шаблон по дизайну
import TemplateEditor from '../views/template/TemplateEditor.vue' //Редактор шаблона
import TemplateShop from '../views/template/TemplateShop.vue' //Магазин шаблонов

// Letter View
import LetterEditor from '../views/letter/LetterEditor.vue' //Редактор письма
import ModuleLibrary from '../views/letter/ModuleLibrary.vue' //Библиотека модулей
import LetterSettings from '../views/letter/LetterSettings.vue' //Настройки письма
import LetterTest from '../views/letter/LetterTest.vue' //Тест письма
import LetterDevices from '../views/letter/LetterDevices.vue' //Выбор устройств emailOnAcid
import LetterRenders from '../views/letter/LetterRenders.vue' //Результаты emailOnAcid
import LetterRenderPreview from '../views/letter/LetterRenderPreview.vue' //Просмотр полной версии превью emailOnAcid

// Custom Letter View
import LetterCustomCreate from '../views/letter/LetterCustomCreate.vue' //Создание кастомного письма
import LetterEditorCustom from '../views/letter/LetterEditorCustom.vue' //Редактор кастомного письма

// Share view
import LetterPreview from '../views/letter/LetterPreview.vue' //Веб версия писем
import LetterShareRender from '../views/letter/LetterShareRender.vue' //Публичная версия рендеров
import LetterShareRenderPreview from '../views/letter/LetterShareRenderPreview.vue' //Полная версия конкретного рендера emailOnAcid

import Error404 from '../views/Error404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/letter',
    component: LayoutLetter,
    children: [
      // Редактор письма
      {
        path: ':id',
        name: 'letter-editor',
        components: {
          default: LetterEditor,
          header: HeaderLetterEditor,
          sidebar: SidebarLetter,
        },
      },
      // Библиотека модулей
      {
        path: ':id/library/:index',
        name: 'letter-library',
        components: {
          default: ModuleLibrary,
          header: HeaderLetterEditor,
          sidebar: SidebarLibrary,
        },
      },
      // Настройки письма
      {
        path: ':id/settings',
        name: 'letter-settings',
        components: {
          default: LetterSettings,
          header: HeaderLetterSettings,
        },
      },
      // Страница теста письма
      {
        path: ':id/test',
        name: 'letter-test',
        components: {
          default: LetterTest,
          header: HeaderLetterTest,
          sidebar: SideBarTest,
        },
      },
      // Список устройств из EmailOnAcid [Переписать] отделать роут, чтобы не было дубля в letter-custom
      {
        path: ':id/devices',
        name: 'letter-devices',
        components: {
          default: LetterDevices,
          header: HeaderLetterEditor,
        },
      },
      // Рендеры из EmailOnAcid
      {
        path: ':id/renders',
        name: 'letter-renders',
        components: {
          default: LetterRenders,
          header: HeaderLetterRenders,
        },
      },
      // Просмотр одного рендера из EmailOnAcid
      {
        path: ':id/render-preview',
        name: 'letter-render-preview',
        components: {
          default: LetterRenderPreview,
          header: HeaderLetterRenders,
        },
      },
    ],
  },
  {
    path: '/share',
    component: LayoutShare,
    children: [
      // Веб версия кастомных и обычных писем
      {
        path: 'letter/:id',
        name: 'letter-preview',
        components: {
          default: LetterPreview,
          header: HeaderPreview,
        },
      },
      // Публичная версия рендеров писем из EmailOnAcid
      {
        path: ':id/render',
        name: 'share-render',
        components: {
          default: LetterShareRender,
        },
      },
      // Просмотр конкретного рендера писем из EmailOnAcid
      {
        path: ':id/render-preview',
        name: 'share-render-preview',
        components: {
          default: LetterShareRenderPreview,
        },
      },
    ],
  },
  {
    path: '/letter-custom',
    component: LayoutCustom,
    children: [
      // Создание кастомного письма
      {
        path: 'create',
        name: 'letter-custom-create',
        components: {
          default: LetterCustomCreate,
          header: HeaderLetterCustomCreate,
          sidebar: SidebarMain,
        },
      },
      // Редактор кастомного письма
      {
        path: ':id',
        name: 'letter-custom-editor',
        components: {
          default: LetterEditorCustom,
          header: HeaderLetterCustomEditor,
          sidebar: SidebarLetter,
        },
      },
      // Настройки кастомного письма
      {
        path: ':id/settings',
        name: 'letter-custom-settings',
        components: {
          default: LetterSettings,
          header: HeaderLetterCustomSettings,
          sidebar: SidebarMain,
        },
      },
      // Тест кастомного письма
      {
        path: ':id/test',
        name: 'letter-custom-test',
        components: {
          default: LetterTest,
          header: HeaderLetterCustomTest,
          sidebar: SideBarTest,
        },
      },
      // Список устройств из EmailOnAcid
      {
        path: ':id/devices',
        name: 'letter-custom-devices',
        components: {
          default: LetterDevices,
          header: HeaderLetterCustomEditor,
        },
      },
      // Рендеры из EmailOnAcid
      {
        path: ':id/renders',
        name: 'letter-custom-renders',
        components: {
          default: LetterRenders,
          header: HeaderLetterRenders,
        },
      },
      // Просмотр одного рендера из EmailOnAcid
      {
        path: ':id/render-preview',
        name: 'letter-custom-render-preview',
        components: {
          default: LetterRenderPreview,
          header: HeaderLetterRenders,
        },
      },
    ],
  },
  {
    path: '/template',
    component: LayoutTemplate,
    children: [
      // Страница выбора типа для создания шаблона
      {
        path: 'create',
        name: 'template-create',
        components: {
          default: TemplateCreate,
          header: HeaderTemplate,
          sidebar: SidebarMain,
        },
      },
      // Создание шаблона с помощью генератора
      {
        path: 'form',
        name: 'template-form',
        components: {
          default: TemplateForm,
          header: HeaderTemplate,
          sidebar: SidebarMain,
        },
      },
      // Создание шаблона с помощью архива
      {
        path: 'load',
        name: 'template-load',
        components: {
          default: TemplateLoad,
          header: HeaderTemplate,
          sidebar: SidebarMain,
        },
      },
      // Создание шаблона по дизайну из фигмы (Сейчас не используется)
      {
        path: 'design',
        name: 'template-design',
        components: {
          default: TemplateDesign,
          header: HeaderTemplate,
          sidebar: SidebarMain,
        },
      },
      // Редактор шаблона
      {
        path: ':id',
        name: 'template-editor',
        components: {
          default: TemplateEditor,
          header: HeaderTemplateEditor,
          sidebar: SidebarMain,
        },
      },
      // Магазин шаблонов (Не доделан)
      {
        path: 'shop',
        name: 'template-shop',
        components: {
          default: TemplateShop,
          header: HeaderTemplate,
          sidebar: SidebarMain,
        },
      },
    ],
  },
  // [Переписать] подписать роуты
  {
    path: '/project',
    component: LayoutProject,
    children: [
      {
        path: '/project-create',
        name: 'project-create',
        components: {
          default: ProjectCreate,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      {
        path: '/project-select',
        name: 'project-select',
        components: {
          default: ProjectSelect,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      {
        path: ':id/integrations',
        name: 'project-integrations',
        components: {
          default: ProjectIntegrations,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      {
        path: ':id/tariff',
        name: 'project-tariff',
        components: {
          default: ProjectTariff,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      {
        path: ':id/balance',
        name: 'project-balance',
        components: {
          default: ProjectBalance,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      {
        path: ':id/history-payment',
        name: 'project-history-payment',
        components: {
          default: ProjectHistoryPayment,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      {
        path: ':id/onboarding',
        name: 'project-onboarding',
        components: {
          default: ProjectOnboarding,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      {
        path: ':id',
        name: 'project-view',
        components: {
          default: ProjectView,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
    ],
  },
  // [Переписать] подписать роуты
  {
    path: '/registration',
    component: LayoutRegistration,
    children: [
      // Авторизация
      {
        path: '/login',
        name: 'login',
        components: {
          default: Login,
        },
        // beforeEnter: (to, from, next) => {
        //   middlewareRedirect(to, from, next)
        // },
      },
      // Регистрация
      {
        path: '/signup',
        name: 'signup',
        components: {
          default: Signup,
        },
      },
      // Завершение регистрации
      {
        path: '/signup-complete',
        name: 'signup-complete',
        components: {
          default: SignupComplete,
        },
        beforeEnter: middlewareSignup,
      },
      // Запрос на восстановление пароля
      {
        path: '/forgot',
        name: 'forgot',
        components: {
          default: Forgot,
        },
      },
      // Смена пароля
      {
        path: '/recovery',
        name: 'recovery',
        components: {
          default: Recovery,
        },
        beforeEnter: middlewareSignup,
      },
    ],
  },
  {
    path: '/',
    component: LayoutMain,
    children: [
      // Страница шаблонов - Главная
      {
        path: '/templates',
        name: 'template-index',
        components: {
          default: Home,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
        beforeEnter: (to, from, next) => {
          middlewareRedirect(to, from, next)
        },
      },
      // Страница писем
      {
        path: '/letters',
        name: 'letter-index',
        components: {
          default: Home,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      // Страница кастомных писем
      {
        path: '/custom-letters',
        name: 'letter-custom-index',
        components: {
          default: Home,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      // Папка с шаблонами
      {
        path: 'templates-directory/:id',
        name: 'directory-template',
        components: {
          default: Home,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      // Папка с письмами
      {
        path: 'letters-directory/:id',
        name: 'directory-letter',
        components: {
          default: Home,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      // Папка с кастомными письмами
      {
        path: 'letter-custom-directory/:id',
        name: 'directory-letter-custom',
        components: {
          default: Home,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      // Страница аналитики
      {
        path: '/analytics',
        name: 'analytics',
        components: {
          default: Home,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      // Страница создания письма
      {
        path: 'letter-create',
        name: 'letter-create',
        components: {
          default: LetterCreate,
          header: HeaderLetterSave,
          sidebar: SidebarMain,
        },
      },
      // Профиль пользователя
      {
        path: '/user-profile',
        name: 'user-profile',
        components: {
          default: UserProfile,
          header: HeaderBase,
          sidebar: SidebarMain,
        },
      },
      { path: '/', redirect: { name: 'template-index' } },
    ],
  },
  // Просмотр аналитики конкретного письма
  {
    path: '/analytics/:id',
    component: LayoutAnalytics,
  },
  // Просмотр карты аналитики конкретного письма
  {
    path: '/analytics/:id/map',
    component: LayoutAnalyticsMap,
  },
  {
    path: '*', // сопоставляется со всеми неизвестными маршрутами
    component: Error404,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  middlewareAuth(to, from, next)
  // middlewareAccountChange(to, from, next)
  // middlewareSaveRoute(to, from, next) //заменить на router.options.history.state.back
})

export default router
