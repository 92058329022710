<template>
  <v-app>
    <router-view name="header" class="header">
      <template #share-menu>
        <HeaderMenuShare />
      </template>
    </router-view>
    <router-view class="page" />
  </v-app>
</template>

<script>
import HeaderMenuShare from '@/components/header/HeaderMenuShare.vue'

import dynamicStoreMixin from '@/mixins/Share/dynamicStore.mixin.js'

export default {
  name: 'LayoutShare',
  components: { HeaderMenuShare },
  mixins: [dynamicStoreMixin],
}
</script>

<style lang="scss">
.v-application p {
  margin-bottom: 0;
}
</style>
