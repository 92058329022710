<template>
  <v-main class="select-project">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="7" sm="7" cols="10">
          <div class="font-weight-medium">
            {{ $t('projectSelect.choose') }}
            <router-link to="/project-create" class="bordored-link">
              {{ $t('projectSelect.create_project') }}
            </router-link>
          </div>
          <v-text-field
            v-model="search"
            class="mt-5"
            prepend-inner-icon="mdi-magnify"
            outlined
            single-line
            hide-details
            :placeholder="$t('projectSelect.find_project')" />
          <div class="list-project-wrapper">
            <v-virtual-scroll
              :items="projects"
              bench="10"
              item-height="55"
              max-height="550"
              class="scrollbar">
              <template v-slot:default="{ index, item }">
                <v-list-item
                  :key="item"
                  class="list-project-item"
                  :ripple="false"
                  @click="selectProject(index)">
                  <v-img
                    class="mr-3"
                    max-height="36"
                    max-width="36"
                    :src="calcLogoUrl(item)" />
                  <div class="list-project-name font-weight-medium title">
                    {{ item.name }}
                  </div>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import project from '@/mixins/project.mixin.js'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectSelect',
  mixins: [project],
  data() {
    return {
      search: '',
      selectedProject: null,
    }
  },
  computed: {
    ...mapGetters('userStore', ['searchUserProjects']),
    projects() {
      const searchString = this.search.toLowerCase()
      return this.searchUserProjects(searchString)
    },
  },
  created() {
    document.title = this.$t('projectSelect.find_project')
  },
  methods: {
    calcLogoUrl(account) {
      return account.logoUrl ?? '/icons/project.svg'
    },
    async selectProject(event) {
      const data = this.projects[event]
      localStorage.setItem('account_id', data.id)
      // [Переписать] loadProject нужно сделать глобальным, чтобы он вызывался сам, если данных о проекте нету в сторе
      // Возможно это подключение должно быть в App.vue, а при переключение аккаунта должны отрабатывать прослушки с использованием vuex subscribe или vuex watch
      await this.loadProject()
      this.$router.push(`/?account_id=${data.id}`)
    },
  },
}
</script>

<style lang="scss">
.select-project {
  .list-project-wrapper {
    padding: 1rem 0;
    margin: 1.25rem 0 0 0;
    background-color: #f2f2f2;
    border-radius: 15px;
  }

  .list-project-item {
    display: flex;
    flex-flow: row nowrap;
    margin: 10px 0 0 0;
    cursor: pointer;
    background-color: inherit;

    &:hover {
      color: #001dff;
    }
    &:first-of-type {
      margin: 0;
    }
  }

  .list-project {
    list-style: none;
    max-height: 570px;
    padding: 0;
    background-color: inherit;
  }
}
</style>
