import letterStore from '@/store/dynamic/letter.js'

export default {
  created() {
    // console.log('created letter')
    if (!this.$store.hasModule('letter')) {
      this.$store.registerModule('letter', letterStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('letter')) {
      this.$store.registerModule('letter', letterStore)
    }
  },
  destroyed() {
    // console.log('destroyed letter')
    if (this.$store.hasModule('letter')) {
      this.$store.unregisterModule('letter')
    }
  },
}
