/* eslint-disable */

import { getHTML } from '@/utilities/test.js'

const state = () => ({
  html: '',
  isHtmlLoaded: null,
})

const getters = {}

const mutations = {
  clearHtmlLoaded(state) {
    state.isHtmlLoaded = null
  },
  toggleHtmlLoaded(state, payload) {
    state.isHtmlLoaded = payload
  },
  setHTML(state, payload) {
    state.html = payload
  },
}

const actions = {
  async getHTML({ state, commit }, id) {
    try {
      const result = await getHTML(id)
      if (result.status) {
        commit('setHTML', result.webVersion)
        commit('toggleHtmlLoaded', true)
        return true
      } else {
        throw new Error(result.error)
      }
    } catch (e) {
      commit('toggleHtmlLoaded', false)
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
