import axios from '../plugins/axios_and_toast.js'

async function getRobokassaParams(data) {
  const result = await axios.post('/account/prepare-payment', data)
  return result.data
}

async function createInvoice(data) {
  const result = await axios.post('/account/create-invoice', data)
  return result.data
}

export { getRobokassaParams, createInvoice }
