<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <g clip-path="url(#clip0_5829_53)">
      <path d="M19.8273 3.19862L21.1327 4.50404C22.3236 5.69496 22.2045 7.73785 20.8662 9.07622L9.01812 20.9242C8.72643 21.2159 8.33643 21.3922 7.94731 21.4149L3.72332 21.8065C2.91265 21.8538 2.29429 21.2355 2.34155 20.4248L2.96502 16.2438C2.9877 15.8547 3.16394 15.4647 3.45564 15.173L15.2723 3.44801C16.6097 2.12585 18.644 2.01533 19.8273 3.19862Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.5168 5.98218L18.4027 10.868" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_5829_53">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>