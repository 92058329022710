<template src="./TemplateForm/index.vue"></template>

<script>
// [Переписать] разбить на компоненты [LATER]
import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.min.css'

import PromiseDialogWrapper from '@/components/PromiseDialogWrapper.vue'
import PromiseDialogFrame from '@/components/PromiseDialogFrame.vue'

import ColorPicker from '@/components/tools/ColorPicker.vue'
import ImageUploader from '@/components/tools/ImageUploader.vue'

import { validateURL, isValidHttpUrl, RGBAToHex } from '@/utilities/helpers.js'
import {
  createTemplateByIndustry,
  loadTemplateByIndustryId,
  uploadFileByIndustry,
  getFonts,
  getColors,
  sendTgInfo,
} from '@/utilities/template.js'

export default {
  name: 'TemplateForm',
  components: {
    // IconGallery,
    ColorPicker,
    PromiseDialogWrapper,
    PromiseDialogFrame,
    ImageUploader,
  },

  data() {
    const fonts = [
      {
        label: 'Arial',
        value: 'Arial, sans-serif',
      },
      {
        label: 'Verdana',
        value: 'Verdana, sans-serif',
      },
      {
        label: 'Helvetica',
        value: 'Helvetica, sans-serif',
      },
      {
        label: 'Tahoma',
        value: 'Tahoma, sans-serif',
      },
      {
        label: 'Trebuchet MS',
        value: 'Trebuchet MS, sans-serif',
      },
      {
        label: 'Times New Roman',
        value: 'Times New Roman, sans-serif',
      },
      {
        label: 'Georgia',
        value: 'Georgia, sans-serif',
      },
      {
        label: 'Garamond',
        value: 'Garamond, sans-serif',
      },
      {
        label: 'Courier New',
        value: 'Courier New, monospace',
      },
      {
        label: 'Brush Script MT',
        value: 'Brush Script MT, cursive',
      },
    ]
    const socialsIconsColors = [
      {
        label: 'Белые с черной обводкой',
        value: 'white',
      },
      {
        label: 'Черные иконки',
        value: 'black',
      },
      {
        label: 'Цветные',
        value: 'color',
      },
      {
        label: 'Круглые(черные) на белом фоне',
        value: 'circle_white',
      },
      {
        label: 'Круглые цветные',
        value: 'circle_color',
      },
      {
        label: 'Круглые(белые) на черном фоне',
        value: 'circle_black',
      },
    ]
    // added prop and '(скоро)' are temporary, todo delete it, when all templates are uploaded
    const industries = [
      {
        label: 'Стандартный',
        value: 1,
        added: true,
      },
      {
        label: 'Мода',
        value: 2,
        added: true,
      },
      {
        label: 'Косметика и уход',
        value: 3,
        added: true,
      },
      {
        label: 'Marketing и B2B (скоро)',
        value: 4,
        added: false,
      },
      {
        label: 'Дети и малыши',
        value: 5,
        added: true,
      },
      {
        label: 'Кино и развлечения',
        value: 6,
        added: true,
      },
      {
        label: 'Книги и образование',
        value: 7,
        added: true,
      },
      {
        label: 'Гаджеты',
        value: 8,
        added: true,
      },
      {
        label: 'Отели и рестораны',
        value: 9,
        added: true,
      },
      {
        label: 'Электроника',
        value: 10,
        added: true,
      },
      {
        label: 'Автомобили и аренда',
        value: 11,
        added: true,
      },
      {
        label: 'Спорт и фитнес (скоро)',
        value: 12,
        added: false,
      },
      {
        label: 'Хобби',
        value: 13,
        added: true,
      },
      {
        label: 'Дом и сад',
        value: 14,
        added: true,
      },
      {
        label: 'Еда',
        value: 15,
        added: true,
      },
      {
        label: 'Товары для животных',
        value: 16,
        added: true,
      },
      {
        label: 'Благотворительность',
        value: 17,
        added: true,
      },
      {
        label: 'Недвижимость (скоро)',
        value: 18,
        added: false,
      },
      {
        label: 'Финансы',
        value: 19,
        added: true,
      },
      {
        label: 'Технологии, софт, saas',
        value: 20,
        added: true,
      },
      {
        label: 'Доставка',
        value: 21,
        added: true,
      },
    ]

    return {
      industries,
      platforms: [
        {
          name: 'Mailganer',
          icon: '/img/template/platforms/Mailganer.png',
          value: { url: '{{unsubscribeUrl}}', webversion: '{{webversion}}' },
        },
        {
          name: 'UniSender',
          icon: '/img/template/platforms/UniSender.png',
          value: { url: '{{UnsubscribeUrl}}', webversion: '{{WebLetterUrl}}' },
        },
        {
          name: 'ExpertSender',
          icon: '/img/template/platforms/Expertsender.png',
          value: {
            url: '*[link_unsubscribe]*',
            webversion: '*[link_viewinbrowser]*',
          },
        },
        {
          name: 'Sendsay',
          icon: '/img/template/platforms/Sendsay.png',
          value: {
            url: '[% param.url_unsub %]',
            webversion: '[% param.issue_archive_url %]',
          },
        },
      ],
      fontsHeadline: fonts,
      fontsParagraph: fonts,
      socials: [
        {
          label: 'Telegram',
          value: 'Telegram',
          icon: 'telegram.png',
        },
        {
          label: 'Facebook',
          value: 'Facebook',
          icon: 'facebook.png',
        },
        {
          label: 'Instagram',
          value: 'Instagram',
          icon: 'instagram.png',
        },
        {
          label: 'Vkontakte',
          value: 'Vkontakte',
          icon: 'vk.png',
        },
        {
          label: 'Youtube',
          value: 'Youtube',
          icon: 'youtube.png',
        },
        {
          label: 'TikTok',
          value: 'TikTok',
          icon: 'tiktok.png',
        },
        {
          label: 'Twitter',
          value: 'Twitter',
          icon: 'twitter.png',
        },
      ],

      socialsIconsColors,
      colorMenuAccentColor: false,
      colorMenuBgColor: false,
      colorMenuAlternativeColor: false,
      form: {
        industryId: 1,
        accentColor: '#000000',
        alternativeColor: '#ffffff',
        bgColor: '#ffffff',
        selectedSocial: [
          { value: 'Telegram', label: 'Telegram' },
          { value: 'Vkontakte', label: 'Vkontakte' },
        ],
        selectedSocialIconsColor: socialsIconsColors[0],
        selectedPlatform: null,
        buttonShape: null,
        buttonType: null,
        templateName: 'Новый шаблон',
        prevPlatform: null,
        logoUrl: null,
      },
      file: null,
      template: null,
      companyColors: [],
      colorPickerType: '',
      isDisable: true,
      isIsland: false,
    }
  },
  computed: {
    logo() {
      return this.form.logoUrl ?? '/icons/project.svg'
    },
  },
  mounted() {
    this.loadTemplate()
  },
  methods: {
    async linkInit(e) {
      if (!validateURL(e)) return false
      // Доп проверка урла с учетом протокола
      if (!isValidHttpUrl(e)) return false

      this.colorPickerType = 'worksheet-loading'
      const result = await getFonts(e)

      if (result.list && result.list.length) {
        result.list.forEach((item) => {
          this.fontsHeadline.push({
            label: item.font.name,
            value: item.url,
          })

          this.fontsParagraph.push({
            label: item.font.name,
            value: item.url,
          })
        })
      }

      this.colorsPrepare(e)
    },
    async colorsPrepare(url) {
      const result = await getColors(url)

      if (result.colors) {
        const colors = Object.entries(result.colors)
          .sort((a, b) => b[1] - a[1])
          .map((item) => {
            const colorName = item[0]
            return colorName
          })
          .filter((item) => {
            return (
              item !== 'rgba(0, 0, 0, 0)' &&
              item !== 'rgb(0, 0, 0)' &&
              item !== 'rgba(255, 255, 255, 1)' &&
              item !== 'rgb(255, 255, 255)'
            )
          })

        this.companyColors = colors.slice(0, 5).map((item) => RGBAToHex(item))
        this.colorPickerType = 'worksheet'
      }
    },
    async sendTg() {
      // console.log(sendTgInfo)
      await sendTgInfo()

      new Notify({
        status: 'success',
        title: 'Мы получили ваш запрос',
        effect: 'fade',
        speed: 300,
        customClass: null,
        customIcon: null,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'right top',
      })
    },
    uploadFileHandler(e) {
      console.log(e.url ? e.url : e.file ? e.file : '')
    },
    getImagePath(image, srcset = '') {
      if (srcset) srcset = ` ${srcset}`
      return require(`@/assets/img/${image}`) + srcset
    },
    async loadTemplate() {
      try {
        this.$refs['industry-select'].blur()
        this.template = await loadTemplateByIndustryId(this.form.industryId)
        this.isDisable = false
      } catch (e) {
        console.log('Load template error', e)
      }
    },
    changeSocialIconsColor(item) {
      const path = `/img/template/social_icons/${item.value}/`
      const find = 'letteros-template-social'

      this.form.selectedSocial.forEach((selectedSocial) => {
        this.handleChangesDOM({
          replace: {
            attributes: [
              {
                property: 'src',
                value: `${path}${selectedSocial.icon}`,
                find: `[${find}] img[alt="${selectedSocial.label}"]`,
              },
            ],
          },
        })
      })
    },
    changeSocial() {
      this.$nextTick(() => {
        this.changeSocialIconsColor(this.form.selectedSocialIconsColor)
      })
    },
    changeSocials(item) {
      console.log(item)
    },
    changeSocialItem(item) {
      const idx =
        this.form.selectedSocial.findIndex((el) => el.label === item.label) + 1
      const find = 'letteros-template-social'

      this.handleChangesDOM({
        replace: {
          attributes: [
            {
              property: 'href',
              value: item.value,
              find: `[${find}]:nth-child(${idx}) a`,
            },
            {
              property: 'letteros-hide',
              value: item.label,
              find: `[${find}]:nth-child(${idx})`,
            },
            {
              property: 'alt',
              value: item.label,
              find: `[${find}]:nth-child(${idx}) img`,
            },
          ],
        },
      })
    },
    changePhone(phone) {
      this.handleChangesDOM({
        replace: {
          attributes: [
            {
              property: 'href',
              value: `tel:${phone}`,
              find: '[letteros-template-phone-link]',
            },
          ],
          text: [
            {
              value: phone,
              find: '[letteros-template-phone]',
            },
          ],
        },
      })
    },
    changeButtonShape(btnShape) {
      const type = btnShape.at(-1)
      const options = { replace: {} }
      const find = `[letteros-template-btn] td`

      switch (type) {
        case '1':
          options.replace = {
            style: [
              {
                property: 'border-radius',
                find,
                value: '0px',
              },
            ],
          }
          break
        case '2':
          options.replace = {
            style: [
              {
                property: 'border-radius',
                find,
                value: '15px',
              },
            ],
          }
          break
        case '3':
          options.replace = {
            style: [
              {
                property: 'border-radius',
                find,
                value: '30px',
              },
            ],
          }
          break
      }
      this.handleChangesDOM(options)
    },
    changeLetterType(letterType) {
      console.log(letterType)
      const type = letterType.at(-1)
      let replace = {}
      const selector = `[letteros-border-radius]`

      switch (type) {
        case '1':
          replace = {
            selector: selector,
            styles: ['border-radius'],
            attrs: [],
            type: 'remove',
          }
          this.form.bgColor = '#FFFFFF'
          break
        case '3':
          replace = {
            selector: selector,
            styles: ['background-color'],
            attrs: ['bgcolor'],
            type: 'round',
          }
          this.form.bgColor = '#EDEDED'
          break
        case '4':
          replace = {
            selector: selector,
            styles: ['background-color'],
            attrs: ['bgcolor'],
            type: 'island',
          }
          this.form.bgColor = '#EDEDED'
          break
      }

      if (type !== 2) {
        this.handleNodes(replace)
      } else {
        console.log('show info and send tg msg')
      }
    },
    changeButtonType(btnType) {
      const type = btnType.at(-1)
      const options = { replace: {} }
      const selfAccentColor = this.form.accentColor
      const selfAlternativeColor = this.form.alternativeColor
      const findTd = '[letteros-template-btn] td'
      const findA = '[letteros-template-btn] a'

      switch (type) {
        case '1':
          options.replace = {
            style: [
              {
                property: 'background-color',
                find: findTd,
                value: selfAccentColor,
                mix: {
                  attributes: [
                    {
                      property: 'bgcolor',
                      value: selfAccentColor,
                    },
                  ],
                },
              },
              {
                property: 'border',
                value: `1px solid ${selfAccentColor}`,
                find: findTd,
              },
              {
                property: 'color',
                value: selfAlternativeColor,
                find: findA,
              },
              {
                property: 'padding',
                value: '0',
                find: findTd,
              },
              {
                property: 'padding',
                value: 'restore-default',
                find: findA,
              },
            ],
          }
          break

        case '2':
          options.replace = {
            style: [
              {
                property: 'background-color',
                find: findTd,
                value: 'transparent',
                mix: {
                  attributes: [
                    {
                      property: 'bgcolor',
                      value: selfAccentColor,
                    },
                  ],
                },
              },
              {
                property: 'border',
                value: `1px solid ${selfAccentColor}`,
                find: findTd,
              },
              {
                property: 'color',
                value: selfAccentColor,
                find: findA,
              },
              {
                property: 'padding',
                value: '0',
                find: findTd,
              },
              {
                property: 'padding',
                value: 'restore-default',
                find: findA,
              },
            ],
          }
          break

        case '3':
          options.replace = {
            attributes: [
              {
                property: 'padding',
                value: 'set-default-style',
                find: findA,
              },
            ],
            style: [
              {
                property: 'background-color',
                find: findTd,
                value: 'transparent',
                mix: {
                  attributes: [
                    {
                      property: 'bgcolor',
                      value: selfAccentColor,
                    },
                  ],
                },
              },
              {
                property: 'border',
                value: 'none',
                find: findTd,
              },
              {
                property: 'border-radius',
                value: '0px',
                find: findTd,
              },
              {
                property: 'border-bottom',
                value: `1px solid ${selfAccentColor}`,
                find: findTd,
              },
              {
                property: 'padding',
                value: '2px 0',
                find: findTd,
              },
              {
                property: 'padding',
                value: '0',
                find: findA,
              },
              {
                property: 'color',
                value: selfAccentColor,
                find: findA,
              },
            ],
          }
          break
      }

      this.handleChangesDOM(options)
    },
    changeBackgroundColor(bgColor) {
      this.form.bgColor = bgColor
      this.handleChangesDOM({
        replace: {
          style: [
            {
              property: 'background-color',
              value: bgColor,
              find: '[letteros-body]',
              mix: {
                attributes: [
                  {
                    property: 'bgcolor',
                    value: bgColor,
                  },
                  {
                    property: 'letteros-body',
                    value: bgColor,
                  },
                ],
              },
            },
          ],
        },
      })
    },
    changeColorAlternative(colorAlternative) {
      this.form.alternativeColor = colorAlternative
      this.handleChangesDOM({
        replace: {
          style: [
            {
              property: 'color',
              value: colorAlternative,
              find: '[letteros-template-alternative-color]',
            },
            {
              property: 'background-color',
              value: colorAlternative,
              find: '[letteros-template-alternative-bgcolor]',
              mix: {
                attributes: [
                  {
                    property: 'bgcolor',
                    value: colorAlternative,
                  },
                ],
              },
            },
            {
              property: 'border',
              value: `1px solid ${colorAlternative}`,
              find: '[letteros-template-alternative-bordercolor]',
            },
          ],
        },
      })
    },
    changeColorAccent(colorAccent) {
      this.form.accentColor = colorAccent
      this.handleChangesDOM({
        replace: {
          style: [
            {
              property: 'color',
              value: colorAccent,
              find: '[letteros-template-accent-color]',
            },
            {
              property: 'background-color',
              value: colorAccent,
              find: '[letteros-template-accent-bgcolor]',
              mix: {
                attributes: [
                  {
                    property: 'bgcolor',
                    value: colorAccent,
                  },
                ],
              },
            },
            {
              property: 'border',
              value: `1px solid ${colorAccent}`,
              find: '[letteros-template-accent-bordercolor]',
            },
          ],
        },
      })
    },
    changeFontParagraph(fontParagraph) {
      this.handleChangesDOM({
        replace: {
          style: [
            {
              property: 'font-family',
              value: `${fontParagraph.value}`,
              find: 'td:not([letteros-template-title]), a:not([letteros-template-title])',
            },
          ],
        },
      })
    },
    changeFontHeadline(fontHeadline) {
      this.handleChangesDOM({
        replace: {
          style: [
            {
              property: 'font-family',
              value: `${fontHeadline.value}`,
              find: '[letteros-template-title]',
            },
          ],
        },
      })
    },
    changeLogo(logo) {
      this.file = logo instanceof File ? logo : null

      this.handleChangesDOM({
        replace: {
          attributes: [
            {
              property: 'src',
              value:
                logo instanceof File ? window.URL.createObjectURL(logo) : logo,
              find: '[letteros-template-logo]',
            },
          ],
        },
      })
    },
    changePlatform(platform) {
      const findUrl = !this.form.prevPlatform
        ? '{{unsubscribeUrl}}'
        : this.form.prevPlatform.value.url
      const findWebversion = !this.form.prevPlatform
        ? '{{webversionUrl}}'
        : this.form.prevPlatform.value.webversion

      this.form.prevPlatform = platform

      this.handleChangesDOM({
        replace: {
          attributes: [
            {
              property: 'href',
              value: platform.value.url,
              find: `[href="${findUrl}"]`,
            },
            {
              property: 'href',
              value: platform.value.webversion,
              find: `[href="${findWebversion}"]`,
            },
          ],
        },
      })
    },
    changeEmail(email) {
      this.handleChangesDOM({
        replace: {
          attributes: [
            {
              property: 'href',
              value: `mailto:${email}`,
              find: '[letteros-template-mail-link]',
            },
          ],
          text: [
            {
              value: email,
              find: '[letteros-template-mail]',
            },
          ],
        },
      })
    },
    handleChangesDOM(options) {
      if (this.template?.modulesGroups)
        this.template.modulesGroups = this.template.modulesGroups.map(
          (group) => {
            if (group.id === 0) return group

            group.modules = group.modules.map((module) => {
              module.html = this.changeDOM(module.html, options)
              return module
            })

            return group
          }
        )
    },
    handleNodes(replace) {
      if (this.template?.modulesGroups) {
        this.template.modulesGroups = this.template.modulesGroups.map(
          (group, groupIndex) => {
            if (group.id === 0) return group

            group.modules = group.modules.map((module) => {
              // if (moduleIndex > 0) return module

              if (replace.type === 'round') {
                if (groupIndex === 0) {
                  // console.log('first module')
                  module.html = this.changeNodeRound(module.html, {
                    style: 'border-radius',
                    value: '15px 15px 0 0',
                    replace: replace,
                  })
                } else if (
                  groupIndex ===
                  this.template.modulesGroups.length - 2
                ) {
                  // console.log('last module')
                  module.html = this.changeNodeRound(module.html, {
                    style: 'border-radius',
                    value: '0 0 15px 15px',
                    replace: replace,
                  })
                } else {
                  module.html = this.changeNode(module.html, replace)
                }
              } else {
                module.html = this.changeNode(module.html, replace)
              }

              return module
            })

            return group
          }
        )
      }
    },

    changeNodeRound(htmlText, options) {
      // Добавление первому и последнему блоку border-radius и Восстановление стилей
      const doc = new DOMParser().parseFromString(
        `<table><tbody>${htmlText}</tbody></table>`,
        'text/html'
      )

      const elems = doc.querySelectorAll(options.replace.selector)

      if (elems.length) {
        const elem = elems[0]
        if (!elem.getAttribute(`data-cache-style-border-radius`)) {
          elem.setAttribute(
            `data-cache-style-border-radius`,
            elem.style.getPropertyValue('border-radius')
          )
        }
        elem.style.setProperty(options.style, options.value)

        options.replace.attrs.forEach((att) => {
          const cacheValue = elem.getAttribute(`data-cache-attr-${att}`)
          if (cacheValue) {
            elem.setAttribute(att, cacheValue)
          }
        })

        options.replace.styles.forEach((style) => {
          const cacheValue = elem.getAttribute(`data-cache-style-${style}`)
          if (cacheValue) {
            elem.style.setProperty(style, cacheValue)
          }
        })

        //Удаление отступа островков
        this.isIsland = false
      }

      return doc.documentElement.lastChild.firstElementChild.firstElementChild
        .firstElementChild.outerHTML
    },

    changeNode(htmlText, replace) {
      const doc = new DOMParser().parseFromString(
        `<table><tbody>${htmlText}</tbody></table>`,
        'text/html'
      )

      const elems = doc.querySelectorAll(replace.selector)

      if (elems.length) {
        elems.forEach((el, index) => {
          if (index > 0) return

          if (replace.type === 'remove') {
            // Удаление атрибутов и стилей + сохранение их значений для восстановления в других типах
            replace.attrs.forEach((att) => {
              if (!el.getAttribute(`data-cache-attr-${att}`)) {
                if (el.getAttribute(att))
                  el.setAttribute(
                    `data-cache-attr-${att}`,
                    el.getAttribute(att)
                  )
              }
              el.removeAttribute(att)
            })

            replace.styles.forEach((style) => {
              if (!el.getAttribute(`data-cache-style-${style}`)) {
                if (el.style.getPropertyValue(style)) {
                  el.setAttribute(
                    `data-cache-style-${style}`,
                    el.style.getPropertyValue(style)
                  )
                }
              }
              el.style.removeProperty(style)
            })

            //Удаление отступа островков
            this.isIsland = false
          } else if (replace.type === 'round') {
            // Восстановление атрибутов и стилей + удаление border-radius
            replace.attrs.forEach((att) => {
              const cacheValue = el.getAttribute(`data-cache-attr-${att}`)
              if (cacheValue) {
                el.setAttribute(att, cacheValue)
              }
            })

            replace.styles.forEach((style) => {
              const cacheValue = el.getAttribute(`data-cache-style-${style}`)
              if (cacheValue) {
                el.style.setProperty(style, cacheValue)
              }
            })

            if (!el.getAttribute(`data-cache-style-border-radius`)) {
              el.setAttribute(
                `data-cache-style-border-radius`,
                el.style.getPropertyValue('border-radius')
              )
            }
            el.style.removeProperty('border-radius')

            //Удаление отступа островков
            this.isIsland = false
          } else if (replace.type === 'island') {
            // Восстановление атрибутов и стилей + добавление border-radius и отступов
            replace.attrs.forEach((att) => {
              const cacheValue = el.getAttribute(`data-cache-attr-${att}`)
              if (cacheValue) {
                el.setAttribute(att, cacheValue)
              }
            })

            replace.styles.forEach((style) => {
              const cacheValue = el.getAttribute(`data-cache-style-${style}`)
              if (cacheValue) {
                el.style.setProperty(style, cacheValue)
              }
            })

            if (!el.getAttribute(`data-cache-style-border-radius`)) {
              el.setAttribute(
                `data-cache-style-border-radius`,
                el.style.getPropertyValue('border-radius')
              )
            }

            el.style.setProperty('border-radius', '15px')

            //Добавление отступа островков
            this.isIsland = true
          }
        })
      }

      return doc.documentElement.lastChild.firstElementChild.firstElementChild
        .firstElementChild.outerHTML
    },

    /**
     * @param {string} htmlText - html строка
     *
     * @typedef options - параметры парсинга и замены
     * @type {object}
     * @property {object[]} style|text|attributes - массивы атрибутов/текст/стилей для замены
     * @property {string} property - какой атрибут заменяем
     * @property {string|number} value - значение для атрибута
     * @property {string} find  - css селектор поиска
     * @property {boolean} parent - изменить у родителя элемента
     * @property {options} mix - тоже самое, что и options, но замена идет у того же HTML элемента, который нашли по селектору find
     * @returns {string}
     */
    changeDOM(htmlText, { replace = {} } = {}) {
      const html = new DOMParser().parseFromString(
        `<table><tbody>${htmlText}</tbody></table>`,
        'text/html'
      )
      Object.keys(replace).forEach((replacer) => {
        replace[replacer].forEach((options) => {
          let elementsReplace = html.querySelectorAll(`${options.find}`)
          const actionReplace = (callback) => {
            elementsReplace.forEach((el) => {
              if (options?.parent && options.parent) callback(el.parentElement)
              else callback(el)
            })
          }

          const switcher = (replacer, options) => {
            if (elementsReplace) {
              switch (replacer) {
                case 'attributes':
                  actionReplace((el) => {
                    if (options.value === 'set-default-style') {
                      const defaultValue = el.style[options.property]
                      el.setAttribute(
                        options.property + '-default',
                        defaultValue
                      )
                    } else {
                      el.setAttribute(options.property, options.value)
                    }
                  })
                  break

                case 'style':
                  actionReplace((el) => {
                    if (options.value === 'restore-default') {
                      const defaultValue = el.getAttribute(
                        `${options.property}-default`
                      )
                      el.style.cssText += `${options.property}: ${defaultValue};`
                    } else {
                      el.style.cssText += `${options.property}: ${options.value};`
                    }
                  })
                  break

                case 'text':
                  actionReplace((el) => {
                    el.textContent = options.value
                  })
                  break
              }
            }
          }

          switcher(replacer, options)

          if (options?.mix && options.mix)
            Object.keys(options.mix).forEach((mixKey) =>
              switcher(mixKey, options.mix[mixKey])
            )
        })
      })

      return html.documentElement.lastChild.firstElementChild.firstElementChild
        .firstElementChild.outerHTML
    },
    async createModule() {
      this.$loader('show')
      if (!this.template?.modulesGroups) return

      try {
        if (this.file) {
          const res = await uploadFileByIndustry(this.file)

          if (res) this.changeLogo(res)
        }

        const res = await createTemplateByIndustry({
          modulesGroups: this.template.modulesGroups,
          templateName: this.form.templateName,
        })

        if (res) {
          this.$loader('hide')
          const answ = await this.$refs.goToTemplate.open()

          if (answ) await this.$router.push({ name: 'template-index' })
        }
      } catch (err) {
        console.error(err)
      }
      this.$loader('hide')
    },
  },
}
</script>

<style lang="scss" src="./TemplateForm/index.scss"></style>
