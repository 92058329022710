<template>
  <div class="table-actions-menu">
    <ElementMenuLink :id="item.id" :loading="loading" />
    <ElementMenuList :id="item.id" />
  </div>
</template>

<script>
import ElementMenuLink from '@/components/home/element/ElementMenuLink.vue'
import ElementMenuList from '@/components/home/element/ElementMenuList.vue'

import project from '@/mixins/project.mixin.js'

export default {
  name: 'ElementMenu',
  components: {
    ElementMenuList,
    ElementMenuLink,
  },
  mixins: [project],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Определяем состояние шаблона создан или нет
    loading() {
      return (
        this.item.state < 50 &&
        this.item.state >= 0 &&
        !this.item.isAppLetterosCom
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.table-actions-menu {
  display: flex;
  max-width: 223px;

  .list-card-main {
    flex: 3;
  }

  .list-card-dropdown {
    flex: 1;
  }

  .menu-item-header {
    background-color: $color-white;
    padding: 1rem;
  }

  .list-project {
    list-style: none;
    max-height: 375px;
    padding: 0;
    background-color: $color-white;

    .list-project-item {
      display: flex;
      flex-flow: row nowrap;
      margin: 1rem 0 0 0;
      cursor: pointer;

      &:hover {
        color: #001dff;
      }
    }
  }
}

.menu-blue {
  .v-btn {
    color: $color-white;
    background-color: $color-primary;
  }
}
</style>
