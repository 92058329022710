<template>
  <div class="option-condition">
    <label class="option__label">
      {{ $t('optionCondition.dynamic-conditions') }}
      <v-tooltip content-class="tooltip tooltip-white" top max-width="316">
        <template #activator="{ on, attrs }">
          <v-icon class="mt-n4 ml-n2 pa-2" size="14" v-bind="attrs" v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>
          {{ $t('optionCondition.use-this-setting-if-you-need-to-wrap') }}
          <span class="primary--text">{%if gender=man %}...{% endif %}</span>
          {{ $t('optionCondition.the-construction') }}
          <span class="primary--text">{%if gender=man %}</span>
          {{ $t('optionCondition.will-be-substituted-before-the-module-and') }}
          <span class="primary--text">{%endif%}</span>
          {{ $t('optionCondition.after') }}
          {{
            $t('optionCondition.three-dots-indicate-the-content-of-the-module')
          }}
        </span>
      </v-tooltip>
    </label>
    <v-textarea
      id="letter-editor-sidebar-conditions"
      :value="dynamicCondition"
      class="mb-n2"
      outlined
      dense
      rows="4"
      @change="update" />
  </div>
</template>

<script>
/* eslint-disable */
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'ModuleOptionCondition',
  props: {
    element: [HTMLElement, null],
  },
  data() {
    return {
      dynamicCondition: '',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const savedCondition = this.element.getAttribute('letteros-condition')
      if (!savedCondition) return
      const buffer = Buffer.from(savedCondition, 'base64')
      this.dynamicCondition = buffer.toString()
    },
    update(value) {
      if (!value) return

      this.dynamicCondition = value
      const buffer = Buffer.from(value)
      const encoded = buffer.toString('base64')
      this.element.setAttribute('letteros-condition', encoded)
      displayNotify(this.$t('optionCondition.dynamic-success'))
    },
  },
}
</script>
