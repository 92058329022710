<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M16.5122 16.9058H7.49656C6.40054 16.9058 5.50781 16.0113 5.50781 14.913V9.07649C5.50781 7.97827 6.40054 7.08374 7.49656 7.08374H16.5122C17.6083 7.08374 18.501 7.97827 18.501 9.07649V14.913C18.501 16.0113 17.6083 16.9058 16.5122 16.9058Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.15234 9.83813L12.0049 13.4605L16.8398 9.83813" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 4.17069V19.8559C1 21.5918 2.591 23 4.5444 23H19.4556C21.4178 23 23 21.5918 23 19.8559V7.2351C23 6.40258 22.6288 5.60548 21.957 5.01208L18.4656 1.92109C17.7939 1.3277 16.8923 1 15.9466 1L4.53556 1.02657C2.58216 1.03543 1 2.43478 1 4.17069Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>