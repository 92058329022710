<template>
  <div class="list-card">
    <div class="list-card-preview">
      <!-- Кнопка -->
      <div class="list-card-bar">
        <v-btn
          class="list-card-main d-flex"
          :ripple="false"
          x-large
          color="primary"
          @click.prevent="showPreview"
          depressed>
          {{ $t('watch') }}
        </v-btn>
      </div>

      <!-- Чекбокс -->
      <!-- <v-checkbox
        color="#fff"
        class="list-card-checkbox checkbox-white ml-auto" /> -->

      <!-- Превью -->
      <div class="list-card-image-wrapper">
        <div
          class="render-loader-wrapper"
          :class="{ 'over-top': loadingRender }">
          <div class="render-loader" v-if="loadingRender">
            <div class="render-stripes">
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
        <img
          class="list-card-image"
          :src="`/img/letterPreview.png`"
          @error="reloadPreview"
          @load="finishPreview"
          :ref="item.name"
          alt=""
          width="245" />
      </div>
    </div>

    <!-- Название устройства -->
    <div class="list-card-info">
      <div class="font-weight-medium" v-html="item.name"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RenderCard',
  props: {
    item: Object,
    type: String,
  },
  data() {
    return {
      loadingRender: true,
    }
  },
  computed: {
    pageType() {
      return this.$route.name == 'letter-custom-renders' ? 'custom' : 'default'
    },
    previewRoute() {
      if (this.type == 'public') {
        return 'share-render-preview'
      } else {
        return this.pageType == 'custom'
          ? 'letter-custom-render-preview'
          : 'letter-render-preview'
      }
    },
  },
  mounted() {
    const img = this.$refs[this.item.name]
    setTimeout(() => {
      img.src = this.item.thumb
    }, 500)
  },
  methods: {
    reloadPreview(e) {
      e.target.src = `/img/letterPreview.png`
      setTimeout(() => {
        e.target.src = this.item.thumb
      }, 500)
    },
    finishPreview(e) {
      let path = e.target.src.split('/')
      if (path[path.length - 1] != 'letterPreview.png') {
        this.loadingRender = false
      }
    },
    showPreview() {
      localStorage.setItem('render_preview', this.item.id)
      this.$router.push({
        name: this.previewRoute,
        params: { id: this.$route.params.id },
      })
    },
  },
}
</script>

<style lang="scss">
.list-card-image-wrapper {
  position: relative;
}

.render-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  .render-loader {
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.render-loader-wrapper.over-top {
  z-index: 1000;
}

.render-stripes {
  width: 50px;
  text-align: center;

  span {
    display: inline-block;
    width: 5px;
    height: 20px;
    background-color: $color-primary;
    margin-right: 3px;
  }

  span:nth-child(1) {
    animation: grow 1s ease-in-out infinite;
  }

  span:nth-child(2) {
    animation: grow 1s ease-in-out 0.15s infinite;
  }

  span:nth-child(3) {
    animation: grow 1s ease-in-out 0.3s infinite;
  }

  span:nth-child(4) {
    animation: grow 1s ease-in-out 0.45s infinite;
  }
}

@keyframes grow {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}
</style>

<i18n>
{
  "ru": {
    "watch": "Посмотреть"
  },
  "en": {
    "watch": "Watch"
  }
}
</i18n>
