import axios from '../plugins/axios_and_toast.js'
import pkgInfo from './../../package.json'

const { version } = pkgInfo

async function uploadFile(
  file,
  url = 'import/image/upload',
  cdnFolder = 'letter',
  size = null,
  dontResize = false
) {
  // [Переписать] Входящие парамметры сделать ввиде ключей объекта
  const token = localStorage.getItem('jwt_key') ?? false
  const accountId = localStorage.getItem('account_id') ?? false
  const fullUrl = `${url}`
  const config = {
    headers: {
      'X-Client-SPA': 1.11,
      'X-API-Version': version,
      Authorization: token,
      'X-Account-ID': accountId,
      'Content-Type': 'multipart/form-data',
    },
  }

  if (!file) return null

  let formData = new FormData()
  formData.append('file', file)
  formData.append('type', cdnFolder)

  if (size) formData.append('size', size)
  if (dontResize) formData.append('dontResize', true)

  const result = await axios.post(fullUrl, formData, config)
  return result.data
}

async function downloadFile(url) {
  const fullUrl = `${url}`
  const token = localStorage.getItem('jwt_key') ?? false
  const accountId = localStorage.getItem('account_id') ?? false
  const config = {
    headers: {
      'X-Client-SPA': 1.11,
      'X-API-Version': version,
      Authorization: token,
      'X-Account-ID': accountId,
      'Content-Type': 'multipart/form-data',
    },
  }

  axios({
    url: fullUrl,
    method: 'GET',
    responseType: 'blob',
    ...config,
  }).then((response) => {
    console.log(response)
    const url = URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url

    const regexp = new RegExp(/filename="([^"]*)"/)
    const fileName = response.headers['content-disposition'].match(regexp)
    link.setAttribute('download', fileName[1] ?? `letter-${accountId}.html`) //or any other extension
    document.body.appendChild(link)
    link.click()
  })
}

export { uploadFile, downloadFile }
