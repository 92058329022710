// Обработка выделения активного модуля
/* eslint-disable */
import { closeEmojiPicker } from '@/utilities/emoji-picker.js'
import { moduleParser, getElementOptions } from '@/utilities/moduleParser.js'
// [Переписать] обращения к тегам
export default {
  methods: {
    // Закрытие сайдбара-настроек модуля при клике вне модуля
    outsideClickHandler(e) {
      // console.log('outside click handler')
      const target = e.target.closest(
        '.sidebar-for-page, .v-dialog, .v-menu__content'
      )

      if (!target) {
        this.$store.commit('editor/clearSelected')
      }
    },
    moduleClickHandler(event, uuid, groupID) {
      // Если клик по кнопке открытия бибилотеки, то завершаем выполнение
      if (!event.target.closest('.module-code')) {
        return false
      }

      closeEmojiPicker(event)

      if (groupID) this.$store.commit('editor/updateGroupId', groupID)
      this.$store.commit('editor/toggleSidebarOptions', false)

      try {
        const textTags = ['P', 'A', 'SPAN']
        const module = event.target.closest('.editor-module')
        const isText = textTags.some((tag) => {
          return event.target.tagName == tag || event.target.closest(tag)
        })
        const isImage = event.target.tagName === 'IMG'

        this.selectModule(module, uuid)

        if (isText) {
          this.selectTextElement(event.target)
        } else if (isImage) {
          const isTimer = event.target.closest('letteros-timer-box')
          if (!isTimer) {
            this.selectImage(event.target)
          } else {
            // Интересно [Переписать]
            console.log('Timer img selected', event)
          }
        } else {
          this.$store.commit('editor/clearSelectedElementOptions')
          this.$store.commit('editor/clearSelectedElement')
        }

        // [Переписать] открытие сайдбара
        const menuBtn = document.querySelector('.header-sidebar-toggle')
        if (menuBtn.classList.contains('btn-menu-active')) {
          menuBtn.click()
        }
      } catch (e) {
        console.log('module Click Handler', e)
      }
    },
    selectModule(module, uuid) {
      if (this.selectedModuleUuid !== uuid) {
        const moduleCode = module.querySelector('.module-code')
        const moduleOptions = this.modules.find(
          (module) => module.uuid == uuid
        ).options
        const resultOptions = moduleParser(moduleCode, moduleOptions)

        this.$store.commit('editor/setSelectedModuleOptions', resultOptions)
        this.$store.commit('editor/updateSelected', {
          module,
          uuid,
        })
      }
    },
    // [Переписать] в selectedElement нужно записывать [letteros-type], а не дочерние ноды
    // Чтобы при каждом клике не запускалась инициализация optionTextEditor
    selectTextElement(target) {
      const validTags = ['P', 'A', 'SPAN']
      const textElement = validTags.includes(target.tagName)
        ? target
        : target.closest('p') || target.closest('a') || target.closest('span')

      if (!textElement) return null

      // Очищаем стор
      this.$store.commit('editor/clearSelectedElementOptions')

      // Задаем новые данные
      const elementOptions = getElementOptions(target)
      target.classList.add('active-element')
      this.$store.commit('editor/updateSelectedElement', textElement)
      this.$store.commit('editor/pushSelectedElementOptions', elementOptions)
    },
    selectImage(target) {
      this.clearActiveElement()
      if (target.classList.contains('letteros-timer-image')) {
        return false
      }
      // Очищаем стор
      this.$store.commit('editor/clearSelectedElementOptions')

      // Задаем новые данные
      const elementOptions = getElementOptions(target)
      target.classList.add('active-element')
      this.$store.commit('editor/updateSelectedElement', target)
      this.$store.commit('editor/pushSelectedElementOptions', elementOptions)
    },
    clearActiveElement() {
      const elements = document.querySelectorAll('.active-element')
      elements.forEach((element) => {
        element.classList.remove('active-element')
      })
    },
  },
}
