/* eslint-disable */
import {
  uploadTemplateZIP,
  loadTemplate,
  updateTemplate,
} from '@/utilities/template.js'

//Временные функции
const helpers = {
  // Подготовка модулей перед сохранением в обход стора
  prepareModule(groups) {
    groups.forEach((group) => {
      group.modules.forEach((module) => {
        const clone = helpers.clearModule(module.id)
        module.html = clone.innerHTML
      })
    })

    return groups
  },
  clearModule(uuid) {
    const element = document.querySelector(
      `.editor-module[data-uuid="${uuid}"]`
    )
    let clone = element.querySelector('.module-code').cloneNode(true)

    clone.querySelectorAll('.active-element').forEach((el) => {
      el.classList.remove('active-element')
    })
    clone.querySelectorAll('.mce-content-body').forEach((el) => {
      el.classList.remove('mce-content-body')
      el.removeAttribute('id')
    })
    clone.querySelectorAll('.mce-edit-focus').forEach((el) => {
      el.classList.remove('mce-edit-focus')
    })
    // Очистка изображений
    clone.querySelectorAll('[data-mce-style]').forEach((el) => {
      el.removeAttribute('data-mce-style')
      el.removeAttribute('data-mce-src')
    })
    // Очистка текстовых узлов
    clone.querySelectorAll('[letteros-type]').forEach((el) => {
      el.removeAttribute('contenteditable')
      el.removeAttribute('spellcheck')
      if (el.getAttribute('class') == '') el.removeAttribute('class')
    })

    // clone = helpers.fixRow(clone)

    return clone
  },
  // Перенос стилей для letteros-row (Не помогло, бэк все равно ломает шаблон)
  fixRow(module) {
    const table = module.children[0]
    const row = table.querySelector('[letteros-row]')
    const attrs = table.getAttributeNames().reduce((acc, name) => {
      return { ...acc, [name]: table.getAttribute(name) }
    }, {})

    Object.entries(attrs).forEach(([name, value]) => {
      if (name != 'cellpadding' && name != 'cellspacing' && name != 'width') {
        if (row) {
          table.removeAttribute(name)
          row.setAttribute(name, value)
        }
      }
    })

    return module
  },
}

const state = () => ({
  sidebarShow: false,
  deviceType: 'desktop',
  groups: null,
  config: null,
  id: null,
  enableDarkTheme: false,
})

const getters = {
  // Используются только для чтения, чтобы отрабатывал функционал selectable
  getModulesWithUuid(state) {
    let modules = []

    // Добавляем uuid для фронта, так как с бэка он в шаблонах не летит
    state.groups.forEach((group) => {
      group.modules.forEach((module) => {
        const newModule = Object.assign({}, module)
        newModule.uuid = module.id
        modules.push(newModule)
      })
    })

    return modules
  },
  getModuleById: (state) => (payload) => {
    let module = null
    state.groups.forEach((group) => {
      if (group.id == payload.group) {
        module = group.modules.find((module) => module.id == payload.id)
      }
    })

    return module
  },
}

const mutations = {
  toggleSidebar(state) {
    state.sidebarShow = !state.sidebarShow
  },
  showSidebar(state) {
    state.sidebarShow = true
  },
  hideSidebar(state) {
    state.sidebarShow = false
  },
  toggleDevicyType(state, payload) {
    state.deviceType = payload
  },
  setTemplate(state, payload) {
    state.groups = payload.modulesGroups
    state.config = payload.config
    state.id = payload.id
  },
  deleteModule(state, payload) {
    state.groups.forEach((group) => {
      if (group.id == payload.group) {
        group.modules = group.modules.filter((module) => {
          return module.id != payload.id
        })
      }
    })
  },
  cloneModule(state, payload) {
    state.groups.forEach((group) => {
      if (group.id == payload.module.group_id) {
        const index = group.modules.findIndex(
          (module) => module.id == payload.oldId
        )
        group.modules.splice(index + 1, 0, payload.module)
      }
    })
  },
  toggleDarkTheme(state) {
    state.enableDarkTheme = !state.enableDarkTheme
  },
}

const actions = {
  async uploadZip({ commit }, payload) {
    try {
      await uploadTemplateZIP(payload.data, payload.id)
      return true
    } catch (e) {
      return false
    }
  },
  async loadTemplate({ commit }, id) {
    try {
      return await loadTemplate(id)
    } catch (e) {
      return false
    }
  },
  async saveTemplate({ state, commit }, groups) {
    try {
      const groupsActual = helpers.prepareModule(
        JSON.parse(JSON.stringify(groups))
      )

      return await updateTemplate(state.id, {
        modulesGroups: groupsActual,
      })
    } catch (e) {
      // console.log(e)
      return false
    }
  },
  // Клонирование модуля
  cloneModule({ state, commit }, payload) {
    payload.module.html = helpers.clearModule(payload.oldId).innerHTML
    commit('cloneModule', payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
