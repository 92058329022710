<template>
  <div>
    <v-list>
      <!-- Объекты только из версии 1.0 -->
      <v-list-item :inactive="false" :ripple="false" @click="showOld">
        {{ $t('tableHeader.old-editor') }}
      </v-list-item>

      <!-- Объекты только из новой версии -->
      <v-list-item :inactive="false" :ripple="false" @click="showNew">
        {{ $t('tableHeader.new-editor') }}
      </v-list-item>

      <!-- Снятие выделения -->
      <v-list-item :inactive="false" :ripple="false" @click="toggleSelected">
        <IconCheck />
        {{ selectedText }}
      </v-list-item>

      <!-- Добавление в папку выбранных объектов -->
      <v-list-item
        :disabled="!isSelectedExist"
        :ripple="false"
        @click="moveToFolderShow = true">
        <IconFolder />
        {{ $t('tableHeader.add-to-folder') }}
      </v-list-item>

      <!-- Удаление объектов из папки -->
      <v-list-item
        v-show="isFolder"
        :disabled="!isSelectedExist"
        :ripple="false"
        @click="removeFromFolder">
        <IconDelete />
        {{ $t('tableHeader.delete-from-folder') }}
      </v-list-item>

      <!-- Удаление объектов -->
      <v-list-item
        :disabled="!isSelectedExist"
        :ripple="false"
        @click="deleteSelected">
        <IconDelete />
        {{ $t('tableHeader.remove') }}
      </v-list-item>
    </v-list>

    <!-- Модалка перемещения в папку -->
    <v-dialog v-model="moveToFolderShow" max-width="600">
      <DocumentFolderMove
        :items-to-move-ids="selectedList"
        @close="moveToFolderShow = false" />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import IconCheck from '@/components/icons/IconCheck.vue'
import IconDelete from '@/components/icons/IconDelete.vue'
import IconFolder from '@/components/icons/IconFolder.vue'
import project from '@/mixins/project.mixin.js'

import DocumentFolderMove from '@/components/home/document/DocumentFolderMove.vue'

export default {
  name: 'ControlActions',
  mixins: [project],
  components: {
    IconCheck,
    IconDelete,
    IconFolder,
    DocumentFolderMove,
  },
  data() {
    return {
      moveToFolderShow: false,
    }
  },
  computed: {
    isFolder() {
      const route = this.$route.name
      let state = false

      switch (true) {
        case route == 'directory-template':
          state = true
          break
        case route == 'directory-letter':
          state = true
          break
        case route == 'directory-letter-custom':
          state = true
          break
      }

      return state
    },
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    selectedItems() {
      return this.$store.state.itemsStore.selected
    },
    isSelectedExist() {
      return this.$store.getters['itemsStore/activeSelected']
    },
    selectedText() {
      return this.isSelectedExist
        ? this.$t('tableHeader.unselect')
        : this.$t('tableHeader.select-all')
    },
    selectedList() {
      if (this.selectedItems.length === 0) return []

      const idList = this.selectedItems
        .filter((n) => {
          if (n.selected) return n.id
        })
        .map((n) => n.id)

      return idList
    },
  },
  methods: {
    async showOld() {
      this.$store.commit('tableStore/changeLetterType', 1)
      this.$store.commit('tableStore/changeTemplateType', 1)
      await this.$store.dispatch('itemsStore/getData')
    },
    async showNew() {
      this.$store.commit('tableStore/changeLetterType', 0)
      this.$store.commit('tableStore/changeTemplateType', 0)
      await this.$store.dispatch('itemsStore/getData')
    },
    async removeFromFolder() {
      await this.$store.dispatch('itemsStore/removeFromFolder', {
        idList: this.selectedList,
        folderID: this.$route.params.id,
        pageType: this.pageType,
      })

      await this.$store.dispatch('itemsStore/getData')
      await this.loadProject()
    },
    async deleteSelected() {
      for (const index in this.selectedItems) {
        if (this.selectedItems[index].selected) {
          await this.$store.dispatch(
            'itemsStore/removeItem',
            this.selectedItems[index].id
          )
        }
      }

      return await this.$store.dispatch('itemsStore/getData')
    },
    toggleSelected() {
      if (this.isSelectedExist) {
        this.$store.commit('itemsStore/unselectAll')
      } else {
        this.$store.commit('itemsStore/selectAll')
      }
    },
  },
}
</script>
