<template>
  <div
    v-show="display"
    style="margin-bottom: 10px !important; margin-top: -18px !important">
    <span class="file-note red--text">
      {{ getMessage() }}
    </span>
  </div>
</template>

<script>
// [Переписать] отрефакторить [LATER]
export default {
  name: 'ValidateErrorHint',
  components: {},
  props: {
    fieldName: {
      type: String,
      default: 'any',
    },
    errorType: {
      type: String,
      default: 'required',
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getMessage() {
      return this.$t(this.fieldName + '-' + this.errorType)
    },
  },
}
</script>

<i18n>
{
  "ru": {
    "email-email": "Некорректный еmail!",
    "any-required": "Поле обязательно для заполнения!",
    "password-minlen-8": "Пароль должен состоять как минимум из 8 символов!",
    "password-confirm-same-as-password": "Пароли не совпадают!",
    "checking-account-decimal": "Рассчетный счёт состоит только из цифр!",
    "bic-decimal": "БИК состоит только из цифр!",
    "inn-decimal": "ИНН состоит только из цифр!",
    "bic-length-9": "БИК состоит из 9 цифр!",
    "checking-account-length-20": "Рассчетный счёт состоит из 20 цифр!",
    "inn-length-10-or-12": "INN состоит из 10 или 12 цифр!",
    "inn-control-sum": "Не сходится контрольное число!",
    "checking-account-control-sum": "Не сходится контрольное число!"
  },
  "en": {
    "email-email": "Email is not valid!",
    "any-required": "Field should not be empty!",
    "password-minlen-8": "Password should have at least 8 symbols in it!",
    "password-confirm-same-as-password": "Passwords should be same!",
    "checking-account-decimal": "Checking account can consist only of digits!",
    "bic-decimal": "BIC can consist only of digits!",
    "inn-decimal": "INN can consist only of digits!",
    "bic-length-9": "BIC consists of 9 digits!",
    "checking-account-length-20": "Checking account consists of 20 digits!",
    "inn-length-10-or-12": "INN consists of 10 or 12 digits!",
    "inn-control-sum": "Bad checksum!",
    "checking-account-control-sum": "Bad checksum!"
  }
}
</i18n>
