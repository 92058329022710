<template>
  <div>
    <v-menu
      content-class="project-menu"
      nudge-bottom="4"
      offset-y
      min-width="170"
      left>
      <template #activator="{ on, attrs }">
        <v-btn
          class="btn-small project-btn hidden-xs-only"
          width="170"
          outlined
          v-bind="attrs"
          :ripple="false"
          v-on="on">
          <span class="flex-grow-1 text-left text-truncate">
            {{ $t('headerMenuShare.share') }}
          </span>
          <v-icon right size="25">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="pt-0">
        <v-list-item
          class="project-menu-item project-menu-settings"
          :ripple="false"
          @click="copyLink">
          {{ $t('headerMenuShare.copy-link') }}
        </v-list-item>
        <v-list-item
          class="project-menu-item project-menu-settings"
          :ripple="false"
          @click="openDialogue">
          {{ $t('headerMenuShare.insert-the-code') }}
        </v-list-item>
        <v-list-item
          class="project-menu-item project-menu-settings"
          :ripple="false">
          <a :href="oldAppRoute" target="_blank">
            {{ $t('headerMenuShare.without-header') }}
          </a>
        </v-list-item>
      </v-list>
    </v-menu>
    <InfoMessage
      :show-message="showSuccessCopyMessage"
      :header="$t('headerMenuShare.success')"
      :message="$t('headerMenuShare.copy-success')"
      @close-message="closeInfoMessage" />
    <InsertTheCodeDialogWrapper ref="insertTheCode">
      <InsertTheCodeDialogueFrame @copy-code="copy" />
    </InsertTheCodeDialogWrapper>
  </div>
</template>

<script>
import InsertTheCodeDialogueFrame from '@/components/InsertTheCodeDialogueFrame.vue'
import InsertTheCodeDialogWrapper from '@/components/InsertTheCodeDialogWrapper.vue'
import InfoMessage from '@/components/InfoMessage'

export default {
  name: 'HeaderMenuShare',
  components: {
    InfoMessage,
    InsertTheCodeDialogWrapper,
    InsertTheCodeDialogueFrame,
  },
  computed: {
    showSuccessCopyMessage() {
      return this.$store.state.share.showSuccessCopyMessage
    },
    oldAppRoute() {
      return `${
        window.appBaseUrl ?? process.env.VUE_APP_BACKEND_HOST
      }/public/letter/preview?id=${this.$route.params.id}`
    },
  },
  methods: {
    copyLink() {
      const text = window.location.href
      this.copy(text)
    },
    copy(text) {
      const dummy = document.createElement('input')
      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)
      this.$store.commit('share/openMessage')
      setTimeout(() => {
        this.$store.commit('share/closeMessage')
      }, 2500)
    },
    openDialogue() {
      this.$refs.insertTheCode.open()
    },
    closeInfoMessage() {
      this.$store.commit('share/closeMessage')
    },
  },
}
</script>
