<template>
  <header class="header-template-editor">
    <div class="header-left">
      <v-btn
        class="header-sidebar-toggle btn-small mr-4"
        :class="{ 'btn-menu-active': sidebarShow }"
        outlined
        :ripple="false"
        @click="toggleSidebar">
        {{ $t('headerTemplateEditor.menu') }}
        <v-icon right size="25">mdi-chevron-down</v-icon>
      </v-btn>
      <slot name="project-menu" />
      <div v-if="headerExpanded" class="header-history-bar">
        <v-btn outlined disabled icon class="btn-small mr-3" :ripple="false">
          <IconUndo />
        </v-btn>
        <v-btn outlined disabled icon class="btn-small" :ripple="false">
          <IconRedo />
        </v-btn>
      </div>
    </div>
    <div v-if="headerExpanded" class="header-middle">
      <div class="header-mode-bar">
        <v-divider class="mx-3" vertical />
        <v-btn
          outlined
          :ripple="false"
          icon
          class="btn-small mr-3"
          :class="{ active: deviceType === 'desktop' }"
          @click="toggleDevice('desktop')">
          <IconDesktop />
        </v-btn>
        <v-btn
          outlined
          :ripple="false"
          icon
          class="btn-small"
          :class="{ active: deviceType === 'mobile' }"
          @click="toggleDevice('mobile')">
          <IconMobile />
        </v-btn>
        <v-divider class="mx-3" vertical />
        <v-btn
          outlined
          icon
          :ripple="false"
          class="btn-small black-hover invert-color"
          :class="{ active: enableDarkTheme }"
          @click="toggleTheme">
          <IconMode />
        </v-btn>
        <v-divider class="mx-3" vertical />
      </div>
    </div>
    <div class="header-right">
      <div class="header-dropdown d-flex">
        <v-btn
          class="dropdown-link"
          :ripple="false"
          depressed
          color="primary"
          @click="saveExit">
          {{ $t('headerTemplateEditor.save-and-exit') }}
        </v-btn>
        <v-menu
          content-class="header-dropdown-menu"
          attach=".header-dropdown"
          nudge-bottom="6"
          offset-y
          min-width="245"
          left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="dropdown-btn"
              :ripple="false"
              depressed
              color="primary"
              v-bind="attrs"
              v-on="on">
              <v-icon size="23">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="$route.name === 'template-settings'"
              class="dropdown-menu-item"
              link
              :to="{
                name: 'template-editor',
                params: { id: $route.params.id },
              }">
              <IconUndo class="dropdown-menu-icon dropdown-menu-icon-undo" />
              {{ $t('headerTemplateEditor.to-the-editor') }}
            </v-list-item>
            <v-list-item
              class="dropdown-menu-item mb-1"
              :ripple="false"
              link
              @click="downloadTemplate">
              <IconDownload class="dropdown-menu-icon" />
              {{ $t('headerTemplateEditor.download-template') }}
            </v-list-item>
            <v-list-item
              class="dropdown-menu-item mb-1"
              :ripple="false"
              link
              @click="codeWrapShow = true">
              <IconCode class="dropdown-menu-icon" />
              {{ $t('headerTemplateEditor.wrap-editor') }}
            </v-list-item>
            <v-divider />
            <v-list-item
              class="dropdown-menu-item mb-1"
              :ripple="false"
              link
              @click="saveTemplate">
              <IconSave class="dropdown-menu-icon" />
              {{ $t('headerTemplateEditor.save') }}
            </v-list-item>
            <v-list-item
              class="dropdown-menu-item"
              :ripple="false"
              link
              @click="saveExit">
              <IconExit class="dropdown-menu-icon" />
              {{ $t('headerTemplateEditor.save-and-exit') }}
            </v-list-item>
            <v-list-item
              class="dropdown-menu-item"
              link
              :ripple="false"
              @click="exitWithoutSave">
              <IconExit class="dropdown-menu-icon" />
              {{ $t('headerTemplateEditor.exit') }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-dialog
      v-if="codeWrapShow"
      v-model="codeWrapShow"
      width="100%"
      content-class="code-editor"
      :transition="false">
      <ModuleWrapUpdate @close="codeWrapShow = false" />
      <v-btn
        class="code-editor-close"
        ref="wrap-editor-close"
        :ripple="false"
        icon
        color="white"
        @click="codeWrapShow = false">
        <IconClose />
      </v-btn>
    </v-dialog>
  </header>
</template>

<script>
/* eslint-disable */
import IconRedo from '@/components/icons/IconRedo'
import IconUndo from '@/components/icons/IconUndo.vue'
import IconSave from '@/components/icons/IconSave.vue'
import IconDownload from '@/components/icons/IconDownload.vue'
import IconExit from '@/components/icons/IconExit.vue'
import IconDesktop from '@/components/icons/IconDesktop.vue'
import IconMobile from '@/components/icons/IconMobile.vue'
import IconMode from '@/components/icons/IconMode.vue'
import IconCode from '@/components/icons/IconCode.vue'
import IconClose from '@/components/icons/IconClose.vue'

import { downloadFile } from '@/utilities/fileHandler'
import { displayNotify } from '@/utilities/helpers'

import ModuleWrapUpdate from '@/components/editor/ModuleWrapUpdate.vue'
import moduleMixin from '@/mixins/module.mixin.js'

export default {
  name: 'HeaderTemplateEditor',
  components: {
    IconRedo,
    IconUndo,
    IconSave,
    IconDownload,
    IconExit,
    IconDesktop,
    IconMobile,
    IconMode,
    IconCode,
    ModuleWrapUpdate,
    IconClose,
  },
  mixins: [moduleMixin],
  data() {
    return {
      menu: null,
      test: false,
      codeWrapShow: false,
      headerExpanded: true,
    }
  },
  computed: {
    sidebarShow() {
      return this.$store.state.template.sidebarShow
    },
    deviceType() {
      return this.$store.state.template.deviceType
    },
    enableDarkTheme() {
      return this.$store.state.template.enableDarkTheme
    },
  },
  methods: {
    async downloadTemplate() {
      this.$loader('show')
      try {
        // [Переписать] проверить запрос [NEW_API]
        await downloadFile(
          `/import/template/download-zip?id=` + this.$route.params.id
        )
        displayNotify(this.$t('headerTemplateEditor.download-success'))
      } catch (e) {
        displayNotify(this.$t('headerTemplateEditor.download-error'), 'error')
      }
      this.$loader('hide')
    },
    async saveExit() {
      await this.saveTemplate()
      // [Переписать] проработать ошибки [NEW_API]
      this.$router.push({ name: 'template-index' })
    },
    exitWithoutSave() {
      this.$router.push({ name: 'template-index' })
    },
    async saveTemplate() {
      this.$loader('show')

      let groups = this.$store.state.template.groups

      groups = groups.map((group) => {
        const transformedModules = group.modules.map((module) =>
          this.getHTMLModule(module, true)
        )
        return { ...group, modules: transformedModules }
      })

      const result = await this.$store.dispatch('template/saveTemplate', groups)
      if (result) {
        displayNotify('Шаблон успешно сохранен')
      } else {
        displayNotify('При сохранении шаблона произошла ошибка', 'error')
      }
      this.$loader('hide')
      return
    },
    toggleTheme() {
      this.$store.commit('template/toggleDarkTheme')
    },
    toggleSidebar() {
      this.$store.commit('template/toggleSidebar')
    },
    toggleDevice(type) {
      this.$store.commit('template/toggleDevicyType', type)
    },
  },
}
</script>

<style lang="scss">
.header-template-editor {
  .invert-color {
    &:hover {
      color: #151515 !important;
      background-color: #ffffff !important;
      svg {
        transform: rotate(180deg);
      }
    }
    &.active {
      &:hover {
        color: #ffffff !important;
        background-color: #151515 !important;
        svg {
          transform: rotate(180deg);
        }
      }
      svg {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }
  }
}
.redo {
  transform: scaleX(-1);
}

.header-middle-buttons {
  .v-divider--vertical {
    margin: 0 10px 0 0;
  }
}

.header-middle-btn {
  border-color: $color-white;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.active {
    background: $color-dark;

    svg {
      color: $color-white;
    }
  }
}

.header-middle-btn-out {
  border-color: currentColor;
}
</style>
