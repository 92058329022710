<template src="./LetterEditor/index.vue"></template>

<script>
/**
 * Представление и стилизация вынесены в отдельные файлы для удобства
 */
/* eslint-disable */
import ModuleCodeEditor from '@/components/editor/ModuleCodeEditor.vue'
import LetterModuleControls from '@/components/editor/LetterModuleControls.vue'

import SidebarEditor from '@/components/sidebars/SidebarEditor.vue' // сайдбар с настройками выбранного модуля

import IconClose from '@/components/icons/IconClose.vue' //structure and codeEditor
import IconPlus from '@/components/icons/IconPlus.vue'
import IconFolder from '@/components/icons/IconFolder.vue'

import PromiseDialogWrapper from '@/components/PromiseDialogWrapper.vue'
import PromiseDialogFrame from '@/components/PromiseDialogFrame.vue'
import { displayNotify } from '@/utilities/helpers'

// Mixins List
import dynamicStore from '@/mixins/LetterEditor/dynamicStore.mixin.js'
// import structureMixin from '@/mixins/LetterEditor/structure.mixin.js'
import dragableMixin from '@/mixins/LetterEditor/dragable.mixin.js'
import resizableMixin from '@/mixins/resizable.mixin.js'
import selectableMixin from '@/mixins/selectable.mixin.js'
import editorMixin from '@/mixins/editor.mixin.js'
import emojiMixin from '@/mixins/emoji.mixin.js'
import moduleMixin from '@/mixins/module.mixin.js'

export default {
  name: 'LetterEditor',
  mixins: [
    dynamicStore,
    // structureMixin,
    dragableMixin,
    resizableMixin,
    selectableMixin,
    emojiMixin,
    editorMixin,
    moduleMixin,
  ],
  components: {
    PromiseDialogFrame,
    PromiseDialogWrapper,
    IconPlus,
    IconClose,
    IconFolder,
    SidebarEditor,
    LetterModuleControls,
    ModuleCodeEditor,
  },
  data() {
    return {
      structureExist: false, // Для скрытия структур
      showGridSelect: false, // Из структур
      gridWidthMobile: 375, // Статична для мобил
      headerCollapsed: false,
      textEditor: null,
    }
  },
  computed: {
    documentData() {
      return this.$store.state.letter.letter
    },
    selectedModuleUuid() {
      return this.$store.state.editor.selectedModuleUuid
    },
    selectedModule() {
      return this.$store.state.editor.selectedModule
    },
    selectedElement() {
      return this.$store.state.editor.selectedElement
    },
    isSuperUser() {
      return this.$store.state.userStore.user.superUser
    },
    modules() {
      return this.$store.getters['letter/getLetterModules']
    },
    device() {
      return this.$store.state.letter.workAreaDeviceType
    },
    // [Переписать]
    viewPortCss() {
      if (this.device !== 'desktop') {
        return {
          maxWidth: `${this.gridWidthMobile}px`,
          width: `${this.gridWidthMobile}px`,
        }
      }

      let grid = this.$store.state.letter.letter.template?.config?.grid
      if (grid === undefined || !grid) {
        return {
          maxWidth: `${this.gridWidth}px`,
          width: `${this.gridWidth}px`,
        }
      }

      // из-за непоследовательности на беке, есть шаблоны и с px и без
      if (typeof grid === 'number') grid += 'px'

      return {
        maxWidth: grid,
        width: grid,
      }
    },
    computedGrid() {
      if (this.device !== 'desktop') return this.gridWidthMobile

      let grid = +this.$store.state.letter.letter.template?.config?.grid
      if (grid === undefined || !grid) return this.gridWidth

      return grid
    },
    selectedElementCode() {
      return this.selectedModule
        ? this.selectedModule.querySelector('.module-code')
        : null
    },
    sidebarEditorShow() {
      return !!this.selectedModule || !!this.selectedElement
    },
    groups() {
      return this.$store.getters['letter/getTemplateModules']
    },
    structureModulesId() {
      return this.groups.findIndex((item) => item.name === 'Структура')
    },
    enableDarkTheme() {
      return this.$store.state.letter.enableDarkTheme
    },
    codeEditorModalShow: {
      get() {
        return this.$store.state.editor.codeEditorModalShow
      },
      set() {
        this.$store.commit('editor/toggleCodeEditor')
      },
    },
    triggerSidebarOptions() {
      return this.$store.state.editor.triggerSidebarOptions
    },
    selectedModuleOptions() {
      return this.$store.state.editor.selectedModuleOptions
    },
    selectedElementOptions() {
      return this.$store.state.editor.selectedElementOptions
    },
    gridWidth() {
      return this.$store.getters['letter/getLetterGrid'] ?? 600
    },
    linkColor() {
      return this.$store.state.letter.letter.template.config.link ?? '#001dff'
    },
    isEditorChanged() {
      return this.$store.state.editor.isChanged
    },
    isLetterSave() {
      return this.$store.state.letter.isLetterSave
    },
    emojiPickerId() {
      return this.$store.getters['editor/getEmojiPickerId']
    },
  },
  created() {
    this.$store.commit('letter/sortModules')
    this.updateModules()
    this.clearMainFields()
  },
  mounted() {
    document
      .getElementById('app')
      .addEventListener('mousedown', this.outsideClickHandler)

    if (!this.emojiPickerId) {
      this.initEmojiPicker()
    }
    this.$store.commit('letter/hideSidebar')

    this.setActiveModule()
  },
  beforeDestroy() {
    document
      .getElementById('app')
      .removeEventListener('mousedown', this.outsideClickHandler)
    if (this.emojiPickerId) {
      document.getElementById(this.emojiPickerId).remove()
    }
  },
  async beforeRouteLeave(to, from, next) {
    // [Переписать] всплывает когда не надо
    if (this.isEditorChanged) {
      const result = await this.$refs.leaveDialog.open()
      if (result) {
        await this.$store.dispatch('letter/saveLetter', { autosave: false })
        if (this.isLetterSave) {
          displayNotify(this.$t('letterLayout.letter-saved'))
        } else {
          displayNotify(this.$t('letterLayout.letter-not-saved'), true)
        }
        // Подстановкка актуального html кода модулей в стор
        this.$store.dispatch('letter/refreshModules')
      }
    }
    next()
  },
  methods: {
    // Пост-обработка модулей сортировка и ресайз ui
    updateModules() {
      const modules = this.$store.getters['letter/getLetterModules']
      const updatedModules = modules.map((module) => this.getHTMLModule(module))

      this.$store.commit('letter/updateModules', updatedModules)
    },
    // Используется в get Letter (Очистка полей письма)
    clearMainFields() {
      if (this.$store.state.letter.letter.subject === 'Тема') {
        this.$store.commit('letter/clearLetterSubject')
      }

      if (this.$store.state.letter.letter.name === 'Название') {
        this.$store.commit('letter/clearLetterName')
      }

      if (this.$store.state.letter.letter.preheader === 'Прехедер') {
        this.$store.commit('letter/clearLetterPreheader')
      }
    },
    // Обновление полей в шапке письма
    updateLetterData(e, fieldName) {
      this.$store.commit('letter/updateLetterFields', {
        fieldName,
        value: e.target.value,
      })
    },
    // Какой-то костыль для emoji-picker, чтобы он вставлял emoji в нужное поле в шапке письма [Переписать]
    setFocusField(fieldName) {
      const field = document.getElementById('last-focused-field-for-emoji')
      field.value = fieldName
    },
    // Открытие библиотеки модулей
    showLibrarySidebar() {
      this.$router.push({
        name: 'letter-library',
        params: { id: this.$route.params.id, index: 0 },
      })
    },
    // Подсветка модуля при добавление в рабочую область
    setActiveModule() {
      const newModuleUuid = this.$route.query.select
      if (!newModuleUuid) return false

      this.modules.forEach((module, ix) => {
        if (module.uuid === newModuleUuid) {
          const newModule = this.$refs[`module-${newModuleUuid}`]

          if (newModule) {
            this.$store.commit('editor/toggleSidebarOptions', false)
            this.selectModule(newModule[0], newModuleUuid, ix)
          }

          if (this.$route.query.select) {
            this.$router.replace({ query: null })
          }
        }
      })
    },
    // Закрытие редактора кода
    closeCodeEditor() {
      this.$store.commit('editor/toggleCodeEditor')
    },
  },
}
</script>

<style lang="scss" src="./LetterEditor/index.scss"></style>
