/* eslint-disable */
export default async (to, from, next) => {
  console.log('middleware auth')
  let token = localStorage.getItem('jwt_key') ?? false

  // Если это восстановление пароля из письма на почте
  if (!token) {
    token = new URLSearchParams(location.search).get('token') ?? false
  }
  const accountId = localStorage.getItem('account_id') ?? false
  const publicRoute = [
    'login',
    'signup',
    'signup-complete',
    'forgot',
    'recovery',
    'letter-preview',
    'share-render',
    'share-render-preview',
  ]
  const routeWithoutAccountId = [
    'project-select',
    'new-project-create',
    'recovery',
    'letter-preview',
    'signup-complete',
  ]

  // Запрос на паблик роуты
  if (publicRoute.includes(to.name)) return next()

  // При попытке запроса закрытого роута без токена
  if (!token) return next({ name: 'login' })

  // Если есть токен
  return next()

  // if (!token) {
  //   next({ name: 'login' })
  // } else {
  //   next()
  // }

  // if (to.name === 'signup-complete' && to.query.token === undefined) {
  //   next({ name: 'signup' })
  // }
  // const routeAccess = routeWithoutToken.includes(to.name)
  // if (routeAccess) {
  //   next()
  // } else if (!token) {
  //   next({ name: 'login' })
  // } else if (!accountId && !routeWithoutAccountId.includes(to.name)) {
  //   if ('recovery' === to.name) {
  //     next({ name: 'recovery' })
  //   }
  //   next({ name: 'project-select' })
  // } else next()
}
