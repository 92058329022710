<template>
  <v-navigation-drawer
    class="sidebar-test"
    v-bind:value="show"
    :hide-overlay="true"
    fixed
    stateless
    width="290"
    style="top: 51px"
    height="calc(100% - 51px)">
    <v-list>
      <v-list-item class="mt-6 mb-7">
        <h2 class="title-h5 text-center">
          {{ $t('sidebarTest.testing-the-letter') }}
        </h2>
      </v-list-item>
      <v-divider class="mb-6" />
      <v-list-item>
        <div class="flex-grow-1">
          <p class="title-h6 mb-3">
            {{ $t('sidebarTest.my-addresses') }}
          </p>
          <div
            class="test-recipient d-flex"
            v-for="(recipient, index) in recipients"
            :key="index">
            <v-checkbox
              class=""
              v-model="recipient.is_checked"
              @change="updateRecipient(index)" />
            <v-text-field
              class=""
              v-model="recipient.value"
              outlined
              dense
              type="email"
              @change="updateRecipient(index)" />
          </div>
          <v-btn
            class="btn-small mb-6 btn-black-border"
            :ripple="false"
            depressed
            block
            outlined
            @click="addRecipient">
            {{ $t('sidebarTest.add-a-recipient') }}
          </v-btn>
          <v-btn
            class="mb-8 text-body-1 font-weight-medium"
            :ripple="false"
            color="primary"
            depressed
            block
            x-large
            @click="sendTestEmail">
            {{ $t('sidebarTest.submit-a-test') }}
          </v-btn>
        </div>
      </v-list-item>
      <v-divider class="mb-6" />
      <v-list-item v-if="isSuperUser">
        <div class="flex-grow-1">
          <p class="title-h6 mb-2">{{ $t('sidebarTest.virtual-devices') }}</p>
          <p class="text-caption gray--text mb-5">
            {{ $t('sidebarTest.see-how-the-newsletter-will-look-like') }}
          </p>
          <v-btn
            class="btn-small mb-8 btn-black-border"
            :ripple="false"
            depressed
            block
            outlined
            :to="{ name: devicesRoute, params: { id: $route.params.id } }">
            {{ $t('sidebarTest.select-devices') }}
          </v-btn>
        </div>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { createTestEmail, updateTestEmail } from '@/utilities/letter.js'

import { sendEmailServices } from '@/utilities/letter.js'
import { displayNotify } from '@/utilities/helpers'

/* eslint-disable */
export default {
  name: 'SideBarTest',
  data() {
    return {
      recipients: [],
    }
  },
  computed: {
    type() {
      return this.$route.name == 'letter-custom-test' ? 'custom' : 'default'
    },
    show() {
      return this.type == 'custom'
        ? this.$store.state.customStore.sidebarVisible
        : this.$store.state.letter.sidebarVisible
    },
    isSuperUser() {
      return this.$store.state.userStore.user.superUser
    },
    accountData() {
      return this.$store.getters['project/getProjectFull']
    },
    accountID() {
      return this.accountData.id
    },
    devicesRoute() {
      return this.type == 'custom' ? 'letter-custom-devices' : 'letter-devices'
    },
  },
  watch: {
    // [Переписать] стор проектов, и настроить там отслеживание загрузки данных, и полностью его структурировать, а так же избавиться от этого костыля [NEW_API]
    accountID(newID) {
      if (newID != null) {
        this.appendRecipient()
      }
    },
  },
  mounted() {
    if (this.type == 'custom') {
      this.$store.commit('customStore/showSidebar')
    } else {
      this.$store.commit('letter/showSidebar')
    }
  },
  methods: {
    // Сборка получателей из стора
    appendRecipient() {
      for (const email of this.accountData.inputed_test_emails) {
        this.recipients.push({
          id: email.id,
          value: email.value,
          is_checked: email.is_checked,
          account_id: localStorage.getItem('account_id'),
        })
      }
    },
    // Добавление получателей [Переписать] [NEW_API]
    async addRecipient() {
      const recipient = {
        value: '',
        is_checked: true,
        account_id: +localStorage.getItem('account_id'),
      }
      // [Переписать] в стор [NEW_API]
      const result = await createTestEmail(recipient)
      recipient.id = result.id
      this.recipients.push(recipient)
    },
    // Обновление получателей [Переписать] после фикса на бэке [NEW_API]
    async updateRecipient(key) {
      // [Переписать] в стор [NEW_API]
      await updateTestEmail(this.recipients[key])
    },
    // Отправка тестового письма на почту
    async sendTestEmail() {
      console.log(this.accountData)
      const filteredRecipients = this.recipients.filter(
        (recipient) => +recipient.is_checked === 1
      )
      if (!filteredRecipients.length) return

      for (const recipient of filteredRecipients) {
        // [Переписать] Вынести в стор, и перехватывать ошибки, чтобы axios не дублировал notify, после того, как будет готов бэк [NEW_API]
        // [Переписать] Использовать новый метод sendEmail для отправки письма, старый метод sendEmailServices удалить в @/utilities/letter.js [NEW_API]
        try {
          await sendEmailServices({
            id: this.$route.params.id,
            recipient: recipient.value,
          })
          displayNotify(
            this.$t('letterLayout.email-successfully-sent', {
              email: recipient.value,
            })
          )
        } catch (e) {
          // displayNotify(
          //   this.$t('letterLayout.email-not-sent', { email: recipient.value }),
          //   'error'
          // )
        }
      }
    },
  },
}
</script>

<style lang="scss">
.sidebar-test {
  .test-recipient {
    .v-input--selection-controls {
      margin: 0;
      padding: 0;
    }
  }
}
</style>

