<template>
  <v-navigation-drawer
    v-bind:value="show"
    :hide-overlay="true"
    fixed
    stateless
    width="290"
    style="top: 57px"
    height="calc(100% - 57px)">
    <v-list class="sidebar-library">
      <v-list-item class="mt-5 mb-6" style="padding-right: 0">
        <h2 class="title-h5">
          {{ $t('sidebarLibrary.library') }}
        </h2>
        <v-icon
          size="30"
          class="collapse-icon"
          :class="{ clicked: libraryCollapsed }"
          @click="libraryCollapsed = !libraryCollapsed">
          mdi-chevron-down
        </v-icon>
      </v-list-item>
      <v-divider />
      <template v-for="(module, index) in groups">
        <v-list-item
          v-show="showItemOrNot(module)"
          :key="index"
          class="module-groups"
          :to="calcRoute(index)">
          <a class="module-link">
            {{ module.name }}
          </a>
        </v-list-item>
      </template>
      <v-divider class="mb-6" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SidebarLibrary',
  data() {
    return {
      libraryCollapsed: false,
      scrollElement: null,
      currentPos: 0,
      scrollToNext: false,
      sideebarShow: null,
    }
  },
  computed: {
    show() {
      return this.$store.state.letter.sidebarVisible
    },
    documentId() {
      return this.$store.state.letter.letter.id
    },
    groups() {
      return this.$store.getters['letter/getTemplateModules']
    },
  },
  watch: {
    // [Переписать] [LATER]
    sideebarShow(value) {
      if (value) {
        this.scrollElement = document.querySelector(
          '[id="app"].main-app-wrapper'
        )
        this.scrollElement.addEventListener(
          'scroll',
          () => this.mbGoToNextElement(),
          { passive: true }
        )
      } else {
        this.currentPos = 0
        this.scrollElement.removeEventListener('scroll', () =>
          this.mbGoToNextElement()
        )
        return
      }
      if (this.groups.length > 0 && this.$route.name !== 'letter-library') {
        const scrollElement = document.querySelector(
          '[id="app"].main-app-wrapper'
        )
        scrollElement.scrollTo({ top: 0 })
        this.$router.push(this.calcRoute(0))
      }
    },
  },
  // [Переписать] [LATER]
  created() {
    if (this.$route.name !== 'letter-library') return
    this.scrollElement = document.querySelector('[id="app"].main-app-wrapper')
    this.scrollElement.addEventListener(
      'scroll',
      () => this.mbGoToNextElement(),
      { passive: true }
    )
  },
  mounted() {
    this.$store.commit('letter/showSidebar')
  },
  methods: {
    showItemOrNot(module) {
      if (this.libraryCollapsed) return false
      return module.showInLibrary !== false
    },
    mbGoToNextElement() {
      if (
        this.$route.name !== 'letter-library' ||
        this.groups.length <= this.currentPos
      )
        return
      if (
        this.scrollElement.offsetHeight + this.scrollElement.scrollTop >=
        this.scrollElement.scrollHeight
      ) {
        if (!this.scrollToNext) {
          this.scrollToNext = true
          this.scrollElement.scrollBy({ top: -5 })
          return
        }
        this.scrollToNext = false
        this.scrollElement.scrollTo({ top: 0 })
        this.currentPos++
        this.$router.push(this.calcRoute(this.currentPos))
      }
    },
    // [Переписать] computed [LATER]
    calcRoute(index) {
      const routeName = this.$route.name.includes('template')
        ? 'template-library'
        : 'letter-library'
      return {
        name: routeName,
        params: { id: this.documentId, index: index },
        query: { openSidebar: true },
      }
    },
  },
}
</script>

<style lang="scss">
.sidebar-library {
  .module-groups {
    padding-top: 10px;
    padding-bottom: 10px;
    .module-link {
      color: $color-dark;
      text-decoration: none;
    }
    .module-link.router-link-active {
      color: $color-primary;
    }
    .module-link:hover {
      color: $color-primary;
    }
  }

  .collapse-icon {
    margin-left: 1px;
    cursor: pointer;
    margin-top: 4px;
  }

  .collapse-icon::after {
    background-color: #fdfdfe;
  }

  .collapse-icon.clicked {
    transform: rotate(-180deg);
  }
}
</style>
