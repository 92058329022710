<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M1 19.6763V4.23298C1 3.00569 1.97908 2 3.19247 2H8.46444C8.95816 2 9.43515 2.19602 9.78661 2.55398L12.6402 5.46026C12.8912 5.71595 13.2259 5.86084 13.5858 5.86084H20.7657C21.9958 5.86084 23 6.87505 23 8.13643V19.5911C23 20.9803 21.8954 22.1053 20.5314 22.1053H3.38494C2.06276 22.1053 1 21.0144 1 19.6763Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>