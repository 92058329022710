<template>
  <svg width="26" height="26" id="clock" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13.1298 5.32275V13.8493H17.8177" stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 32},
    height: {type: [Number, String], default: 26},
    viewBox: {type: String, default: '0 0 32 26'},
    fill: {type: String, default: '#151515'}
  }
}
</script>
