<template>
  <div class="row">
    <v-col
      v-for="(item, index) in items"
      :key="index"
      lg="3"
      md="4"
      sm="6"
      cols="12">
      <ItemCard :item="item" />
    </v-col>
  </div>
</template>

<script>
import ItemCard from '@/components/home/items/ItemCard.vue'

export default {
  name: 'CardContainer',
  components: {
    ItemCard,
  },
  computed: {
    items() {
      return this.$store.state.itemsStore.items
    },
  },
}
</script>
