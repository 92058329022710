<template>
  <div class="image-uploader-wrapper">
    <div
      class="image-uploader-image"
      :style="'background-image: url(' + getSrc + ');'" />
    <label
      class="image-uploader"
      @drop.prevent="fileHandler($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false">
      <input
        ref="fileInput"
        class="image-input"
        type="file"
        :accept="accept.join(', ')"
        @change="fileHandler" />
      <slot />
      <p class="gray--text mt-1 mb-n1">
        {{ file ? fileName : $t('or-drag-it-here') }}
      </p>
    </label>
  </div>
</template>

<script>
// [Переписать] отрефакторить [NEW_API]
// [Переписать] попробовать объеденить с FileUploader
import { uploadFile } from '@/utilities/fileHandler.js'
import { displayNotify } from '@/utilities/helpers.js'

export default {
  name: 'ImageUploader',
  props: {
    url: {
      type: String,
      default: 'import/image/upload',
    },
    accept: {
      type: Array,
      default: () => [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/pjpeg',
        'image/webp',
        'image/tiff',
        'image/svg+xml',
      ],
    },
    documentId: {
      type: [Number],
      default: 0,
    },
    documentType: {
      type: String,
      default: 'letter',
    },
    size: {
      type: Number,
      default: 134,
    },
    placeholderImage: {
      type: String,
      default: '/icons/userLarge.svg',
    },
    immediately: Boolean,
  },
  data() {
    return {
      file: undefined,
      dragover: false,
    }
  },
  computed: {
    fileName() {
      return this.file ? this.file.name : ''
    },

    getSrc() {
      return this.file
        ? this.file.url
          ? this.file.url
          : window.URL.createObjectURL(this.file)
        : this.placeholderImage
    },
  },
  methods: {
    fileHandler(event) {
      if (
        event.dataTransfer?.items &&
        event.dataTransfer?.items[0].kind === 'file'
      ) {
        this.file = event.dataTransfer.items[0].getAsFile()
      } else if (event.target?.files) {
        this.file = event.target.files[0]
      }

      if (this.file && this.accept.includes(this.file.type)) {
        if (this.immediately) this.sendFile()
        else this.$emit('file-loaded', this.file)
      } else {
        this.file = undefined
      }
    },
    async sendFile() {
      this.$loader('show')
      try {
        const result = await uploadFile(
          this.file,
          this.url,
          this.documentType,
          this.documentId
        )
        this.$emit('file-uploaded', result)
        displayNotify(this.$t('image-uploaded'))
      } catch (e) {
        displayNotify(this.$t('image-not-uploaded'), 'error')
        console.log('sendFile', { error: e })
      } finally {
        this.$loader('hide')
      }
    },
  },
}
</script>

<style lang="scss">
.image-uploader-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .image-uploader {
    position: relative;

    .image-input {
      display: none;
    }
  }

  .image-uploader-image {
    width: 256px;
    height: 256px;
    border-radius: 50%;
    cursor: pointer;
    background-size: 256px;
    background-position: center center;
  }
}
</style>
<i18n>
{
  "ru": {
    "change": "Изменить",
    "or-drag-it-here": "или перетащите его сюда",
    "image-uploaded": "Изображение загружено",
    "image-not-uploaded": "Изображение не загружено"
  },
  "en": {
    "change": "Change",
    "or-drag-it-here": "or drag it here",
    "image-uploaded": "Image is uploaded",
    "image-not-uploaded": "Image is not uploaded"
  }
}
</i18n>
