import axios from '../plugins/axios_and_toast.js'

async function loadTemplates(
  page = 1,
  limit = 10,
  orderBy = '-id',
  filter,
  templateType
) {
  const url =
    '/template/index?fields=id,name,state,preview,type,updatedAt,isAppLetterosCom'
  let params = {}
  Object.entries({ page, limit, orderBy, ...filter }).forEach(
    ([key, value]) => {
      if (value !== undefined) {
        params[key] = value.toString()
      }
    }
  )

  if (templateType !== null) {
    params.is_app_letteros_com = templateType
  }

  const result = await axios.get(url, { params: { ...params } })
  return result.data
}

async function loadTemplate(id) {
  const url = `/template/view?id=${id}`
  const result = await axios.get(url)
  return result.data
}

async function migrateTemplate(id) {
  const url = `/import/template/migrate`
  const result = await axios.post(url, { template: id })
  return result.data
}

async function migrateState(id) {
  const url = `/import/template/migrate-state`
  const result = await axios.post(url, { queue_id: id })
  return result.data
}

async function uploadTemplateZIP(data, id = null) {
  const url = id
    ? `/import/template/upload-zip?id=${id}`
    : `/import/template/upload-zip`
  const result = await axios.post(url, data)
  return result.data
}

async function uploadTemplateUrl(data) {
  const url = `/import/template/upload-url`
  const result = await axios.post(url, data)
  return result.data
}

async function uploadTemplateCode(data) {
  const url = `/import/template/upload-code`
  const result = await axios.post(url, data)
  return result.data
}

async function cloneTemplate(templateId) {
  const url = `/template/clone?id=${templateId}`
  const result = await axios.post(url)
  return result.data
}
async function updateTemplate(templateId, data) {
  const url = `/template/update?id=${templateId}`
  const result = await axios.put(url, data)
  return result.data
}

async function deleteTemplate(templateId) {
  const url = `/template/delete?id=${templateId}`
  const result = await axios.delete(url)
  return result.data
}

async function updateTemplatesFolder(templatesId, folderId) {
  const url = `/template/update-folders?id=${folderId}`
  const result = await axios.put(url, { templatesId: templatesId })
  return result.data
}

async function deleteTemplatesFromFolder(templatesId, folderId) {
  const url = `/template/delete-templates-from-folder?id=${folderId}`
  const result = await axios.put(url, { templatesId: templatesId })
  return result.data
}

async function loadTemplateByIndustryId(industryId) {
  const url = `/template/industry?id=${industryId}`
  const result = await axios(url)
  return result.data
}

async function createTemplateByIndustry({ modulesGroups, templateName }) {
  const url = `/template/create`
  const result = await axios.post(url, { modulesGroups, templateName })
  return result.data
}

async function uploadFileByIndustry(file) {
  const url = '/template/upload-image'
  const image = new FormData().append('image', file)
  const result = await axios.post(url, image)
  return result.data
}

async function getFonts(sitePath) {
  const url = `/worksheet/get-fonts`
  const result = await axios.post(url, { url: sitePath })
  return result.data
}

async function getColors(sitePath) {
  const url = `/worksheet/get-colors`
  const result = await axios.post(url, { url: sitePath, test_browser: true })
  return result.data
}

async function sendTgInfo() {
  const url = `/worksheet/tg-info`
  const result = await axios.get(url)
  return result.data
}

async function share(options) {
  const url = `/template/share`
  const result = await axios.post(url, options)
  return result.data
}

async function wrapper(options) {
  // set/get request
  const url = `/template/wrapper`
  const result = await axios.post(url, options)
  return result.data
}

export {
  loadTemplate,
  loadTemplates,
  uploadTemplateZIP,
  uploadTemplateUrl,
  uploadTemplateCode,
  cloneTemplate,
  updateTemplate,
  deleteTemplate,
  updateTemplatesFolder,
  deleteTemplatesFromFolder,
  migrateTemplate,
  loadTemplateByIndustryId,
  migrateState,
  createTemplateByIndustry,
  uploadFileByIndustry,
  getFonts,
  getColors,
  sendTgInfo,
  share,
  wrapper,
}
