<template>
  <div v-show="showMessage" class="jq-toast-wrap bottom-right">
    <div class="jq-toast-single jq-has-icon jq-icon-success">
      <span class="jq-toast-loader jq-toast-loaded" />
      <span class="close-jq-toast-single" @click="$emit('close-message')">×</span>
      <h2 class="jq-toast-heading">
        {{ header }}
      </h2>
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoMessage',
  components: {},
  props: {
    showMessage: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss">
.jq-toast-wrap.bottom-right {
  bottom: 20px;
}
.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}
.jq-toast-wrap {
  z-index: 90000 !important;
}
.jq-toast-wrap {
  display: block;
  position: fixed;
  width: 250px;
  pointer-events: none!important;
  letter-spacing: normal;
  z-index: 9000!important;
}
.jq-toast-wrap, .jq-toast-wrap * {
  margin: 0;
  padding: 0;
}
.jq-toast-wrap .jq-icon-success {
  background-color: #fdfdfe;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
  border-radius: 15px;
  border: 1px solid #a9a9a9;
}
.jq-icon-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
  color: #dff0d8;
  background-color: #3c763d;
  border-color: #d6e9c6;
}
.jq-has-icon {
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px;
}
.jq-toast-single {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial,sans-serif;
  line-height: 17px;
  position: relative;
  pointer-events: all!important;
  background-color: #444;
  color: #000000;
  text-align: left;
}
.jq-toast-loader {
  -webkit-transition: width 1.1s ease-in;
  -o-transition: width 1.1s ease-in;
  transition: width 1.1s ease-in;
  background-color: #EC5418;
}
.jq-has-icon {
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px;
}
.jq-toast-loaded {
  width: 100%;
  -webkit-transition: width 1.1s ease-in;
  -o-transition: width 1.1s ease-in;
  transition: width 1.1s ease-in;
  background-color: #EC5418;
}
.close-jq-toast-single {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 14px;
  cursor: pointer;
}
.jq-toast-loader {
  display: block;
  position: absolute;
  top: -2px;
  height: 5px;
  width: 0;
  left: 0;
  border-radius: 5px;
  background: red;
}
.jq-toast-single h2 {
  font-family: arial,sans-serif;
  font-size: 14px;
  margin: 0 0 7px;
  background: 0 0;
  color: inherit;
  line-height: inherit;
  letter-spacing: normal;
}
</style>
<i18n>
  {
    "ru": {

      "success": "Успешно!",
      "copy-success": "Ссылка на веб-версию скопирована"
    },
    "en": {
      "success": "Success!",
      "copy-success": "Link to web version is successfully copied"
    }
  }
</i18n>
