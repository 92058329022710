<template>
  <v-container class="table-empty">
    <ControlPanel />

    <v-row justify="center">
      <v-col lg="6" sm="8" md="10" cols="12">
        <div class="empty-list d-flex flex-column align-center">
          <IconTemplate class="mb-3" />
          <p class="text-center mb-6" style="padding: 0 31px">
            {{ textTranslate }}
          </p>

          <div class="d-flex flex-wrap justify-center mr-n4 mb-n4">
            <v-btn
              class="mr-4 mb-4"
              :ripple="false"
              x-large
              depressed
              color="primary"
              :to="{ name: buttonRoute }">
              {{ buttonTranslate }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IconTemplate from '@/components/icons/IconTemplate.vue'
import ControlPanel from '@/components/home/ControlPanel.vue'

export default {
  name: 'EmptyBox',
  components: { IconTemplate, ControlPanel },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    textTranslate() {
      if (this.pageType == 'template') {
        return this.$t('tableEmpty.not-template')
      }

      if (this.pageType == 'letter' || this.pageType == 'letter-custom') {
        return this.$t('tableEmpty.not-letter')
      }

      return ''
    },
    buttonTranslate() {
      if (this.pageType == 'template') {
        return this.$t('tableEmpty.create-template')
      }

      if (this.pageType == 'letter' || this.pageType == 'letter-custom') {
        return this.$t('tableEmpty.create-letter')
      }

      return ''
    },
    buttonRoute() {
      if (this.pageType == 'letter') {
        return 'letter-create'
      }

      if (this.pageType == 'letter-custom') {
        return 'letter-custom-create'
      }

      if (this.pageType == 'template') {
        return 'template-create'
      }

      return 'template-index'
    },
  },
}
</script>

<style lang="scss" scoped>
.table-empty {
  .empty-list {
    border: 1px dashed $color-gray;
    border-radius: 15px;
    padding: 2rem;
  }
}
</style>
