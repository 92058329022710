<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <circle cx="3" cy="3" r="2" :fill="stroke" />
    <circle cx="3" cy="9" r="2" :fill="stroke" />
    <circle cx="3" cy="15" r="2" :fill="stroke" />
    <circle cx="15" cy="3" r="2" :fill="stroke" />
    <circle cx="15" cy="9" r="2" :fill="stroke" />
    <circle cx="15" cy="15" r="2" :fill="stroke" />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>