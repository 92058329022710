import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { Vuelidate } from 'vuelidate'
import vuetify from './plugins/vuetify'
import Highcharts from 'highcharts'
import variablepie from 'highcharts/modules/variable-pie'
import HighchartsVue from 'highcharts-vue'
import axios from './plugins/axios_and_toast'
import { i18n } from './plugins/i18n'
import { VueMaskDirective } from 'v-mask'
import loader from './plugins/loader'
import './utilities/filters'
import '@/assets/scss/main.scss'

variablepie(Highcharts)
Vue.use(HighchartsVue)
Vue.use(Vuelidate)
Vue.use(loader, { color: '#2D8962' })
Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false

const letteros = new Vue({
  store,
  vuetify,
  router,
  axios,
  i18n,
  created() {
    this.$on('switch-lang', (locale) => {
      console.log('Switch locale to:', locale)
    })
  },
  render: (h) => h(App),
})
letteros.$mount('#app')
