<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M19.4806 22.9999H10.7846C8.83515 22.9999 7.25806 21.4347 7.25806 19.4999V10.8804C7.25806 8.94559 8.83515 7.38037 10.7846 7.38037H19.4806C21.43 7.38037 23.0071 8.94559 23.0071 10.8804V19.5108C22.9962 21.4347 21.4191 22.9999 19.4806 22.9999Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.53096 16.6196C2.58149 16.6196 1.00439 15.0543 1.00439 13.1196V4.5C1.00439 2.56522 2.58149 1 4.53096 1H13.2269C15.1764 1 16.7535 2.56522 16.7535 4.5" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>