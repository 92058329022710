<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M5.02297 3.18876L5.02342 3.18799C5.61946 2.14241 6.72768 1.5 7.92466 1.5H16.0753C17.2723 1.5 18.3805 2.14241 18.9766 3.18799L18.977 3.18876L23.0519 10.3073C23.052 10.3074 23.0521 10.3075 23.0522 10.3077C23.6493 11.3555 23.6493 12.6445 23.0522 13.6923C23.0521 13.6925 23.052 13.6926 23.0519 13.6927L18.977 20.8112L18.9766 20.812C18.3805 21.8576 17.2723 22.5 16.0753 22.5H7.92466C6.72768 22.5 5.61946 21.8576 5.02342 20.812L5.02297 20.8112L0.948076 13.6927C0.948008 13.6926 0.94794 13.6925 0.947872 13.6924C0.350709 12.6445 0.350709 11.3554 0.947872 10.3076C0.94794 10.3075 0.948008 10.3074 0.948076 10.3073L5.02297 3.18876Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 16.4282C14.4248 16.4282 16.3904 14.4465 16.3904 12.0019C16.3904 9.55738 14.4248 7.57568 12 7.57568C9.57527 7.57568 7.60962 9.55738 7.60962 12.0019C7.60962 14.4465 9.57527 16.4282 12 16.4282Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>