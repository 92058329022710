<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <g clip-path="url(#clip0_5829_81)">
      <path d="M23.5091 12.6357V17.8532C23.5091 20.1339 21.703 21.9908 19.4846 21.9908H4.51526C2.29686 21.9908 0.490723 20.1339 0.490723 17.8532V12.6559" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.8577 17.2083V2" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.02808 12.5459L11.1311 17.2487C11.4747 17.6422 12.0637 17.6422 12.417 17.2587L16.6281 12.556" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_5829_81">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>