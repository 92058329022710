import creatorStore from '@/store/dynamic/creator.js'

export default {
  beforeCreate() {
    if (!this.$store.hasModule('creatorStore')) {
      this.$store.registerModule('creatorStore', creatorStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('creatorStore')) {
      this.$store.registerModule('creatorStore', creatorStore)
    }
  },
  beforeDestroy() {
    // console.log('destroyed letter')
    if (this.$store.hasModule('creatorStore')) {
      this.$store.unregisterModule('creatorStore')
    }
  },
}
