<template>
  <v-main class="template-design">
    <v-container>
      <h1 class="design-markup-title title-h1 text-center mb-7">
        {{ $t('templateDesign.layout-according-to-your-design') }}
      </h1>
      <div class="design-markup-container">
        <a class="design-markup-instructions dark--text mb-12" href="#">
          <icon-document class="design-markup-instructions-icon" />
          {{
            $t(
              'templateDesign.instructions-for-preparing-the-layout-for-loading'
            )
          }}
        </a>

        <label class="label" for="letteros-design-markup-link">
          {{ $t('templateDesign.download-the-layout-from-the-link') }}
        </label>
        <v-text-field
          id="letteros-design-markup-link"
          class="mb-n2"
          :placeholder="$t('templateDesign.link-to-the-template')"
          outlined
          type="url" />

        <div class="file mb-8">
          <label for="file">
            <div class="label">{{ $t('templateDesign.upload-as-a-file') }}</div>
            <div class="file-inner text-center">
              <input id="file" ref="fileInput" class="d-sr-only" type="file" />
              <v-btn
                depressed
                :ripple="false"
                outlined
                x-large
                @click="$refs.fileInput.click()">
                {{ $t('templateDesign.select-a-file') }}
              </v-btn>
              <p class="gray--text mt-1 mb-n1">
                {{ $t('templateDesign.or-drag-it-here') }}
              </p>
            </div>
          </label>
          <p class="file-note gray--text mt-2">
            {{ $t('templateDesign.allowed-file-types') }}
          </p>
        </div>

        <v-btn
          class="design-markup-btn px-8 white--text d-table mx-auto"
          :ripple="false"
          x-large
          depressed
          color="primary">
          {{ $t('templateDesign.create-a-template') }}
        </v-btn>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import IconDocument from '@/components/icons/IconDocument.vue'
export default {
  name: 'TemplateDesign',
  components: {
    IconDocument,
  },
}
</script>

<style lang="scss">
.template-design {
  .design-markup-title {
    @media (max-width: 767px) {
      padding: 5px 0 0;
      line-height: 1.1 !important;
    }
  }

  .design-markup-container {
    max-width: 610px;
    margin: 0 auto;
  }

  .design-markup-instructions {
    position: relative;
    display: table;
    margin: 0 auto;
    padding-left: 45px;

    &:hover {
      text-decoration: none;
    }
  }

  .design-markup-instructions-icon {
    position: absolute;
    top: -8px;
    left: 0;
  }

  .design-markup-btn {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
