<template>
  <v-col cols="12" sm="4">
    <v-select
      class="normal"
      color="grey"
      :items="options"
      :menu-props="{ offsetY: true, nudgeBottom: 4 }"
      :value="'updated_at'"
      item-text="name"
      outlined
      height="auto"
      :hide-details="true"
      append-icon="mdi-chevron-down"
      @change="update">
      <template #selection="{ item }">
        <span class="select-text">{{ item.name }}</span>
      </template>
      <template #item="{ item, on }">
        <v-list-item v-on="on" :inactive="false" :ripple="false">
          <component :is="item.icon" />
          {{ item.name }}
        </v-list-item>
      </template>
    </v-select>
  </v-col>
</template>

<script>
import IconDate from '@/components/icons/IconDate.vue'
import IconText from '@/components/icons/IconText.vue'

// import IconStatExcel from '@/components/icons/IconStatExcel.vue'
// import IconStatPdf from '@/components/icons/IconStatPdf.vue'
// import IconStatPrint from '@/components/icons/IconStatPrint.vue'
// import IconStatShare from '@/components/icons/IconStatShare.vue'
// import IconStatSheets from '@/components/icons/IconStatSheets.vue'

export default {
  name: 'ControlSort',
  components: {
    IconDate,
    IconText,
  },
  computed: {},
  data() {
    return {
      options: [
        {
          name: this.$t('tableHeader.by-date'),
          value: 'updated_at',
          icon: IconDate,
        },
        {
          name: this.$t('tableHeader.by-name'),
          value: 'name',
          icon: IconText,
        },
      ],
    }
  },
  methods: {
    async update(value) {
      this.$store.commit('itemsStore/changeOrderBy', value)
      await this.$store.dispatch('itemsStore/getData')
    },
  },
}
</script>
