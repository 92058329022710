<template>
  <header class="header-letter-save">
    <v-btn
      class="header-sidebar-toggle btn-small mr-4"
      :class="{ 'btn-menu-active': sidebarShow }"
      outlined
      :ripple="false"
      @click="toggleSidebar">
      {{ $t('headerLetterSave.menu') }}
      <v-icon right size="25">mdi-chevron-down</v-icon>
    </v-btn>
    <slot name="project-menu"></slot>
    <v-btn
      class="ml-auto"
      depressed
      color="primary"
      :ripple="false"
      @click="createLetter">
      {{ $t('headerLetterSave.create-letter') }}
    </v-btn>

    <PromiseDialogWrapper ref="paymentForExtraLetter">
      <PromiseDialogFrame
        question-code="email-not-free"
        agree-code="agree"
        cancel-code="cancel" />
    </PromiseDialogWrapper>
  </header>
</template>

<script>
import { checkIfLettersLimitReached } from '@/utilities/project.js'
import PromiseDialogWrapper from '@/components/PromiseDialogWrapper.vue'
import PromiseDialogFrame from '@/components/PromiseDialogFrame.vue'

export default {
  name: 'HeaderLetterSave',
  components: {
    PromiseDialogFrame,
    PromiseDialogWrapper,
  },
  computed: {
    sidebarShow() {
      return this.$store.state.tableStore.sidebarShow
    },
  },
  methods: {
    async createLetter() {
      if (checkIfLettersLimitReached(this.$store.state.project.full)) {
        const answer = await this.$refs.paymentForExtraLetter.open()
        if (answer === false) return
      }

      const result = await this.$store.dispatch('creatorStore/createLetter')
      if (result) {
        await this.$router.push({
          name: 'letter-editor',
          params: { id: result.id },
        })
      }
    },
    toggleSidebar() {
      this.$store.commit('tableStore/changeSidebarShow', !this.sidebarShow)
    },
  },
}
</script>
