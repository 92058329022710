<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill" :stroke="stroke">
    <path :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" d="M9.08594 17.1719C13.5517 17.1719 17.1719 13.5517 17.1719 9.08594C17.1719 4.6202 13.5517 1 9.08594 1C4.6202 1 1 4.6202 1 9.08594C1 13.5517 4.6202 17.1719 9.08594 17.1719Z" />
    <path :fill="stroke" :stroke="stroke" stroke-width="0.4" d="M13.5433 9.85109C13.5025 9.84048 13.4599 9.83806 13.4181 9.84398C13.3763 9.84989 13.3361 9.86403 13.2998 9.88556C13.2634 9.9071 13.2318 9.93562 13.2065 9.96946C13.1813 10.0033 13.163 10.0418 13.1527 10.0828C12.9177 11.0058 12.3819 11.8243 11.6298 12.4089C10.8778 12.9934 9.95243 13.3108 8.99992 13.3108C8.04741 13.3108 7.12205 12.9934 6.37001 12.4089C5.61797 11.8243 5.08213 11.0058 4.84713 10.0828C4.8367 10.0419 4.81832 10.0036 4.79306 9.96984C4.76779 9.93612 4.73613 9.9077 4.69988 9.88621C4.66363 9.86471 4.6235 9.85057 4.58179 9.84459C4.54007 9.83861 4.49759 9.8409 4.45676 9.85134C4.41593 9.86177 4.37756 9.88015 4.34383 9.90541C4.31011 9.93068 4.28169 9.96234 4.2602 9.99859C4.23871 10.0348 4.22457 10.075 4.21858 10.1167C4.2126 10.1584 4.21489 10.2009 4.22533 10.2417C4.49563 11.303 5.11177 12.2439 5.97645 12.9159C6.84112 13.588 7.90504 13.9528 9.00016 13.9528C10.0953 13.9528 11.1592 13.588 12.0239 12.9159C12.8886 12.2439 13.5047 11.303 13.775 10.2417C13.7855 10.2008 13.7877 10.1583 13.7818 10.1166C13.7758 10.0748 13.7616 10.0347 13.7401 9.9984C13.7186 9.96212 13.6901 9.93044 13.6564 9.90517C13.6226 9.87989 13.5842 9.86152 13.5433 9.85109Z" />
    <path :fill="stroke" d="M6.51084 8.77873C7.16309 8.77873 7.69185 8.15035 7.69185 7.3752C7.69185 6.60006 7.16309 5.97168 6.51084 5.97168C5.85859 5.97168 5.32983 6.60006 5.32983 7.3752C5.32983 8.15035 5.85859 8.77873 6.51084 8.77873Z" />
    <path :fill="stroke" d="M11.4901 8.77873C12.1423 8.77873 12.6711 8.15035 12.6711 7.3752C12.6711 6.60006 12.1423 5.97168 11.4901 5.97168C10.8378 5.97168 10.3091 6.60006 10.3091 7.3752C10.3091 8.15035 10.8378 8.77873 11.4901 8.77873Z" />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>