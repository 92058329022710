<template>
  <v-form @submit.prevent="updateProfile">
    <p class="title-h4 mb-5">
      {{ $t('profileData.user-data') }}
    </p>

    <label class="label">
      {{ $t('profileData.company') }}
    </label>
    <v-text-field
      v-model="profileForm.company"
      :placeholder="$t('profileData.company-name')"
      outlined />

    <label class="label">
      {{ $t('profileData.company-size') }}
    </label>
    <v-select
      v-model="profileForm.companySize"
      :items="companySize"
      :menu-props="{ offsetY: true }"
      :placeholder="$t('profileData.choose')"
      outlined
      append-icon="mdi-chevron-down" />

    <label class="label">
      {{ $t('profileData.post') }}
    </label>
    <v-text-field
      v-model="profileForm.companyPosition"
      :placeholder="$t('profileData.title-of-the-position')"
      outlined />

    <label class="label">
      {{ $t('profileData.specialization') }}
    </label>
    <v-select
      v-model="profileForm.companySpecialization"
      :items="companySpecialization"
      :menu-props="{ offsetY: true }"
      :placeholder="$t('profileData.choose')"
      outlined
      append-icon="mdi-chevron-down" />

    <v-btn
      type="submit"
      class="user-settings-btn white--text px-8"
      :ripple="false"
      x-large
      depressed
      color="primary">
      {{ $t('profileData.save') }}
    </v-btn>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'

import { displayNotify } from '@/utilities/helpers.js'

export default {
  name: 'ProfileData',
  data() {
    return {
      profileForm: {
        company: '',
        companySize: '',
        companyPosition: '',
        companySpecialization: '',
      },
    }
  },
  computed: {
    ...mapGetters('userStore', {
      user: 'getUser',
      profile: 'getUserProfile',
    }),
    companySize() {
      return this.$store.state.userStore.companySize
    },
    companySpecialization() {
      return this.$store.state.userStore.companySpecialization
    },
  },
  created() {
    this.profileForm = { ...this.profileForm, ...this.profile }
  },
  methods: {
    async updateProfile() {
      this.$loader('show')

      try {
        await this.$store.dispatch('userStore/updateUserProfile', {
          id: this.user.id,
          form: this.profileForm,
        })
        displayNotify(this.$t('profileData.user-updated'))
      } catch (e) {
        displayNotify(this.$t('profileData.user-not-updated'), 'error')
      } finally {
        this.$loader('hide')
      }
    },
  },
}
</script>
