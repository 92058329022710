<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M4.4375 4.43652L13.3117 13.3107" :stroke="stroke" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.43359 13.3076L13.3078 4.43343" :stroke="stroke" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 18},
    height: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>