<template>
  <aside id="sidebar-for-page" class="sidebar-for-page">
    <div class="sidebar-content">
      <div class="module-options__header" @click="goBack">
        <IconArrowLeft :size="20" />
        {{ headerTitle }}
      </div>
      <v-list class="sidebar-main">
        <v-list-item
          class="module-wrapper pa-5"
          v-for="(module, moduleKey) in options"
          :key="`module${moduleKey}`">
          <component
            class="option"
            :is="getModuleComponent(option, module)"
            v-for="(option, optionIndex) in module"
            :key="getKey(optionIndex)"
            :element="option.element"
            :label="option.label"
            :value="option.value"
            :input-type="option.inputType"
            :style-name="option.styleName"
            :text-editor="textEditor" />
        </v-list-item>
      </v-list>
    </div>
  </aside>
</template>

<script>
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { v4 as uuidv4 } from 'uuid'

import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'

// Импортировать новые опции в том же порядке, что и в папке moduleOptions, чтобы наглядно видеть лишние файлы в директиве
// Так как больше нигде, кроме этого компонента они не используются
import OptionAlign from '@/components/moduleOptions/OptionAlign.vue'
import OptionBackgroundImage from '@/components/moduleOptions/OptionBackgroundImage.vue'
import OptionBorderRadius from '@/components/moduleOptions/OptionBorderRadius.vue'
import OptionColor from '@/components/moduleOptions/OptionColor.vue'
import OptionColumnsCount from '@/components/moduleOptions/OptionColumnsCount.vue'
import OptionCondition from '@/components/moduleOptions/OptionCondition.vue'
import OptionGoogleSpreadsheets from '@/components/moduleOptions/OptionGoogleSpreadsheets.vue'
import OptionGpa from '@/components/moduleOptions/OptionGpa.vue'
import OptionHide from '@/components/moduleOptions/OptionHide.vue'
import OptionImage from '@/components/moduleOptions/OptionImage.vue'
import OptionLink from '@/components/moduleOptions/OptionLink.vue'
import OptionMetaParse from '@/components/moduleOptions/OptionMetaParse.vue'
import OptionSelectorParse from '@/components/moduleOptions/OptionSelectorParse.vue'
import OptionTextEditor from '@/components/moduleOptions/OptionTextEditor.vue'
import OptionTimer from '@/components/moduleOptions/OptionTimer.vue'
import OptionVideo from '@/components/moduleOptions/OptionVideo.vue'
import OptionVisibility from '@/components/moduleOptions/OptionVisibility.vue'

export default {
  name: 'SidebarEditor',
  components: {
    IconArrowLeft,
    OptionLink,
    OptionTextEditor,
    OptionColor,
    OptionBorderRadius,
    OptionCondition,
    OptionVisibility,
    OptionImage,
    OptionAlign,
    OptionHide,
    OptionBackgroundImage,
    OptionTimer,
    OptionVideo,
    OptionColumnsCount,
    OptionGpa,
    OptionGoogleSpreadsheets,
    OptionSelectorParse,
    OptionMetaParse,
  },
  props: {
    textEditor: Object,
  },
  data() {
    return {}
  },
  computed: {
    options() {
      return this.selectedElementOptions && this.selectedElementOptions.length
        ? this.selectedElementOptions
        : this.selectedModuleOptions && this.selectedModuleOptions.length
        ? this.selectedModuleOptions
        : []
    },
    selectedElementOptions() {
      return this.$store.state.editor.selectedElementOptions
    },
    selectedModuleOptions() {
      return this.$store.state.editor.selectedModuleOptions
    },
    triggerSidebarOptions() {
      return this.$store.state.editor.triggerSidebarOptions
    },
    selectedElement() {
      return this.$store.state.editor.selectedElement
    },
    headerTitle() {
      if (this.triggerSidebarOptions)
        return this.$t('sidebarEditor.structure-settings')

      const tag = this.selectedElement ? this.selectedElement.tagName : null
      const isChildImg = this.selectedElement?.querySelector('img')
        ? true
        : false

      if (tag) {
        if (isChildImg) {
          return this.$t('sidebarEditor.image')
        }

        if (tag === 'A') {
          return this.$t('sidebarEditor.button')
        } else {
          return this.$t('sidebarEditor.text')
        }
      } else {
        return this.$t('sidebarEditor.structure-settings')
      }
    },
    selectedModule() {
      return this.$store.state.editor.selectedModule
    },
  },
  methods: {
    getModuleComponent(option) {
      if (option.inputType) {
        return `Option${upperFirst(camelCase(option.inputType))}`
      }
      return ''
    },
    getKey() {
      return `option-${uuidv4(4)}`
    },
    goBack() {
      // [Переписать] проработать смену состояния через стор
      this.$store.commit('editor/clearSelected')
    },
  },
}
</script>

<style lang="scss">
.sidebar-for-page {
  .sidebar-content {
    background-color: $color-white;
  }
  .module-options__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 20px;
    padding: 20px;
    border-bottom: 1px solid $color-gray-lighten;
    font-weight: 500;
    cursor: pointer;
  }

  .sidebar-main > .module-wrapper {
    flex-flow: row wrap;
  }

  .module-wrapper {
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid $color-gray-lighten;

    .option {
      width: 100%;

      .option__label {
        position: relative;
        display: table;
        margin: 0 0 10px;
        font-weight: 500;
        width: 100%;
      }

      .option__value {
        height: 36px;
      }
    }
    .option + .option {
      margin-top: 1rem;
    }
  }
}
</style>
