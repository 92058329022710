<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M0.572185 7.55255C0.695639 3.59689 3.98025 0.5 7.95546 0.5H15.0445C19.0198 0.5 22.3044 3.59688 22.4278 7.55255C22.4708 8.9292 22.5 10.3052 22.5 11.5C22.5 12.6948 22.4708 14.0708 22.4278 15.4474C22.3044 19.4031 19.0197 22.5 15.0445 22.5H7.95545C3.98025 22.5 0.695639 19.4031 0.572186 15.4475C0.529221 14.0708 0.5 12.6948 0.5 11.5C0.5 10.3052 0.529221 8.9292 0.572185 7.55255Z" :fill="stroke" stroke="#E1E1E1"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 23},
    height: {type: [Number, String], default: 23},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 23 23'},
    fill: {type: String, default: 'none'}
  }
}
</script>