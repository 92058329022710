<template>
  <v-main class="template-shop">
    <v-row>
      <v-col offset="4" md="4" class="pt-10">В процессе разработки...</v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: 'TemplateShop',
  mounted() {
    document.title = this.$t('templateStore.title')
  },
}
</script>
