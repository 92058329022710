<template>
  <div class="table-actions-menu">
    <v-btn
      class="list-card-main list-card-main--analytics dropdown-link d-flex black-hover"
      :ripple="false"
      outlined
      :to="`/analytics/${item.id}`">
      {{ $t('tableAnalyticsMenu.more') }}
    </v-btn>
    <v-menu
      v-model="showMenu"
      content-class="dropdown-menu"
      nudge-bottom="4"
      offset-y
      min-width="245"
      left>
      <template #activator="{ on, attrs }">
        <v-btn
          class="list-card-dropdown list-card-dropdown--analytics dropdown-btn d-flex black-hover"
          :ripple="false"
          outlined
          v-bind="attrs"
          v-on="on">
          <v-icon size="23">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          class="dropdown-menu-item dropdown-menu-item-view"
          :ripple="false"
          link
          target="_blank"
          :to="{ name: 'letter-preview', params: { id: item.id } }">
          <IconView />
          {{ $t('tableAnalyticsMenu.view-letter') }}
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import IconView from '@/components/icons/IconView.vue'

export default {
  name: 'ElementMenuAnalytics',
  components: {
    IconView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    project() {
      return this.$store.state.project.full
    },
    directories() {
      return this.$store.state.tableStore.pageType === 'letter'
        ? this.$store.state.project.full.lettersFolders
        : this.$store.state.project.full.templatesFolders
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.table-actions-menu {
  display: flex;
  max-width: 223px;

  .list-card-main {
    flex: 3;
    &.list-card-main--analytics {
      border-right: 0;
      height: 56px;
    }
  }

  .list-card-dropdown {
    flex: 1;
    &.list-card-dropdown--analytics {
      height: 56px;
    }
  }

  .menu-item-header {
    background-color: $color-white;
    padding: 1rem;
  }

  .list-project {
    list-style: none;
    max-height: 375px;
    padding: 0;
    background-color: $color-white;

    .list-project-item {
      display: flex;
      flex-flow: row nowrap;
      margin: 1rem 0 0 0;
      cursor: pointer;

      &:hover {
        color: #001dff;
      }
    }
  }
}

.menu-blue {
  .v-btn {
    color: $color-white;
    background-color: $color-primary;
  }
}
</style>
