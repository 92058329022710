/* eslint-disable */

// [Переписать] подключить динамически
import Vue from 'vue'
import router from '@/router/index'
import {
  cloneLetter,
  deleteLetter,
  deleteLettersFromFolder,
  getAnalyticOpen,
  getAnalytics,
  loadLetters,
  updateLetter,
} from '@/utilities/letter'
import {
  cloneTemplate,
  deleteTemplate,
  deleteTemplatesFromFolder,
  loadTemplates,
  updateTemplate,
} from '@/utilities/template'
import { downloadFile } from '@/utilities/fileHandler'

const state = () => ({
  items: [],
  headersAnalytics: [
    { text: '', value: 'empty', class: 'table-analytics', sortable: false },
    {
      text: 'Название',
      value: 'name',
      class: 'table-analytics',
      sortable: false,
    },
    {
      text: 'Отправлено',
      value: 'sent',
      class: 'table-analytics',
      sortable: false,
    },
    {
      text: 'Открытия',
      value: 'open',
      class: 'table-analytics',
      sortable: false,
    },
    {
      text: 'Клики',
      value: 'click',
      class: 'table-analytics',
      sortable: false,
    },
  ],
  filter: {
    name: '',
  },
  ascending: false,
  orderBy: 'updatedAt',
  analyticsData: null,
  selected: [],
})

const getters = {
  itemById: (state) => (id) => {
    const result = state.items.find((item) => item.id === id)
    return result || {}
  },
  selectedById: (state) => (id) => {
    const result = state.selected.find((n) => n.id === id)
    return result.selected
  },
  activeSelected: (state) => {
    const result = state.selected.filter((n) => n.selected)
    return result.length || false
  },
}

const mutations = {
  changeAnalyticsData(state, payload) {
    state.analyticsData = payload
  },
  changeItems(state, payload) {
    state.items = payload
  },
  changeFilterName(state, payload) {
    state.filter.name = payload
  },
  changeOrderBy(state, payload) {
    state.orderBy = payload
  },
  changeItemName(state, payload) {
    state.items.find((item) => item.id === payload.id).name = payload.value
  },
  setSelected(state, payload) {
    state.selected = []
    payload.forEach((n) => {
      state.selected.push({ id: n.id, selected: false })
    })
  },
  changeSelected(state, payload) {
    const item = state.selected.find((n) => n.id == payload.id)
    item.selected = payload.selected
  },
  selectAll(state) {
    state.selected.forEach((n) => (n.selected = true))
  },
  unselectAll(state) {
    state.selected.forEach((n) => (n.selected = false))
  },
}

const actions = {
  // [Переписать]
  async getData({ state, commit, dispatch, rootState, rootGetters }) {
    const orderBy = state.ascending ? `${state.orderBy}` : `-${state.orderBy}`

    let filter = { ...state.filter }
    if (router.currentRoute.params.id) {
      filter.folder = router.currentRoute.params.id
    }

    if (rootState.tableStore.pageType === 'letter-custom') {
      filter.type = 'custom'
    } else {
      filter.type = 'default'
    }

    Vue.prototype.$loader('show')

    let result
    if (
      rootState.tableStore.pageType === 'letter' ||
      rootState.tableStore.pageType === 'letter-custom'
    ) {
      result = await loadLetters(
        rootState.tableStore.page,
        rootGetters['tableStore/limitAdaptive'],
        orderBy,
        filter,
        rootState.tableStore.letterType
      )
    } else if (rootState.tableStore.pageType === 'analytics') {
      result = await getAnalytics(rootState.tableStore.page)
      commit('changeAnalyticsData', result)
      commit('tableStore/changeMeta', state.analyticsData.meta, {
        root: true,
      })
      dispatch('openLoop', 0)
    } else {
      result = await loadTemplates(
        rootState.tableStore.page,
        rootGetters['tableStore/limitAdaptive'],
        orderBy,
        filter,
        rootState.tableStore.templateType
      )
    }

    Vue.prototype.$loader('hide')

    commit('setSelected', result.items)
    commit('changeItems', result.items)
    commit('tableStore/changeMeta', result._meta, {
      root: true,
    })
    return !!result.items.length
  },

  async downloadTemplate(context, payload) {
    return await downloadFile(`/import/template/download-zip?id=${payload}`)
  },

  async removeItem({ rootState }, payload) {
    if (rootState.tableStore.pageType === 'letter') {
      return await deleteLetter(payload)
    }
    return await deleteTemplate(payload)
  },

  async renameHandler({ rootState, commit }, payload) {
    commit('changeItemName', {
      id: payload.id,
      value: payload.name,
    })

    const data = { name: payload.name }

    if (rootState.tableStore.pageType === 'template') {
      return await updateTemplate(payload.id, data)
    }
    return await updateLetter(payload.id, data)
  },

  // [Переписать]
  async clone({ rootState }, payload) {
    let result

    switch (rootState.tableStore.pageType) {
      case 'letter':
        result = await cloneLetter(payload)
        router.push({ name: 'letter-editor', params: { id: result.id } })
        break
      case 'letter-custom':
        result = await cloneLetter(payload)
        router.push({
          name: 'letter-custom-editor',
          params: { id: result.id },
        })
        break
      default:
        result = await cloneTemplate(payload)
        router.push({
          name: 'template-editor',
          params: { id: result.id },
        })
    }
  },

  // [Переписать]
  async openLoop({ state, commit, dispatch }, payload) {
    const len = state.analyticsData.items.length
    if (payload.index < len) {
      const res = await getAnalyticOpen(
        state.analyticsData.items[payload.index].id
      )
      commit('changeDataInsideLoop', res)
      dispatch('openLoop', payload.index + 1)
    }
  },

  // [Переписать]
  async removeFromFolder({ commit }, payload) {
    if (payload.pageType === 'letter') {
      await deleteLettersFromFolder(payload.idList, payload.folderID)
    } else {
      await deleteTemplatesFromFolder(payload.idList, payload.folderID)
    }
    commit('unselectAll')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
