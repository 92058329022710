<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <path :fill="stroke" d="M9 17C8.01562 17 7.15625 16.8889 6.42188 16.6667C5.6875 16.4444 5.07031 16.1407 4.57031 15.7556C4.07031 15.3556 3.67188 14.8963 3.375 14.3778C3.09375 13.8444 2.89844 13.2741 2.78906 12.6667L4.99219 12.6667C5.07031 13.0074 5.20313 13.3333 5.39062 13.6444C5.57812 13.9407 5.82812 14.2 6.14063 14.4222C6.46875 14.6444 6.86719 14.8222 7.33594 14.9556C7.80469 15.0741 8.35938 15.1333 9 15.1333C10.3281 15.1333 11.3281 14.9037 12 14.4444C12.6719 13.9704 13.0078 13.3778 13.0078 12.6667C13.0078 12.237 12.9297 11.8741 12.7734 11.5778C12.6328 11.2815 12.3828 11.0222 12.0234 10.8C11.6797 10.5778 11.2187 10.3778 10.6406 10.2C10.0781 10.0222 9.38281 9.84444 8.55469 9.66667C7.64844 9.47407 6.85937 9.26667 6.1875 9.04444C5.51562 8.80741 4.96094 8.51852 4.52344 8.17778C4.10156 7.82222 3.78125 7.4 3.5625 6.91111C3.35937 6.42222 3.25781 5.82222 3.25781 5.11111C3.25781 4.53333 3.39062 4 3.65625 3.51111C3.92188 3.00741 4.29687 2.57037 4.78125 2.2C5.28125 1.82963 5.88281 1.54074 6.58594 1.33333C7.30469 1.11111 8.10938 1 9 1C9.875 1 10.6406 1.11111 11.2969 1.33333C11.9531 1.54074 12.5078 1.82963 12.9609 2.2C13.4297 2.57037 13.7969 3.00741 14.0625 3.51111C14.3281 4 14.5156 4.53333 14.625 5.11111L12.4219 5.11111C12.2656 4.48889 11.9297 3.96296 11.4141 3.53333C10.9141 3.08889 10.1094 2.86667 9 2.86667C7.875 2.86667 7 3.08889 6.375 3.53333C5.76562 3.96296 5.46094 4.48889 5.46094 5.11111C5.46094 5.51111 5.53906 5.85926 5.69531 6.15556C5.85156 6.43704 6.09375 6.68148 6.42188 6.88889C6.75 7.0963 7.16406 7.28148 7.66406 7.44444C8.17969 7.60741 8.78906 7.76296 9.49219 7.91111C10.5078 8.11852 11.375 8.35556 12.0938 8.62222C12.8281 8.88889 13.4219 9.20741 13.875 9.57778C14.3437 9.93333 14.6797 10.363 14.8828 10.8667C15.1016 11.3704 15.2109 11.9704 15.2109 12.6667C15.2109 13.2741 15.0703 13.8444 14.7891 14.3778C14.5078 14.8963 14.1016 15.3556 13.5703 15.7556C13.0391 16.1407 12.3906 16.4444 11.625 16.6667C10.8594 16.8889 9.98438 17 9 17Z" />
    <path :fill="stroke" d="M1.5 11.2222L16.5 11.2222L16.5 12.3333L1.5 12.3333L1.5 11.2222Z" />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>