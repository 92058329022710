<template>
  <header class="header-letter-editor">
    <div class="header-left">
      <v-btn
        v-if="hideMenu"
        class="header-sidebar-toggle btn-small mr-4"
        :class="{ 'btn-menu-active': sidebarState }"
        outlined
        :ripple="false"
        @click="toggleSideBar">
        {{ $t('headerLetterEditor.menu') }}
        <v-icon right size="25">mdi-chevron-down</v-icon>
      </v-btn>
      <slot name="project-menu" />
    </div>
    <div class="header-right d-flex flex-row">
      <div class="header-nav-bar mr-4">
        <v-btn
          primary
          depressed
          color="primary"
          link
          :ripple="false"
          @click="createLetter">
          {{ $t('headerLetterEditor.create') }}
        </v-btn>
      </div>
      <v-btn
        class="btn-small btn-black-border"
        text
        :ripple="false"
        @click="exitWithoutSave">
        <IconExit />
      </v-btn>
    </div>
  </header>
</template>

<script>
import IconExit from '@/components/icons/IconExit.vue'

import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'HeaderLetterCustomCreate',
  components: {
    IconExit,
  },
  computed: {
    sidebarState() {
      return this.$store.state.customStore.sidebarVisible
    },
    hideMenu() {
      return !['letter-devices'].includes(this.$route.name)
    },
    isLetterCreate() {
      return this.$store.state.customStore.isLetterCreate
    },
  },
  methods: {
    // Показ сайдбара
    toggleSideBar() {
      this.$store.commit('customStore/toggleSidebar')
    },
    // Выход на страницу писем
    exitWithoutSave() {
      this.$router.push({ name: 'letter-index' })
    },
    // Cоздание письма и переход в редактор [Переписать]
    async createLetter() {
      this.$loader('show')
      const result = await this.$store.dispatch('customStore/createLetter')

      if (this.isLetterCreate) {
        this.$router.push({
          name: 'letter-custom-editor',
          params: { id: result.id },
        })
        displayNotify(this.$t('letterLayout.letter-created'))
      } else {
        this.$loader('hide')
        displayNotify(this.$t('letterLayout.letter-not-created'), 'error')
      }
    },
  },
}
</script>

<style lang="scss">
.header-letter-editor {
  .header-middle {
    position: absolute;
    left: calc(50% - 97px);
  }
  .button-approved {
    background-color: #2d8962 !important;
  }
  .invert-color {
    &:hover {
      color: #151515 !important;
      background-color: #ffffff !important;
      svg {
        transform: rotate(180deg);
      }
    }
    &.active {
      &:hover {
        color: #ffffff !important;
        background-color: #151515 !important;
        svg {
          transform: rotate(180deg);
        }
      }
      svg {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }
    }
  }
}
</style>
