<!--Страница статична-->
<template>
  <v-main class="project-onboarding">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="8" sm="10" cols="12">
          <div class="project">
            <h1
              class="title-h2 title-expand d-flex justify-center text-center pb-1"
              :class="{ 'title-expanded': expandProject }"
              @click="toggleValue('expandProject')">
              <span>
                {{ $t('projectOnboarding.the-project-is-configured') }}
                <span class="primary--text">
                  {{ $t('projectOnboarding.to') }} 20%
                </span>
              </span>
              <v-icon class="ml-2 mt-1">mdi-chevron-down</v-icon>
            </h1>
            <div class="project-progress">
              <v-expand-transition>
                <v-expansion-panels v-show="expandProject" accordion flat tile>
                  <v-expansion-panel
                    class="accordion-item accordion-item-completed">
                    <v-expansion-panel-header
                      class="font-weight-medium"
                      disabled>
                      <div class="accordion-icon accordion-icon-off">
                        <icon-checkbox-second-off />
                      </div>
                      <div class="accordion-icon accordion-icon-on">
                        <icon-checkbox-second-on />
                      </div>
                      {{
                        $t(
                          'projectOnboarding.the-project-has-been-created-and-confirmed'
                        )
                      }}
                    </v-expansion-panel-header>
                  </v-expansion-panel>
                  <v-expansion-panel class="accordion-item">
                    <v-expansion-panel-header class="font-weight-medium">
                      <div class="accordion-icon accordion-icon-off">
                        <icon-checkbox-second-off />
                      </div>
                      <div class="accordion-icon accordion-icon-on">
                        <icon-checkbox-second-on />
                      </div>
                      {{
                        $t(
                          'projectOnboarding.templates-and-a-library-of-blocks'
                        )
                      }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="accordion-item-text mb-7">
                        {{
                          $t('projectOnboarding.fill-out-a-small-questionnaire')
                        }}
                      </p>
                      <div class="mb-n4">
                        <v-btn
                          class="white--text mr-4 mb-4"
                          :ripple="false"
                          x-large
                          depressed
                          color="primary">
                          {{ $t('projectOnboarding.create') }}
                        </v-btn>
                        <v-btn
                          class="mr-4 mb-4"
                          :ripple="false"
                          x-large
                          outlined>
                          {{ $t('projectOnboarding.template-as-a-gift') }}
                          <v-img
                            class="ml-2"
                            src="/img/gift.png"
                            width="21"
                            height="21" />
                        </v-btn>
                        <v-btn
                          class="pa-0 text-decoration-underline mb-4"
                          :ripple="false"
                          color="dark"
                          plain
                          x-large>
                          {{ $t('projectOnboarding.i-ll-do-it-later') }}
                        </v-btn>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="accordion-item">
                    <v-expansion-panel-header class="font-weight-medium">
                      <div class="accordion-icon accordion-icon-off">
                        <icon-checkbox-second-off />
                      </div>
                      <div class="accordion-icon accordion-icon-on">
                        <icon-checkbox-second-on />
                      </div>
                      {{ $t('projectOnboarding.letters') }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="accordion-item-text mb-7">
                        {{
                          $t(
                            'projectOnboarding.use-your-template-or-generate-a-letter'
                          )
                        }}
                      </p>
                      <div class="mb-n4">
                        <v-btn
                          class="white--text mr-6 mb-4"
                          :ripple="false"
                          x-large
                          depressed
                          color="primary"
                          to="/letter-editor">
                          {{ $t('projectOnboarding.create') }}
                        </v-btn>
                        <v-btn
                          class="pa-0 text-decoration-underline mb-4"
                          :ripple="false"
                          color="dark"
                          plain
                          x-large>
                          {{ $t('projectOnboarding.i-ll-do-it-later') }}
                        </v-btn>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="accordion-item">
                    <v-expansion-panel-header class="font-weight-medium">
                      <div class="accordion-icon accordion-icon-off">
                        <icon-checkbox-second-off />
                      </div>
                      <div class="accordion-icon accordion-icon-on">
                        <icon-checkbox-second-on />
                      </div>
                      {{ $t('projectOnboarding.integrations') }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="accordion-item-text mb-7">
                        {{
                          $t('projectOnboarding.connect-platforms-to-letteros')
                        }}
                      </p>
                      <div class="mb-n4">
                        <v-btn
                          class="white--text mr-6 mb-4"
                          :ripple="false"
                          x-large
                          depressed
                          color="primary"
                          to="/integrations">
                          {{
                            $t(
                              'projectOnboarding.go-to-the-integration-section'
                            )
                          }}
                        </v-btn>
                        <v-btn
                          class="pa-0 text-decoration-underline mb-4"
                          :ripple="false"
                          color="dark"
                          plain
                          x-large>
                          {{ $t('projectOnboarding.i-ll-do-it-later') }}
                        </v-btn>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="accordion-item">
                    <v-expansion-panel-header class="font-weight-medium">
                      <div class="accordion-icon accordion-icon-off">
                        <icon-checkbox-second-off />
                      </div>
                      <div class="accordion-icon accordion-icon-on">
                        <icon-checkbox-second-on />
                      </div>
                      {{ $t('projectOnboarding.users') }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="accordion-item-text mb-7">
                        {{
                          $t(
                            'projectOnboarding.invite-your-colleagues-to-have-fun'
                          )
                        }}
                      </p>
                      <div class="mb-n4">
                        <v-btn
                          class="white--text mr-6 mb-4"
                          :ripple="false"
                          x-large
                          depressed
                          color="primary">
                          {{ $t('projectOnboarding.invite') }}
                        </v-btn>
                        <v-btn
                          class="pa-0 text-decoration-underline mb-4"
                          :ripple="false"
                          color="dark"
                          plain
                          x-large>
                          {{ $t('projectOnboarding.i-ll-do-it-later') }}
                        </v-btn>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expand-transition>
            </div>
          </div>
          <div class="tariff">
            <h2
              class="title-h2 title-expand d-flex justify-center text-center pb-9"
              :class="{ 'title-expanded': expandTariff }"
              @click="toggleValue('expandTariff')">
              <span>
                {{ $t('projectOnboarding.tariff-in') }}
                {{ $t('projectOnboarding.july') }} 2021
              </span>
              <v-icon class="ml-2 mt-1">mdi-chevron-down</v-icon>
            </h2>
            <v-expand-transition>
              <div v-show="expandTariff">
                <div class="tariff-items">
                  <div
                    v-for="(item, index) in tariffItems"
                    :key="index"
                    class="tariff-item">
                    <div class="d-flex align-center">
                      <p
                        class="tariff-item-title title-h4 font-weight-medium flex-grow-1">
                        {{ item.name }}
                      </p>
                      <v-btn
                        class="tariff-item-btn text-decoration-underline mb-4"
                        :ripple="false"
                        color="dark"
                        plain
                        x-large>
                        {{ item.actionName }}
                      </v-btn>
                    </div>
                    <v-progress-linear
                      height="32"
                      :value="progressValue(item.value, item.maxValue)"
                      :class="{
                        'progress-fulfilled': item.value === item.maxValue,
                      }"
                      background-color="#D7DCF5"
                      rounded>
                      <template v-slot="{ value }">
                        {{ progressVisibleValue(value, item.maxValue) }} из
                        {{ item.maxValue }}
                        <div
                          class="progress-value"
                          :style="progressClipPath(value)">
                          {{ progressVisibleValue(value, item.maxValue) }} из
                          {{ item.maxValue }}
                        </div>
                      </template>
                    </v-progress-linear>
                  </div>
                </div>
                <div class="tariff-panel d-flex flex-wrap align-center">
                  <p class="tariff-panel-price mb-0 d-flex align-center">
                    <span class="tariff-panel-amount font-weight-medium">
                      7840
                    </span>
                    ₽/мес.
                    <br />
                    без НДС
                  </p>
                  <v-btn
                    class="tariff-panel-btn px-13"
                    :ripple="false"
                    x-large
                    outlined>
                    {{ $t('projectOnboarding.change-the-tariff') }}
                  </v-btn>
                  <p class="tariff-panel-date mb-0">
                    {{ $t('projectOnboarding.valid-until') }}:
                    <br />
                    <span class="font-weight-medium primary--text">
                      20.07.2021
                    </span>
                  </p>
                </div>
              </div>
            </v-expand-transition>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import IconCheckboxSecondOff from '@/components/icons/IconCheckboxSecondOff.vue'
import IconCheckboxSecondOn from '@/components/icons/IconCheckboxSecondOn.vue'

export default {
  name: 'ProjectOnboarding',
  components: {
    IconCheckboxSecondOff,
    IconCheckboxSecondOn,
  },
  data() {
    return {
      expandProject: true,
      expandTariff: true,
      tariffItems: [
        {
          name: 'Писем создано',
          actionName: 'Увеличить лимит',
          value: 98,
          maxValue: 100,
        },
        {
          name: 'Проектов',
          actionName: 'Добавить проект',
          value: 1,
          maxValue: 1,
        },
        {
          name: 'Поддержка верстальщика',
          actionName: 'Отправить запрос',
          value: 2,
          maxValue: 2,
        },
        {
          name: 'Экспорт каждого письма',
          actionName: 'Включить безлимит',
          value: 10,
          maxValue: 10,
        },
        {
          name: 'Тест писем',
          actionName: 'Увеличить лимит',
          value: 300,
          maxValue: 500,
        },
        {
          name: 'Оптимизация веса картинок',
          actionName: 'Увеличить лимит',
          value: 100,
          maxValue: 200,
        },
        {
          name: 'Проверка грамматики письма',
          actionName: 'Увеличить лимит',
          value: 2,
          maxValue: 10,
        },
        {
          name: 'Раздача картинок, гигабайт',
          actionName: 'Увеличить лимит',
          value: 60,
          maxValue: 100,
        },
        {
          name: 'Виджет в CRM, пользователей',
          actionName: 'Добавить пользователей',
          value: 1,
          maxValue: 1,
        },
      ],
    }
  },
  methods: {
    progressValue: function (value, maxValue) {
      return (value / maxValue) * 100
    },
    progressVisibleValue: function (value, maxValue) {
      return Math.ceil((value / 100) * maxValue)
    },
    progressClipPath: function (value) {
      value = Math.ceil(value)
      return `clip-path: polygon(0 0, ${value}% 0, ${value}% 100%, 0% 100%)`
    },
    toggleValue: function (value) {
      return (this[value] = !this[value])
    },
  },
}
</script>

<style lang="scss">
.project-onboarding {
  .project {
    margin: 0 0 70px;
  }

  .project-progress {
    .accordion-item {
      &:not(:last-child) {
        border-bottom: 1px solid $color-gray-lighten;
      }
    }

    .title-expanded + & {
      margin-bottom: -52px;
    }

    .v-expansion-panel-header {
      transition: margin-bottom 0.3s;

      &--active {
        margin-bottom: -28px;
      }
    }

    .v-expansion-panel-content {
      padding-left: 47px;
    }
  }

  .tariff {
    margin: 0 0 55px;
  }

  .tariff-items {
    margin-bottom: 33px;
  }

  .tariff-item {
    &:not(:last-child) {
      margin-bottom: 46px;
    }
  }

  .tariff-item-title {
    @media (max-width: 767px) {
      &.title-h4 {
        font-size: 16px;
      }
    }
  }

  .tariff-item-btn {
    @media (max-width: 767px) {
      &.v-btn {
        font-size: 16px;
      }
    }
  }

  .tariff-panel {
    justify-content: space-between;

    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  .tariff-panel-price {
    line-height: 1.4;
  }

  .tariff-panel-amount {
    margin-right: 15px;
    font-size: 36px;
  }

  .tariff-panel-btn {
    @media (max-width: 767px) {
      margin: 16px 0;
    }
  }

  .tariff-panel-date {
    line-height: 1.4;

    @media (max-width: 767px) {
      flex-basis: 100%;
      text-align: center;
    }
  }

  .video-slider {
    overflow: hidden;
    margin-bottom: 40px;
    padding: 0 20px;

    .swiper-button-prev,
    .swiper-button-next {
      margin-top: -50px;
    }

    .swiper-slide {
      width: 400px;

      @media (max-width: 1423px) {
        width: 385px;
      }

      @media (max-width: 991px) {
        width: 354px;
      }

      @media (max-width: 767px) {
        width: 335px;
      }
    }
  }

  .card-video-img {
    display: block;
    border-radius: $border-radius-root;
  }

  .card-video-time {
    position: relative;
    display: table;
    margin: -40px 0 0 18px;
    padding: 2px 9px;
    border-radius: 8px;
    background: rgba($color-white, 0.5);
    color: $color-white;
    font-size: 12px;
  }

  .card-video-title {
    line-height: 1.4;
  }

  .card-idea-img {
    display: block;
    margin: 0 0 15px;
    border-radius: 10px;
  }

  .card-idea-title {
    display: -webkit-box;
    overflow: hidden;
    height: 44px;
    font-weight: 500;
    line-height: 1.4;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .ideas-slider {
    overflow: hidden;
    padding: 0 20px;

    .swiper-slide {
      width: 190px;

      @media (max-width: 1423px) {
        width: 182px;
      }

      @media (max-width: 991px) {
        width: 167px;
      }
    }
  }
}
</style>
