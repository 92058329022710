<template>
  <svg width="24" height="24" id="apple" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.9827 23.0001C5.70842 22.9813 3 16.2974 3 12.8934C3 7.33303 7.17122 6.11572 8.77877 6.11572C9.50323 6.11572 10.2768 6.40022 10.9591 6.65198C11.4362 6.82759 11.9297 7.00888 12.2041 7.00888C12.3684 7.00888 12.7555 6.85466 13.0973 6.71935C13.8262 6.42918 14.7332 6.06854 15.7893 6.06854C15.7912 6.06854 15.7938 6.06854 15.7956 6.06854C16.5843 6.06854 18.9755 6.24162 20.4131 8.40056L20.7498 8.9066L20.2652 9.27231C19.5728 9.79473 18.3096 10.7477 18.3096 12.6354C18.3096 14.8711 19.7402 15.7309 20.4276 16.1444C20.731 16.327 21.045 16.5152 21.045 16.9268C21.045 17.1956 18.8999 22.9668 15.7849 22.9668C15.0227 22.9668 14.4839 22.7377 14.0087 22.5356C13.5278 22.3311 13.113 22.1548 12.4275 22.1548C12.0801 22.1548 11.6407 22.3191 11.1756 22.4935C10.5399 22.7307 9.82047 23.0001 9.0041 23.0001H8.9827Z" fill="#151515"></path> <path d="M16.1195 1C16.2006 3.92516 14.1087 5.9545 12.0192 5.82722C11.6748 3.49285 14.1084 1 16.1195 1Z" fill="#151515"></path> </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 32},
    height: {type: [Number, String], default: 26},
    viewBox: {type: String, default: '0 0 32 26'},
    fill: {type: String, default: '#151515'}
  }
}
</script>
