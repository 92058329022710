<template>
  <v-main class="module-library">
    <section class="modules">
      <div
        v-for="(module, index) in groupModules"
        :ref="`module-${index}`"
        :key="index"
        class="module view-desktop"
        :style="`width: ${width};`">
        <div class="module-header mb-2">
          <textarea
            style="resize: none; width: 315px; outline: none; overflow: hidden"
            :value="module.name"
            maxlength="75"
            :disabled="systemGroup"
            @change="renameModule($event.target.value, index)" />
          <div class="module-btn-bar">
            <v-btn
              outlined
              :ripple="false"
              class="module-btn-add btn-black-border btn-small mr-2"
              @click.prevent="selectModule(index, module)">
              {{ $t('moduleLibrary.add-module-header') }}
            </v-btn>
            <v-btn
              class="btn-small mr-2 module-view-desktop"
              :ripple="false"
              icon
              @click="toggleModuleViewMode($event, 'desktop')">
              <IconDesktop />
            </v-btn>
            <v-btn
              class="btn-small module-view-mobile"
              :ripple="false"
              icon
              @click="toggleModuleViewMode($event, 'mobile')">
              <IconMobile />
            </v-btn>
            <v-divider vertical />
          </div>
        </div>
        <template v-if="systemGroup">
          <div
            class="module-wrapper"
            @click.prevent="selectModule(index, module)">
            <img
              class="module-preview"
              :src="module.options.preview_url"
              alt="timer_preview"
              @click.prevent="selectModule(index, module)" />
            <v-btn
              outlined
              class="module-btn-add btn-black-border btn-small"
              :ripple="false">
              <IconPlus />
            </v-btn>
          </div>
        </template>
        <div
          v-else
          class="module-wrapper"
          @click.prevent="selectModule(index, module)">
          <div class="module-code" v-html="module.html" />
          <v-btn
            outlined
            class="module-btn-add btn-black-border btn-small"
            :ripple="false">
            <IconPlus />
          </v-btn>
        </div>
      </div>
    </section>
    <v-btn
      icon
      fixed
      top
      right
      :ripple="false"
      style="margin-right: 18px"
      @click="goBack">
      <IconClose />
    </v-btn>
  </v-main>
</template>

<script>
import IconDesktop from '@/components/icons/IconDesktop.vue'
import IconMobile from '@/components/icons/IconMobile.vue'
import IconPlus from '@/components/icons/IconPlus.vue'
import IconClose from '@/components/icons/IconClose.vue'

import moduleMixin from '@/mixins/module.mixin.js'

import { v4 as uuidv4 } from 'uuid'
import { getTimer } from '@/utilities/timer.js'

export default {
  name: 'ModuleLibrary',
  mixins: [moduleMixin],
  components: { IconClose, IconMobile, IconDesktop, IconPlus },
  data() {
    return {
      defaultWidth: '600px',
    }
  },
  computed: {
    width() {
      let grid = this.$store.state.letter.letter.template?.config?.grid
      if (grid === undefined || !grid) return this.defaultWidth
      // из-за непоследовательности на беке, есть шаблоны и с px и без
      if (typeof grid === 'number') grid += 'px'
      return grid
    },
    groupIndex() {
      return this.$route.params.index
    },
    groups() {
      return this.$store.getters['letter/getTemplateModules']
    },
    groupModules() {
      return this.groups.length ? this.groups[+this.groupIndex]?.modules : []
    },
    systemGroup() {
      return this.groups[this.groupIndex].id === 0
    },
  },
  methods: {
    // Добавление модуля в редактор
    selectModule(index) {
      let payload = { groupIndex: this.groupIndex, moduleIndex: index }
      if (Number.isInteger(this.$route.params.hoverIndex))
        payload.hoverIndex = this.$route.params.hoverIndex
      this.addLibraryModule(payload)
    },
    // Добавление модуля в редактор
    addLibraryModule(event) {
      this.$store.commit('letter/hideSidebar')
      const uuid = uuidv4()
      let module = null
      const { groupIndex, moduleIndex } = { ...event }
      const templateModules = this.$store.getters['letter/getTemplateModules']
      const libraryModule = templateModules[groupIndex].modules[moduleIndex]

      if (
        templateModules[groupIndex].id === 0 &&
        libraryModule.name !== 'Видео' &&
        libraryModule.name !== 'Gmail Promotions Annotations'
      ) {
        const timerModule = getTimer(libraryModule)
        module = {
          ...libraryModule,
          html: timerModule.html,
          options: { ...timerModule.options, ...libraryModule.options },
          uuid: uuid,
          groupIndex: groupIndex,
          moduleIndex: moduleIndex,
        }
      } else {
        module = {
          ...libraryModule,
          uuid: uuid,
          groupIndex: groupIndex,
          moduleIndex: moduleIndex,
        }
      }

      module.position = this.$store.getters['letter/getLetterModulesSize']
      module = this.getHTMLModule(module)
      this.$store.commit('letter/addModule', { module })

      this.$router.push({
        name: 'letter-editor',
        params: { id: this.$route.params.id },
        meta: { scrollBottom: true },
        query: { select: uuid },
      })
    },
    // Изменения названия модуля, кроме системных модулей
    renameModule(value, index) {
      let data = JSON.parse(JSON.stringify(this.groups))
      data[this.groupIndex].modules[index].name = value
      this.$emit('template-modules-update', { modulesGroups: data })
    },
    // Возвращение на страницу редактора
    goBack() {
      this.$router.push({
        name: 'letter-editor',
        params: { id: this.$route.params.id },
      })
    },
    // Смена отображения модуля Декстоп/Мобайл
    toggleModuleViewMode(e, mode) {
      const module = e.target.closest('.module')
      const moduleWrapper = e.target.closest('.module-wrapper')
      if (mode === 'desktop') {
        module.classList.add('view-desktop')
        module.classList.remove('view-mobile')
        moduleWrapper.style.width = this.width
      } else {
        module.classList.add('view-mobile')
        module.classList.remove('view-desktop')
        moduleWrapper.style.width = 375 + 'px'
      }
    },
  },
}
</script>

<style lang="scss">
.module-library {
  position: relative;
  width: 100%;
  height: calc(100vh - 51px);

  .v-btn--fixed.v-btn--top {
    top: 71px;
  }

  .module-groups {
    position: absolute;
    left: 0;
    width: 300px;
    border-right: 1px solid $color-gray-lighten;
    height: calc(100vh - 51px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }

  .modules {
    width: calc(100% - 290px);
    position: absolute;
    left: 290px;
    max-height: calc(100vh - 66px);
    padding: 15px 0 0 0;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
  }

  .module {
    margin-bottom: 2rem;
    position: relative;

    .module-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    .module-btn-bar {
      display: flex;
      flex-flow: row nowrap;
      height: fit-content;
    }
    .module-preview {
      width: 100%;
    }
  }
  .module-wrapper {
    position: relative;
    margin: auto;
    .module-btn-add {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 25;
      display: none;
      &:hover:before {
        opacity: 0;
      }
    }
  }
  .module-code,
  .module-preview {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  .module-code:before,
  .module-preview:before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 1px solid $color-dark;
    pointer-events: none;
    z-index: 1;
  }
  .module:hover {
    cursor: pointer;
    .module-code:before,
    .module-preview:before {
      border: 1px solid $color-red;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 9;
    }
    .module-btn-add {
      display: block;
    }
  }
  .module.view-desktop {
    .module-view-desktop {
      background: $color-dark;
      svg {
        color: $color-white;
      }
    }
  }
  .module.view-mobile {
    .module-wrapper {
      max-width: 375px;
    }
    .module-view-mobile {
      background: $color-dark;
      svg {
        color: $color-white;
      }
    }
  }
}
</style>
