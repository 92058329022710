/* eslint-disable */

//Определение позиции элемента для Drag & Drop [Переписать] вынести в mixin
function getNextElement(cursorPosition, currentElement) {
  const currentElementCoord = currentElement.getBoundingClientRect()

  const currentElementCenter =
    currentElementCoord.y + currentElementCoord.height / 2

  const nextElement =
    cursorPosition < currentElementCenter
      ? currentElement
      : currentElement.nextElementSibling

  return nextElement
}

export { getNextElement }
