<template>
  <label
    class="file-uploader"
    @drop.prevent="dropHandler"
    @dragover.prevent="dragOverHandler">
    <input
      ref="fileInput"
      class="d-sr-only"
      type="file"
      :accept="accept"
      @change="fileHandler" />
    <v-btn
      class="black-hover"
      depressed
      outlined
      x-large
      :ripple="false"
      @click="$refs.fileInput.click()">
      {{ file ? $i18n.t('replace-the-file') : $i18n.t('select-file') }}
    </v-btn>
    <p class="gray--text mt-1 mb-n1">
      {{ file ? fileName : $i18n.t('or-drag-it-here') }}
    </p>
  </label>
</template>

<script>
// [Переписать] отрефакторить [NEW_API]
import { uploadFile } from '../utilities/fileHandler'
export default {
  name: 'FileUploader',
  props: {
    url: String,
    accept: {
      type: String,
      default: 'image/jpeg, image/png, image/gif',
    },
    documentId: Number,
    documentType: {
      type: String,
      default: 'letter',
    },
    immediately: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      file: null,
    }
  },
  computed: {
    fileName() {
      return this.file ? this.file.name : ''
    },
  },
  created() {},
  methods: {
    fileHandler(event) {
      const file = event.target.files ? event.target.files[0] : undefined
      if (file) {
        this.file = file
        if (this.immediately) {
          this.sendFile(file)
        } else {
          this.$emit('file-loaded', file)
        }
      } else this.$emit('file-empty', event.target.files)
    },
    dropHandler(event) {
      console.log('dropHandler', { event: event })
      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        if (event.dataTransfer.items[0].kind === 'file') {
          const file = event.dataTransfer.items[0].getAsFile()
          if (file) {
            this.file = file
            if (this.immediately) {
              this.sendFile(file)
            } else {
              this.$emit('file-loaded', file)
            }
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        this.fileHandler(event)
      }
    },
    async sendFile(file) {
      try {
        const result = await uploadFile(
          file,
          this.url,
          this.documentType,
          this.documentId
        )
        this.$emit('file-uploaded', result)
      } catch (e) {
        console.log('sendFile', { error: e })
      }
    },
    dragOverHandler() {
      // console.log('dragOverHandler', {event: e})
    },
  },
}
</script>

<style lang="scss" >
.file-uploader {
  display: flex;
  border: 1px dashed #a9a9a9;
  border-radius: 15px;
  padding: 1rem;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .letteros-image-uploader__input {
    display: none;
  }

  .letteros-image-uploader_btn {
    padding: 5px 20px;
    width: fit-content;
    text-align: center;
    border: 1px solid #151515;
    border-radius: 10px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .letteros-image-uploader_title {
    color: #a9a9a9;
    text-align: center;
  }

  .letteros-image-uploader__wrapper {
    padding: 10px 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
  }
}
</style>
<i18n>
{
  "ru": {
    "select-file": "Выберите файл",
    "replace-the-file": "Замените файл",
    "or-drag-it-here": "или перетащите его сюда"
  },
  "en": {
    "select-file": "Select file",
    "replace-the-file": "Replace the file",
    "or-drag-it-here": "or drag it here"
  }
}
</i18n>
