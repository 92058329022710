<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M0.993652 4.17069V19.8559C0.993652 21.5918 2.5851 23 4.53905 23H19.4545C21.4173 23 22.9999 21.5918 22.9999 19.8559V7.2351C22.9999 6.40258 22.6285 5.60548 21.9566 5.01208L18.4642 1.92109C17.7923 1.3277 16.8905 1 15.9444 1L4.53021 1.02657C2.57626 1.03543 0.993652 2.43478 0.993652 4.17069Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.77466 1.11499V8.66088" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.78327 10.6802C6.52687 10.6802 5.49243 12.239 5.49243 13.222C5.49243 13.9837 6.03176 14.6923 6.78327 14.6923C7.64973 14.6923 8.04759 13.9217 8.04759 13.2043C8.04759 12.3187 7.03967 10.6802 6.78327 10.6802Z" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>