<template>
  <div class="list-card-simple">
    <div class="list-card-preview">
      <div class="list-card-bar" />
      <v-checkbox
        :value="selected"
        class="list-card-checkbox checkbox-white ml-auto" />
      <div class="list-card-image-wrapper">
        <img
          class="list-card-image"
          :src="item.preview"
          :alt="item.name"
          width="245"
          @load="loadSuccess"
          onerror="this.src=`/img/letterPreview.png`" />
      </div>
    </div>
    <div class="list-card-info">
      <div class="name font-weight-medium">
        {{ item.name }}
      </div>
      <div class="time">{{ $t('theCard.updated') }} {{ item.updatedAt }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemCardSimple',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    loadSuccess(e) {
      const preview = e.target.src

      if (preview.indexOf('/img/letterPreview.png') === -1) {
        e.target.classList.add('loaded')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-card-simple {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  .list-card-preview {
    position: relative;
    border-radius: 15px;
    overflow: hidden;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
      border: 1px solid $color-gray;
      overflow-x: hidden;
    }

    .list-card-image-wrapper {
      width: 245px;
      height: 330px;
      overflow-y: hidden;
      .list-card-image {
        width: 100%;
        border-radius: 15px;
      }
    }
  }

  .list-card-bar {
    position: absolute;
    z-index: 1;
    bottom: 22px;
    left: 0;
    width: 100%;
    display: none;
    justify-content: center;
  }

  .list-card-info {
    margin-top: 1rem;
    width: 245px;
    .name {
      width: 100%;
    }
    .time {
      color: $color-gray;
    }
  }

  .list-card-checkbox {
    display: none;
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
  }
  .v-input--checkbox.v-input--selection-controls {
    .v-icon__component {
      color: $color-white;
    }

    &.text--dark {
      .v-label {
        color: $color-white;
      }
    }
    &:hover {
      .v-icon__component {
        color: $color-white;
      }
    }
  }
}

.list-card-simple:hover,
.list-card-simple.selected {
  .list-card-preview::before {
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
  }
  .list-card-checkbox {
    display: flex;
  }

  .list-card-bar {
    display: flex;
  }
}
</style>
