<template>
  <v-app>
    <router-view name="header" class="header" v-if="letter !== null">
      <template #project-menu>
        <HeaderMenuProject />
      </template>
      <template #user-menu>
        <HeaderMenuUser />
      </template>
      <template #letter-menu>
        <HeaderMenuLetter />
      </template>
    </router-view>
    <router-view
      v-if="letter !== null"
      v-click-outside="sidebarOutsideClickHandler"
      name="sidebar"
      class="sidebar" />
    <router-view v-if="letter !== null" class="page" />
    <PromiseDialogWrapper ref="leaveDialog">
      <PromiseDialogFrame />
    </PromiseDialogWrapper>
    <PromiseDialogWrapper ref="paymentForExtraLetter">
      <PromiseDialogFrame
        question-code="email-not-free"
        agree-code="agree"
        cancel-code="cancel" />
    </PromiseDialogWrapper>
  </v-app>
</template>

<script>
/**
 * Sctructure event listeners: (default_view) [Переписать]
 * @library-select-module-to-structure="addLibraryModuleToStructure"
 * @library-select-module-temporary="addLibraryModule" link to ModuleLibrary
 * @element-clone="cloneStructureElement"
 * @element-hide="hideStructureElement"
 * @element-delete="deleteStructureElement"
 * @element-align-left="alignLeftStructureElement"
 * @element-align-center="alignCenterStructureElement"
 * @element-align-right="alignRightStructureElement"
 * @element-link="linkStructureElement"
 */

/* eslint-disable */
import HeaderMenuProject from '@/components/header/HeaderMenuProject.vue'
import HeaderMenuUser from '@/components/header/HeaderMenuUser.vue'
import HeaderMenuLetter from '@/components/header/HeaderMenuLetter.vue'
import PromiseDialogWrapper from '@/components/PromiseDialogWrapper.vue'
import PromiseDialogFrame from '@/components/PromiseDialogFrame.vue'

import dynamicStoreMixin from '@/mixins/LetterLayout/dynamicStore.mixin.js'
// import structureMixin from '@/mixins/LetterLayout/structure.mixin.js'

import { dbAdd, dbRefresh } from '@/utilities/db.js'

export default {
  name: 'LayoutLetter',
  mixins: [dynamicStoreMixin],
  components: {
    PromiseDialogFrame,
    PromiseDialogWrapper,
    HeaderMenuLetter,
    HeaderMenuUser,
    HeaderMenuProject,
  },
  data() {
    return {
      autoSaveInterval: null, //Неиспользуется временно
      initialStateSaved: false, //странный триггер, который отработает лишь раз [Переписать]
      allModulesHtml: '', //думаю можно избавиться используется для сравнения, там где оно не нужно [Переписать]
      ignoreWorkareaMutation: false, //бесполезный триггер [Переписать]
    }
  },
  computed: {
    letter() {
      return this.$store.state.letter.letter
    },
  },
  created() {
    const routesWithLoadingData = [
      'letter-editor',
      'letter-settings',
      'letter-devices',
      'letter-renders',
      'letter-render-preview',
      'letter-test',
      'letter-library',
      'letter-analytics',
      'letter-dark',
    ]
    // Подгрузка данных письма на указанных роутах и стейт на показ селекта аккаунта
    if (routesWithLoadingData.includes(this.$route.name)) this.getLetter()

    //Установка заголовка из i18n
    document.title = this.$t('letterLayout.letter-title')
  },
  async mounted() {
    // Автосохранение
    // Временно отключено todo можно врубить после починки workarea-update
    //this.autoSaveInterval = setInterval(async () => {
    //  await this.$store.dispatch('letter/saveLetter', { autosave: true })
    //}, 30 * 1000)
  },
  async beforeRouteLeave(to, from, next) {
    // Стейт Автосохранения
    // clearTimeout(this.autoSaveInterval)

    // Сохранение письма в letter-editor происходит в нем самом
    const routeWithSave = ['letter-settings', 'letter-test', 'letter-library']
    // Открываем поп-ап с сохранением только на указанных в routeWithSave маршрутах
    if (routeWithSave.includes(from.name)) {
      const result = await this.$refs.leaveDialog.open()
      if (result)
        await this.$store.dispatch('letter/saveLetter', { autosave: false })
    }
    next()
  },
  methods: {
    // Загрузка данных письма и добавление структуры
    async getLetter() {
      try {
        this.$loader('show')
        // [Переписать] проработать ошибки [NEW_API]
        await this.$store.dispatch('letter/getLetter', this.$route.params.id)
        // Данные по структуре перенесы в utilities/mock.js
        this.$loader('hide')

        // DEPRECATED
        // if (!this.initialStateSaved) {
        //   await dbRefresh() // [Переписать] [LATER]
        //   await this.addDocumentToDb() // initial state of letter
        //   this.initialStateSaved = true
        // }
      } catch (e) {
        console.log('prepareLetterData error', { error: e })
        this.$loader('hide')
      }
    },
    // Закрытие сайдбара с меню
    sidebarOutsideClickHandler(e) {
      if (!this.$store.state.letter.sidebarVisible) return false

      const target = e.target.closest(
        '.header-sidebar-toggle, .sidebar, .result-links'
      )

      if (
        !target &&
        this.$route.name !== 'letter-library' &&
        this.$route.name !== 'letter-test'
      ) {
        this.$store.commit('letter/hideSidebar')
      }
    },
    // Используется в get Letter и save Work Area (Сохранение данных в IndexedDB)
    async addDocumentToDb() {
      try {
        const id = await dbAdd(
          'letter',
          this.$store.getters['letter/getLetterID'],
          this.$store.getters['letter/getLetterModules']
        )
        this.$store.commit('letter/updateVersionId', id)
        this.$store.commit('letter/updateVersionCount', id)
      } catch (error) {
        console.log('add Document To Db error', error)
      }
    },
  },
}
</script>

<style lang="scss">
.v-application p {
  margin-bottom: 0;
}
</style>
