<template>
  <v-main class="login" align-center v-if="!token">
    <v-container>
      <v-row justify="center">
        <v-col lg="6" md="7" sm="7" cols="10">
          <div>
            <h1 class="title-h3 mb-2">
              {{ $t('theLogin.login') }}
            </h1>
            <form ref="form" @submit.prevent="login">
              <label class="label">
                {{ $t('theLogin.email') }}
              </label>
              <v-text-field
                name="email"
                required
                outlined
                placeholder="mail@mail.com" />
              <label class="label mt-n2">
                {{ $t('theLogin.password') }}
              </label>
              <v-text-field
                name="password"
                type="password"
                outlined
                required
                :placeholder="$t('theLogin.enter-the-password')" />
              <div class="d-flex justify-space-between">
                <v-btn
                  type="submit"
                  class="login-form-btn white--text mb-2"
                  :ripple="false"
                  x-large
                  depressed
                  color="primary">
                  {{ $t('theLogin.enter') }}
                </v-btn>
                <v-btn
                  type="button"
                  class="login-form-btn black-hover"
                  :ripple="false"
                  x-large
                  depressed
                  outlined
                  to="/forgot">
                  {{ $t('theLogin.forgot') }}
                </v-btn>
              </div>
            </form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import authMixin from '@/mixins/Auth/auth.mixin.js'

export default {
  name: 'Login',
  mixins: [authMixin],
  computed: {
    token() {
      return this.getAuthToken()
    },
  },
  created() {
    document.title = this.$t('theLogin.login')
    if (this.token) this.$router.push('/')
  },
  methods: {
    async login(e) {
      this.$loader('show')

      const formData = new FormData(e.target)
      const response = await this.$store.dispatch('auth/login', formData)
      if (response) {
        this.setAuthData(response)
        this.$store.commit('userStore/setUser', response.data)
        this.accountHandler(response.data)
      }
      this.$loader('hide')
    },
  },
}
</script>
