<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="m13.02 10.55 2.6-2.59c1.7-1.68 1.91-3.97.18-5.7-1.72-1.71-3.96-1.64-5.59-.03L7.43 5M4.98 7.45l-2.6 2.59C.68 11.72.47 14 2.2 15.74c1.72 1.71 3.96 1.64 5.59.03L10.57 13M11.25 6.77l-4.79 4.76"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
};
</script>
