<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M16.5798 6.09106H5.90302C3.19697 6.09106 0.999988 8.33605 0.999988 11.1012C0.999988 13.8664 3.19697 16.1114 5.90302 16.1114H16.5798" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.7802 1.88867L17 6.2007L13.0414 10.2458" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>