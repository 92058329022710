<template>
  <div class="option-link">
    <label class="option__label">
      {{ $t('optionMetaParse.download-by-meta') }}
    </label>
    <v-text-field
      v-model="data.url"
      placeholder="https://google.com"
      outlined
      dense />
    <div class="option-element">
      <v-btn
        depressed
        block
        outlined
        class="btn-small btn-black-border"
        :ripple="false"
        @click="download">
        {{ $t('optionMetaParse.download') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { parseProduct } from '@/utilities/letter'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'OptionMetaParse',
  props: {
    element: [HTMLElement, null],
  },
  data() {
    return {
      data: {
        url: '',
        type: 'meta',
        userAgent: '',
        options: {
          letter: this.$route.params.id,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const links = this.element.querySelectorAll('[letteros-product-url]')

      if (links.length) {
        links.forEach((link) => {
          const path = link.getAttribute('href')
          if (path != '' && path != '#') {
            this.data.url = path
            return
          }
        })
      }

      this.data.userAgent = this.element.getAttribute('letteros-product-card')
    },
    async download() {
      this.$loader('show')
      let result = null

      try {
        result = await parseProduct(this.data)
      } catch (e) {
        this.$loader('hide')
        displayNotify(this.$t('optionMetaParse.parse-error'), 'error')
        return
      }

      if (!result) {
        this.$loader('hide')
        displayNotify(this.$t('optionMetaParse.parse-error'), 'error')
        return
      }

      this.insert(result)

      displayNotify(this.$t('optionMetaParse.parse-success'))
      this.$loader('hide')
    },
    insert(result) {
      result.forEach((item) => {
        const validTag = item.tag.replace('mailfit', 'letteros')
        const elements = this.element.querySelectorAll('[' + validTag + ']')

        elements.forEach((el) => {
          if (validTag === 'letteros-product-url' && el.tagName === 'A') {
            el.setAttribute('href', item.value)
          } else if (validTag === 'letteros-product-img') {
            if (el.tagName === 'IMG' || el.tagName == 'AMP_IMG') {
              el.setAttribute('src', item.value)
            } else {
              el.style['background-image'] = 'url(' + item.value + ')'
              el.setAttribute('background', item.value)
            }
          } else {
            // letteros-product-price & letteros-product-price-before-sale & letteros-product-name
            el.innerHTML = item.value
          }
        })
      })
    },
  },
}
</script>
