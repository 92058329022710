/* eslint-disable */
import { tinyInit, tinyDestroy } from '@/utilities/tinyMceWrapper.js'
export default {
  computed: {
    onceRefresh() {
      return this.$store.state.editor.moduleOnceRefresh
    },
    pageType() {
      return this.$route.name.includes('letter') ? 'letter' : 'template'
    },
  },
  watch: {
    onceRefresh(newState) {
      if (newState) {
        this.initEditorOnce(this.selectedModuleUuid)
        this.$store.commit('editor/toggleModuleOnceRefresh', null)
      }
    },
  },
  methods: {
    // При клонирование/редактирование кода
    initEditorOnce(uuid) {
      // console.log(uuid, 'init editor')
      const element = document.querySelector(
        `.editor-module[data-uuid="${uuid}"]`
      )

      if (element.classList.contains('init-editor')) {
        this.destroyEditor(element)
        this.disableResize(element)
      }

      this.initEditor(element)
      this.enableResize(element)
    },
    // Отслеживание элемента в пользовательской области видимости
    onIntersect(isIntersecting, entries, observer) {
      const element = isIntersecting[0].target
      if (observer) {
        // В зоне видимости
        this.initEditor(element)
        this.enableResize(element)
      } else if (!observer && element.classList.contains('init-editor')) {
        // Вне зоны видимости
        this.destroyEditor(element)
        this.disableResize(element)
      }
    },
    initEditor(element) {
      const store = this.pageType == 'letter' ? this.$store : false
      element.classList.add('init-editor')

      const textElements = element.querySelectorAll('[letteros-type="text"]')
      this.textEditor = tinyInit(textElements, store)
    },
    destroyEditor(element) {
      element.classList.remove('init-editor')

      const textElements = element.querySelectorAll('[letteros-type="text"]')
      this.textEditor = tinyDestroy(textElements)
    },
  },
}
