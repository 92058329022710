<template>
  <div id="app" class="main-app-wrapper">
    <router-view />
  </div>
</template>


<script>
// import {evtSource} from "./utilities/sse.js";//Заготовка для SSE

import project from '@/mixins/project.mixin.js'

export default {
  name: 'LettrosSpa',
  components: {},
  mixins: [project],
  data() {
    return {}
  },
  created() {
    this.checkUserAndAccount()
    // this.evtSourceHandler()//Заготовка для SSE
    this.$on('switch-lang', () =>
      console.log('Locale: ', this.$root.$i18n?.locale)
    )
  },
  methods: {
    async checkUserAndAccount() {
      const userId = localStorage.getItem('user_id')
      const token = localStorage.getItem('jwt_key')
      if (!this.$store.state.userStore.user.id && userId && token) {
        await this.loadUserData(+userId)
      }

      if (!this.$store.state.project.id) {
        await this.loadProject()
      }
    },
    async loadUserData(userId) {
      if (!this.$store.state.userStore.userLoading) {
        this.$store.commit('userStore/setUserLoading', true)
        const userData = await this.$store.dispatch(
          'userStore/viewUser',
          userId
        )
        this.$store.commit('userStore/setUser', userData)
        this.$store.commit('userStore/setUserLoading', false)
      }
    },
    /*    evtSourceHandler() {//Заготовка для SSE
      evtSource.onmessage = function (e) {
        console.log('evtSourceHandler', e)
      }
    }*/
  },
}
</script>

<style lang="scss"></style>
