import GlobalLoader from './GlobalLoader.vue'

export default {
	install(Vue, option) {
		Vue.component('GlobalLoader', require('./GlobalLoader').default)
		Vue.prototype.$loader = function (status) {
			if (status === 'show') {
				loader.showLoader()
			} else {
				loader.hideLoader()
			}
		}
		const mountNode = document.createElement('div')
		mountNode.id = 'loaderNode'
		mountNode.ref = 'loaderRef'
		document.body.appendChild(mountNode)
		let loaderComp = Vue.extend(GlobalLoader)
		let loader = new loaderComp().$mount('#loaderNode')
		loader.$props.size = option.size
		loader.$props.color = option.color
	}
}
