<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.189 5.04806L12.4809 1.09275L12.4807 1.09268C12.1731 0.970052
        11.8312 0.967559 11.5148 1.09449L1.81098 5.04806C1.31822 5.24561 1
        5.71489 1 6.24431V7.91396C1 8.62498 1.58054 9.20344 2.29412 9.20344H21.7059C22.4195
        9.20344 23 8.62498 23 7.91396V6.24431C23 5.71489 22.6818 5.24561 22.189
        5.04806ZM9.97266 10.4932H14.0276V17.6283H9.97266V10.4932ZM16.8745
        10.4932H20.9294V17.6283H16.8745V10.4932ZM2.29412 18.9169H21.7059C22.4195 18.9169
        23 19.4954 23 20.2064V21.7108C23 22.4218 22.4195 23.0003 21.7059 23.0003H2.29412C1.58054
        23.0003 1 22.4218 1 21.7108V20.2064C1 19.4954 1.58054 18.9169 2.29412 18.9169ZM7.1257
        10.4932H3.0708V17.6283H7.1257V10.4932Z"
        :fill="fill"
    >
    </path>
  </svg>
</template>
<script>
export default {
  props: {
    width: {type: [Number, String], default: 32},
    height: {type: [Number, String], default: 26},
    viewBox: {type: String, default: '0 0 32 26'},
    fill: {type: String, default: '#151515'}
  }
}
</script>
