<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <path
      stroke="currentColor"
      d="M.7 9.44A9.33 9.33 0 0 1 10.07.5h15.86a9.33 9.33 0 0 1 9.37 8.94c.11 2.9.2 6 .2 8.56 0 2.57-.09 5.66-.2 8.56a9.33 9.33 0 0 1-9.37 8.94H10.07A9.33 9.33 0 0 1 .7 26.56c-.11-2.9-.2-6-.2-8.56 0-2.57.09-5.66.2-8.56Z"
    />
    <path
      :stroke="stroke"
      stroke-linecap="round"
      stroke-width="2.39"
      d="M12.2 18.8h11.6"
    />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>
