import {getUnixTime, addDays,} from 'date-fns'

const timerParams = {
  fz: 72, //font size
  font: 'tahoma', //font family
  bg: '0f133a', //background color не нужен
  color: 'ffffff', //font color
  pd: 75, //  Отступы по ширине блока
  ofs: 50, // Отступы по высоте блока
  lh: 25, // Интервал между строками блока
  sep: 0, // insert colon
  zd: 0, // zero day
  ln: 'ru', // i18n
  time: calcTime(), //time
}

function getTimer(module, size) {
  const width = size ? size - 40 : 560;
  let moduleCode = module.html
  let params = timerParams
  let moduleElement = document.createElement('div')
  moduleElement.innerHTML = moduleCode
  let imageElement = moduleElement.querySelector('.letteros-timer-image')
  const timerWithImage = moduleElement.querySelector('[letteros-timer-bgi]')?.getAttribute('letteros-timer-bgi') === 'true'
  const whiteTimer = moduleElement.querySelector('[data-type-by-module]')?.getAttribute('data-type-by-module') === 'timer-3'

  if (timerWithImage) {
      params.tp = 'true'
      params.color = '0f133a'
      params.bg = 'ffffff'
  }

  if (whiteTimer) {
    params.tp = 'true'
    params.color = '0f133a'
    params.bg = 'ffffff'
  }

  imageElement.src = calcUrl(params, timerWithImage)
  imageElement.width = width
  imageElement.maxWidth = width
  imageElement.closest('table').style.maxWidth = `${width}px`

  // console.log('getTimerByType', {moduleElement:moduleElement, imageElement: imageElement,})
  return {html: moduleElement.innerHTML, options: {timer: params}}
}

function calcTime() {
  return getUnixTime(addDays(new Date(), 2))
}

function calcUrl(timerParams, timerWithImage) {
  let url = new URL(`${(window.apiBaseUrl ?? process.env.VUE_APP_BACKEND_HOST + '/api')}/services/timer/view`)
  for (const key in timerParams) {
      url.searchParams.append(key, timerParams[key].toString());
  }
  if (timerWithImage) {
      url.searchParams.set('tp', 'true');
      url.searchParams.set('bg', 'ffffff');
  }
  return url
}
export {getTimer, calcUrl, timerParams}
