var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"editor-html"},[(_vm.isLetterLoaded)?_c('editor',{attrs:{"api-key":"b6evd6vjvr8lgqrzcbaxyl96yxhcjrx100uqc1ogp9rpzvh2","init":{
      resize: false,
      menubar: false,
      branding: false,
      contextmenu: false,
      forced_root_block: false,
      contextmenu_never_use_native: true,
      plugins: ['lists autolink link image table'],
      language: 'ru',
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | image link removeformat',
      convert_urls: false,
      table_grid: false,
      relative_urls: false,
      remove_script_host: false,
      visual: false,
      images_upload_handler: _vm.loadImage,
    }},on:{"onInit":_vm.initEditor,"onChange":_vm.changeContent}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }