/* eslint-disable */
export default {
  methods: {
    getAuthToken() {
      if (
        localStorage.getItem('refreshTokenInProcess') === 'false' ||
        localStorage.getItem('refreshTokenInProcess') === null
      ) {
        return localStorage.getItem('jwt_key')
      }

      return false
    },
    setAuthData(responseData) {
      localStorage.setItem('refresh', responseData.headers.refresh)
      localStorage.setItem(
        'jwt_key',
        'Bearer ' + responseData.headers.authorization
      )
      localStorage.setItem('refreshTokenInProcess', 'false')
      // todo put in jwt
      localStorage.setItem('user_id', responseData.data.id)
    },
    accountHandler(payload) {
      if (payload.accounts?.length > 1) {
        return this.$router.push('project-select')
      }

      if (payload.accounts?.length === 1) {
        localStorage.setItem('account_id', payload.accounts[0].id)
        this.$store.commit('project/setProjectId', payload.accounts[0].id)
        return this.$router.push('/')
      }

      return this.$router.push('project-create')
    },
  },
}
