<template>
  <div class="header-project">
    <v-menu
      content-class="project-menu"
      nudge-bottom="6"
      offset-y
      min-width="265"
      :close-on-content-click="false"
      left
      v-model="projectMenuShow">
      <template #activator="{ on, attrs }">
        <template v-if="editorView">
          <v-btn
            class="project-logo mr-4"
            icon
            max-width="36"
            v-bind="attrs"
            :ripple="false"
            v-on="on">
            <img
              class="project-logo-image"
              :src="logoUrl"
              width="36"
              alt="logo"
              onerror="this.onerror=null;this.src=`/icons/default-account.svg`;" />
          </v-btn>
          <span
            class="font-weight-medium text-h5 flex-grow-1 text-left text-no-wrap">
            {{ project ? project.name : '' }}
          </span>
        </template>
        <v-btn
          v-else
          class="btn-small project-btn hidden-xs-only"
          width="215"
          outlined
          :ripple="false"
          v-bind="attrs"
          v-on="on">
          <span class="flex-grow-1 text-left text-truncate">
            {{ project ? project.name : '' }}
          </span>
          <v-icon right size="25">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <div class="project-menu-head">
        <p class="font-weight-medium mb-1">
          {{ $t('your-tariff') }} {{ project ? project.tarif?.name : '' }}
        </p>
        <p class="mb-1">
          <span class="title-h4 mr-2">{{ tariffPrice }}</span>
          ₽./мес без НДС
        </p>
        <p class="mb-4">На балансе {{ project.balance }}₽</p>
        <v-btn
          class="btn-small black-hover"
          outlined
          :ripple="false"
          :to="{ name: 'project-tariff', params: { id: project.id } }">
          {{ $t('change-the-tariff') }}
        </v-btn>
      </div>
      <v-list class="pt-1">
        <v-list-item
          class="project-menu-item project-menu-settings"
          :ripple="false"
          @click="goProjectView()"
          :class="{
            'v-list-item--active': this.$route.name === 'project-view',
          }">
          <IconSettings class="mr-4" />
          {{ $t('project-settings') }}
        </v-list-item>

        <v-list-item
          class="project-menu-item"
          :ripple="false"
          @click="goToIntegrations"
          :class="{
            'v-list-item--active': this.$route.name === 'project-integrations',
          }">
          <IconSettings class="mr-4" />
          {{ $t('my-integrations') }}
        </v-list-item>

        <v-list-item class="project-menu-item" link :ripple="false">
          <v-menu
            content-class="project-menu profile-submenu"
            nudge-bottom="4"
            :offset-x="true"
            left
            nudge-left="20"
            min-width="265"
            :close-on-content-click="false">
            <template #activator="{ on, attrs }">
              <div
                class="d-flex flex-grow-1"
                v-bind="attrs"
                v-on="on"
                to="project-select">
                <IconChange class="mr-4" />
                {{ $t('change-the-project') }}
                <v-icon class="ml-auto mr-n2" size="24">
                  mdi-chevron-right
                </v-icon>
              </div>
            </template>
            <label class="d-sr-only">{{ $t('search') }}</label>
            <v-text-field
              class="project-search mx-4 mt-4"
              outlined
              hide-details
              :placeholder="$t('search')"
              v-model="searchProjects">
              <template #prepend-inner>
                <IconMagnifier />
              </template>
            </v-text-field>
            <v-virtual-scroll
              :items="projects"
              bench="10"
              item-height="55"
              max-height="385"
              class="scrollbar">
              <template v-slot:default="{ index, item }">
                <v-list-item
                  class="px-4"
                  :ripple="false"
                  :key="index"
                  @click="selectProject(index)">
                  {{ item ? item.name : '' }}
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-menu>
        </v-list-item>

        <v-list-item
          class="project-menu-item project-menu-settings"
          :ripple="false"
          @click="switchLang">
          <icon-change class="mr-4" />
          {{ $t('switch-lang') }}
        </v-list-item>

        <v-list-item
          class="project-menu-item d-flex"
          link
          to="/project-create"
          :ripple="false">
          <IconAdd class="mr-4" />
          {{ $t('new-project') }}
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import IconSettings from '@/components/icons/IconSettings.vue'
import IconChange from '@/components/icons/IconChange.vue'
import IconAdd from '@/components/icons/IconAdd.vue'
import IconMagnifier from '@/components/icons/IconMagnifier.vue'
import project from '@/mixins/project.mixin.js'

export default {
  name: 'HeaderMenuProject',
  components: {
    IconMagnifier,
    IconSettings,
    IconChange,
    IconAdd,
  },
  mixins: [project],
  data() {
    return {
      searchProjects: '',
      headerProjects: [{ text: 'name', value: 'name' }],
      projectMenuShow: false,
      editorView: false,
    }
  },
  created() {
    this.$on('switch-lang', this.switchLang)
  },
  mounted() {
    this.calcHeaderProjectType()
  },
  computed: {
    projects() {
      const searchString = this.searchProjects.toLowerCase()
      return this.$store.state.userStore.user.projects.filter(
        (project) => project.name.toLowerCase().indexOf(searchString) > -1
      )
    },
    project() {
      return this.$store.getters['project/getProjectFull']
    },
    tariffPrice() {
      return `${this.project.tarif?.price ?? 0}`
    },
    logoUrl() {
      return this.project.logo_url
        ? this.project.logo_url
        : `icons/default-account.svg`
    },
  },
  methods: {
    goToIntegrations() {
      if (this.$route.name === 'project-integrations') return null
      this.projectMenuShow = false
      this.$router.push({
        name: 'project-integrations',
        params: { id: String(this.project.id) },
      })
    },
    async selectProject(event) {
      const data = this.projects[event]
      localStorage.setItem('account_id', data.id)
      await this.loadProject()
      this.projectMenuShow = false
      this.$router.push(`/?account_id=${data.id}`)
    },
    goToClassicSite() {
      document.cookie = 'theme=classic'
      window.location = `${process.env.VUE_APP_HOST}`
    },
    calcHeaderProjectType() {
      const routeNames = [
        'letter-test',
        'letter-editor',
        'letter-library',
        'letter-settings',
        'letter-dark',
        'letter-devices',
        'template-editor',
        'letter-custom-create',
        'letter-custom-editor',
        'letter-custom-test',
        'letter-custom-settings',
        'letter-custom-devices',
      ]
      this.editorView = routeNames.includes(this.$route.name)
    },
    ctProject(data) {
      this.projectMenuShow = false
      localStorage.setItem('account_id', data.id)
      this.$store.commit('project/setProjectId', data.id)
      window.location = `/?account_id=${data.id}`
    },
    goProjectView(anchor) {
      if (this.$route.name === 'project-view') return null
      this.projectMenuShow = false
      this.$router.push({
        name: 'project-view',
        params: { id: this.project.id, anchor },
      })
    },
    switchLang() {
      this.$root.$i18n.locale = 'ru' === this.$root.$i18n.locale ? 'en' : 'ru'
    },
  },
}
</script>

<style lang="scss" scoped>
.project-logo {
  &:hover {
    .v-btn__content {
      border-radius: 20px;
      background-color: #cccccd;
    }
  }

  &:before {
    display: none !important;
  }
}
.header-project {
  margin: 0 1rem 0 0;
  display: flex;
  align-items: center;
  .project-btn {
    .v-btn__content {
      width: 100%;
    }
  }
}
.project-logo-image {
  border-radius: 16px;
}
.project-menu {
  .project-search {
    position: sticky;
    top: 0;

    .v-input__prepend-inner {
      margin: auto;
    }
  }
}
</style>
<i18n>
{
  "ru": {
    "menu": "Меню",
    "back": "Назад",
    "your-tariff": "Ваш тариф",
    "change-the-tariff": "Сменить тариф",
    "back-to-classic": "Перейти в классический дизайн",
    "project-settings": "Настройки проекта",
    "my-integrations": "Мои интеграции",
    "change-the-project": "Сменить проект",
    "search": "Поиск",
    "new-project": "Новый проект",
    "add-a-participant": "Добавить участника",
    "profile-settings": "Настройки профиля",
    "switch-lang": "Switch to english"
  },
  "en": {
    "menu": "Menu",
    "back": "Back",
    "your-tariff": "Your plan",
    "change-the-tariff": "Change the plan",
    "back-to-classic": "To standard design",
    "project-settings": "Project settings",
    "my-integrations": "My Integrations",
    "change-the-project": "Change the project",
    "search": "Search",
    "new-project": "New project",
    "add-a-participant": "Add a member",
    "profile-settings": "Profile settings",
    "switch-lang": "Переключить на русский"
  }
}
</i18n>
