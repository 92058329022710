import templateStore from '@/store/dynamic/template.js'

export default {
  created() {
    // console.log('created letter')
    if (!this.$store.hasModule('template')) {
      this.$store.registerModule('template', templateStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('template')) {
      this.$store.registerModule('template', templateStore)
    }
  },
  destroyed() {
    // console.log('destroyed letter')
    if (this.$store.hasModule('template')) {
      this.$store.unregisterModule('template')
    }
  },
}
