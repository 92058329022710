<template>
  <v-menu
    content-class="module-menu menu-dark"
    :attach="`#mc-${uuid}`"
    absolute
    offset-y
    :position-x="-10"
    :position-y="42"
    min-width="270"
    max-width="270"
    dark
    style="left: 0"
    v-model="showMenu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :id="`mc-${uuid}`"
        class="module-move btn-small btn-gray-border"
        icon
        outlined
        :ripple="false"
        v-bind="attrs"
        v-on="on">
        <IconSixDots />
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        class="module-menu-item"
        link
        :ripple="false"
        @click.stop="cloneModule">
        <div class="module-menu-icon">
          <IconCopy />
        </div>
        {{ $t('clone') }}
      </v-list-item>
      <v-list-item
        class="module-menu-item"
        link
        :ripple="false"
        @click.stop="showCodeEditor">
        <div class="module-menu-icon">
          <IconCode />
        </div>
        {{ $t('view-the-code') }}
      </v-list-item>
      <v-list-item
        class="module-menu-item"
        link
        :ripple="false"
        @click.stop="deleteModule">
        <div class="module-menu-icon">
          <IconDelete />
        </div>
        {{ $t('delete') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// [Переписать] Отрефакторить и объеденить LetterModuleControls [NEW_API]
/* eslint-disable */
import IconDelete from '@/components/icons/IconDelete'
import IconCode from '@/components/icons/IconCode'
import IconCopy from '@/components/icons/IconCopy'
import IconSixDots from '@/components/icons/IconSixDots'

export default {
  name: 'ModuleControls',
  components: {
    IconSixDots,
    IconDelete,
    IconCode,
    IconCopy,
  },
  props: {
    uuid: Number,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    module() {
      return this.$store.getters['template/getModuleById']({
        id: this.uuid,
        group: this.$store.state.editor.selectedGroupId,
      })
    },
  },
  methods: {
    showCodeEditor() {
      this.showMenu = false
      this.$store.commit('editor/toggleCodeEditor')
    },
    // Клонирование модуля
    cloneModule() {
      const module = {
        ...this.module,
      }
      const oldId = module.id
      module.id = oldId + 1000

      this.$store.dispatch('template/cloneModule', {
        module,
        oldId,
      })
      // Включить после рефакторинга редактора текста
      this.$store.commit('editor/toggleModuleRefresh', module.id)
    },
    // Удаление модуля
    deleteModule() {
      this.$store.commit('editor/clearSelected')
      this.$store.commit('template/deleteModule', {
        id: this.uuid,
        group: this.$store.state.editor.selectedGroupId,
      })
    },
  },
}
</script>

<style lang="scss">
.module-menu {
  border: 1px solid #151515;
  &:before,
  &:after {
    top: -8px;
    left: 19px;
  }
}

.module-move {
  &.v-btn:focus::before {
    opacity: 0;
  }
}
</style>
<i18n>
{
  "ru": {
    "move-up": "Переместить вверх",
    "move-down": "Переместить вниз",
    "clone": "Клонировать",
    "view-the-code": "Смотреть код",
    "delete": "Удалить"
  },
  "en": {
    "move-up": "Move up",
    "move-down": "Move down",
    "clone": "Clone",
    "view-the-code": "View the code",
    "delete": "Delete"
  }
}
</i18n>
