<template>
  <v-expansion-panel class="accordion-item">
    <v-expansion-panel-header class="font-weight-medium">
      <v-img
        class="integrations-items-logo mr-4"
        src="/icons/default_integration.svg"
        max-width="36" />
      {{ $t('projectIntegrations.ftp.storage-ftp') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.ftp.host')"
        outlined
        v-model="integrationData.host" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.ftp.login')"
        outlined
        v-model="integrationData.login" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.ftp.password')"
        outlined
        v-model="integrationData.password" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.ftp.directory-name')"
        outlined
        v-model="integrationData.directoryName" />
      <v-text-field
        class="integrations-input"
        :placeholder="$t('projectIntegrations.ftp.directory-url')"
        outlined
        v-model="integrationData.directoryUrl" />
      <v-btn
        class=""
        :ripple="false"
        x-large
        depressed
        color="primary"
        @click="saveData(integrationData, 'ftp')">
        {{ $t('projectIntegrations.save') }}
      </v-btn>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ProjectFTP',
  data() {
    return {
      integrationData: {
        host: '',
        login: '',
        password: '',
        directoryName: '',
        directoryUrl: '',
      },
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('project', ['saveIntegration']),
    loadData() {
      // [Переписать] после правок в структуре стора, этих записей быть не должно
      const options = this.$store.state.project.integrationFtp
      for (const key in this.integrationData) {
        this.integrationData[key] = options[key] ?? ''
      }
    },
    async saveData(data, type) {
      await this.saveIntegration({ data, type })
      // [Переписать] в случае ошибки выводить уведомление
    },
  },
}
</script>

<style lang="scss"></style>
