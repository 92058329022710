<template>
  <v-card>
    <v-card-title class="mb-4 justify-center">
      {{ $t('insertTheCodeDialogueFrame.code-for-insert') }}
    </v-card-title>
    <p @click="$emit('copy-code', getText())">
      {{ getText() }}
    </p>
  </v-card>
</template>

<script>
export default {
  name: 'InsertTheCodeDialogueFrame',
  methods: {
    calcLink() {
      return `${
        window.appBaseUrl ?? process.env.VUE_APP_BACKEND_HOST
      }/public/letter/preview?id=${this.$route.params.id}`
    },
    getText() {
      return (
        '<iframe src="' +
        this.calcLink() +
        '" frameborder="0" width="600" height="600"></iframe>'
      )
    },
  },
}
</script>

<style>
p {
  text-align: center;
  cursor: pointer;
  padding-bottom: 30px;
}
</style>
