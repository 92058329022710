<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill">
    <path :fill="stroke" d="M9 15.2C8.07945 15.2 7.24658 15.08 6.50137 14.84C5.77078 14.6 5.14247 14.2333 4.61644 13.74C4.09041 13.2467 3.68128 12.6267 3.38904 11.88C3.11142 11.12 2.9726 10.2267 2.9726 9.2V1H5.03288V9.2C5.03288 9.96 5.12055 10.6133 5.29589 11.16C5.48584 11.7067 5.74886 12.1533 6.08493 12.5C6.42101 12.8467 6.83744 13.1067 7.33425 13.28C7.83105 13.44 8.3863 13.52 9 13.52C9.6137 13.52 10.169 13.44 10.6658 13.28C11.1626 13.1067 11.579 12.8467 11.9151 12.5C12.2511 12.1533 12.5068 11.7067 12.6822 11.16C12.8721 10.6133 12.9671 9.96 12.9671 9.2V1H15.0274V9.2C15.0274 10.2267 14.8813 11.12 14.589 11.88C14.3114 12.6267 13.9096 13.2467 13.3836 13.74C12.8575 14.2333 12.2219 14.6 11.4767 14.84C10.7461 15.08 9.92055 15.2 9 15.2Z" />
    <path :fill="stroke" d="M1 17H17V18H1V17Z" />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 18},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 18 18'},
    fill: {type: String, default: 'none'}
  }
}
</script>