import axios from '@/plugins/axios_and_toast.js'

async function createLetter(content) {
  const url = `/letter-custom`
  const result = await axios.post(url, { content })
  return result.data
}

async function getLetter(id) {
  const url = `/letter-custom/${id}`
  const result = await axios.get(url)
  return result.data
}

async function saveLetter(id, payload) {
  const url = `/letter-custom/${id}`
  const result = await axios.put(url, payload)
  return result.data
}

async function cloneLetter(id) {
  const url = `/letter/clone?id=${id}`
  const result = await axios.post(url)
  return result.data
}

export { createLetter, getLetter, saveLetter, cloneLetter }
