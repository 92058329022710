<template>
  <v-card class="folder-move">
    <v-card-title class="text-h5 justify-space-between">
      {{ $t('generateRandomSubject.random-subject-generator') }}
      <v-btn depressed :ripple="false" icon class="" @click="$emit('close')">
        <IconClose />
      </v-btn>
    </v-card-title>
    <v-card-text class="justify-center" style="display: flex">
      <v-select
        v-model="emailType"
        outlined
        :placeholder="$t('generateRandomSubject.email-type')"
        :items="typeOptions"
        item-text="title"
        :return-object="true"
        append-icon="mdi-chevron-down" />
      <v-btn
        class="btn-small black-hover ml-2 mb-n3"
        style="height: 56px"
        :ripple="false"
        outlined
        link
        large
        @click="generate">
        {{ $t('generateRandomSubject.generate-text') }}
      </v-btn>
    </v-card-text>
    <v-card-text class="justify-center">
      <v-text-field
        v-model="subject"
        class="mb-1"
        outlined
        :placeholder="$t('generateRandomSubject.subject')" />
    </v-card-text>
    <v-card-text class="justify-center">
      <v-text-field
        v-model="preheader"
        class="mb-1"
        outlined
        :placeholder="$t('generateRandomSubject.preheader')" />
    </v-card-text>
    <v-card-actions class="justify-space-between">
      <v-btn
        depressed
        color="primary px-8"
        x-large
        :ripple="false"
        @click="save">
        {{ $t('generateRandomSubject.save') }}
      </v-btn>
      <v-btn
        depressed
        outlined
        x-large
        class="mr-3 px-8 black-hover"
        :ripple="false"
        @click="close">
        {{ $t('generateRandomSubject.cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import IconClose from '@/components/icons/IconClose'
import mockup from '@/components/tools/mockup/randomSubject.json'

export default {
  name: 'GenerateRandomSubject',
  components: { IconClose },
  data() {
    return {
      emailType: null,
      subject: '',
      preheader: '',
      typeOptions: mockup[0].typeOptions,
      randomSubjects: mockup[0].randomSubjects,
      randomPreheaders: mockup[0].randomPreheaders,
    }
  },
  methods: {
    save() {
      this.$emit('save', { subject: this.subject, preheader: this.preheader })
    },
    close() {
      this.$emit('close')
    },
    generate() {
      let possibleSubjects = this.randomSubjects.filter(
        (possibleValue) => possibleValue.value !== this.subject
      )
      if (this.emailType) {
        possibleSubjects = possibleSubjects.filter(
          (possibleSubject) => possibleSubject.typeId === this.emailType.id
        )
      }
      const countOfPossibleValues = possibleSubjects.length
      const randomNumber = Math.floor(Math.random() * countOfPossibleValues)
      const subject = possibleSubjects[randomNumber]
      const preheader = this.randomPreheaders.find(
        (preheader) => preheader.id === subject.id
      )
      this.subject = subject.value
      this.preheader = preheader.value
    },
  },
}
</script>
