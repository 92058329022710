<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <path d="M19 4.5C17.5 3 15 1.5 12 1.5C5.93009 1.5 1 6 1 12.4227C1 18.2593 5.92105 22.9999 12 22.9999C18.0789 22.9999 23 18.268 23 12.4227" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3025 0.585334L19.3825 4.68002L15.0001 4.72332" :stroke="stroke" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 24},
    height: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>