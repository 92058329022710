<template>
  <v-main class="letter-analytics">
    <v-container>
      <div class="analytics-main">
        <div class="d-sm-flex">
          <p class="analytics-data-fill mb-2 mb-sm-4 text-center">
            <span class="gray--text">{{ $t('created') }}:</span>
            {{ letter.publish }}
          </p>
        </div>
        <h1 class="title-h2 text-sm-center mb-6">{{ letter.name }}</h1>
        <div class="d-sm-flex">
          <p class="analytics-data mb-2">
            <span class="gray--text">{{ $t('subject') }}:</span>
            {{ letter.subject }}
          </p>
          <p class="analytics-data">
            <span class="gray--text">{{ $t('preheader') }}:</span>
            {{ letter.preheader }}
          </p>
        </div>

        <div class="dropdown d-table mx-auto mb-13">
          <v-btn
            x-large
            depressed
            outlined
            class="btn-black-border"
            target="_blank"
            :to="{ name: 'letter-preview', params: { id: $route.params.id } }">
            <span class="font-weight-bold">{{ $t('view-letter') }}</span>
          </v-btn>
          <!-- <v-menu
            content-class="dropdown-menu"
            attach=".dropdown"
            nudge-bottom="4"
            offset-y
            min-width="284"
            left>
            <template #activator="{ on, attrs }">
              <v-btn
                class="dropdown-btn"
                x-large
                depressed
                outlined
                v-bind="attrs"
                v-on="on">
                <v-icon size="23">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="dropdown-menu-item" link>
                <icon-upload></icon-upload>
                {{ $t('share-a-report') }}
              </v-list-item>
              <v-list-item class="dropdown-menu-item" link>
                <icon-pdf></icon-pdf>
                {{ $t('download-in-pdf') }}
              </v-list-item>
              <v-list-item class="dropdown-menu-item" link>
                <icon-excel></icon-excel>
                {{ $t('download-in-excel') }}
              </v-list-item>
              <v-list-item class="dropdown-menu-item" link>
                <icon-google-sheets></icon-google-sheets>
                {{ $t('download-in-g-sheets') }}
              </v-list-item>
              <v-list-item class="dropdown-menu-item" link>
                <icon-print></icon-print>
                {{ $t('print') }}
              </v-list-item>
            </v-list>
          </v-menu> -->
        </div>

        <div class="stat-group mb-11">
          <p class="title-h4">Отправлено</p>
          <div class="stat-field">
            <v-text-field
              ref="editor-link"
              class="mb-n2"
              :value="subscribers"
              @change="changeSubscribers"
              outlined
              dense />
          </div>
        </div>

        <div v-for="(item, index) in progressItems" :key="index" class="mb-11">
          <p class="title-h4">
            {{ item.name }}
          </p>
          <div class="d-flex align-center">
            <div class="analytics-progress-item-value title-h6">
              {{ item.value }}
            </div>
            <v-progress-linear
              height="32"
              :value="item.percents"
              background-color="#D7DCF5"
              rounded>
              <template v-slot="{ value }">
                <small>{{ item.prefix }}</small>
                &nbsp;
                <i18n-n :value="value"></i18n-n>
                %
                <div class="progress-value" :style="progressClipPath(value)">
                  <i18n-n :value="value"></i18n-n>
                  %
                </div>
              </template>
            </v-progress-linear>
          </div>
        </div>
      </div>

      <div class="analytics-container">
        <div
          class="analytics-rating d-sm-flex mb-14"
          v-if="ratingClicks.length">
          <div class="analytics-rating-content flex-grow-1">
            <p class="title-h4">
              {{ $t('click-rating') }}
            </p>
            <v-simple-table class="analytics-table mb-4">
              <tbody>
                <tr v-for="(item, index) in ratingClicks" :key="index">
                  <template v-if="item.show">
                    <td class="analytics-table-number">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <div class="mr-7">
                        <a
                          class="analytics-table-link"
                          :href="item.link"
                          target="_blank">
                          {{ item.link }}
                        </a>
                      </div>
                    </td>
                    <td class="analytics-table-value">
                      {{ item.value }}
                    </td>
                    <td class="analytics-table-percents">
                      <i18n-n :value="item.percents"></i18n-n>
                      %
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
            <v-btn plain color="dark" @click.prevent="toggleVisible('links')">
              <span class="title-h6 mr-1">{{ $t('show-the-full-list') }}</span>
              <v-icon size="24">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
          <div class="analytics-map-сlicks">
            <v-img src="/img/letterPreview.png" height="313"></v-img>
            <div class="analytics-map-сlicks-overlay">
              <v-btn
                class="px-6"
                depressed
                x-large
                color="white"
                :to="`${$route.params.id}/map`">
                {{ $t('map-of-сlicks') }}
              </v-btn>
            </div>
          </div>
        </div>

        <div
          class="analytics-rating d-sm-flex mb-14"
          v-if="ratingBrowsers.length">
          <div class="analytics-rating-content flex-grow-1">
            <p class="title-h4">
              {{ $t('browsers-click-through-rating') }}
            </p>
            <v-simple-table class="analytics-table mb-4">
              <tbody>
                <tr v-for="(item, index) in ratingBrowsers" :key="index">
                  <template v-if="item.show">
                    <td class="analytics-table-number">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <div class="mr-7">
                        {{ item.name }}
                      </div>
                    </td>
                    <td class="analytics-table-value">
                      {{ item.value }}
                    </td>
                    <td class="analytics-table-percents">
                      <i18n-n :value="item.percents"></i18n-n>
                      %
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
            <v-btn plain color="dark" @click.prevent="toggleVisible('clients')">
              <span class="title-h6 mr-1">{{ $t('show-the-full-list') }}</span>
              <v-icon size="24">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
          <highcharts
            class="analytics-chart"
            :options="browsersChartOptions"></highcharts>
        </div>

        <div
          class="analytics-rating d-sm-flex mb-14"
          v-if="ratingDevices.length">
          <div class="analytics-rating-content flex-grow-1">
            <p class="title-h4">
              {{ $t('devices-click-through-rating') }}
            </p>
            <v-simple-table class="analytics-table mb-4">
              <tbody>
                <tr v-for="(item, index) in ratingDevices" :key="index">
                  <template v-if="item.show">
                    <td class="analytics-table-number">
                      {{ index + 1 }}
                    </td>
                    <td>
                      <div class="mr-7">
                        {{ item.name }}
                      </div>
                    </td>
                    <td class="analytics-table-value">
                      {{ item.value }}
                    </td>
                    <td class="analytics-table-percents">
                      <i18n-n :value="item.percents"></i18n-n>
                      %
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
            <v-btn plain color="dark" @click.prevent="toggleVisible('devices')">
              <span class="title-h6 mr-1">{{ $t('show-the-full-list') }}</span>
              <v-icon size="24">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
          <highcharts
            class="analytics-chart"
            :options="devicesChartOptions"></highcharts>
        </div>

        <div class="analytics-rating d-sm-flex">
          <div class="analytics-rating-content flex-grow-1">
            <p class="title-h4">Коэффициент вовлеченности</p>
            <v-simple-table class="analytics-table analytics-table--dsr mb-4">
              <tbody>
                <tr v-for="(item, index) in dsrData" :key="index">
                  <td class="analytics-table-number">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <div class="mr-7">
                      {{ item.name }}
                    </div>
                  </td>
                  <td class="analytics-table-value">
                    {{ item.value }}
                  </td>
                  <td class="analytics-table-percents">
                    <i18n-n :value="item.percents"></i18n-n>
                    %
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <highcharts class="analytics-chart" :options="dsrChart"></highcharts>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
// import IconUpload from '@/components/icons/IconUpload.vue'
// import IconPdf from '@/components/icons/IconPdf.vue'
// import IconExcel from '@/components/icons/IconExcel.vue'
// import IconGoogleSheets from '@/components/icons/IconGoogleSheets.vue'
// import IconPrint from '@/components/icons/IconPrint.vue'

import { getAnalyticOne, setAnalyticSubscribers } from '@/utilities/letter.js'

import { Chart } from 'highcharts-vue'

export default {
  name: 'LetterAnalytics',
  components: {
    // IconUpload,
    // IconPdf,
    // IconExcel,
    // IconGoogleSheets,
    // IconPrint,
    highcharts: Chart,
  },
  data() {
    return {
      progressItems: [
        {
          name: 'Открытий',
          value: 0,
          percents: 0,
          prefix: 'OpenRate',
        },
        {
          name: 'Кликов',
          value: 0,
          percents: 0,
          prefix: 'ClickRate',
        },
      ],
      ratingClicks: [],

      ratingBrowsers: [],
      browsersChartOptions: {
        chart: {
          type: 'variablepie',
        },
        title: {
          text: '',
        },
        tooltip: {
          headerFormat: '',
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {point.name}<br>{point.y} ({point.z}%)',
          useHTML: true,
          backgroundColor: '#FFF',
          borderColor: '#A9A9A9',
          borderRadius: 15,
          padding: 15,
          shadow: false,
          outside: true,
        },
        plotOptions: {
          variablepie: {
            colors: [
              '#E3D269',
              '#C4DEF4',
              '#AAAAAD',
              '#CCF7CB',
              '#FBD5BB',
              '#C6C9F2',
              '#FAC5D2',
            ],
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: '20%',
            zMin: 10,
            data: [],
          },
        ],
      },

      ratingDevices: [],
      devicesChartOptions: {
        chart: {
          type: 'variablepie',
        },
        title: {
          text: '',
        },
        tooltip: {
          headerFormat: '',
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {point.name}<br>{point.y} ({point.z}%)',
          useHTML: true,
          backgroundColor: '#FFF',
          borderColor: '#A9A9A9',
          borderRadius: 15,
          padding: 15,
          shadow: false,
          outside: true,
        },
        plotOptions: {
          variablepie: {
            colors: [
              '#E3D269',
              '#C4DEF4',
              '#AAAAAD',
              '#CCF7CB',
              '#FBD5BB',
              '#C6C9F2',
              '#FAC5D2',
            ],
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: '20%',
            zMin: 10,
            data: [],
          },
        ],
      },

      dsrChart: {
        chart: {
          type: 'variablepie',
        },
        title: {
          text: '',
        },
        tooltip: {
          headerFormat: '',
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {point.name}<br>{point.y} ({point.z}%)',
          useHTML: true,
          backgroundColor: '#FFF',
          borderColor: '#A9A9A9',
          borderRadius: 15,
          padding: 15,
          shadow: false,
          outside: true,
        },
        plotOptions: {
          variablepie: {
            colors: ['#ef1c1c', '#efc21c', '#26c232'],
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            minPointSize: 10,
            innerSize: '20%',
            zMin: 10,
            data: [
              { name: 'Незаинтересованные', y: 30, z: 65 },
              { name: 'Читающие', y: 10, z: 25 },
              { name: 'Вовлеченные', y: 4, z: 10 },
            ],
          },
        ],
      },
      dsrData: [
        { name: 'Незаинтересованные', value: 0, percents: 0 },
        { name: 'Читающие', value: 0, percents: 0 },
        { name: 'Вовлеченные', value: 0, percents: 0 },
      ],

      letter: {
        name: '',
        publish: '',
        subject: '',
        preheader: '',
      },
      subscribers: 100,
    }
  },
  created() {
    this.enableUserLoggedIn()
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const result = await getAnalyticOne(this.$route.params.id)
      this.letter = result.item
      this.subscribers = result.data.info.subscribers

      //opens
      this.progressItems[0].value = result.data.open_uniq
      this.progressItems[0].percents = parseFloat(result.data.open_uniq_percent)

      //clicks
      this.progressItems[1].value = result.data.click_uniq
      this.progressItems[1].percents = parseFloat(
        result.data.click_uniq_percent
      )

      //clients
      this.prepareClients(result.data.clients)
      this.prepareDevices(result.data.devices)
      this.prepareDsr(result.data.info.trashold, result.data.trasholds)
      this.prepareLinks(result.data.links)
    },
    prepareDsr(settings, data) {
      settings = JSON.parse(settings)
      let totalCount = data.delete + data.skim + data.read

      this.dsrData[0].name += ` (0-${settings.delete}s)`
      this.dsrData[1].name += ` (${settings.delete * 1 + 1}-${settings.skim}s)`
      this.dsrData[2].name += ` (${settings.skim * 1 + 1}-${settings.read}s)`

      this.dsrData[0].value = data.delete
      this.dsrData[1].value = data.skim
      this.dsrData[2].value = data.read

      this.dsrData[0].percents = parseFloat(
        ((data.delete / totalCount) * 100).toFixed(2)
      )
      this.dsrData[1].percents = parseFloat(
        ((data.skim / totalCount) * 100).toFixed(2)
      )
      this.dsrData[2].percents = parseFloat(
        ((data.read / totalCount) * 100).toFixed(2)
      )

      this.dsrChart.series[0].data[0].y = data.delete
      this.dsrChart.series[0].data[0].z = parseFloat(
        ((data.delete / totalCount) * 100).toFixed(2)
      )

      this.dsrChart.series[0].data[1].y = data.skim
      this.dsrChart.series[0].data[1].z = parseFloat(
        ((data.skim / totalCount) * 100).toFixed(2)
      )

      this.dsrChart.series[0].data[2].y = data.read
      this.dsrChart.series[0].data[2].z = parseFloat(
        ((data.read / totalCount) * 100).toFixed(2)
      )

      // this.dsrData.sort((a, b) => {
      //   return a.value < b.value ? 1 : -1
      // })

      // this.dsrChart.series[0].data.sort((a, b) => {
      //   return a.y < b.y ? 1 : -1
      // })
    },
    prepareClients(clients) {
      let totalCount = 0

      for (let client in clients) {
        totalCount = totalCount + clients[client]
        this.ratingBrowsers.push({
          name: client,
          value: clients[client],
          percents: 0,
          show: false,
        })
      }

      this.ratingBrowsers.filter((item) => {
        item.percents = parseFloat(((item.value / totalCount) * 100).toFixed(2))
      })

      this.ratingBrowsers.sort((a, b) => {
        return a.value < b.value ? 1 : -1
      })

      this.ratingBrowsers.filter((item, key) => {
        if (key < 5) {
          item.show = true
        }
      })

      this.ratingBrowsers.forEach((item) => {
        this.browsersChartOptions.series[0].data.push({
          name: item.name,
          y: item.value,
          z: item.percents,
        })
      })
    },
    prepareDevices(devices) {
      let totalCount = 0

      for (let device in devices) {
        totalCount = totalCount + devices[device]
        this.ratingDevices.push({
          name: device,
          value: devices[device],
          percents: 0,
          show: false,
        })
      }

      this.ratingDevices.filter((item) => {
        item.percents = parseFloat(((item.value / totalCount) * 100).toFixed(2))
      })

      this.ratingDevices.sort((a, b) => {
        return a.value < b.value ? 1 : -1
      })

      this.ratingDevices.filter((item, key) => {
        if (key < 5) {
          item.show = true
        }
      })

      this.ratingDevices.forEach((item) => {
        this.devicesChartOptions.series[0].data.push({
          name: item.name,
          y: item.value,
          z: item.percents,
        })
      })
    },
    prepareLinks(links) {
      let totalCount = 0

      for (let link in links) {
        totalCount = totalCount + links[link].uniq
        this.ratingClicks.push({
          link: link,
          value: links[link].uniq,
          percents: 0,
          show: false,
        })
      }

      this.ratingClicks.filter((item) => {
        item.percents = parseFloat(((item.value / totalCount) * 100).toFixed(2))
      })

      this.ratingClicks.sort((a, b) => {
        return a.value < b.value ? 1 : -1
      })

      this.ratingClicks.filter((item, key) => {
        if (key < 5) {
          item.show = true
        }
      })
    },
    enableUserLoggedIn() {
      this.$emit('is-user-logged-in', true)
    },
    progressValue: function (value, maxValue) {
      return (value / maxValue) * 100
    },
    progressVisibleValue: function (value, maxValue) {
      return Math.ceil((value / 100) * maxValue)
    },
    progressClipPath: function (value) {
      value = Math.ceil(value)
      return `clip-path: polygon(0 0, ${value}% 0, ${value}% 100%, 0% 100%)`
    },
    changeSubscribers: function (value) {
      this.progressItems[0].percents = parseFloat(
        ((this.progressItems[0].value / value) * 100).toFixed(2)
      )
      this.progressItems[1].percents = parseFloat(
        ((this.progressItems[1].value / value) * 100).toFixed(2)
      )

      setAnalyticSubscribers({
        id: this.$route.params.id,
        size: value * 1,
      })
    },
    toggleVisible: function (type) {
      if (type == 'clients') {
        this.ratingBrowsers.filter((item, key) => {
          if (key >= 5) {
            item.show = !item.show
          }
        })
      } else if (type == 'devices') {
        this.ratingDevices.filter((item, key) => {
          if (key >= 5) {
            item.show = !item.show
          }
        })
      } else if (type == 'links') {
        this.ratingClicks.filter((item, key) => {
          if (key >= 5) {
            item.show = !item.show
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
.letter-analytics {
  .analytics-main {
    max-width: 610px;
    margin: 110px auto 70px;

    @media (max-width: 767px) {
      margin-top: 10px;
    }

    .v-progress-linear__content small {
      font-size: 12px;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }

  .stat-label {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 5px;
    display: block;
  }
  .stat-field {
    .v-text-field__details {
      display: none;
    }
  }

  .analytics-data {
    width: 50%;
    font-size: 18px;
    line-height: 1.4;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .analytics-data-fill {
    width: 100%;
  }

  .analytics-progress-item-value {
    flex-shrink: 0;
    width: 73px;
  }

  .analytics-container {
    max-width: 1030px;
    margin: 0 auto 70px;

    @media (max-width: 991px) {
      padding-right: 8px;
      padding-left: 8px;
    }

    @media (max-width: 767px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .analytics-rating-content {
    @media (max-width: 767px) {
      margin-bottom: 33px;
    }
  }

  .analytics-table {
    margin-right: 105px;
    line-height: 1.4;

    @media (max-width: 1423px) {
      margin-right: 65px;
    }

    @media (max-width: 991px) {
      margin-right: 50px;
    }

    @media (max-width: 767px) {
      margin-right: 0;
    }

    td {
      height: 45px !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      border: none !important;
      font-size: 18px !important;
    }

    &.analytics-table--dsr {
      padding-bottom: 75px;
    }
  }

  .analytics-table-number {
    width: 34px;
    font-weight: 500;
  }

  .analytics-table-link {
    display: inline-block;
    overflow: hidden;
    max-width: 425px;
    margin-bottom: 1px;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;

    @media (max-width: 1423px) {
      max-width: 330px;
    }

    @media (max-width: 991px) {
      max-width: 220px;
    }

    @media (max-width: 767px) {
      width: 160px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .analytics-table-value {
    width: 65px;

    @media (max-width: 991px) {
      width: 55px;
    }
  }

  .analytics-table-percents {
    width: 70px;
    text-align: right;

    @media (max-width: 767px) {
      width: auto;
    }
  }

  .analytics-map-сlicks {
    position: relative;
    overflow: hidden;
    width: 294px;
    height: 313px;
    border-radius: $border-radius-root;

    @media (max-width: 767px) {
      width: 335px;
      margin: 0 auto;
    }
  }

  .analytics-map-сlicks-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color-black, 0.3);
  }

  .analytics-chart {
    width: 294px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .highcharts-tooltip > span {
    font-family: 'Golos Text', serif !important;
    font-size: 18px !important;
    line-height: 1.4;
  }

  .highcharts-credits {
    display: none;
  }
}
</style>

<i18n>
{
  "ru": {
    "created": "Создано",
    "sent": "Отправлено",
    "to": "Кому",
    "the-entire-database": "Вся база",
    "subject": "Тема",
    "export": "Экспорт",
    "preheader": "Прехедер",
    "view-letter": "Просмотреть письмо",
    "share-a-report": "Поделиться отчетом",
    "download-in-pdf": "Скачать в PDF",
    "download-in-excel": "Скачать в Excel",
    "download-in-g-sheets": "Скачать в G Sheets",
    "print": "Распечатать",
    "click-rating": "Рейтинг кликов",
    "show-the-full-list": "Показать полный список",
    "browsers-click-through-rating": "Рейтинг email клиентов",
    "devices-click-through-rating": "Рейтинг устройств",
    "map-of-сlicks": "Карта кликов"
  },
  "en": {
    "created": "Created",
    "sent": "Sent",
    "to": "To",
    "the-entire-database": "All database",
    "subject": "Subject",
    "export": "Export",
    "preheader": "Preheader",
    "view-letter": "View email",
    "share-a-report": "Share a report",
    "download-in-pdf": "Download as PDF",
    "download-in-excel": "Download as Excel",
    "download-in-g-sheets": "Download as G Sheets",
    "print": "Print",
    "click-rating": "Click rate",
    "show-the-full-list": "Show the full list",
    "browsers-click-through-rating": "Browser click rate",
    "devices-click-through-rating": "Devices click rate",
    "map-of-сlicks": "Click map"
  }
}
</i18n>
