import gtmStore from '@/store/dynamic/gtm.js'

export default {
  created() {
    if (!this.$store.hasModule('gtm')) {
      this.$store.registerModule('gtm', gtmStore)
    }
    this.createGtm(window,document,'script','dataLayer','GTM-K9W83VN')
  },
  mounted() {
    if (!this.$store.hasModule('gtm')) {
      this.$store.registerModule('gtm', gtmStore)
    }
  },
  destroyed() {
    if (this.$store.hasModule('gtm')) {
      this.$store.unregisterModule('gtm')
    }
    this.destroyGtm()
  },
  computed: {
    triggeredEvent() {
      return this.$store.state.gtm?.triggeredEvent
    },
  },
  watch: {
    triggeredEvent(name) {
      if (name) {
        this.addGtmEvent(name)
        this.$store.commit('gtm/clearEvent')
      }
    },
  },
  methods: {
    createGtm(w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js',
      })
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''
      j.defer = true
      j.id = 'gtm-id'
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    },
    destroyGtm() {
      if (window.dataLayer) delete window.dataLayer
      const script = document.getElementById('gtm-id')
      if (script) {
        script.remove()
      }
    },
    addGtmEvent(name) {
      window.dataLayer?.push({event: name})
    }
  },
}
