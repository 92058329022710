<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill" :stroke="stroke">
    <line :stroke="stroke" stroke-linecap="round" x1="0.5" y1="1.24414" x2="23.5" y2="1.24414" />
    <line :stroke="stroke" stroke-linecap="round" x1="0.5" y1="12.2441" x2="23.5" y2="12.2441" />
    <line :stroke="stroke" stroke-linecap="round" x1="0.5" y1="23.2441" x2="23.5" y2="23.2441" />
  </svg>

</template>

<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>

<style scoped>

</style>