<template>
  <v-main class="render-preview">
    <v-container v-if="active">
      <v-row justify="center" align="center" class="mb-10">
        <v-btn :ripple="false" large depressed @click.prevent="prev" icon>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
        <h2 class="render-preview-title text-center">
          {{ active.name }} {{ active.os }} {{ active.browser }}
        </h2>
        <v-btn :ripple="false" large depressed @click.prevent="next" icon>
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          class="render-preview-close"
          icon
          :ripple="false"
          @click.prevent="back">
          <v-icon size="21">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-responsive max-width="600">
          <img
            class="render-preview-img"
            :src="active.full"
            width="600"
            alt="" />
        </v-responsive>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'

import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'LetterRenderPreview',
  computed: {
    ...mapGetters('share', {
      active: 'getActiveRender',
      renders: 'getRenders',
      nextRender: 'getNextRenderId',
      prevRender: 'getPrevRenderId',
    }),
    isRendersLoaded() {
      return this.$store.state.share.isRendersLoaded
    },
  },
  mounted() {
    this.run()
  },
  methods: {
    async run() {
      // оставил работу preview_id с localStorage, так как он задается в RenderCard, который используется в разных layout, а store динамический, работает только на LayoutShare
      const preview_id = localStorage.getItem('render_preview')
      if (!preview_id) return false
      this.$store.commit('share/setActiveRenderId', preview_id)

      const options = {
        type: 'letter',
        id: this.$route.params.id,
      }
      await this.$store.dispatch('share/getPublicRenders', options)
      if (!this.isRendersLoaded) {
        displayNotify(this.$t('letterShareRender.loading-error'), 'error')
      }
      if (!this.renders) return false
    },
    next() {
      const nextRender = this.nextRender
      if (nextRender) this.$store.commit('share/setActiveRenderId', nextRender)
    },
    prev() {
      const prevRender = this.prevRender
      if (prevRender) this.$store.commit('share/setActiveRenderId', prevRender)
    },
    back() {
      this.$router.push({
        name: 'share-render',
        params: { id: this.$route.params.id },
      })
    },
  },
}
</script>

<style lang="scss">
.render-preview-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 2px;
}

.render-preview-close {
  position: absolute;
  top: 26px;
  right: 36px;
}

.render-preview-img {
  display: block;
  margin: 0 0 20px;
  border-radius: 15px;
}
</style>
