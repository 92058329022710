import tinymce from 'tinymce'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/skins/ui/oxide/skin.css'

/* eslint-disable */
function tinyInit(elements, store) {
  if (elements) {
    elements.forEach((element) => {
      element.style.outline = 'none'

      let cacheEditor = tinymce.get(element.getAttribute('id'))
      if (cacheEditor) return

      tinymce.init({
        target: element,
        menubar: false,
        inline: true,
        theme: 'silver',
        skin: false,
        plugins: ['link', 'lists'],
        forced_root_block: false,
        toolbar: false,
        contextmenu_never_use_native: true,
        contextmenu: false,
        convert_fonts_to_spans: true,
        extended_valid_elements:
          'img[letteros-*|data-*|src|width|height|style|border|alt|align|title],' +
          'span[letteros-*|data-*|style|align|title]',
        init_instance_callback: function (editor) {
          if (!store) return

          editor.on('Change', function (e) {
            // console.log('Editor change', store)
            // const eventType = e?.originalEvent?.type
            // Обновляем стейт и при уходе на соседние роуты предлагаем сохранение
            store.commit('editor/toggleChanged', true)
          })
        },
      })
    })
  }
  return tinymce
}

function tinyDestroy(elements) {
  if (elements) {
    elements.forEach((element) => {
      let cacheEditor = tinymce.get(element.getAttribute('id'))
      if (cacheEditor) {
        cacheEditor.remove()
      }
    })
  }
  return tinymce
}

export { tinyInit, tinyDestroy }
