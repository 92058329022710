<template>
  <div class="option-color" @click.stop="">
    <label class="option__label" v-if="label">{{ label }}</label>
    <v-text-field
      :value="color"
      class="option__value"
      placeholder=""
      outlined
      dense
      @input="updateColor">
      <template v-slot:prepend-inner>
        <v-btn
          :ripple="false"
          :color="color"
          icon
          plain
          @click="showPicker = !showPicker">
          <IconCircle :stroke="color" />
        </v-btn>
      </template>
    </v-text-field>
    <ColorPicker
      class="color-picker__item"
      v-if="showPicker"
      v-click-outside="outsideColorPickerHandler"
      :label="$t('optionColor.background-color')"
      :color="color"
      :color-options="colorList"
      @close="showPicker = false"
      @update="updateColor($event.color)" />
  </div>
</template>

<script>
import ColorPicker from '@/components/tools/ColorPicker.vue'
import IconCircle from '@/components/icons/IconCircle.vue'
import colorFilters from '@/mixins/filters/color.mixin.js'

export default {
  name: 'OptionColor',
  components: {
    IconCircle,
    ColorPicker,
  },
  mixins: [colorFilters],
  props: {
    element: [HTMLElement, null],
    label: String,
    styleName: String,
    value: String,
  },
  data() {
    return {
      showPicker: false,
      color: '',
      colorList: [],
      default: {
        text: '#000000',
        background: '#FFFFFF',
        border: '#FFFFFF',
      },
    }
  },
  created() {
    this.run()
  },
  methods: {
    run() {
      switch (this.styleName) {
        case 'backgroundColor':
          this.backgroundColor()
          break
        case 'borderColor':
          this.borderColor()
          break
        case 'color':
          this.textColor()
          break
        case 'timer':
          this.timer()
          break
      }
    },
    backgroundColor() {
      const bgColor = this.element.style.backgroundColor
      this.color = bgColor
        ? this.$options.filters.rgbaToHex(bgColor)
        : this.default.background

      this.bgColorList('letteros-background-list')
    },
    borderColor() {
      const bdColor = this.element.style.borderColor
      this.color = bdColor
        ? this.$options.filters.rgbaToHex(bdColor)
        : this.default.border

      this.bgColorList('letteros-background-list')
    },
    textColor() {
      const textColor = this.element.style.color
      this.color = textColor
        ? this.$options.filters.rgbaToHex(textColor)
        : this.default.text

      this.bgColorList('letteros-color-list')
    },
    timer() {
      this.color = this.value
    },
    bgColorList(attrName) {
      const bgColorList = this.element.getAttribute(attrName)

      if (bgColorList) {
        const explodedList = bgColorList.split(';')
        if (explodedList.length) this.colorList = explodedList
      }
    },
    updateColor(color) {
      switch (this.styleName) {
        case 'backgroundColor':
          this.element.style.backgroundColor = color
          break
        case 'borderColor':
          this.element.style.borderColor = color
          break
        case 'color':
          this.element.style.color = color
          break
        case 'timer':
          // отработает внутри OptionTimer.vue
          this.$emit('change-color', color)
          break
      }

      this.color = color
    },
    // Закрытие колорпикера
    outsideColorPickerHandler(e) {
      if (!this.showPicker) return false

      const target = e.target.closest('.color-picker__item')

      if (!target) {
        this.showPicker = false
      }
    },
  },
}
</script>

<style lang="scss">
.option-color {
  .option__value {
    display: flex;
    flex-flow: row nowrap;
  }
  .color-picker__container .quad {
    width: 22px;
    height: 22px;
    border-radius: 8px;
    border: 1px solid black;
    margin-right: 5px;
    position: relative;
    top: -1px;
  }

  .color-picker {
    &__container {
      position: relative;
    }
  }
  .color-picker__item {
    position: fixed;
    top: 10px;
    left: 300px;
    transform: translate(0%, 0%);
    z-index: 15;
  }
}
.color-picker-saved-colors {
  max-width: 295px;
}
</style>
