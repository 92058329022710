<template>
  <v-card class="folder-create">
    <v-card-title class="text-h5 justify-space-between text-no-wrap">
      {{ title }}
      <v-btn icon :ripple="false" depressed class="" @click="$emit('close')">
        <IconClose />
      </v-btn>
    </v-card-title>
    <v-card-actions class="justify-end">
      <v-btn
        depressed
        outlined
        x-large
        class="mr-3 px-8"
        @click="$emit('close')"
        :ripple="false">
        {{ $t('documentFolderRemove.cancel') }}
      </v-btn>
      <v-btn
        depressed
        color="primary px-8"
        x-large
        @click="removeFromFolder"
        :ripple="false">
        {{ $t('documentFolderRemove.delete') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue'
import { displayNotify } from '@/utilities/helpers'
import project from '@/mixins/project.mixin'

export default {
  name: 'DocumentFolderRemove',
  mixins: [project],
  components: { IconClose },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    selectedId() {
      return this.$store.state.tableStore.currentEditId
    },
    title() {
      return this.pageType == 'letter'
        ? this.$t('documentFolderRemove.want-delete-letter')
        : this.$t('documentFolderRemove.want-delete-template')
    },
  },
  methods: {
    async removeFromFolder() {
      try {
        const result = await this.$store.dispatch(
          'foldersStore/deleteFromFolder',
          {
            type: this.pageType,
            id: this.$route.params.id,
            items: [this.selectedId],
          }
        )

        if (!result) return false

        await this.$store.dispatch('itemsStore/getData')
        await this.loadProject()
      } catch (e) {
        displayNotify(this.$t('documentFolderRemove.delete-error'))
      } finally {
        this.$emit('close')
      }

      if (this.pageType === 'letter') {
        displayNotify(
          this.$t('documentFolderRemove.letter-deleted-from-folder')
        )
      } else {
        displayNotify(
          this.$t('documentFolderRemove.template-deleted-from-folder')
        )
      }
    },
  },
}
</script>
