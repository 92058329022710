<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill">
    <circle cx="18" cy="13" r="6.5" :stroke="stroke"/>
    <path d="M4.5 30.5C6.54398 25.2431 11.3172 22 17.5 22C23.6828 22 29.456 25.2431 31.5 30.5" :stroke="stroke"/>
    <path d="M0.504734 15.7618C0.537918 7.29919 7.45463 0.5 15.9254 0.5H20.1199C28.5341 0.5 35.4347 7.21103 35.4913 15.6121C35.4969 16.4412 35.5 17.2433 35.5 18C35.5 18.7567 35.4969 19.5588 35.4913 20.3879C35.4347 28.789 28.5341 35.5 20.1198 35.5H15.9254C7.45463 35.5 0.537918 28.7008 0.504734 20.2382C0.501691 19.4624 0.5 18.7112 0.5 18C0.5 17.2888 0.501691 16.5376 0.504734 15.7618Z" :stroke="stroke"/>

  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    width: {type: [Number, String], default: 36},
    height: {type: [Number, String], default: 36},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 36 36'},
    fill: {type: String, default: 'none'}
  }
}
</script>