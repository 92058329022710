<template>
  <svg :width="size" :height="size" :viewBox="viewBox" :fill="fill" :stroke="stroke">
    <path :stroke="stroke" d="M1.01025 11.6911C1.01025 11.6911 5.0093 6.25562 12.0001 6.25562C18.991 6.25562 23 11.6911 23 11.6911" />
    <path :stroke="stroke" d="M12.0002 17.7412C14.2584 17.7412 16.089 15.9784 16.089 13.8038C16.089 11.6293 14.2584 9.86646 12.0002 9.86646C9.74199 9.86646 7.91138 11.6293 7.91138 13.8038C7.91138 15.9784 9.74199 17.7412 12.0002 17.7412Z" />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {type: String, default: 'box'},
    size: {type: [Number, String], default: 24},
    stroke: {type: String, default: 'currentColor'},
    viewBox: {type: String, default: '0 0 24 24'},
    fill: {type: String, default: 'none'}
  }
}
</script>