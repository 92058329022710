import axios from '../plugins/axios_and_toast.js'

async function createDevices() {
  const url = `/devices/create`
  const result = await axios.post(url)
  return result.data
}

async function getDevices() {
  const url = `/devices/get`
  const result = await axios.post(url)
  return result.data
}

async function toggleStatus(options) {
  const url = `/devices/toggle`
  const result = await axios.post(url, { ...options })
  return result.data
}

async function runProcess(devices, options) {
  const url = `/devices/run-process`
  const result = await axios.post(url, { devices, options })
  return result.data
}

async function getRenders(options) {
  const url = `/devices/get-renders`
  const result = await axios.post(url, { ...options })
  return result.data
}

async function getPublicRenders(options) {
  const url = `${process.env.VUE_APP_BACKEND_HOST}/public/share/render`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  })
  return response.json()
}

export {
  toggleStatus,
  getPublicRenders,
  runProcess,
  createDevices,
  getDevices,
  getRenders,
}
