<template>
  <header class="header-test">
    <div class="header-left"></div>
    <div class="header-right">
      <v-btn
        class="btn-small btn-black-border"
        outlined
        :ripple="false"
        exact
        :to="{ name: devicesRoute, params: { id: this.$route.params.id } }">
        {{ $t('headerLetterRenders.settings') }}
      </v-btn>
      <v-btn
        class="btn-small btn-black-border ml-3"
        @click="shareToggle"
        outlined
        :ripple="false">
        {{
          shareStatus
            ? $t('headerLetterRenders.unpublish')
            : $t('headerLetterRenders.publish')
        }}
      </v-btn>
      <v-btn
        v-if="shareStatus"
        class="btn-small btn-black-border ml-3"
        outlined
        target="_blank"
        :to="{ name: 'share-render', params: { id: this.$route.params.id } }"
        :ripple="false">
        <IconLink />
      </v-btn>
      <v-btn
        class="ml-3"
        depressed
        color="primary"
        link
        :ripple="false"
        :to="{ name: testRoute, params: { id: this.$route.params.id } }">
        {{ $t('headerLetterRenders.approve') }}
      </v-btn>
    </div>
  </header>
</template>

<script>
import { toggleStatus } from '@/utilities/acid.js'
import IconLink from '@/components/icons/IconLink'

export default {
  name: 'HeaderLetterRenders',
  components: {
    IconLink,
  },
  computed: {
    type() {
      const customs = ['letter-custom-renders', 'letter-custom-render-preview']

      return customs.includes(this.$route.name) ? 'custom' : 'default'
    },
    shareStatus() {
      return this.type == 'custom'
        ? this.$store.state.customStore.shared
        : this.$store.state.letter.letter.shared
    },
    testRoute() {
      return this.type == 'custom' ? 'letter-custom-test' : 'letter-test'
    },
    devicesRoute() {
      return this.type == 'custom' ? 'letter-custom-devices' : 'letter-devices'
    },
  },
  methods: {
    // Изменения статуса публикации рендеров
    async shareToggle() {
      const options = {
        status: !this.shareStatus,
        id: this.$route.params.id,
      }

      // [Переписать] после фикса на бэке
      const result = await toggleStatus(options)
      if (!result.state) return false

      if (this.type == 'custom') {
        this.$store.commit('customStore/updateSharedStatus', result.status)
      } else {
        this.$store.commit('letter/updateSharedStatus', result.status)
      }
    },
  },
}
</script>
