<template>
  <form @submit.prevent="sendEmail">
    <!-- Email -->
    <label class="label">
      {{ $t('signupForm.email') }}
    </label>
    <v-text-field
      placeholder="email@mail.com"
      outlined
      tabindex="1"
      :error="$v.email.$error"
      v-model="email" />

    <template v-if="$v.email.$error">
      <ValidateErrorHint :display="!$v.email.required" />
      <ValidateErrorHint
        field-name="email"
        error-type="email"
        :display="!$v.email.email" />
    </template>

    <!-- Password -->
    <label class="label">
      {{ $t('signupForm.password') }}
    </label>
    <v-text-field
      placeholder="Введите пароль"
      outlined
      tabindex="1"
      type="password"
      :error="$v.password.$error"
      v-model="password" />

    <template v-if="$v.password.$error">
      <ValidateErrorHint :display="!$v.password.required" />
      <ValidateErrorHint
        field-name="password"
        error-type="minlen-8"
        :display="!$v.password.minLength" />
    </template>

    <!-- Submit -->
    <p class="agree text-center gray--text mb-7">
      {{ $t('signupForm.by-registering-i-agree-to') }}
      <br />
      {{ $t('signupForm.with') }}&nbsp;
      <a
        class="gray--text"
        href="https://letteros.com/privacy-policy/"
        style="text-decoration: none; border-bottom: 1px solid #a9a9a9"
        target="_blank">
        {{ $t('signupForm.the-personal-data-processing-policy') }}
      </a>
    </p>
    <div class="text-center">
      <v-btn
        type="submit"
        class="pl-10 pr-10 white--text"
        :ripple="false"
        x-large
        depressed
        color="primary"
        tabindex="1">
        {{ $t('signupForm.continue') }}
      </v-btn>
    </div>
  </form>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators'

import ValidateErrorHint from '@/components/tools/ValidateErrorHint.vue'
import authMixin from '@/mixins/Auth/auth.mixin.js'
import gtmMixin from '@/mixins/gtm.mixin.js'

export default {
  name: 'SignupForm',
  mixins: [authMixin, gtmMixin],
  components: { ValidateErrorHint },
  computed: {
    email: {
      get() {
        return this.$store.state.auth.email
      },
      set(value) {
        this.$store.commit('auth/changeEmail', value)
      },
    },
    password: {
      get() {
        return this.$store.state.auth.password
      },
      set(value) {
        this.$store.commit('auth/changePassword', value)
      },
    },
  },
  methods: {
    async sendEmail() {
      this.$v.email.$touch()
      this.$v.password.$touch()
      if (this.$v.email.$invalid || this.$v.password.$invalid) return

      const response = await this.$store.dispatch('auth/signup')
      if (response) {
        this.$store.commit('auth/changeRegistrationStep', 2)
        this.$store.commit('gtm/setEvent', 'registration')
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
}
</script>
