/* eslint-disable */
import { createFolder } from '@/utilities/folder'
import { displayNotify } from '@/utilities/helpers'
import {
  updateLettersFolder,
  deleteLettersFromFolder,
} from '@/utilities/letter'
import {
  updateTemplatesFolder,
  deleteTemplatesFromFolder,
} from '@/utilities/template'

import i18n from '@/plugins/i18n'

export const moduleFolders = {
  state: () => ({
    // folders: [],
  }),

  mutations: {
    // addFolder(state, payload) {
    //   state.folders.push(payload)
    // },
  },

  actions: {
    // Добавление объектов в папку
    async updateFolder({ commit }, payload) {
      let result = null
      try {
        if (payload.type === 'letter' || payload.type == 'custom') {
          result = await updateLettersFolder(payload.items, payload.id)
        } else {
          result = await updateTemplatesFolder(payload.items, payload.id)
        }

        if (result.hasOwnProperty('name')) {
          if (payload.items.length === 1) {
            const itemTypeText =
              payload.type === 'letter' || payload.type == 'custom'
                ? i18n.t('documentFolderMove.email')
                : i18n.t('documentFolderMove.template')
            displayNotify(
              i18n.t('documentFolderMove.item-successfully-added', {
                item_type: itemTypeText,
                folder: result.name,
              })
            )
          } else {
            const itemTypeText =
              payload.type === 'letter'
                ? i18n.t('documentFolderMove.emails')
                : i18n.t('documentFolderMove.templates')
            displayNotify(
              i18n.t('documentFolderMove.items-successfully-added', {
                items_type: itemTypeText,
                folder: result.name,
              })
            )
          }
          return true
        } else {
          throw new Error('any error')
        }
      } catch (e) {
        return false
      }
    },
    // Удаление из папки
    async deleteFromFolder({ commit }, payload) {
      try {
        if (payload.type == 'letter') {
          await deleteLettersFromFolder(payload.items, payload.id)
        } else {
          await deleteTemplatesFromFolder(payload.items, payload.id)
        }

        return true
      } catch (e) {
        return false
      }
    },
    // Создание папки
    async createFolder({ commit }, payload) {
      try {
        const result = await createFolder(payload)
        if (result.status) {
          displayNotify(i18n.t('documentFolderMove.new-folder-created'))
          // commit('addFolder', {
          //   ...payload,
          //   id: result.id,
          //   count: 0,
          //   items: [],
          // })
          return result.id
        } else {
          throw new Error(result.error)
        }
      } catch (e) {
        displayNotify(i18n.t('documentFolderMove.folder-not-created'), 'error')
        return false
      }
    },
  },
}
