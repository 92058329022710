import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/lib/locale/ru'

import IconCheckboxOn from '../components/icons/IconCheckboxOn.vue'
import IconCheckboxOff from '../components/icons/IconCheckboxOff.vue'
import IconCheckboxIndeterminate from '../components/icons/IconCheckboxIndeterminate.vue'
import IconCheckboxSecondOn from '../components/icons/IconCheckboxSecondOn.vue'
import IconCheckboxSecondOff from '../components/icons/IconCheckboxSecondOff.vue'
import IconCheckboxThirdOn from '../components/icons/IconCheckboxThirdOn.vue'
import IconCheckboxStarOn from '../components/icons/IconCheckboxStarOn.vue'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    themes: {
      light: {
        primary: '#001dff',
        dark: '#151515',
        gray: '#a9a9a9',
        'gray-lightest': '#eaeaea',
        red: '#d42137',
        green: '#2d8962',
        yellow: '#e19b33',
      },
    },
  },
  icons: {
    values: {
      checkboxOn: {
        component: IconCheckboxOn,
      },
      checkboxOff: {
        component: IconCheckboxOff,
      },
      checkboxIndeterminate: {
        component: IconCheckboxIndeterminate,
      },
      checkboxSecondOn: {
        component: IconCheckboxSecondOn,
      },
      checkboxSecondOff: {
        component: IconCheckboxSecondOff,
      },
      checkboxThirdOn: {
        component: IconCheckboxThirdOn,
      },
      checkboxStardOn: {
        component: IconCheckboxStarOn,
      },
    },
  },
})
