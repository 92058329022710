<template>
  <div class="option-link">
    <label class="option__label">
      {{ $t('optionSelectorParse.download-by-selector') }}
    </label>
    <v-text-field
      v-model="data.url"
      placeholder="https://google.com"
      outlined
      dense />
    <div class="option-element">
      <v-btn
        depressed
        block
        outlined
        class="btn-small btn-black-border"
        :ripple="false"
        @click="download">
        {{ $t('optionSelectorParse.download') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { parseProduct } from '@/utilities/letter'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'OptionSelectorParse',
  props: {
    element: [HTMLElement, null],
  },
  data() {
    return {
      data: {
        url: '',
        type: 'page',
        text: [],
        image: [],
        options: {
          letter: this.$route.params.id,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const links = this.element.querySelectorAll('[letteros-page-url]')

      if (links.length) {
        links.forEach((link) => {
          const path = link.getAttribute('href')
          if (path != '' && path != '#') {
            this.data.url = path
            return
          }
        })
      }

      const textBlocks = this.element.querySelectorAll('[letteros-page-text]')
      const imageBlocks = this.element.querySelectorAll('[letteros-page-src]')

      textBlocks.forEach((textBlock) => {
        const id = uuidv4()
        textBlock.setAttribute('data-page-text-id', id)
        this.data.text.push({
          id: id,
          value: textBlock.getAttribute('letteros-page-text'),
        })
      })

      imageBlocks.forEach((imageBlock) => {
        const id = uuidv4()
        imageBlock.setAttribute('data-page-image-id', id)
        this.data.image.push({
          id: id,
          value: imageBlock.getAttribute('letteros-page-src'),
        })
      })
    },
    async download() {
      this.$loader('show')
      let result = null

      try {
        result = await parseProduct(this.data)
      } catch (e) {
        this.$loader('hide')
        displayNotify(this.$t('optionSelectorParse.parse-error'), 'error')
        return
      }

      if (!result) {
        this.$loader('hide')
        displayNotify(this.$t('optionSelectorParse.parse-error'), 'error')
        return
      }

      this.insert(result)

      displayNotify(this.$t('optionSelectorParse.parse-success'))
      this.$loader('hide')
    },
    insert(result) {
      const links = this.element.querySelectorAll('[letteros-page-url]')

      if (links.length) {
        links.forEach((link) => {
          link.setAttribute('href', this.data.url)
        })
      }

      result.forEach((item) => {
        const element = this.element.querySelector(
          '[data-page-' + item.type + '-id="' + item.id + '"]'
        )
        if (!element) return

        if (item.type === 'text') {
          element.innerHTML = item.value
          return
        }

        if (element.tagName === 'IMG' || element.tagName === 'AMP_IMG') {
          element.setAttribute('src', item.value)
        } else {
          element.style['background-image'] = 'url(' + item.value + ')'
          element.setAttribute('background', item.value)
        }
      })
    },
  },
}
</script>
