<template>
  <form ref="form" @submit.prevent="completeRegistration">
    <!-- NAME -->
    <LabelForRequiredField
      :content="$t('signupCompleteForm.first-name-last-name')" />
    <v-text-field
      v-model="nameUnion"
      :placeholder="$t('signupCompleteForm.first-name-last-name')"
      outlined
      :error="$v.userForm.firstname.$error || $v.userForm.lastname.$error" />
    <template
      v-if="$v.userForm.firstname.$error || $v.userForm.lastname.$error">
      <ValidateErrorHint
        :display="
          !$v.userForm.firstname.required || !$v.userForm.lastname.required
        " />
    </template>

    <!-- PHONE -->
    <LabelForRequiredField :content="$t('signupCompleteForm.phone')" />
    <v-text-field
      v-model="userForm.phone"
      v-mask="'+7 (###) ### ## ##'"
      placeholder="+7 (999) 999 99 99"
      outlined
      :error="$v.userForm.phone.$error" />
    <template v-if="$v.userForm.phone.$error">
      <ValidateErrorHint :display="!$v.userForm.phone.required" />
    </template>

    <!-- COMPANY NAME -->
    <LabelForRequiredField :content="$t('signupCompleteForm.company-name')" />
    <v-text-field
      v-model="accountForm.projectName"
      :placeholder="$t('signupCompleteForm.spacex')"
      outlined
      :error="$v.accountForm.projectName.$error" />
    <template v-if="$v.accountForm.projectName.$error">
      <ValidateErrorHint :display="!$v.accountForm.projectName.required" />
    </template>

    <!-- COMPANY URL -->
    <label class="label">{{ $t('signupCompleteForm.website-address') }}</label>
    <v-text-field
      v-model="accountForm.projectUrl"
      placeholder="https://site.com"
      outlined />

    <!-- CHECKBOX LIST -->
    <div class="mb-5">
      <v-checkbox
        v-model="promoShow"
        :label="$t('signupCompleteForm.there-is-a-promo-code')" />
      <template v-if="promoShow">
        <label class="label">
          {{ $t('signupCompleteForm.specify-the-promo-code') }}
        </label>
        <v-text-field
          v-model="accountForm.promoCode"
          placeholder="PROMOCODE"
          outlined />
      </template>

      <v-checkbox
        v-model="templateShow"
        :label="$t('signupCompleteForm.there-is-already-a-template')" />
      <template v-if="templateShow">
        <p class="mt-n1 mb-2">
          {{ $t('signupCompleteForm.let-s-transfer-your-template') }}
        </p>
        <label class="label">
          {{ $t('signupCompleteForm.link-to-the-layout') }}
          ({{ $t('signupCompleteForm.for-layouts-from-figma-tilda-stripo') }})
        </label>
        <v-text-field
          v-model="accountForm.templateUrl"
          :placeholder="$t('signupCompleteForm.link-to-the-layout')"
          outlined />
        <p class="mb-5">
          {{ $t('signupCompleteForm.upload-as-a-file') }}
        </p>
        <FileUploader
          :accept="'.zip,.rar,.7zip'"
          :immediately="false"
          @file-loaded="templateFile = $event"
          style="margin-bottom: 12px" />
      </template>

      <v-checkbox
        v-model="userForm.emailSubscriptionAgree"
        :label="
          $t('signupCompleteForm.i-agree-to-receive-notifications-by-email')
        " />
    </div>

    <!-- SUBMIT -->
    <div class="text-center">
      <v-btn
        type="submit"
        class="pl-10 pr-10 white--text"
        :ripple="false"
        x-large
        depressed
        color="primary">
        {{ $t('signupCompleteForm.complete-registration') }}
      </v-btn>
    </div>
  </form>
</template>

<script>
/* eslint-disable */
import { required } from 'vuelidate/lib/validators'

import FileUploader from '@/components/FileUploader.vue'
import ValidateErrorHint from '@/components/tools/ValidateErrorHint.vue'
import LabelForRequiredField from '@/components/tools/LabelForRequiredField.vue'

import { notifyRegistration } from '@/utilities/user.js'
import gtmMixin from '@/mixins/gtm.mixin.js'
import authMixin from '@/mixins/Auth/auth.mixin.js'

export default {
  name: 'SignupCompleteForm',
  components: {
    LabelForRequiredField,
    ValidateErrorHint,
    FileUploader,
  },
  mixins: [gtmMixin, authMixin],
  data() {
    return {
      userForm: {
        verificationCode: '',
        firstname: '',
        lastname: '',
        phone: '',
        emailSubscriptionAgree: false,
      },
      accountForm: {
        projectName: '',
        projectUrl: '',
        promoCode: '',
        templateUrl: '',
      },
      promoShow: false,
      templateShow: false,
      templateFile: null,
      fileOutlook: true,
      linkOutlook: true,
      codeOutlook: true,
      templateCode: null,
    }
  },
  computed: {
    fileName() {
      return this.templateFile ? this.templateFile.name : ''
    },
    nameUnion: {
      get() {
        return ''
      },
      set(value) {
        const strArr = value.split(' ')
        if (strArr.length === 1) {
          this.userForm.firstname = value
        } else if (strArr.length > 1) {
          this.userForm.firstname = strArr[0]
          this.userForm.lastname = strArr[1]
        }
      },
    },
  },
  created() {
    this.userForm.verificationCode = this.$route.query.token
  },
  methods: {
    async completeRegistration() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$loader('show')

      // Активация нового юзера
      const response = await this.activateUser()

      if (!response) {
        this.$loader('hide')
        return
      }

      // запись в gtm
      this.$store.commit('gtm/setEvent', 'fill_form')

      // авторизация
      this.setAuthData(response)

      // Уведомление в телегу
      await this.telegramNotify(response.data.id)

      // редирект на проекты
      this.$store.commit('userStore/setUser', response.data)
      this.accountHandler(response.data)
      this.$loader('hide')
    },
    // Активация нового юзера
    async activateUser() {
      const formData = { ...this.accountForm, ...this.userForm }
      const response = await this.$store.dispatch('auth/activate', {
        token: this.userForm.verificationCode,
        data: formData,
      })
      return response
    },
    // Уведомление в телегу
    async telegramNotify(id) {
      const formData = new FormData()
      formData.append('templateFile', this.templateFile ?? '')
      formData.append('templateUrl', this.accountForm.templateUrl)
      formData.append('promo', this.accountForm.promoCode)
      formData.append('email', this.email)
      formData.append('id', id)
      formData.append('phone', this.userForm.phone)
      formData.append('firstname', this.userForm.firstname)
      formData.append('lastname', this.userForm.lastname)
      formData.append('projectUrl', this.accountForm.projectUrl)
      formData.append('projectName', this.accountForm.projectName)

      try {
        return await notifyRegistration(formData)
      } catch (e) {
        return false
      }
    },
  },
  validations: {
    userForm: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      phone: {
        required,
      },
      verificationCode: {
        required,
      },
    },
    accountForm: {
      projectName: {
        required,
      },
    },
  },
}
</script>
