import editorStore from '@/store/dynamic/editor.js'

export default {
  data() {
    return {
      unwatch: null,
      unsubscribe: null,
    }
  },
  beforeCreate() {
    // console.log('created editor')
    if (!this.$store.hasModule('editor')) {
      this.$store.registerModule('editor', editorStore)
    }
  },
  mounted() {
    // Для dev, при hotreload отрабатывает хук created+destroyed
    if (!this.$store.hasModule('editor')) {
      this.$store.registerModule('editor', editorStore)
    }

    // Отслеживаем клонирование
    this.unwatch = this.$store.watch(
      (state) => state.editor.moduleRefresh,
      (newUuid) => {
        if (newUuid !== null) {
          this.$nextTick(function () {
            // Сортируем канвас, после добавления нового модуля
            // Если это не сделать модуль добавиться в том порядке, в котором идет в сторе
            this.$store.commit('letter/sortModules')
            // Запускаем текстовый редактор для нового модуля
            this.initEditorOnce(newUuid)
            // Очищаем триггер прослушки
            this.$store.commit('editor/toggleModuleRefresh', null)
          })
        }
      }
    )

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'letter/deleteModule') {
        this.$nextTick(function () {
          this.$store.commit('letter/updateModulesPosition')
        })
      }
    })
  },
  beforeDestroy() {
    // console.log('destroyed editor')
    if (this.$store.hasModule('editor')) {
      this.$store.unregisterModule('editor')
    }
    this.unwatch()
    this.unsubscribe()
  },
}
