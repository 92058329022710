<template>
  <v-row class="table-index-header" justify="center">
    <v-col lg="6" md="8" sm="8" cols="12">
      <!-- Заголовок и переключение вида -->
      <ControlHeader />

      <!-- Поиск -->
      <ControlSearch />

      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-row justify="space-between" v-if="pageType !== 'analytics'">
            <!-- Сортировка -->
            <ControlSort />

            <!-- Действия с выбранными объектами -->
            <v-col cols="12" sm="4">
              <v-menu offset-y nudge-bottom="4" :close-on-content-click="false">
                <template #activator="{ on }">
                  <v-btn
                    class="menu-btn-activator col-12 px-4"
                    :ripple="false"
                    outlined
                    x-large
                    v-on="on">
                    <span class="flex-grow-1 text-left font-weight-regular">
                      {{ $t('tableHeader.actions') }}
                    </span>
                    <v-icon right size="25">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <ControlActions />
              </v-menu>
            </v-col>

            <!-- Кнопка "Создать шаблон" -->
            <v-col
              v-if="showTemplateCreateButton"
              cols="12"
              sm="4"
              class="d-flex justify-end">
              <v-btn
                class="col-12 white--text"
                :ripple="false"
                x-large
                depressed
                color="primary"
                @click="createHandler">
                {{ documentCreateBtnName }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { loadTemplates } from '@/utilities/template'
import { createLetter } from '@/utilities/letter'

import ControlHeader from '@/components/home/panel/ControlHeader.vue'
import ControlSearch from '@/components/home/panel/ControlSearch.vue'
import ControlSort from '@/components/home/panel/ControlSort.vue'
import ControlActions from '@/components/home/panel/ControlActions.vue'

export default {
  name: 'ControlPanel',
  components: {
    ControlHeader,
    ControlSearch,
    ControlSort,
    ControlActions,
  },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    showTemplateCreateButton() {
      if (this.pageType === 'letter') return true
      if (this.$store.state.project.full.tarif?.name === 'free') return false
      return this.$store.state.project.full.tarif?.name !== 'start'
    },
    documentCreateBtnName() {
      return this.pageType === 'template'
        ? this.$t('tableHeader.create-a-template')
        : this.$t('tableHeader.create-a-letter')
    },
  },
  methods: {
    async createHandler() {
      if (this.pageType === 'letter') {
        await this.createNewLetter()
      } else if (this.pageType === 'letter-custom') {
        this.$router.push({ name: 'letter-custom-editor' })
      } else {
        this.$router.push({ name: 'template-create' })
      }
    },
    async createNewLetter() {
      if (this.$store.state.project.full.templatesTotal === 1) {
        try {
          // Если всего один шаблон, то создаем письмо сразу без редиректа
          const templates = await loadTemplates()
          const letterData = {
            name: '',
            subject: '',
            preheader: '',
            template_id: templates.items[0].id,
          }
          const result = await createLetter(letterData)
          await this.$router.push({
            name: 'letter-editor',
            params: { id: result.id },
          })
        } catch (e) {
          console.log('createNewLetter', e)
        }
      } else {
        // Редирект на страницу создания письма по шаблону
        await this.$router.push({ name: 'letter-create' })
      }
    },
  },
}
</script>

<style lang="scss">
.table-index-header {
  .select-text {
    font-size: 18px;
  }
}
</style>
