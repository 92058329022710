<template>
  <div>
    <v-menu
      content-class="dropdown-menu"
      nudge-bottom="4"
      offset-y
      min-width="245"
      left>
      <template v-if="lightMode" #activator="{ on, attrs }">
        <v-btn
          class="list-card-dropdown dropdown-btn d-flex btn-black-border"
          :ripple="false"
          x-large
          outlined
          depressed
          v-bind="attrs"
          v-on="on">
          <v-icon size="23">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <template v-else #activator="{ on, attrs }">
        <v-btn
          class="list-card-dropdown dropdown-btn d-flex"
          :ripple="false"
          x-large
          color="primary"
          depressed
          v-bind="attrs"
          v-on="on">
          <v-icon size="23">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!-- Ссылка на письмо/шаблон -->
        <v-list-item
          class="dropdown-menu-item"
          :ripple="false"
          link
          :to="{
            name: pageType === 'template' ? 'template-editor' : 'letter-editor',
            params: { id },
          }">
          <IconEdit />
          {{ $t('tableElementMenu.edit') }}
        </v-list-item>

        <template v-if="pageType === 'template'">
          <!-- Обновление шаблона -->
          <v-list-item
            :ripple="false"
            link
            class="dropdown-menu-item"
            :to="{ name: 'template-load', params: { templateId: id } }">
            <IconRefresh />
            {{ $t('tableElementMenu.refresh-template') }}
          </v-list-item>

          <!-- Скачивание шаблона -->
          <v-list-item
            :ripple="false"
            link
            class="dropdown-menu-item"
            @click="downloadTemplate">
            <IconDownload />
            {{ $t('tableElementMenu.download') }}
          </v-list-item>
        </template>

        <!-- Просмотр веб-версии -->
        <template>
          <v-list-item
            v-if="pageType !== 'template'"
            class="dropdown-menu-item dropdown-menu-item-view"
            :ripple="false"
            link
            target="_blank"
            :to="{ name: 'letter-preview', params: { id } }">
            <IconView />
            {{ $t('tableElementMenu.view-letter') }}
          </v-list-item>

          <v-list-item
            v-else
            class="dropdown-menu-item dropdown-menu-item-view"
            :ripple="false"
            link
            target="_blank"
            :href="previewUrl">
            <IconView />
            {{ $t('tableElementMenu.view-template') }}
          </v-list-item>
        </template>

        <!-- Создание копии -->
        <v-list-item
          class="dropdown-menu-item"
          link
          :ripple="false"
          @click="clone">
          <IconCopy />
          {{ $t('tableElementMenu.copy') }}
        </v-list-item>

        <!-- Перевод письма -->
        <v-list-item
          v-if="pageType !== 'template'"
          class="dropdown-menu-item"
          link
          :ripple="false"
          @click="showPopup('translate')">
          <IconEdit />
          {{ $t('tableElementMenu.translate-a-letter') }}
        </v-list-item>

        <!-- Перенос в другую папки и удаление из папки | ВНУТРИ ПАПКИ -->
        <template v-if="$route.name.includes('directory')">
          <v-list-item @click="showPopup('folderMove')" :ripple="false">
            <IconFolder />
            {{ $t('tableElementMenu.move-to-folder') }}
          </v-list-item>
          <v-list-item
            class="dropdown-menu-item"
            :ripple="false"
            @click="showPopup('folderRemove')">
            <IconFolder />
            {{ $t('tableElementMenu.remove-from-folder') }}
          </v-list-item>
        </template>

        <!-- Добавление в папку | ВНЕ ПАПКИ -->
        <template v-else>
          <v-list-item :ripple="false" @click="showPopup('folderMove')">
            <IconFolder />
            {{ $t('tableElementMenu.add-to-folder') }}
          </v-list-item>
        </template>

        <!-- Переименование -->
        <v-list-item
          class="dropdown-menu-item"
          :ripple="false"
          link
          @click="showPopup('rename')">
          <IconEdit />
          {{ $t('tableElementMenu.rename') }}
        </v-list-item>

        <!-- Удаление -->
        <v-list-item
          class="dropdown-menu-item"
          :ripple="false"
          link
          @click="showPopup('delete')">
          <IconDelete />
          {{ $t('tableElementMenu.remove') }}
        </v-list-item>

        <!-- Перенос в другой аккаунт -->
        <v-list-item
          class="dropdown-menu-item"
          :ripple="false"
          link
          @click="showPopup('share')">
          <IconCopy />
          Перенести в другой аккаунт
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Модалки -->
    <v-dialog v-model="documentFolderMoveShow" max-width="600">
      <DocumentFolderMove :items-to-move-ids="[id]" @close="closePopup" />
    </v-dialog>
    <v-dialog v-model="documentFolderRemoveShow" max-width="600">
      <DocumentFolderRemove @close="closePopup" />
    </v-dialog>
    <v-dialog v-model="documentDeleteShow" max-width="600">
      <DocumentDelete @close="closePopup" />
    </v-dialog>
    <v-dialog v-model="documentTranslateShow" max-width="600">
      <DocumentTranslate @close="closePopup" />
    </v-dialog>
    <v-dialog v-model="documentShareShow" max-width="600">
      <DocumentShare @close="closePopup" />
    </v-dialog>
  </div>
</template>

<script>
import IconDelete from '@/components/icons/IconDelete.vue'
import IconFolder from '@/components/icons/IconFolder.vue'
import IconCopy from '@/components/icons/IconCopy.vue'
import IconView from '@/components/icons/IconView.vue'
import IconEdit from '@/components/icons/IconEdit.vue'
import IconRefresh from '@/components/icons/IconRefresh.vue'
import IconDownload from '@/components/icons/IconDownload.vue'

import DocumentDelete from '@/components/home/document/DocumentDelete.vue'
import DocumentFolderMove from '@/components/home/document/DocumentFolderMove.vue'
import DocumentFolderRemove from '@/components/home/document/DocumentFolderRemove.vue'
import DocumentShare from '@/components/home/document/DocumentShare.vue'
import DocumentTranslate from '@/components/home/document/DocumentTranslate.vue'

import { displayNotify } from '@/utilities/helpers'
import { checkIfLettersLimitReached } from '@/utilities/project'
import project from '@/mixins/project.mixin'

export default {
  name: 'ElementMenuList',
  components: {
    IconDownload,
    IconRefresh,
    IconDelete,
    IconFolder,
    IconCopy,
    IconView,
    IconEdit,
    DocumentFolderMove,
    DocumentFolderRemove,
    DocumentTranslate,
    DocumentShare,
    DocumentDelete,
  },
  mixins: [project],
  data() {
    return {
      documentFolderMoveShow: false,
      documentFolderRemoveShow: false,
      documentDeleteShow: false,
      documentTranslateShow: false,
      documentShareShow: false,
    }
  },
  props: {
    lightMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pageType() {
      return this.$store.state.tableStore.pageType
    },
    previewUrl() {
      return `${window.appBaseUrl ?? process.env.VUE_APP_BACKEND_HOST}/public/${
        this.pageType
      }/preview?id=${this.id}`
    },
  },
  methods: {
    showPopup(mode) {
      switch (mode) {
        case 'translate':
          this.documentTranslateShow = true
          this.$store.commit('tableStore/changeEditId', this.id)
          break
        case 'folderMove':
          this.documentFolderMoveShow = true
          break
        case 'folderRemove':
          this.documentFolderRemoveShow = true
          this.$store.commit('tableStore/changeEditId', this.id)
          break
        case 'rename':
          this.$store.commit('tableStore/changeEditMode', mode)
          this.$store.commit('tableStore/changeEditId', this.id)
          break
        case 'delete':
          this.documentDeleteShow = true
          this.$store.commit('tableStore/changeEditId', this.id)
          break
        case 'share':
          this.documentShareShow = true
          this.$store.commit('tableStore/changeEditId', this.id)
          break
      }
    },
    closePopup() {
      this.documentFolderMoveShow = false
      this.documentFolderRemoveShow = false
      this.documentDeleteShow = false
      this.documentTranslateShow = false
      this.documentShareShow = false
    },
    downloadTemplate() {
      this.$loader('show')

      try {
        this.$store.dispatch('itemsStore/downloadTemplate', this.id)
        displayNotify(this.$t('tableElementMenuList.download-success'))
      } catch (e) {
        displayNotify(this.$t('tableElementMenuList.download-error'), 'error')
      }

      this.$loader('hide')
    },
    async clone() {
      if (checkIfLettersLimitReached(this.$store.state.project.full)) {
        const answer = await this.$parent.$refs.paymentForExtraLetter.open()
        if (answer === false) return
      }

      await this.$store.dispatch('itemsStore/clone', this.id)
      // [Переписать] в случае ошибки выводим уведомление
      await this.loadProject()
    },
  },
}
</script>
