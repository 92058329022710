import axios from '../plugins/axios_and_toast.js'
import { sleep } from './helpers'
import router from '../router'

function setAuthData(responseData) {
  localStorage.setItem('refresh', responseData.headers.refresh)
  localStorage.setItem(
    'jwt_key',
    'Bearer ' + responseData.headers.authorization
  )
  localStorage.setItem('refreshTokenInProcess', 'false')
  // todo put in jwt
  localStorage.setItem('user_id', responseData.data.id)
}

async function viewUser(id) {
  const result = await axios.get(
    `/user/view?expand=profile,accounts,adminOrOwnerAccounts&id=${id}`
  )
  return result.data
}

async function getJwtToken() {
  if (
    localStorage.getItem('refreshTokenInProcess') === 'false' ||
    localStorage.getItem('refreshTokenInProcess') === null
  ) {
    return localStorage.getItem('jwt_key')
  }

  const waitResult = waitTillTokenRefreshingDone()
  if (waitResult) return localStorage.getItem('jwt_key')

  await router.push('/login')
}

async function waitTillTokenRefreshingDone(timeout = 2000) {
  if (timeout < 100) {
    localStorage.setItem('refreshTokenInProcess', 'false')
    return false
  }
  if (localStorage.getItem('refreshTokenInProcess') === 'false') {
    await sleep(100)
    return true
  }
  await waitTillTokenRefreshingDone(timeout - 100)
}

async function refreshToken() {
  localStorage.setItem('refreshTokenInProcess', 'true')
  const result = await axios.get(`/user/refresh-token`)
  setAuthData(result)
  return result.data
}

async function tokenAuth(data, uri) {
  const result = await axios.put(`${uri}`, { params: { ...data } })
  return result.data
}

async function updateUser(id, data) {
  const result = await axios.put(`/user/update?id=${id}`, data)
  return result.data
}

async function notifyRegistration(data) {
  const result = await axios.post(`/user/telegram-notify`, data)
  return result.data
}

async function updateUserProfile(id, data) {
  const result = await axios.put(`/user/update-profile?id=${id}`, data)
  return result.data
}

async function changePassword(id, data) {
  const result = await axios.put(`/user/change-password?id=${id}`, data)
  setAuthData(result)
  return result.data
}

export {
  viewUser,
  updateUser,
  updateUserProfile,
  changePassword,
  tokenAuth,
  notifyRegistration,
  refreshToken,
  getJwtToken,
}
