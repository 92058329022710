<template>
  <v-app>
    <router-view name="header" class="header">
      <template #project-menu>
        <HeaderMenuProject />
      </template>
      <template #user-menu>
        <HeaderMenuUser />
      </template>
      <template #letter-menu>
        <HeaderMenuLetterCustom />
      </template>
    </router-view>
    <router-view name="sidebar" class="sidebar" />
    <router-view class="page" />
    <PromiseDialogWrapper ref="leaveDialog">
      <PromiseDialogFrame />
    </PromiseDialogWrapper>
  </v-app>
</template>

<script>
import HeaderMenuProject from '@/components/header/HeaderMenuProject.vue'
import HeaderMenuUser from '@/components/header/HeaderMenuUser.vue'
import HeaderMenuLetterCustom from '@/components/header/HeaderMenuLetterCustom.vue'

import PromiseDialogFrame from '@/components/PromiseDialogFrame.vue'
import PromiseDialogWrapper from '@/components/PromiseDialogWrapper.vue'

import dynamicStoreMixin from '@/mixins/LetterCustom/dynamicStore.mixin.js'
import { displayNotify } from '@/utilities/helpers'

export default {
  name: 'LayoutCustom',
  mixins: [dynamicStoreMixin],
  components: {
    HeaderMenuProject,
    HeaderMenuUser,
    PromiseDialogFrame,
    PromiseDialogWrapper,
    HeaderMenuLetterCustom,
  },
  computed: {
    isLetterSave() {
      return this.$store.state.customStore.isLetterSave
    },
  },
  // Отработает при смене layout-а
  async beforeRouteLeave(to, from, next) {
    // Стейт Автосохранения
    // clearTimeout(this.autoSaveInterval)

    const routeWithSave = ['letter-custom-editor', 'letter-custom-settings']
    // Открываем поп-ап с сохранением только на указанных в routeWithSave маршрутах
    if (routeWithSave.includes(from.name)) {
      const result = await this.$refs.leaveDialog.open()
      if (result) {
        await this.$store.dispatch('customStore/saveLetter')
        if (this.isLetterSave) {
          displayNotify(this.$t('letterLayout.letter-saved'))
        } else {
          displayNotify(this.$t('letterLayout.letter-not-saved'), 'error')
        }
      }
    }
    next()
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0;
}
</style>
