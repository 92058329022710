<template>
  <v-app>
    <v-main class="page-error">
      <v-container fill-height>
        <v-row justify="center">
          <v-col
            lg="6"
            md="8"
            sm="8"
            cols="12"
            class="d-flex flex-column align-center">
            <div class="error-box">
              <img src="/img/404.png" alt="404 page" width="218" height="186" />
              <h2>Мы не нашли такую страницу</h2>
              <p>
                Убедитесь, что вы&nbsp;правильно ввели адрес. Вы&nbsp;можете
                вернуться на&nbsp;главную страницу или
                <a href="https://t.me/letterostemp" target="_blank">
                  написать в&nbsp;поддержку
                </a>
              </p>
              <v-btn class="home-link" to="/" :ripple="false">
                Вернуться на главную
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Error404',
  data() {
    return {}
  },
  created() {},
  methods: {
    loadJournal() {},
  },
}
</script>

<style lang="scss">
.page-error {
  line-height: 1.4;
  text-align: center;

  .error-box {
    max-width: 630px;
    margin: 0 auto;
  }

  .post {
    border: 1px solid #a9a9a9;
    border-radius: 15px;

    .post-info,
    .post-footer {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  h2 {
    font-size: 64px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 32px;
    font-size: 24px;

    a {
      color: #001dff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .home-link {
    height: 56px !important;
    border-radius: 15px;
    font-size: 18px;
    background: #001dff !important;
    color: #fff;
    padding-left: 30px !important;
    padding-right: 30px !important;

    &:hover {
      background: #0015bc;
    }
  }
}
</style>
